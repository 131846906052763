<template>
  <div class="col-12 col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter" style="min-height: 400px;">
    <div lass="flex-box-wrap">
        <div class="flex-internal">
            <img src="/img/sad.svg" style="width: 200px"/>
            <p>Sorry, nothing was found...</p>
        </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "NothingFound",
  props: [],
  methods: {},
};
</script>

<style scoped></style>
