<template>
  <div class="mobile-top">
    <!--
    <home-tour v-if="$store.state.tour && !this.$store.getters.hideOrders" />
    <home-tour-no-orders
      v-else-if="$store.state.tour && this.$store.getters.hideOrders"
    />
     :style="{
        backgroundImage: 'url(' + require('@/assets/img/headerbg2.svg') + ')',
      }"
    -->
     <!-- ################### START HERO ################### -->
    <section
      id="v-step-6"
      class="py-5"
      style="
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        position: relative;
        background-image: url('/img/headerbg2.svg');
      "
     
    >
      <div class="container">

        <!-- ################### SEARCH BAR ################### -->
        <div
          v-if="!this.$store.getters.hideOrders"
          class="new-search mb20 bs-shadow"
        >
          <div class="search-wrap">
            <div class="input-group">
              <input
                v-model="searchValue"
                @keyup.enter.prevent="searchOrder"
                type="text"
                class="form-control"
                aria-label="Amount (to the nearest dollar)"
                style="
                  padding: 12px;
                  background: rgb(232 238 241);
                  border: none;
                "
                placeholder="Search listings by order number, PO number, or order name..."
              />
              <div class="input-group-append">
                <span
                  @click="clearSearch"
                  class="input-group-text new-search-btn2"
                >
                  <span class="material-icons">find_replace</span>
                  {{ " " }} Clear
                </span>

                <span
                  @click="searchOrder"
                  class="input-group-text new-search-btn1"
                >
                  <span class="material-icons">search</span>
                  {{ " " }}Search
                </span>
              </div>

            </div><!-- input group -->
          </div><!-- search wrap -->
        </div> <!-- conditional search bar -->
         <!-- ################### END SEARCH BAR ################### -->


        <!-- ################### REPORTS CARDS ################### -->
        <!--
        <div v-if="reportLoading && this.$store.getters.isReports" style="
        height: 144.79px; 
        display: flex;
        align-items: center;
        justify-content: center;">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#6c757d'"
            />
            <p style="color: #6c757d">Loading......</p>
          </div>
        </div>
        </div>
         --> 
      <!--
          <HomeReports
          v-if="reportLoading && this.$store.getters.isReports"
        /> 
        -->
        <HomeReports
          v-if="!reportLoading && this.$store.getters.isReports && homeReports"
          :homeReports="homeReports.homeReports"
          :loading="reportLoading"
        />      
       
        <!-- ################### END REPORTS CARDS ################### -->


        <!-- ################### ORDER, ACK and INVOICE CARDS ################### -->
        <div
          v-if="
            this.$store.getters.isACKSnINVS &&
            !this.$store.getters.hideOrders
          "
          class="row clearfix"
        >
          <div class="col-md-12 col-lg-4">
            <router-link
              to="/listings"
              style="color: #fff; text-decoration: none; flex: 1 1 100%"
            >
              <div
                class="card dashboard-card2 bs-shadow mb20"
                style="width: 100%"
              >
                <div class="body p-4">
                  <div class="row clearfix">
                    <div class="col-auto">
                      <div class="stamp stamp-md teal-bg">
                        <img src="@/assets/img/funds-box-fill-white.svg" />
                      </div>
                    </div>
                    <div class="col text-right">
                      <div class="small text-muted">Orders</div>
                      <div
                        v-if="homeAlerts"
                        class="h3 m-0"
                        style="color: #575757"
                      >
                        {{ homeAlerts.totals.orders }}
                      </div>
                      <div
                        v-else-if="listings && listings.length == 0"
                        class="h3 m-0"
                        style="color: #575757"
                      >
                        {{ listings.length }}
                      </div>
                      <div v-else class="h3 m-0" style="color: #575757">
                        ....
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-12 col-lg-4">


            <router-link
              to="/acknowledgments"
              style="color: #fff; text-decoration: none; flex: 1 1 100%"
            >
              <div class="card dashboard-card2 bs-shadow mb20">
                <div class="body p-4">
                  <div class="row clearfix">
                    <div class="col-auto">
                      <div class="stamp stamp-md orange-bg">
                        <img src="/img/draft-fill-white.svg" />
                        <!--  <i class="fas fa-file"></i> -->
                      </div>
                    </div>
                    <div class="col text-right">
                      <div class="small text-muted">Acknowledgments</div>
                      <div
                        v-if="homeAlerts"
                        class="h3 m-0"
                        style="color: #575757"
                      >
                        {{ homeAlerts.totals.acknowledgments }}
                      </div>
                      <div v-else class="h3 m-0" style="color: #575757">
                        ....
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>



          </div>
          <div class="col-md-12 col-lg-4">
            <router-link
              to="/invoices"
              style="color: #fff; text-decoration: none; flex: 1 1 100%"
            >
              <div class="card dashboard-card2 bs-shadow mb20">
                <div class="body p-4">
                  <div class="row clearfix">
                    <div class="col-auto">
                      <div class="stamp stamp-md fortbg1">
                        <img src="/img/article-fill-white.svg" />
                        <!--      <i class="fas fa-file"></i> -->
                      </div>
                    </div>
                    <div class="col text-right">
                      <div class="small text-muted">Invoices</div>
                      <div
                        v-if="homeAlerts"
                        class="h3 m-0"
                        style="color: #575757"
                      >
                        {{ homeAlerts.totals.invoices }}
                      </div>
                      
                      <div v-else class="h3 m-0" style="color: #575757">
                        ....
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- ################### END ORDER, ACK and INVOICE CARDS ################### -->


        <!-- ################### NEWS SECTION ################### -->
        <div class="row row-eq-height">
          <div class="col-lg-8 mb-lg-0">

            
            <div v-if="newsLoading || !newsPosts.length"
              class="col-xs-12 col-sm-12 col-12 col-lg-12"
              style="
                min-height: 245.06px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
              "
            >
              <div class="flex-box-wrap">


                <div class="flex-internal">
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#6c757d'"
                  />
                  <p style="color: #6c757d">Loading......</p>
                </div>

              </div>
            </div>

            


            <div v-else>
              
              <div
                class="carousel slide bs-shadow"
                id="carousel-ecommerce-01"
                data-ride="carousel"
                data-interval="5000"
              >

     

              <div class="carousel-inner">


    <carousel :slides="calcBanners">
      <carousel-slide v-for="(slide, index) in calcBanners" :key="index" class="carousel-slider">
        <template v-if="slide.ACF.external_link">
          <a :href="slide.ACF.external_link" target="_blank" rel="noopener noreferrer">
            <img :src="slide.ACF.slider_image.sizes.large" :alt="slide" class="img-fluid" style="object-fit: fill;">
          </a>
        </template>
        <template v-else>
          <router-link :to="'/announcement/' + slide.slug">
            <img :src="slide.ACF.slider_image.sizes.large" :alt="slide" class="img-fluid" style="object-fit: fill;">
          </router-link>
        </template>
      </carousel-slide>
    </carousel>


  </div>

     
           
            </div>
            
         
<!--
              <div
                class="carousel slide bs-shadow"
                id="carousel-ecommerce-01"
                data-ride="carousel"
                data-interval="5000"
              >


                <div class="carousel-inner">
                  
                  <div
                    
                    v-for="(slide, index) in calcBanners"
                    v-bind:key="index"
                    class="carousel-item"
                    :class="{ active: index === 0 }"
                  >
                    <a
                      v-if="slide.ACF.external_link"
                      :href="slide.ACF.external_link"
                      target="_blank"
                      rel="noopeneer noreferrer"
                    >
                      <img
                        class="img-fluid"
                        v-bind:src="slide.ACF.slider_image.sizes.large"
                      />
                    </a>

                    <router-link v-else :to="/announcement/ + slide.slug">
                      <img
                        class="img-fluid"
                        v-bind:src="slide.ACF.slider_image.sizes.large"
                      />
                    </router-link>

                  </div>
                </div>



                <div>
                  <a
                    class="carousel-control-prev justify-content-start"
                    href="#carousel-ecommerce-01"
                    role="button"
                    data-slide="prev"
                  >
                    <i class="fas fa-chevron-left controls"></i>
                    <span class="sr-only">Previous</span>
                  </a>

                  <a
                    class="carousel-control-next justify-content-end"
                    href="#carousel-ecommerce-01"
                    role="button"
                    data-slide="next"
                  >
                    <i class="fas fa-chevron-right controls"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </div>

              </div>
            -->
            </div>
          </div>



          <div id="newsList" class="col-lg-4 mb-lg-0">
            <div
              style="
                height: 100%;
                position: absolute;
                overflow: hidden;
                width: calc(100% - 30px);
              "
            >

     
     
<!-- issue start -->
               <div v-if="newsLoading || !newsPosts.length"
              class="col-xs-12 col-sm-12 col-12 col-lg-12"
              style="
                min-height: 245.06px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
              "
            >
              <div class="flex-box-wrap">


                <div class="flex-internal">
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#6c757d'"
                  />
                  <p style="color: #6c757d">Loading......</p>
                </div>

              </div>
              </div>

              <div v-else class="news-scroll masked-overflow-news">
                <div style="display: flex; flex-direction: column; flex-grow: 1">


                  <div
                    v-for="(news, index) in calcNewsPosts.slice(0, 10)"
                    :key="index"
                    style="flex-grow: 1"
                  >
                    <a
                      v-if="news.ACF.external_link"
                      :href="news.ACF.external_link"
                      target="_blank"
                      rel="noopeneer noreferrer"
                      class="news-roll-item"
                    >
                      <p class="top-news-item">
                        
                        <span
                          style="margin-right: 5px; font-size: inherit"
                          class="date-text"
                          >{{
                            formatDate(news.date)
                          }}</span
                        >
                      
                        <span v-html="news.title.rendered" class="fw300"></span>
                      </p>
                    </a>
                    <router-link v-else :to="/announcement/ + news.slug">
                      <p class="top-news-item">
                      
                        <span
                          class="date-text"
                          style="margin-right: 5px; font-size: inherit"
                          >{{
                            formatDate(news.date)
                          }}</span
                        >
                      
                        <span v-html="news.title.rendered"></span>
                      </p>
                    </router-link>
                  </div>


                </div>
              </div>

<!-- issue end -->




              <a
                v-if="!newsLoading || newsPosts.length"
                style="position: absolute; bottom: 0; left: 0; right: 0"
                href="/announcements"
                class="link announcement-link"
              >
                View All
                <i data-v-5a90ec03 class="material-icons"
                  >keyboard_arrow_right</i
                >
              </a>
            </div>

          </div>

        </div>
        <!-- ################### END NEWS SECTION ################### -->

        <div class="spacer"></div>
      </div>
      <img class="top-angle" src="/img/top-angle2.svg" alt="angle"/>
    </section>
    <!-- ################### END HERO ################### -->

   

    <!-- SECTION -->
  
    <section id="v-step-7" class="second-banner">
      <div class="container">
        <div class="row row-eq-height">
          <div class="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6" id="newsFeed">
            <div
              style="
                height: 100%;
                min-height: 500px;
                position: absolute;
                overflow: hidden;
                width: calc(100% - 30px);
                padding-bottom: 20px;
              "
            >
              <div
                v-if="collabLoading"
                class="col-xs-12 col-sm-12 col-12 col-md-12 col-lg-12"
                style="
                  min-height: 302px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 3rem !important;
                "
              >
                <div class="flex-box-wrap">
                  <div class="flex-internal">
                    <atom-spinner
                      :animation-duration="1000"
                      :size="40"
                      :color="'#575757'"
                    />
                    <p style="color: #575757">Loading......</p>
                  </div>
                </div>
              </div>

              <div
                v-else
                style="min-height: 562px; max-height: 562px; overflow: scroll"
                class="masked-overflow-collaborate"
              >
                <div v-bind:key="question.Tid" v-for="question in questions">
                  <home-question
                    :FirstName="question.FirstName"
                    :LastName="question.LastName"
                    :company="question.Name"
                    :Posted="question.Posted"
                    :Fname="question.Fname"
                    :Subject="question.Subject"
                    :Tid="question.Tid"
                    :replies="question.replies"
                    :Fid="question.Fid"
                  />
                </div>
              </div>
          

              <div
                v-if="!collabLoading"
                style="position: absolute; bottom: 0; left: 0; right: 0"
              >
                <a href="/collaborate" class="link announcement-link">
                  View All
                  <i data-v-5a90ec03 class="material-icons"
                    >keyboard_arrow_right</i
                  >
                </a>
              </div>
            </div>
          </div>

          <div class="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
           <FeaturedVideos />
          </div>


        </div>
      </div>
    </section>

    <!-- END -->

    <section style="margin-top: 40px;">
      <div class="container">
<div class="">
            <div class="row row-eq-height">
              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card feature-card mb20">
                  <a @click="showHelp" class="gray-bg tc">
                    <img
                      class="card-img-top"
                      src="/img/learn.svg"
                      alt
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      Learn Spacenet
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      This link will enable the Spacenet 3 training tour.
                    </p>
                    <span class="link" @click="showHelp">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div
                v-if="this.$store.getters.isExpeditedOrders"
                class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3"
              >
                <div class="card feature-card mb20">
                  <a href="/expedited-orders" class="orange-bg tc">
                    <img
                      class="card-img-top"
                      src="/img/locker-orders.svg"
                      alt
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      <span
                        v-if="this.$store.state.expeditedOrderCount !== null"
                        class="badge badge-orange"
                        style="margin-right: 5px; font-size: inherit"
                        >{{ this.$store.state.expeditedOrderCount }}</span
                      >
                      Expedited Orders
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      Order status related to Spacesaver parts requests.
                    </p>
                    <a class="link" href="/expedited-orders">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div
                v-else-if="
                  !this.$store.getters.isExpeditedOrders &&
                  !this.$store.getters.hideOrders
                "
                class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3"
              >
                <div class="card feature-card mb20">
                  <a href="/listings" class="orange-bg tc">
                    <img
                      class="card-img-top"
                      src="/img/locker-orders.svg"
                      alt
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      My Orders
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      View My Order Listings
                    </p>
                    <a class="link" href="/listings">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card feature-card mb20">
                  <a href="/collaborate" class="blue-bg tc">
                    <img
                      class="card-img-top"
                      src="/img/social-chat.svg"
                      alt="collaborate"
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      <span
                        v-if="
                          this.$store.state.newPostCount !== null &&
                          this.$store.state.newPostCount > 0 &&
                          this.$store.state.collaborateView != true
                        "
                        class="badge badge-orange"
                        style="margin-right: 5px; font-size: inherit"
                        >{{ this.$store.state.newPostCount }}</span
                      >Collaborate
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      Join in the discussion with the Spacesaver group!
                    </p>
                    <a class="link" href="/collaborate">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card feature-card mb20">
                  <a href="/marketing-materials" class="teal-bg tc">
                    <img
                      class="card-img-top"
                      src="@/assets/img/marketing-materials.svg"
                      alt
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      Marketing Materials
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      View product images, documents, and videos.
                    </p>
                    <a class="link" href="/marketing-materials">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="@/assets/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div
                v-if="this.$store.getters.hideOrders"
                class="col col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3"
              >
                <div class="card feature-card">
                  <a href="/calendar" class="orange-bg tc">
                    <img
                      class="card-img-top"
                      src="/img/calendar.svg"
                      alt
                    />
                  </a>
                  <div class="card-body">
                    <h5
                      class="card-title my-2"
                      style="max-height: 30px; overflow: hidden"
                    >
                      Spacesaver Events
                    </h5>
                    <p class="small card-text" style="min-height: 38px">
                      Bringing the Spacesaver group together to connect and
                      collaborate!
                    </p>
                    <a class="link" href="/calendar">
                      GO
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
    </section>

 

    <!-- From The Fort -->
    <section id="v-step-8" class="py-5 second-banner fromthefortbg2 fort">
      <img class="bottom-angle" src="@/assets/img/bottom-angle2.svg" />
      <div class="container">
        <!--
        <img class="mw300" src="@/assets/img/fort-blue.svg" />
        -->
        <h2 id="v-step-9" class="mb-0" data-config-id="header">
          From The Fort
        </h2>
        <h5 class="mb-3" style="color: #575757">
          Monthly Insights for the Spacesaver Group
        </h5>

        <div
          v-if="fortLoading == true && !fromTheFortPosts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="
            min-height: 302px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3rem !important;
          "
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p style="color: #575757">Loading......</p>
              
            </div>
          </div>
        </div>

        <div v-else class="row row-eq-height">
          <div class="col-12 col-sm-12 col-md-12">
            <div class="row row-eq-height">
              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[0].images.medium"
                    alt="test image"
                  />
                  -->
                  <div
                    class="fortcardimage"
                    v-bind:style="{
    background: 'linear-gradient(to bottom, rgba(0,180,180, 0.8), rgba(62, 71, 81, 0.8)), url(' + fromTheFortPosts[0].images.large + ')'
}"
                   
                  >
                    <div class="fortimagewrap">
                      <img src="/img/fort-icon.png" />
                    </div>
                  </div>
           
                  <div class="card-content">
                    <h4
                      v-html="truncateTitle(fromTheFortPosts[0].title.rendered)"
                    ></h4>
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[0].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px; min-height: 60px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[0].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
             
                </div>
              </div>
              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[1].images.medium"
                    alt="test image"
                  />
                  -->
                  <div
                    class="fortcardimage"
                    v-bind:style="{
    background: 'linear-gradient(to bottom, rgba(0,180,180, 0.8), rgba(62, 71, 81, 0.8)), url(' + fromTheFortPosts[1].images.large + ')'
}"
                  >
                    <div class="fortimagewrap">
                      <img src="@/assets/img/fort-icon.png" />
                    </div>
                  </div>
             
                  <div class="card-content">
                    <h4
                      class
                      v-html="truncateTitle(fromTheFortPosts[1].title.rendered)"
                    ></h4>
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[1].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px; min-height: 60px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[1].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
               
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <div
                    class="fortcardimage"
                    v-bind:style="{
    background: 'linear-gradient(to bottom, rgba(0,180,180, 0.8), rgba(62, 71, 81, 0.8)), url(' + fromTheFortPosts[2].images.large + ')'
}"
                  >
                    <div class="fortimagewrap">
                      <img src="/img/fort-icon.png" />
                    </div>
                  </div>
                 
                  <div class="card-content">
                    <h4
                      class
                      v-html="truncateTitle(fromTheFortPosts[2].title.rendered)"
                    ></h4>
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[2].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px; min-height: 60px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[2].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
              
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <div
                    class="fortcardimage"
                    v-bind:style="{
    background: 'linear-gradient(to bottom, rgba(0,180,180, 0.8), rgba(62, 71, 81, 0.8)), url(' + fromTheFortPosts[3].images.large + ')'
}"
                  >
                    <div class="fortimagewrap">
                      <img src="/img/fort-icon.png" />
                    </div>
                  </div>
                 
                  <div class="card-content">
                    <h4
                      class
                      v-html="truncateTitle(fromTheFortPosts[3].title.rendered)"
                    ></h4>
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[3].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px; min-height: 60px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[3].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <img
                        style="
                          width: 25px !important;
                          height: 25px !important;
                          max-width: 25px !important;
                          padding: 0;
                        "
                        src="/img/arrow-right-s-line.svg"
                      />
                    </a>
                  </div>
                
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <img class="faq-top-angle" src="/img/faq-top2.svg" />
    </section>

    <section id="v-step-9" class="py-5 faq-tease">
      <div class="container">
        <h2 id="v-step-9" class="mb-3" data-config-id="header">
          Frequently Asked Questions
        </h2>
        <div
          v-if="faqLoading == true || !faqPosts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="
            min-height: 302px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          "
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#333'"
              />
              <p style="color: #333">Loading......</p>
            </div>
          </div>
        </div>

        <div v-else class="accordion" id="faq-01" data-config-id="faq">
          <div
            v-for="(faq, index) in faqPosts"
            :key="faq.index"
            class="mb-2 border-bottom bgwhite bs-shadow"
          >
            <h5 id="faq-heading1-01 faq-heading">
              <button
                class="btn btn-link btn-block text-left d-flex collapsed"
                type="button"
                data-toggle="collapse"
                :data-target="'#faq-collapse1-' + index"
                aria-expanded="false"
                aria-controls="faq-collapse1-01"
                style="white-space: normal !important; word-wrap: break-word"
              >
                <span v-html="faq.title.rendered"></span>
                <i
                  class="p-1 ml-auto my-auto fas fa-arrow-circle-down"
                  style="width: 30px; height: 30px; color: #c3cace"
                ></i>
              </button>
            </h5>
            <div
              class="collapse answer"
              :id="'faq-collapse1-' + index"
              aria-labelledby="faq-heading1-01"
              data-parent="#faq-01"
              style="padding: 0 10px"
            >
              <p id="answer" v-html="faq.content.rendered"></p>
            </div>
          </div>
        </div>
        <div v-if="!faqLoading || faqPosts.length" class="text-center mt20">
          <router-link to="/faq" class="mb0 lh1 mlauto list-btn"
            >See All</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import HomeTour from "../components/Tours/HomeTour";
// import HomeTourNoOrders from "../components/Tours/HomeTourNoOrders";
import HomeQuestion from "../components/HomeQuestion.vue";
import HomeReports from "../components/Organisms/HomeReports.vue";
import FeaturedVideos from "../components/video/FeaturedVideos.vue";
import Carousel from '../components/carousel/Carousel.vue';
import CarouselSlide from '../components/carousel/CarouselSlide.vue';
import moment from 'moment';




import { fetchAllFortData } from "@/services/fortService";
import { fetchAllFAQData } from "@/services/faqService";
import { fetchCollaborateHomeData } from "@/services/collaborateService";

const auth = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    },
};


export default {
  name: "Home",
  components: {
    AtomSpinner,
    // HomeTour,
    // HomeTourNoOrders,
    HomeQuestion,
    HomeReports,
    FeaturedVideos,
    Carousel : Carousel,
    CarouselSlide : CarouselSlide,
},
  data() {
    return {
      server: import.meta.env.VITE_APP_SERVER_URL,
      fromthefort: import.meta.env.VITE_APP_FROM_THE_FORT,
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      media: [],
      alertLoading: false,
      newsLoading: true,
      fortLoading: false,
      faqLoading: false,
      collabLoading: false,
      reportLoading: false,
      loading: false,
      fromTheFortPosts: [],
      newsPosts: [],
      questions: [],
      faqPosts: [],
      searchValue: "",
      homeAlerts: null,
      listings: null,
      acks: null,
      invs: null,
      homeReports: null,
      options: {
        infinite: true,
        slidesToShow: 1,
        navButtons: false,
        dots: false,
      },
    };
  },
  methods: {



    firstLoad: function () {

      console.log(auth)
      this.$store.commit("setActivePage", "home");

      let windowWidth = window.innerWidth;

      if (Number(windowWidth) < 992) {
        //console.log("mobile");
        this.$store.commit("hideTour");
      }

      //Sentry.setUser({ email: this.$store.state.user.Email });
 
      if(this.$store.getters.isReports){
        this.homeReportSync(auth);
      }

      this.newsSync();
      // this.collaborateHomeSync();

      if (
        this.$store.getters.isACKSnINVS &&
        !this.$store.getters.hideOrders
      ) {
        this.HomeAlertSync();
        //this.listingSync();
        //this.getAllAcks();
        //this.getAllInvs();
      }
  


      
      this.collaborateHomeSync();
      console.log('all done')



     
    },

    newsSync: function () {
      this.newsLoading = true;
      if (this.$store.state.announcements.announcementHomePosts.length) {
        this.newsPosts = this.$store.state.announcements.announcementHomePosts;
        this.newsLoading = false;
      } else {
        return axios
          .get(
            `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/news?per_page=30`
          )
          .then((response) => {
            //console.log(response);
            this.newsPosts = response.data;
            this.newsLoading = false;
            this.$store.commit("UPDATE_ANNOUNCEMENTS_HOME", this.newsPosts);
          });
      }
    },



    formatDate: function(date){
        let formattedDate = moment(date).format("MMM Do YYYY") + " - ";
        return formattedDate;
    },


    truncateTitle: function (value) {
      if (value.length > 35) {
        value = value.substring(0, 55) + "...";
      }
      return value;
    },

    truncateExcerpt: function (value) {
      if (value.length > 90) {
        value = value.substring(0, 75) + "...";
      }
      return value;
    },

    /*

    async newsSync() {
      this.newsLoading = true;
      if (this.$store.state.announcements.announcementHomePosts.length) {
        this.newsPosts = this.$store.state.announcements.announcementHomePosts;
        this.newsLoading = false;
      } else {
        const response = await fetch(
          `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/news?per_page=30`
        );
        const data = await response.json();
        this.newsPosts = data;
        this.newsLoading = false;
        this.$store.commit("UPDATE_ANNOUNCEMENTS_HOME", this.newsPosts);
      }
    },
    */
    async HomeAlertSync() {

      if (this.$store.state.homeNotifications) {
        this.homeAlerts = this.$store.state.homeNotifications;

        // update to new data after previous data displayed
        const response = await fetch(
          `${this.ordermanageapi}/homenotifications`,
          auth
        );
        const data = await response.json();
        this.homeAlerts = data;
        this.$store.commit("UPDATE_HOME_NOTIFICATIONS", this.homeAlerts);

      } else {
        this.alertLoading = true;
        const response = await fetch(
          `${this.ordermanageapi}/homenotifications`,
          auth
        );
        const data = await response.json();
        this.homeAlerts = data;
        this.alertLoading = false;
        this.$store.commit("UPDATE_HOME_NOTIFICATIONS", this.homeAlerts);
      }
    },

 /*
    async collaborateHomeSync() {
      // console.log(auth);
      
      if (!this.$store.state.collaborate.questionsHome.length) {
        this.collabLoading = true;
         return axios
          .get(`${import.meta.env.VITE_APP_SERVER_URL}/api/v1/questions-home`, auth)
          .then((response) => {
            console.log(response);
            this.questions = response.data;
            this.collabLoading = false;
            this.$store.commit("UPDATE_COLLABORATE_HOME", this.questions);
          });
        //this.collabLoading = false;
        //this.$store.commit("UPDATE_COLLABORATE_HOME", this.questions);
      } else {
        this.questions = this.$store.state.collaborate.questionsHome.slice(0, 10);
        this.collabLoading = false;
      }
    },
    */


    collaborateHomeSync() {
      console.log(auth);
      if (!this.$store.state.collaborate.questionsHome.length) {
        this.collabLoading = true;
        return axios.get(`${import.meta.env.VITE_APP_SERVER_URL}/api/v1/questions-home`, auth)
          .then((response) => {
            console.log(response);
            this.questions = response.data;
            this.collabLoading = false;
            this.$store.commit("UPDATE_COLLABORATE_HOME", this.questions);
          })
          .catch((error) => {
            console.error('Error fetching collaborate home data:', error);
            this.collabLoading = false;
          });
      } else {
        this.questions = this.$store.state.collaborate.questionsHome.slice(0, 10);
        this.collabLoading = false;
        return Promise.resolve(); // Return a resolved promise to maintain consistency
      }
    },




  homeReportSync(auth) {
    return new Promise((resolve, reject) => {
      if (!this.$store.state.reportsHomePosts) {
        this.reportLoading = true;
        console.log('loading reports');
        // console.log(auth)
        

        fetch(`${this.ordermanageapi}/homeReports`, auth)
          .then(response => {
            if (!response.ok) {
              console.log(response)
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            this.homeReports = data;
            this.reportLoading = false;
            this.$store.commit("UPDATE_REPORTS_HOME", this.homeReports);
            resolve(); // Resolve the promise after data is fetched and committed
          })
          .catch(error => {
            console.error('Error fetching home reports:', error);
            this.reportLoading = false;
            reject(error); // Reject the promise if an error occurs
          });
      } else {
        this.homeReports = this.$store.state.reportsHomePosts;
        this.reportLoading = false;
        resolve(); // Resolve the promise if data is already available in the store
      }
    });
  },

    /*
     homeReportSync: function () {
      return axios
        .get(`${this.ordermanageapi}/homeReports`, auth)
        .then((response) => {
          this.homeReports = response.data;
          //this.$store.commit("UPDATE_FAQS_HOME", this.faqPosts);
        });
    },
*/
     getAllAcks: function () {
      return axios
        .get(
          `${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=ack`,
          auth
        )
        .then((response) => {
          this.acks = response.data;
          //console.log("acks " + response.data);
        });
    },

    getAllInvs: function () {
      return axios
        .get(
          `${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=inv`,
          auth
        )
        .then((response) => {
          this.invs = response.data;
          //console.log("invs " + response.data);
        });
    },

    listingSync: function () {
      if (!this.$store.state.listings.listings.length) {
        //console.log("getting listings");
        return axios
          .get(`${this.ordermanageapi}/order`, auth)
          .then((response) => {
            //fix null estinated dates so sorting will work
            response.data.forEach(function (o) {
              if (o.estimated_Date === null) {
                o.estimated_Date = "";
              }
            });
            this.listings = response.data;
            this.$store.commit("UPDATE_LISTINGS", this.listings);
          });
      } else {
        this.listings = this.$store.state.listings.listings;
      }
    },

    fortSync: function () {
      this.fortLoading = true;
      if (this.$store.state.fromthefort.fromTheFortPosts.length) {

        this.fromTheFortPosts = this.$store.state.fromthefort.fromTheFortPosts;
        this.fortLoading = false;

      } else {

        // const fortData = fetchAllFortData;
        // console.log(fortData);
     
        return axios
          .get(`${this.fromthefort}/wp-json/wp/v2/posts?per_page=6`)
          .then((response) => {
            console.log(response);
            this.fromTheFortPosts = response.data;
            this.fortLoading = false;
            this.$store.commit(
              "UPDATE_FROM_THE_FORT_HOME",
              this.fromTheFortPosts
            );
            console.log("updated")
          });
          
      }
    },

    faqSync: function () {
      this.faqLoading = true;
      if (this.$store.state.faq.faqHomePosts.length) {
        this.faqPosts = this.$store.state.faq.faqHomePosts;
        this.faqLoading = false;
      } else {
        return axios
          .get(
            `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/spacenet_faqs?per_page=6`
          )
          .then((response) => {
            //console.log(response);
            this.faqPosts = response.data;
            this.faqLoading = false;
            this.$store.commit("UPDATE_FAQS_HOME", this.faqPosts);
          });
      }
    },
   
    showHelp: function () {
      this.$store.commit("showTour");
      this.$noty.success(
        "The help tour has been enabled, you can disable it by clicking 'Skip tour' or 'Hide Help' in the menu under your name! Help is only available on desktop devices."
      );
    },

    searchOrder: function () {
      this.$router.push({
        path: "/listings",
        query: { search: this.searchValue.trim(), promised: false },
      });
      //this.$router.push("listings?search=this.searchValue&promised=false")
      ///listings?search=S%20%26%20C%20Electric&promised=false
    },

    clearSearch() {
      this.searchValue = "";
    },

  },

  computed: {

    firstName() {
      return this.$store.state.user.FirstName;
    },


    calcNewsPosts: function () {
      if (this.newsLoading == false && this.newsPosts.length) {
        let filteredNews = this.newsPosts.filter(function (el) {
          return el.ACF.exclude_from_feed != "Yes";
        });

        return filteredNews;
      }
    },


    calcBanners: function () {
      if (this.newsLoading == false && this.newsPosts.length) {
        let filteredBanners = this.newsPosts.filter(function (el) {
          return el.ACF.show_in_banner == "Yes";
        });

        return filteredBanners;
      }
    },


  },

  // after creating the component
  created() {
    this.firstLoad();
    this.fortSync();
    this.faqSync();
  },

  // before creting the component
  mounted() {
    
    this.$store.commit("showNavigation");
    
    if (
      this.$store.getters.isACKSnINVS &&
      !this.$store.getters.hideOrders
    ) {
      this.$store.commit("SET_EO_COUNT");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.slider-banner {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23004033'/%3E%3Cstop offset='0.2' stop-color='%23008066'/%3E%3Cstop offset='0.5' stop-color='%239FF'/%3E%3Cstop offset='0.8' stop-color='%23008066'/%3E%3Cstop offset='1' stop-color='%23004033'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2309F'/%3E%3Cstop offset='0.5' stop-color='%230FC'/%3E%3Cstop offset='1' stop-color='%2309F'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  /*new*/
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23005a5a'/%3E%3Cstop offset='0.2' stop-color='%2300b4b4'/%3E%3Cstop offset='0.5' stop-color='%239FF'/%3E%3Cstop offset='0.8' stop-color='%2300b4b4'/%3E%3Cstop offset='1' stop-color='%23005a5a'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2300b4b4'/%3E%3Cstop offset='0.5' stop-color='%230FC'/%3E%3Cstop offset='1' stop-color='%2300b4b4'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /*newer*/
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23363e45'/%3E%3Cstop offset='0.2' stop-color='%236b7c8a'/%3E%3Cstop offset='0.5' stop-color='%236b7c8a'/%3E%3Cstop offset='0.8' stop-color='%236b7c8a'/%3E%3Cstop offset='1' stop-color='%23363e45'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%236b7c8a'/%3E%3Cstop offset='0.5' stop-color='%236b7c8a'/%3E%3Cstop offset='1' stop-color='%236b7c8a'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

/*
.carousel-item {
  border: 2px solid #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
*/
.carousel {
  border: 2px solid #fff;
  border-radius: 0.25rem !important;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .carousel {
    /*box-shadow: 0 25px 25px -12px rgba(0, 0, 0, 0.45);*/
  }
}
@media screen and (min-width: 991px) {
  .carousel {
    /*box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);*/
  }
}

.carousel-item img {
  width: 100%;
}

.controls {
  font-size: 2em;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}
.second-banner {
  background-color: #f1f6f9;

  padding-top: 30px;
  /*
  padding-bottom: 30px;
  */
}
.welcome-text {
  padding: 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.bgwhite {
  background: #fff;
}
.faq-heading {
  margin-bottom: 0 !important;
}
.collapse {
  padding: 0px 10px !important;
}
.collapse.show {
  padding: 0px 10px !important;
}
.faq-01 .btn {
  text-decoration: none !important;
}
.faq-01 .btn:hover {
  text-decoration: none !important;
}

.btn-link {
  color: #333;
}

.btn-link i {
  color: rgba(92, 109, 126, 0.9);
}

.btn-link:hover {
  color: #000;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus {
  color: #000;
  text-decoration: none !important;
}

.accordion h5 {
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
  line-height: 1.2;
}

.accordion div {
  padding: 10px;
}

.see-all-btn {
  background: #00b4b4;
  color: #fff;
}
.fromfortbg {
  background-color: rgba(92, 109, 126, 0.9);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.2'%3E%3Cg fill='%23262626'%3E%3Cpolygon points='1600 800 1400 800 1600 0'/%3E%3Cpolygon points='800 800 1600 600 1600 800'/%3E%3C/g%3E%3Cg fill='%23262626'%3E%3Cpolygon points='0 0 200 0 0 800'/%3E%3Cpolygon points='800 0 0 200 0 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.mw300 {
  max-width: 300px;
  margin-bottom: 5px;
}
.fortcardimage {
  width: 100%;
  height: auto;
  min-height: 180px;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.fortbg1 {
  background: #2aaae2;
  background-color: #2aaae2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23296e90'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231d3747'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg2 {
  background: #3f5364;
  background-color: #3f5364;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%232c3843'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231a1f24'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg3 {
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23282e32'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23181a1c'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg4 {
  background-color: rgba(92, 109, 126, 1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23282e32'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23181a1c'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.card-content {
  padding: 10px;
}
.fortcard {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 0.25rem !important;
  overflow: hidden;
  margin-bottom: 30px;
}
.fortimagewrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fortimagewrap img {
  max-width: 100px;
}
.more-link {
  display: inline-block !important;
  justify-content: center;
}
.more-link i {
  font-size: 14px;
}
.post-title {
  font-size: 2.2em;
}
.post-excerpt {
  padding-bottom: 0;
  margin-bottom: 0;
}
.feature-card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-radius: 4px !important;
  overflow: hidden;
  /*height: calc(100% - 30px);*/
  position: relative;
  box-sizing: border-box;
}
.feature-card img {
  /*background: #f7fafc;*/
  max-width: 70%;
  text-align: center;
  padding: 40px;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(134, 134, 134, 0.1));
  filter: drop-shadow(3px 3px 2px rgba(134, 134, 134, 0.1));
}
.orange-bg {
  background: #f16232;
  background-color: #f16232;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%239b4324'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%234d2517'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  /*
  background: linear-gradient(to right, #f16232 50%, #c24b23 50%);
*/
  /*
    background: linear-gradient(
    to top,
    rgba(223, 80, 46, 1),
    rgba(223, 80, 46, 0.9)
  );
  background-color: #e05b2f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%23c9512a' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.blue-bg {
  background: #4d5967;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background-color: #4d5967;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23343c45'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231d2125'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.gray-bg {
  background: rgba(92, 109, 126, 0.9);
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background-color: #6b7c8a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%2347525a'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23262b2e'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;

  /*
    background: linear-gradient(
    to top,
    rgba(92, 109, 126, 0.9),
    rgba(92, 109, 126, 0.6)
  );
  background-color: #5c6976;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%234c5661' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.teal-bg {
  background: #00b4b4;
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%231c7474'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23183a3a'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;

  /*
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%2300a1a1' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.feature-card .card-body {
  padding: 10px;
  flex: 1 1 100%;
}
.link {
  width: 100%;
  display: flex;
  border: none;
  /* text-align: center!important; */
  justify-content: center;
  background: #f1f2f3;
  color: #575757;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.5s ease;
  border-radius: 4px !important;
  overflow: hidden;
  padding: 5px;
}
.link:hover {
  background: rgba(223, 224, 224, 0.9);
}
.link:active {
  background: rgba(223, 224, 224, 0.9);
}

@media screen and (min-width: 992px) {
  .banner-wrap {
    box-sizing: border-box;
    margin-bottom: 30px;
  }
}
/*.top-angle
@media screen and (max-width: 991px) {
  .banner-wrap {
    background: rgba(255, 255, 255, 0) !important;
    padding: 0 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
*/
.banner-wrap {
  box-sizing: border-box;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .banner-wrap {
    margin-bottom: 10px;
  }
}
.tc {
  text-align: center;
}
.top-angle {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: -1px;
}
.faq-top-angle {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: -1px;
}
.faq-bottom-angle {
  width: 100%;
  position: relative;
  top: 0;
  /* background: #eef0f1; */
  border: 0;
  margin-top: -79px;
}
.bottom-angle {
  width: 100%;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 575px) {
  .faq-top-angle {
    display: none;
  }
  .faq-bottom-angle {
    display: none;
  }
  .top-angle {
    display: none;
  }
  .bottom-angle {
    display: none;
  }
}
.fort {
  position: relative;
}
.faq-tease {
  background-color: #f1f6f9;
  position: relative;
}
.home-footer-top {
  position: relative;
  background-color: #4d5967;
}
.card-strip-top {
  color: #fff;
  padding: 10px;
}
.card-strip-top h5 {
  margin-bottom: 0;
}
.card-scroll {
  padding: 10px;
  /*height: 100%;*/
  overflow: scroll;
}
.card-deck {
  /*height: 100%;*/
  padding-bottom: 1.5rem !important;
}
.card-deck .card {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}
.h100 {
  height: 100%;
}
.prev-forts {
  background: #f1f2f3;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.5s ease;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}
.prev-forts i {
  margin-left: auto;
}
.prev-forts:hover {
  background: rgba(223, 224, 224, 0.9);
}
.prev-forts:active {
  background: rgba(223, 224, 224, 0.9);
}

.prev-forts p {
  margin-bottom: 0;
  line-height: 1;
}
.card-scroll a {
  text-decoration: none;
  color: #575757;
}
.accordion p {
  font-weight: 300;
}
.top-news-item {
  margin-bottom: 5px;
  padding: 5px;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
  line-height: 1.4;

  color: #6c757d;
  font-size: 90%;
  font-weight: 300;
  cursor: pointer;
  transition: background 0.5s ease;
  transition: all 0.2s ease-in-out;
}

.top-news-item:hover {
  /*background: #f1f2f3;*/
  /*background: rgba(223, 224, 224, 0.9);*/
  background: #f1f1f1;
}
.top-news-item:active {
  /*background: #f1f2f3;*/
  /*background: rgba(223, 224, 224, 0.9);*/
  background: #f1f1f1;
}
.announcement-banner {
  display: flex;
  flex-direction: column;
}
.announcement-link {
  margin-top: auto;
  background: #00b4b4;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}

.announcement-link:hover {
  background: #429da8;
}
.announcement-link:active {
  background: #429da8;
}
/*
@media screen and (max-width: 991px) {
	.announcement-banner {
		height: 200px;
	}
}
*/
@media screen and (min-width: 992px) {
  .announcement-banner {
    height: 214.32px;
  }
}
@media screen and (min-width: 1200px) {
  .announcement-banner {
    height: 256.02px;
  }
}
.news-scroll {
  overflow: scoll;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.news-scroll a:hover {
  text-decoration: none;
}
.news-scroll a:active {
  text-decoration: none;
}
.badge-orange {
  background-color: #00b4b4;
  background-color: #3e4651;
  background-color: #f16232;
  color: #fff;
  font-size: 0.7em !important;
  font-weight: 500;
}
/*
.faq-tease:after {
  content: url(../assets/img/faq-bottom.svg);
}
*/
@media screen and (max-width: 575px) {
  .news-scroll {
    min-height: 180px;
  }
  .card-title {
    min-height: 50px;
  }
  #v-step-6 {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }
  #v-step-8 {
    padding-top: 30px !important;
    padding-bottom: 0px !important;
  }
  .faq-tease {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (min-width: 992px) {
  .orange-bg {
    background-attachment: fixed;
  }
  .teal-bg {
    background-attachment: fixed;
  }
  .gray-bg {
    background-attachment: fixed;
  }
  .blue-bg {
    background-attachment: fixed;
  }
  .fortbg1 {
    background-attachment: fixed;
  }
  .fortbg2 {
    background-attachment: fixed;
  }
  .fortbg3 {
    background-attachment: fixed;
  }
  .fortbg4 {
    background-attachment: fixed;
  }
}
.dashboard-card {
  display: flex;
  flex-direction: row;
}
.dashboard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-card {
  background: rgba(46, 54, 62, 80%);
  transition: background 0.5s ease;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.dashboard-card:hover,
.dashboard-card:active {
  background: rgba(46, 54, 62, 100%);
}
.search-area {
  display: flex;
  margin-bottom: 0px;
}
.search-btns {
  /*display: flex;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-btns .clear {
  margin-right: 0;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.new-search-btn1 {
  background: #00b4b4;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn1:hover {
  background: #429da8;
}
.new-search-btn2 {
  background: #33848e;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn2:hover {
  background: #2d767f;
}
.badge {
  padding: 0.4em 0.4em !important;
}

.stamp.stamp-md {
  min-width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}
.stamp {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.25rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.search-wrap {
  padding: 20px;
  background: #fff;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}
.search-wrap:hover {
  /*transform: scale(1.04);*/
  box-shadow: 0 10px 20px -12px rgb(0 0 0 / 8%) !important;
}
.question-header {
  background: #fff;
}
.dashboard-card2 {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.dashboard-card2:hover {
  /*transform: scale(1.04);*/
  box-shadow: 0 10px 20px -12px rgb(0 0 0 / 8%) !important;
}
.dashboard-card2:hover .stamp {
  /*transform: scale(1.04);*/
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
}
.top-news-item {
  transition: all 0.2s ease-in-out;
}
.top-news-item:hover .date-text {
  color: #00b4b4;
}
.teal {
  color: #00b4b4;
}
.orange {
  color: #f16232;
}
.date-text {
  font-weight: 400;
}
.masked-overflow-collaborate {
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 8px;

  /* mask fade distance, for use in mask calculations */
  --mask-height: 35px;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;

  /* Our height limit */
  height: 639px;

  /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
  padding-bottom: var(--mask-height);

  /* Keep some space between content and scrollbar */
  /* padding-right: 20px;*/

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
}
.masked-overflow-news {
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 8px;

  /* mask fade distance, for use in mask calculations */
  --mask-height: 20px;

  /* If content exceeds height of container, overflow! */
  overflow-y: auto;

  /* Our height limit */
  height: 256px;

  /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
  padding-bottom: var(--mask-height);

  /* Keep some space between content and scrollbar */
  /* padding-right: 20px;*/

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
}
.fromthefortbg2 {
  background-color: #f1f6f9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.1'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23dadfe1'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23c4c8ca'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23AEB1B3'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.fortcardimage::before {
  background-image: linear-gradient(
    to right,
    rgba(42, 170, 226, 0.8),
    rgba(62, 71, 81, 0.8)
  );
}

@media screen and (max-width: 1199px) {
  .masked-overflow-collaborate {
    height: 579px;
    max-height: 579px;
  }
}
@media screen and (min-width: 1200px) {
  .masked-overflow-collaborate {
    height: 639px;
    max-height: 639px;
  }
}
.spacer {
  min-height: 30px;
}
/* carousel */
.carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 94px;

        /*z-index:10;*/
    }

    .carousel-slider {
      
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
   /*     
        display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
    */
        

    }
    .carousel-slider img {
        /*width:100%;
        height:100%;
        */
        flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
    }
    @media screen and (min-width: 424px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 129px;
      }
}
@media screen and (min-width: 479px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 154px;
      }
}
@media screen and (min-width: 559px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 176px;
      }
}
@media screen and (min-width: 669px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 180px;
      }
}
    @media screen and (min-width: 767px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 242px;
      }
}
@media screen and (min-width: 991px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        height: 214px;
      }
}

    @media screen and (min-width: 1200px) {
      .carousel {
        position:relative;
        overflow: hidden;
        width: auto;
        /*height: 245px;*/
      }
    }
    @media screen and (max-width: 991px) {
  .carousel {
        display: none;
      }
}

@media screen and (max-width: 991px) {
  #newsFeed{
  min-height: 500px;
  margin-bottom: 20px;
}
#newsList{
  height: 272px;
}
}



</style>
