import axios from "axios";

const state = {
    invs: [],
    ordermanageapi: "https://ordermanage.spacesaver.com",
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    getINVS: (state) => {
        return state.invs;
    },
    getINVS_LENGTH: (state) => {
        return state.invs.length;
    },
};

const actions = {};

const mutations = {
    SET_INVOICE_LIST(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started Vuex AC List");
        //console.log(process.env.VUE_APP_ORDER_MANAGE_API);
        return axios
            .get(process.env.VUE_APP_ORDER_MANAGE_API + `/unprinted?ac=${state.user.ACId[0]}&type=inv`, axiosConfig)
            .then((response) => {
                console.log(response.data);
                state.invs = response.data;
            });
    },
    UPDATE_INVOICE_LIST(state, data) {
        //console.log('trying to update in vuex');
        //data.sort((a, b) => (a.acName > b.acName) ? 1 : -1);


        //var filtered = data.filter(filter_acs);
        state.invs = data;
        //console.log(state.acList)
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}