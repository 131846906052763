<template>
  <div
    class="listing-header d-flex align-items-center p-3 mt10 mb10 rounded b-shadow"
    style="background: #fff;"
  >
    <div class="pag-wrapper" style="width: 100%; display: flex;">
      <p class="mrauto" style="margin-bottom: 0;">
        <small>Page </small>{{ currPage }} <small>of</small>
        {{ Math.ceil(this.filteredListings.length / 100) }}
      </p>
      <div id="listings-step-4" class="nav-btns mlauto">
        <button v-if="this.currPage === 1" disabled>
          <i class="material-icons">keyboard_arrow_left</i> Back
        </button>

        <button v-else @click.prevent="setPage(currPage - 1)">
          <i class="material-icons">keyboard_arrow_left</i> Back
        </button>

        <button
          v-if="
            this.currPage === this.totalPage ||
            this.currPage == Math.ceil(this.filteredListings.length / 100)
          "
          disabled
        >
          Next
          <i class="material-icons">keyboard_arrow_right</i>
        </button>

        <button v-else @click.prevent="setPage(currPage + 1)">
          Next
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListingsPagination",
  props: {
    currPage: Number,
    totalPage: Number,
    countOfPage: Number,
    filteredListings: Array,
    setPage: {
      type: Function,
    },
  },
  computed: {},
};
</script>

<style scoped></style>
