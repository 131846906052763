<template>
  <div class="bar-chart-wrapper">
    <div id="barChart" style="text-align: center;"></div>
  </div>
</template>

<script>
import * as d3 from "d3";
//const MARGIN = { TOP: 10, BOTTOM: 50, LEFT: 70, RIGHT: 10 };
//const WIDTH = 800 - MARGIN.LEFT - MARGIN.RIGHT;
//const HEIGHT = 500 - MARGIN.TOP - MARGIN.BOTTOM;

const MARGIN = { TOP: 50, BOTTOM: 50, LEFT: 70, RIGHT: 10 };
// get width with border
//let wrapperWidth = document.getElementsByClassName("bar-chart-wrapper")[0].offsetWidth;
//console.log(wrapperWidth);
// get width without border
//document.getElementsByClassName("bar-chart-wrapper")[0].clientWidth;
//const WIDTH = 1070 - MARGIN.LEFT - MARGIN.RIGHT;
const WIDTH = 890 - MARGIN.LEFT - MARGIN.RIGHT;
//const WIDTH = this.wrapperWidth - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 340 - MARGIN.TOP - MARGIN.BOTTOM;
export default {
  name: "BarWhiteYear",
  props: ['chartType', 'stats'],
  data() {
    return {
      gender: this.chartType,
      // genders: ["Month", "Year"],
    };
  },
  methods: {
    generateBar() {
      const vis = this;

      vis.svg = d3
        .select("#barChart")
        .append("svg")
        .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
        .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
        .append("g")
        .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

      vis.xLabel = vis.svg
        .append("text")
        .attr("x", WIDTH / 2)
        .attr("y", HEIGHT + 50)
        .style("fill", "#fff")
        .attr("text-anchor", "middle");

      vis.svg
        .append("text")
        .attr("x", -(HEIGHT / 2))
        .attr("y", -50)
        .attr("text-anchor", "middle")
        .text(`Entered YTD Sales % of Quota`)
        .style("fill", "#fff")
        .attr("transform", "rotate(-90)");

      vis.xAxisGroup = vis.svg
        .append("g")
        .attr("class", "axisWhite")
        .attr("transform", `translate(0, ${HEIGHT})`);

      vis.yAxisGroup = vis.svg.append("g").attr("class", "axisWhite");

      vis.monthData = this.stats;
      vis.yearData = this.stats;
      /*
      vis.monthData = this.month;
      vis.yearData = this.year;
      */
      vis.update("Month");
    },
    update(gender) {
      this.gender = gender;
      const vis = this;
      vis.data = gender == "Month" ? vis.monthData : vis.yearData;
      //vis.data = `vis.${this.gender} + "Data")`;
      //vis.xLabel.text(`${gender} To Date Quota`);

/*
      const y = d3
        .scaleLinear()
        .domain([
          d3.min(vis.data, (d) => d.height) * 0.95,
          d3.max(vis.data, (d) => d.height),
        ])
        .range([HEIGHT, 0]);
*/
      const y = d3
        .scaleLinear()
        .domain([
          d3.min(vis.data, (d) => 0),
          d3.max(vis.data, (d) => 100),
        ])
        .range([HEIGHT, 0]);

      const x = d3
        .scaleBand()
        .domain(vis.data.map((d) => d.marketName))
        .range([0, WIDTH])
        .padding(0.4);

      const xAxisCall = d3.axisBottom(x);
      vis.xAxisGroup.transition().duration(500).call(xAxisCall);


      const yAxisCall = d3.axisLeft(y);
      vis.yAxisGroup.transition().duration(500).call(yAxisCall);

      // DATA JOIN
      const rects = vis.svg.selectAll("rect").data(vis.data);

      // EXIT
      rects
        .exit()
        .transition()
        .duration(500)
        .attr("height", 0)
        .attr("y", HEIGHT)
        .remove();

      // UPDATE
      rects
        .transition()
        .duration(500)
        .attr("x", (d) => x(d.marketName))
        .attr("y", (d) => y(d.y_Percentage))
        .attr("width", x.bandwidth)
        .attr("height", (d) => HEIGHT - y(d.y_Percentage));

      // ENTER
      rects
        .enter()
        .append("rect")
        .attr("x", (d) => x(d.marketName))
        .attr("width", x.bandwidth)
        .attr("fill", "rgba(255,255,255,0.6)")
        .attr("y", HEIGHT)
        .transition()
        .duration(500)
        .attr("height", (d) => HEIGHT - y(d.y_Percentage))
        .attr("y", (d) => y(d.y_Percentage));

      //console.log(rects);
    },
  },
  computed: {
    wrapperWidth: function () {
      return document.getElementsByClassName("bar-chart-wrapper")[0]
        .offsetWidth;
    },
  },

  mounted() {
    this.generateBar();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*this can't be scoped styles*/
.axisWhite {
  color: #fff !important;
}
.dropdown-menu {
  background-color: rgba(50, 50, 50, 0.8);
  color: #fff;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: rgba(100, 100, 100, 0.6);
}
</style>
