import Vuex from "vuex";
// import Vue from "vue";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import router from "../router";
import slugify from "slugify";
import moment from "moment";
import * as Sentry from "@sentry/vue";

//modules
import announcements from './modules/announcements';
import faq from './modules/faq';
import collaborate from './modules/collaborate';
import fromthefort from './modules/fromthefort';
import trailblazor from './modules/trailblazor'
import challengerInsights from './modules/challengerInsights';
import reports from './modules/reports';
import listings from './modules/listings';
import cashManagement from './modules/cashManagement';
import expeditedOrders from './modules/expeditedOrders';
import acList from './modules/acList';
import priceLists from './modules/priceLists';
import fieldService from './modules/serviceInstallation';
import acks from './modules/acks';
import invs from './modules/invs';


/*
return axios
			.post(`${this.server}/api/v1/loglogin/`, body)
			.then((response) => {
			  console.log(response);
			})
			.catch((err) => {
			  console.log("error logging");
			});
*/

// import VueNoty from 'vuejs-noty';
// import todos from './modules/todos';
// import posts from './modules/posts';
// import coolInstalls from './modules/coolInstalls';

// Load Vuex
// Vue.use(Vuex);

// Create store
export default new Vuex.Store({
	modules: {
		announcements,
		faq,
		fromthefort,
		collaborate,
		trailblazor,
		challengerInsights,
		reports,
		listings,
		cashManagement,
		expeditedOrders,
		acList,
		priceLists,
		fieldService,
		acks,
		invs
	},
	state: {
		server: "https://ordermanage.spacesaver.com",
		nav: false,
		tour: false,
		token: localStorage.getItem("SSCUSER"),
		isLoggedIn: localStorage.getItem("SSCUSER") ? true : false,
		user: localStorage.getItem("SSCUSER")
			? jwtDecode(localStorage.getItem("SSCUSER"))
			: {},
		activePage: "images",
		isDashboard: localStorage.getItem("SSCview") == 'website' ? false : true,
		// isClassicView: localStorage.getItem("SSCview") == 'website' ? true : false,
		//isAdmin: jwtDecode(localStorage.getItem("SSCUSER")),	
		expeditedOrderCount: null,
		newPostCount: null,
		homeNotifications: null,
		reportsHomePosts: null,
		collaborateView: null,
		fireworksHints: 0,
		fireworks: false,
		foundFireworks: 0,
		fireworksFound: {
			footer: null,
			collaborate: null,
			media: null,
			order: null,
			hometour: null,
		},
		incomingReport: "https://ordermanage.spacesaver.com/salesdocs/salesdocs/Incoming%20Net%20Revenue%20by%20Market%20and%20Salesperson%20(Ranking)%20YTD.xlsx",
		invoicedReport: "https://ordermanage.spacesaver.com/salesdocs/salesdocs/Invoiced%20Net%20Revenue%20by%20Market%20and%20Salesperson%20(Ranking)%20YTD.xlsx",
		market: "",
		fieldServiceCat: "",
		fileTypes: [
			{ title: "Docs", search: "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)", active: false },
			{ title: "Images", search: "JPEG", active: false },
			{ title: "Videos", search: "MPEG4", active: false },
		],
		limitedFileTypes: [
			{ title: "Documents", search: "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)", active: false },
			{ title: "Videos", search: "MPEG4", active: false },
		],
		markets: [
			{ title: "Agriculture", search: "Market/Agriculture", active: false },
			{ title: "Athletics", search: "Market/athletics", active: false },
			{ title: "Business", search: "Market/business", active: false },
			{ title: "Education", search: "Market/education", active: false },
			{ title: "Healthcare", search: "Market/healthcare", active: false },
			{ title: "Higher Education", search: "Market/Higher Education", active: false },
			{ title: "K-12", search: "Market/K-12", active: false },
			
			{ title: 'Libraries', search: 'Market/library', active: false },
			{ title: "Manufacturing", search: "Market/manufacturing", active: false },
			{ title: "Government", search: "Market/government", active: false },
			{ title: "Military", search: "Market/military", active: false },
			{ title: "Museum", search: "Market/museum", active: false },
			{ title: "Public Safety", search: "Market/public safety", active: false },
		],
		products: [
			{
				title: "Mobile",
				search: "mobile",
				active: false,
				cats: [
					{
						title: "Powered",
						search: "Powered",
						active: false,
						cats: [
							{ title: "Eclipse", search: "Products/Mobile/Powered/Eclipse" },
							{ title: "ActivRAC 16P", search: "Products/Mobile/Powered/ActivRAC 16P" },
							{ title: "ActivRAC 30P", search: "Products/Mobile/Powered/ActivRAC 30P" },
							{ title: "XTend", search: "Products/Mobile/Powered/XTend" },
							{ title: "ActivRAC 7P", search: "Products/Mobile/Powered/ActivRAC 7P" },
						]
					},
					{
						title: "Mechanical Assist",
						search: "Mechanical Assist",
						active: false,
						cats: [
							{ title: "ActivRAC 3M", search: "Products/Mobile/Mechanical%20Assist/ActivRAC%203M" },
							{ title: "ActivRAC 7M", search: "Products/Mobile/Mechanical%20Assist/ActivRAC%207M" },
							{ title: "Standard Mobile", search: "Products/Mobile/Mechanical%20Assist/Standard%20Mobile" },
							{ title: "Wheelhouse", search: "Products/Mobile/Mechanical%20Assist/Wheelhouse" },

						]
					},
					{
						title: "Manual",
						search: "Manual",
						active: false,
						cats: [
							{ title: "Standard Mobile", search: "Products/Mobile/Manual/Standard%20Mobile" },
							{ title: "Wheelhouse", search: "Products/Mobile/Manual/Wheelhouse" }
						]
					},
					{
						title: "Art Racks",
						search: "Art Racks",
						active: false,
						cats: [
							{ title: "Carriage-Mounted", search: "Products/Mobile/Carriage-Mounted Art Rack" },
							{ title: "Floor-Supported", search: "Products/Mobile/Floor-Supported Pull-Out Art Rack" },
							{ title: "Lateral", search: "Products/Mobile/Lateral Art Rack" },
							{ title: "Mod-Glider Pro", search: "Products/Mobile/Mod-Glider Pro" },
							
							
							{ title: "Overhead-Supported", search: "Products/Mobile/Overhead-Supported Pull-Out Art Rack" },
							
							{ title: "Wall-Mounted", search: "Products/Mobile/Wall-Mounted Art Screen" }
						]
					},
				]
			},
			{
				title: "Shelving",
				search: "shelving",
				cats: [
					{ title: "4-Post", search: "Products/Shelving/4-Post" },
					//{ title: "Art Racks - Screens", search: "Products/Shelving/Art Racks - Screens" },
					{ title: "Cantilever", search: "Products/Shelving/Cantilever" },
					{ title: "Case-Type", search: "Products/Shelving/Case-Type" },
					{ title: "Doors and Drawers", search: "Products/Shelving/Doors and Drawers" },
					{ title: "FrameWRX", search: "Products/Shelving/FrameWRX" },
					{ title: "GROW Drying Cart and Rack", search: "Products/Shelving/GROW Drying Rack and Cart" },
					{ title: "Nantucket Drawers and Trays", search: "Products/Shelving/Nantucket Drawers and Trays" },
					//{ title: "Parachute Rack", search: "Products/Shelving/Cantilever/Parachute Rack" },					
					{ title: "Stationary High-Bay", search: "Products/Shelving/Stationary High-Bay" },
					{ title: "Wide Span Shelving", search: "Products/Shelving/Wide Span Shelving" },
				]
			},
			{
				title: "Storage",
				search: "storage",
				cats: [
					{ title: "ActiveStor", search: "Products/Storage/ActiveStor" },
					{ title: "Day Use Locker", search: "Products/Storage/Day Use Locker" },
					{ title: "Evidence Locker", search: "Products/Storage/Evidence Locker" },
					{ title: "FreeStyle Locker", search: "Products/Storage/FreeStyle Locker" },
					{ title: "Laterals", search: "Products/Storage/Laterals" },
					{ title: "Mobility Crate", search: "Products/Storage/Mobility Crate" },
					{ title: "Parachute/Universal Container", search: "Products/Storage/Parachute-Universal Container" },
					{ title: "Rapid Readiness Box", search: "Products/Storage/Rapid Readiness Box" },
					{ title: "Gun Lockers", search: "Products/Storage/Gun Lockers" },
					{ title: "Shipboard UWR", search: "Products/Storage/Shipboard%20UWR" },
					{ title: "Tactical Readiness Locker", search: "Products/Storage/TRL - Tactical Readiness Locker" },
					{ title: "UEWSS", search: "Products/Storage/UEWSS - Universal Expeditionary Weapons Storage System" },
					{ title: "UWR", search: "Products/Storage/UWR - Universal Weapons Rack" },
					{ title: "Museum Cabinets", search: "Products/Storage/Museum Cabinets" },
					{ title: "Weapon Rack III", search: "Products/Storage/Weapon Rack III" },
			
				]
			},
		],
		fieldServiceCats: [
			//{ title: "Operating Instructions", search: "operating instructions", active: false },
			{ title: "Installation Academy", search: "installation academy", active: false },
			//{ title: "Spanish Manuals", search: "spanish Manuals", active: false }	
		],
		fieldServiceInstalls: [
			//{ title: "Operating Instructions", search: "operating instructions", active: false },
			{ title: "Installer Files", search: "Installation%20Software/Installer Files", active: false },
			//{ title: "Spanish Manuals", search: "spanish Manuals", active: false }	
		],
		fieldServiceShelving: [
			{ title: "4-Post and Case Type", search: "4-Post%20and%20Case%20Type" },
			{ title: "Cantilever", search: "Cantilever" },
			{ title: "Cantilever Rack", search: "Cantilever%20Rack" },
			{ title: "Drawers & Doors", search: "Drawers%20&%20Doors" },
			{ title: "Flexible Shelving", search: "Shelving/Flexible%20Shelving" },
			{ title: "Framed Doors", search: "Shelving/Framed%20Doors" },
			{ title: "FrameWRX", search: "Shelving/FrameWRX" },
			{ title: "GROW Drying Cart and Rack", search: "Shelving/GROW Drying Cart and Rack" },
			{ title: "Hinged Doors", search: "Shelving/Hinged%20Doors" },
			{ title: "Widespan", search: "Shelving/Widespan" },
			{ title: "Wire Shelving", search: "Shelving/Wire%20Shelving" },
			{ title: "Miscellaneous", search: "Shelving/Miscellaneous" },
			//{ title: "Art Racks (Static)", search: "Art%20Racks%20(Static)" },
		],
		fieldServiceStorage: [
			{ title: "Evidence Locker", search: "Storage/Evidence%20Locker" },
			{ title: "Weapons Racks", search: "Storage/Weapons Racks" },
			{ title: "Firearm Locker and Gun Box", search: "Storage/Firearm%20Locker%20and%20Gun%20Box" },
			{ title: "Museum Products", search: "Storage/Museum%20Products" },
			{ title: "Day Use lockers", search: "Storage/Day%20Use%20Lockers" },
			//{ title: "Multi-Tier Lockers", search: "Personal%20Storage%20Locker/Multi-Tier%20Lockers" },
			{ title: "FreeStyle", search: "Storage/FreeStyle%20Locker" },
			{ title: "Refrigeration and Drying", search: "Storage/Refrigeration%20and%20Drying" },
			{ title: "ActiveStor", search: "Storage/ActiveStor" },
			{ title: "Military Containers", search: "Storage/Military%20Containers" },
			{ title: "Miscellaneous", search: "Storage/Miscellaneous" },
		],
		fieldServiceMobile: [
			{ title: "Anti-tips", search: "Mobile/Anti-tips" },
			{ title: "Carriages", search: "Mobile/Carriages" },
			//{ title: "Eclipse", search: "Eclipse" },
			{ title: "Face Panels", search: "Mobile/Face%20Panels" },
			{ title: "Floors", search: "Mobile/Floors" },
			{ title: "Laterals", search: "Mobile/Laterals" },
			{ title: "Locks", search: "Mobile/Locks" },
			{ title: "Mechanical Assist", search: "Mobile/Mechanical%20Assist" },
			{ title: "Rails", search: "Mobile/Rails" },
			//{ title: "Safeties", search: "Safeties" },
			{ title: "Wheelhouse", search: "Mobile/Wheelhouse" },
			{ title: "Miscellaneous", search: "Mobile/Miscellaneous" },
		],
		fieldServiceActivRAC: [
			{ title: "3M", search: "Mobile/ActivRAC/3M" },
			{ title: "7M", search: "Mobile/ActivRAC/7M" },
			{ title: "7P", search: "Mobile/ActivRAC/7P" },
			{ title: "16P", search: "Mobile/ActivRAC/16P" },
			{ title: "30P", search: "Mobile/ActivRAC/30P" },
			{ title: "Miscellaneous", search: "Mobile/ActivRAC/Miscellaneous" },
		],
		fieldServiceEclipse: [
			{ title: "Board Functions", search: "Eclipse/Board Functions" },
			{ title: "Control Head", search: "Eclipse/Control Head" },
			{ title: "Distance Sensor and Limits", search: "Eclipse/Distance Sensor and Limits" },
			{ title: "Eclipse Operations", search: "Eclipse/Eclipse Operations" },
			{ title: "Electrical Installation", search: "Eclipse/Electrical Installation" },
			{ title: "General Maintenance", search: "Eclipse/General Maintenance" },
			{ title: "General Troubleshooting", search: "Eclipse/General Troubleshooting" },
			{ title: "Lighting", search: "Eclipse/Lighting" },
			{ title: "ModLogic", search: "Eclipse/ModLogic" },
			{ title: "Safeties", search: "Eclipse/Safeties" },
			{ title: "Upgrading Legacy Systems", search: "Eclipse/Upgrading Legacy Systems" },
			{ title: "Miscellaneous", search: "Eclipse/Miscellaneous" },
		],
		mediaContracts: [
			{ title: "General", search: "Contracts/General", crumb: "> Contracts > General ", active: false },
			{ title: "GPO", search: "Contracts/GPO", crumb: "> Contracts > GPO ", active: false },
			{ title: "GSA", search: "Contracts/GSA", crumb: "> Contracts > GSA ", active: false },
			{ title: "ADS", search: "Contracts/ADS", crumb: "> Contracts > ADS ", active: false },
			{ title: "OMNIA", search: "Contracts/OMNIA", crumb: "> Contracts > OMNIA ", active: false },
			{ title: "KI Sourcewell", search: "Contracts/KI%20Sourcewell", crumb: "> Contracts > Sourcewell KI ", active: false },
			{ title: "Spacesaver Sourcewell", search: "Contracts/Spacesaver%20Sourcewell", crumb: "> Contracts > Sourcewell%20Spacesaver ", active: false },
			{ title: "VHA IDIQ", search: "Contracts/VHA%20IDIQ", crumb: "> Contracts > VHA IDIQ ", active: false },
			{ title: "Equalis", search: "Contracts/Equalis", crumb: "> Contracts > Equalis ", active: false },

			// premiere
			// Vizient
		],
		mediaGeneral: [
			{ title: "Customer Support", search: "Customer%20Support", crumb: "> General > Customer Support ", active: false },
			//{ title: "Engineering", search: "Engineering", crumb: "> General > Engineering ", active: false },
			{ title: "Sales", search: "General%20Sales%20Docs", crumb: "> General > Sales", active: false },
			{ title: "Sustainability", search: "Sustainability", crumb: "> General > Sustainability ", active: false },
			{ title: "Electrical Updates", search: "Electrical%20Updates", crumb: "> General > Electrical Updates ", active: false },
		],
		mediaContEd: [
			{ title: "General", search: "AD%20Continuing%20Education/General", crumb: "> A&D CEU > General ", active: false },
			{ title: "IDCEC", search: "AD%20Continuing%20Education/IDCEC", crumb: "> A&D CEU > IDCEC ", active: false },
			{ title: "AIA Form B", search: "AD%20Continuing%20Education/AIA/AIA Form B", crumb: "> A&D CEU > AIA Form B ", active: false },
			{ title: "AIA Presentations", search: "AD%20Continuing%20Education/AIA/AIA%20Presentations", crumb: "> A&D CEU > AIA Presentations ", active: false },
			{ title: "AIA Presentation Desc", search: "AD%20Continuing%20Education/AIA/AIA%20Presentation%20Description", crumb: "> A&D CEU > AIA Presentation Description ", active: false },
		],
		mediaCET: [
			{ title: "Participant Guides", search: "CET/Participant Guides", crumb: "> CET > Participant Guides ", active: false },
			{ title: "Builds & Tools", search: "CET/Builds & Tools", crumb: "> CET > Builds & Tools ", active: false },
			{ title: "CET Designer Training", search: "CET/CET Designer Training", crumb: "> CET > CET Designer Training ", active: false },
			{ title: "Extension Overview Training", search: "CET/Extension Overview Training", crumb: "> CET > Extension Overview Training ", active: false },
			{ title: "Admin & General Training", search: "CET/Admin & General Training", crumb: "> CET > Admin & General Training ", active: false },
			{ title: "Mobile Training", search: "CET/Mobile Training", crumb: "> CET > Mobile Training ", active: false },	
			{ title: "Shelving Training", search: "CET/Shelving Training", crumb: "> CET > Shelving Training ", active: false },
			{ title: "Storage Training", search: "CET/Storage Training", crumb: "> CET > Storage Training ", active: false },
			{ title: "CET Workshops", search: "CET/CET Workshops", crumb: "> CET > CET Workshops ", active: false },
			{ title: "CET Templates", search: "CET/CET Templates", crumb: "> CET > CET Templates ", active: false },
			{ title: "Tips & Tricks", search: "CET/Tips & Tricks", crumb: "> CET > Tips & Tricks ", active: false },
			
		],
		mediaConfigura: [
			{ title: "Knowledge", search: "Configura/Knowledge", crumb: "> Configura > Knowledge ", active: false },
			{ title: "Manuals", search: "Configura/Manuals", crumb: "> Configura > Manuals ", active: false },
			{ title: "Releases", search: "Configura/Releases", crumb: "> Configura > Releases ", active: false },
			//{ title: "Testing", search: "Testing", active: false },
			{ title: "Training", search: "Configura/Training", crumb: "> Configura > Training ", active: false },
		],
		university: [
			{ title: "Virtual Product-Application School", search: "Sales%20and%20Training%20Support/Virtual%20Product-Application%20School", crumb: "University > Virtual Product/Application School ", active: false },
			{ title: "Market Course", search: "University/Training/Market%20Course", crumb: "> University > Market Course ", active: false },
			{ title: "Product Course", search: "University/Training/Product%20Course", crumb: "> University > Product Course ", active: false },
			{ title: "Configura Course", search: "University/Training/Configura%20Course", crumb: "> University > Configura Course ", active: false },
			{ title: "Selling Spacesaver", search: "University/Training/Selling%20Spacesaver", crumb: "> University > Selling Spacesaver ", active: false },
			{ title: "Webinar", search: "University/Training/Webinar", crumb: "> University > Webinar", active: false },
			{ title: "Product Presentations", search: "University/Training/Product%20Presentations", crumb: "> University > Product Presentations", active: false },
		],
		colors: [
			"#00b4b4",
			"#f50057",
			"#aa00ff",
			"#311b92",
			"#304ffe",
			"#2962ff",
			"#0091ea",
			"#00897b",
			"#01579b",
			"#9e9e9e",
			"#616161",
			"#9e9e9e",
			"#455a64",
			"#1de9b6",
			"#9c27b0",
			"#651fff",
			"#880e4f",
		],
		adminUserList: [],
		eulaAccepted: false,
		leadTimeAccepted: false
	},
	actions: {


		login({ commit }, postData) {
			//console.log("in Vuex");

			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
			};

			// console.log(postData);
			// console.log(axiosConfig);
			// `https://spacenet3api.spacesaver.com/api/v1/loginspacenet`,
			// `http://localhost:5002/api/v1/loginspacenet`, local login

			
			return axios
				.post(`https://spacenet3api.spacesaver.com/api/v1/loginspacenet`, postData, axiosConfig)
				.then((response) => {

					console.log(response);

					if (response.data.success) {
						console.log('setting')
						localStorage.setItem("SSCUSER", response.data.token);
						console.log('set')
						commit("SET_TOKEN", response.data.token);
						commit("SET_USER", response.data.token);
						commit("SET_LOGIN", true);


						if (this.state.user.Roles.includes("ManageExpeditedOrders") || this.state.user.ACId.includes(0)) {
							commit("SET_EO_COUNT");
						}


						commit("SET_NEW_POST_COUNT");
						// Sentry.setUser({ email: this.state.user.Email });


						return true;
						//commit("SET_REPORTS");
						//commit("SET_COMMERCIAL");
						//commit("SET_SERVICE");
						//commit("SET_TRAILBLAZOR");
						//commit('clearPresentation');
						//commit('SET_ADMIN');
						
					} else {
						Vue.noty.error(
							"There seems to be an issue with your credentials."
						);
						return false;
					}


				})

				.catch((err) => {
					console.log(err);
					Vue.noty.error(
						"There seems to be an issue with your credentials"
					);

					return false;
				});

		},
		logout({ commit }) {
			return new Promise((resolve) => {
				localStorage.removeItem("SSCUSER");
				commit("SET_TOKEN", null);
				commit("SET_LOGIN", false);
				commit("DESTROY_USER");
				commit("clearPresentation");
				//commit('SET_ADMIN');
				resolve();
			});
		},
		impersonateUser({ commit }, postData, state) {
			let axiosConfig = {
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'Access-Control-Allow-Origin': 'https://spacenet3.spacesaver.com',
					Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
				},
			};

			return axios
				.post(
					`https://spacenet3api.spacesaver.com/api/v1/admin/impersonate`,
					//`http://localhost:5002/api/v1/admin/impersonate`,
					postData,
					axiosConfig
				)
				.then((response) => {
					localStorage.removeItem("SSCUSER");
					localStorage.setItem("SSCUSER", response.data.token);
					commit("SET_TOKEN", response.data.token);
					commit("SET_USER", response.data.token);
					commit("SET_LOGIN", true);
					if (this.state.user.Roles.includes("ManageExpeditedOrders") || this.state.user.ACId.includes(0)) {
						//console.log("is expedited orders")
						commit("SET_EO_COUNT");
					}
					commit("SET_NEW_POST_COUNT");
					//commit("GET_LAUNCH_GAME");
					//commit('clearPresentation');
					//commit('SET_ADMIN');
				});
		},

		resetPW({ commit }, postData) {
			//console.log("in Vuex");
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					"Access-Control-Allow-Origin": "https://spacenet3.spacesaver.com",
				},
			};
			return axios
				.post(`${process.env.VUE_APP_SERVER_URL}/api/v1/reset-password`, postData, axiosConfig)
				.then((response) => {
					console.log(response);
					if (response.data.type == 'success') {
						return true;
					} else {
						Vue.noty.error("There was an issue updating your password. Please check the security code that has a duration of 20 minutes.");
						return false;
						
					}
				})
				.catch((err) => {
					console.log(err);
					Vue.noty.error("There was an issue updating your password. Please check the security code that has a duration of 20 minutes.");
					return false;
				});
		},
	},

	mutations: {
		SET_USER(state, token) {
			const decoded = jwtDecode(token);
			state.user = decoded;
		},
		SET_TOKEN(state, token) {
			state.token = token;
		},
		DESTROY_USER(state) {
			// state.user = {};
			state.token = "";
		},
		SET_ADMIN(state, token) {
			if (state.user.admin == true) {
				state.isAdmin = true;
			} else {
				state.isAdmin = false;
			}
			//state.admin = jwtDecode(localStorage.getItem("SSCUSER")).admin
		},
		SET_LOGIN(state, val) {
			state.isLoggedIn = val;
		},
		setActivePage(state, name) {
			state.activePage = name;
		},
		UPDATE_REPORTS_HOME(state, data) {      
			state.reportsHomePosts = data;
		},
		UPDATE_HOME_NOTIFICATIONS(state, data) {      
			state.homeNotifications = data;
		},
		deleteUserFinal(state, postData) {
			let axiosConfig = {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
					"Access-Control-Allow-Origin": "*",
				},
			};

			return axios
				.post(
					`https://spacenet3api.spacesaver.com/api/v1/admin/deleteUser`,
					postData,
					axiosConfig
				)
				.then((response) => {
					//console.log(response);

					if (response.status == 200) {
						//this.getMyPresentations();
						//console.log('success');
						Vue.noty.success("Successfully deleted user");
					} else if (response.status == 500) {
						//console.log('failed');
						this.$noty.error("Oooops! Something went wrong");
						logout();
						router.push("/");
					}
				});
		},
		SET_EO_COUNT(state) {
			let axiosConfig = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("SSCUSER")
				},
			};
			return axios
				.get(
					`https://ordermanage.spacesaver.com/expeditedorders?pending=true`,
					axiosConfig
				)
				.then((response) => {
					if (response.data.length) {
						state.expeditedOrderCount = response.data.length;
						// if not a spacesaver employee

						let orders = response.data;
						let ACNum = state.user.ACId[0]
						if (state.user.ACId.length == 1 || !state.user.ACId.length) {
							if (ACNum != 0) {
								let acOrders = orders.filter((f) => {
									return f.acid == ACNum;
								});
								let alertNum = acOrders.length;
								//console.log(alertNum);
								state.expeditedOrderCount = alertNum;
							} else {
								//if a spacesaver employee
								state.expeditedOrderCount = response.data.length;
							}
						} else {
							state.expeditedOrderCount = '!'
								;
						}



					}
				});
		},
		SET_NEW_POST_COUNT(state) {
			let axiosConfig = {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("SSCUSER")
				},
			};
			return axios
				.get(`https://spacenet3api.spacesaver.com/api/v1/questions`, axiosConfig)
				.then((response) => {
					//console.log(response.data.length);
					//state.newPostCount = response.data.length;
					let allPosts = response.data;

					let newPosts = allPosts.filter((f) => {
						return moment(f.Posted).isAfter(state.user.LastActivity);
					});

					//console.log(newPosts);

					state.newPostCount = newPosts.length;
					//response.data.Posted
					/*
					if (response.data.length) {
						state.newPostCount = response.data.length;
					}
					*/
				});
		},	
		SET_VIEWED_COLLABORATE(state, token) {
			state.collaborateView = true;
		},
		//start homePage content	
		changeMarket(state, name) {
			state.market = name;
		},
		changefieldServiceCat(state, name) {
			state.fieldServiceCat = name;
		},
		showNavigation(state) {
			state.nav = true;
		},
		hideNavigation(state) {
			state.nav = false;
		},
		hideNavigation(state) {
			state.nav = false;
		},
		hideTour(state) {
			state.tour = false;
		},
		showTour(state) {
			state.tour = true;
		},
		ACCEPT_EULA(state, val) {
			state.eulaAccepted = true;
		},
		ACCEPT_LEADTIMES(state, val) {
			state.leadTimeAccepted = true;
		},
		SET_LAYOUT: (layout) => {
			if (layout == 'website') {
				state.isDashboard == false
			} else if (layout == 'dashboard') {
				state.isDashboard == true;
			}
			//this.$forceUpdate();
		},

	},

	getters: {
		isLoggedIn: (state) => {
			return state.isLoggedIn;
		},
		isAdmin: (state) => {
			if (state.user.admin) {
				return true;
			}
			return false;
		},
		isCashManagement: (state) => {
			const hasAdminRole = state.user?.Roles?.includes("admin");
			const hasAccountingRole = state.user?.Roles?.includes("acaccounting");
			const hasPrincipalRole = state.user?.Roles?.includes("acprincipal");
			const hasSSCRole = state.user?.Roles?.includes("ssc");
		  
			if (hasAdminRole || hasAccountingRole || hasPrincipalRole || hasSSCRole) {
			  return true;
			}
			return false;
		},
		  
		isReports: (state) => {
			const hasAdminRole = state.user?.Roles?.includes("admin");
			const hasAccountingRole = state.user?.Roles?.includes("acaccounting");
			const hasPrincipalRole = state.user?.Roles?.includes("acprincipal");
			const hasAdminAssistantRole = state.user?.Roles?.includes("acadminassistant");
			const hasSalespersonRoleWithRepId = state.user?.Roles?.includes("acsalesperson") && state.user?.RepId !== 0;
			const hasSSC = state.user?.Roles?.includes("ssc");
		  
			if (hasAdminRole || hasAccountingRole || hasPrincipalRole || hasAdminAssistantRole || hasSalespersonRoleWithRepId || hasSSC) {
			  return true;
			}
			return false;
		},
		  
		hideOrders: (state) => {
			const hasSalespersonRole = state.user?.Roles?.includes("acsalesperson");
			const hasExternalPersonRole = state.user?.Roles?.includes("externalperson");
			
			if ((hasSalespersonRole && state.user?.RepId === 0) || hasExternalPersonRole) {
			  return true;
			}
			return false;
		},

		isExpeditedOrders: (state) => {
			const canManageExpeditedOrders = state.user?.Roles?.includes("manageexpeditedorders");
			const hasACIdZero = state.user?.ACId?.includes(0);
		  
			if (canManageExpeditedOrders || hasACIdZero) {
			  return true;
			}
			return false;
		},
		  
		isSales: (state) => {
			const isSalesperson = state.user?.Roles?.includes("acsalesperson");
		  
			if (isSalesperson) {
			  return true;
			}
			return false;
		},
		  
		isACKSnINVS: (state) => {
			const hasPrincipalRole = state.user?.Roles?.includes("acprincipal");
			const hasAccountingRole = state.user?.Roles?.includes("acaccounting");
			const hasAdminAssistantRole = state.user?.Roles?.includes("acadminassistant");
		  
			if (hasPrincipalRole || hasAccountingRole || hasAdminAssistantRole) {
			  return true;
			}
			return false;
		},

		isGSA: (state) => {
			const isAdmin = state.user?.Roles?.includes("admin");
			const isGsa = state.user?.Roles?.includes("gsa");

			if (isAdmin || isGsa) {
				return true;
			}
			return false;
		},

		isSpacesaverEmployee: (state) => {
			const hasACIdZero = state.user?.ACId?.includes(0);

			if (hasACIdZero) {
				return true;
			}
			return false;
		},

		isExternalPerson: (state) => {
			const isExternal = state.user?.Roles?.includes("externalperson");
			const isSalespersonWithoutRepId = state.user?.Roles?.includes("acsalesperson") && state.user?.RepId === 0;

			if (isExternal || isSalespersonWithoutRepId) {
				return true;
			}
			return false;
		},

		token: (state) => state.token,

		getUser: (state) => {
			return state.user;
		},

		getColors: (state) => {
			return state.colors;
		},

		getActivePage: (state) => {
			return state.activePage;
		},

		getActivity: (state) => {
			return state.idleVue.isIdle;
		},
		
		getHomeNotifications: (state) => {    
			return state.homeNotifications;
		}
	},
});
