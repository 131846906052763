<template>
  <div class="mobile-top">
    <!--
    <home-tour v-if="$store.state.tour && !this.$store.getters.hideOrders" />
    <home-tour-no-orders
      v-else-if="$store.state.tour && this.$store.getters.hideOrders"
    />

       :style="{
        backgroundImage: 'url(' + require('/img/headerbg.svg') + ')',
      }"
  
    -->

    <section
      id="v-step-6"
      class="py-5"
      style="
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        position: relative;
      "
   
   
    >
      <div class="container">
<!--
        <div class="new-search mb20">
          <div class="input-group">
            <input 
            v-model="searchValue" 
            @keyup.enter.prevent="searchOrder"
            type="text" 
            class="form-control" 
            aria-label="Search listings by order number, PO number, or order name.." 
            style="padding: 14px"
            placeholder="Search listings by order number, PO number, or order name..."
            >
            <div class="input-group-append">
              <span class="input-group-text new-search-btn2">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </span>

              <span @click="searchOrder" class="input-group-text new-search-btn1">
                <span class="material-icons">search</span>
                {{ " " }}Search
              </span>

            </div>
          </div>
        </div>
        
 
      
        <div class="row row-eq-height mb20">
          <div class="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="" style="color: #fff">
              <router-link
                  to="/listings"
                  class="card feature-card dashboard-card" style="color: #fff; text-decoration: none; flex: 1 1 100%;"
            
                >
              <span class="teal-bg tc dashboard-icon">
                <img
                  style="padding: 0"
                  class="card-img-top"
                  src="@/assets/img/order.svg"
                  alt
                />
              </span>
               
              <div class="card-body">
                <h5 class="card-title mb5" style="margin-top: 0;">
                  <span
                    v-if="listings && listings.length > 0"
                    class="badge badge-orange"
                    style="margin-right: 5px; font-size: inherit"
                    >{{listings.length}}</span
                  >Orders</h5>
                <p class="small card-text">
                  My SSC order Listings
                </p>
              </div>
              </router-link>
            </div>
          </div>
          <div class="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="" style="color: #fff">
               <router-link
                    to="/acknowledgments"
                    class="card feature-card dashboard-card" style="color: #fff; text-decoration: none; flex: 1 1 100%;"
             
                  >
              <span class="orange-bg tc dashboard-icon">
                <img
                  style="padding: 0"
                  class="card-img-top"
                  src="@/assets/img/ack.svg"
                  alt
                />
              </span>
              
              <div class="card-body">
                <h5 class="card-title mb5" style="display: flex; margin-top: 0;">
                  <span
                    v-if="acks && acks.length > 0"
                    class="badge badge-orange"
                    style="margin-right: 5px; font-size: inherit"
                    >{{acks.length}}</span
                  >
                  Acknowledgments
                </h5>
                <p class="small card-text">
                  Orders with a promised ship date posted
                </p>
              </div>
               </router-link>
            </div>
          </div>
          <div class="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="" style="color: #fff">
               <router-link
                    to="/invoices"
                    class="card feature-card dashboard-card" style="color: #fff; text-decoration: none; flex: 1 1 100%;"
             
                  >
              <span class="fortbg1 tc dashboard-icon">
                <img
                  style="padding: 0"
                  class="card-img-top"
                  src="@/assets/img/inv.svg"
                  alt
                />
              </span>
               
              <div class="card-body">
                <h5 class="card-title mb5" style="display: flex; margin-top: 0;">
                 <span
                    v-if="invs && invs.length > 0"
                    class="badge badge-orange"
                    style="margin-right: 5px; font-size: inherit"
                    >{{invs.length}}</span
                  >
                  Invoices
                </h5>
                <p class="small card-text fw300">
                  Order invoices for shipped orders.
                </p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
        -->
      
        

        <div class="row row-eq-height hero-wrap">
          <div class="col-lg-8 mb-lg-0">
            <div
              v-if="newsLoading == true || !newsPosts.length"
              class="col-xs-12 col-sm-12 col-12 col-lg-12"
              style="
                min-height: 245.06px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
              "
            >
              <div class="flex-box-wrap">
                <div class="flex-internal">
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#fff'"
                  />
                  <p style="color: #fff">Loading......</p>
                </div>
              </div>
            </div>
            <div v-else class="banner-wrap">


              <carousel >
                <carousel-slide v-for="(slide, index) in calcBanners"
                    v-bind:key="index" class="carousel-slider">
                    <a
                      v-if="slide.ACF.external_link"
                      :href="slide.ACF.external_link"
                      target="_blank"
                      rel="noopeneer noreferrer"
                    >
                    <img :src="slide.ACF.slider_image.sizes.large" :alt="slide" class="img-fluid" style="object-fit: fill;">
                  </a>
                  <router-link v-else :to="/announcement/ + slide.slug">
                    <img :src="slide.ACF.slider_image.sizes.large" :alt="slide" class="img-fluid" style="object-fit: fill;">
                    </router-link>
                </carousel-slide>
            </carousel>

<!--
              <div
                class="carousel slide"
                id="carousel-ecommerce-01"
                data-ride="carousel"
                data-interval="5000"
              >
                <div class="carousel-inner">
                  <div
                    v-for="(slide, index) in calcBanners"
                    v-bind:key="index"
                    class="carousel-item"
                    :class="{ active: index === 0 }"
                  >
                    <a
                      v-if="slide.ACF.external_link"
                      :href="slide.ACF.external_link"
                      target="_blank"
                      rel="noopeneer noreferrer"
                    >
                      <img
                        class="img-fluid"
                        v-bind:src="slide.ACF.slider_image.sizes.large"
                        alt
                      />
                    </a>

                    <router-link v-else :to="/announcement/ + slide.slug">
                      <img
                        class="img-fluid"
                        v-bind:src="slide.ACF.slider_image.sizes.large"
                        alt
                      />
                    </router-link>
                  </div>
                </div>
                <div>
                  <a
                    class="carousel-control-prev justify-content-start"
                    href="#carousel-ecommerce-01"
                    role="button"
                    data-slide="prev"
                  >
                    <i class="fas fa-chevron-left controls"></i>
                    <span class="sr-only">Previous</span>
                  </a>

                  <a
                    class="carousel-control-next justify-content-end"
                    href="#carousel-ecommerce-01"
                    role="button"
                    data-slide="next"
                  >
                    <i class="fas fa-chevron-right controls"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>

            -->  
            </div>
          </div>

          <div class="col-lg-4 mb-lg-0">
            <div
              v-if="newsLoading == true || !newsPosts.length"
              class="col-xs-12 col-sm-12 col-12 col-lg-12"
              style="
                min-height: 245.06px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 30px;
              "
            >
              <div class="flex-box-wrap">
                <div class="flex-internal">
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#fff'"
                  />
                  <p style="color: #fff">Loading......</p>
                </div>
              </div>
            </div>
            <div v-else class="banner-wrap announcement-banner">
              <!-- <h6>Announcements</h6>-->
              <div class="news-scroll">
                <div v-if="!calcNewsPosts">Loading</div>
                <div
                  v-else
                  style="display: flex; flex-direction: column; flex-grow: 1"
                >
                  <div
                    v-for="(news, index) in calcNewsPosts.slice(0, 4)"
                    :key="index"
                    style="flex-grow: 1"
                  >
                    <a
                      v-if="news.ACF.external_link"
                      :href="news.ACF.external_link"
                      target="_blank"
                      rel="noopeneer noreferrer"
                    >
                      <p class="top-news-item">
                        <!--
												<i class="fas fa-newspaper" aria-hidden="true"></i>
												-->
                        <!--
                        <span
                          class="news-date"
                          style="margin-right: 5px; font-size: inherit"
                          >{{ moment(news.date).format("MMM Do YYYY")}}</span
                        >
                        -->
                        <span v-html="news.title.rendered" class="fw300"></span>
                      </p>
                    </a>
                    <!--
										<a
											v-if="news.ACF.external_link"
											:href="news.ACF.external_link"
											target="_blank"
											rel="noopeneer noreferrer"
										>
											<p class="top-news-item">
												<i class="fa fa-newspaper-o" aria-hidden="true"></i>
												<span v-html="news.title.rendered"></span>
											</p>
										</a>
										-->
                    <router-link v-else :to="/announcement/ + news.slug">
                      <p class="top-news-item">
                        <!--
												<i class="fas fa-newspaper" aria-hidden="true"></i>
												-->
                        <!--
                        <span
                          class="news-date"
                          style="margin-right: 5px; font-size: inherit"
                          >{{ moment(news.date).format("MMM Do YYYY") }}</span
                        >
                        -->
                        <span v-html="news.title.rendered"></span>
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
              <a
                data-v-5a90ec03
                href="/announcements"
                class="link announcement-link"
              >
                View All
                <i data-v-5a90ec03 class="material-icons"
                  >keyboard_arrow_right</i
                >
              </a>
            </div>
          </div>
        </div>
      </div>
      <img class="top-angle" src="@/assets/img/top-angle.svg" />
    </section>

    <!-- SECTION -->
    <section id="v-step-7" class="second-banner">
      <div class="container">
        <!--
        <div class="row">
          <div class="col col-12 col-xs-12">
            <h1>Welcome back, {{ firstName }}!</h1>
            <p>
              Below are a few helpful links to help you find your way around
              <span
                class="arrow-down"
              >&cudarrl;</span>
            </p>
          </div>
        </div>
        -->


        <div class="row row-eq-height">
          <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="card feature-card">
              <a @click="showHelp" class="gray-bg tc">
                <img class="card-img-top" src="@/assets/img/learn.svg" alt />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">Learn Spacenet</h5>
                <p class="small card-text" style="min-height: 38px">
                  This link will enable the Spacenet 3 training tour.
                </p>
                <span class="link" @click="showHelp">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="this.$store.getters.isExpeditedOrders"
            class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3"
          >
            <div class="card feature-card">
              <a href="/expedited-orders" class="orange-bg tc">
                <img
                  class="card-img-top"
                  src="@/assets/img/locker-orders.svg"
                  alt
                />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">
                  <span
                    v-if="this.$store.state.expeditedOrderCount !== null"
                    class="alert-badge"
                    >{{ this.$store.state.expeditedOrderCount }}</span
                  >
                  Expedited Orders
                </h5>
                <p class="small card-text" style="min-height: 38px">
                  Order status related to Spacesaver parts requests.
                </p>
                <a class="link" href="/expedited-orders">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>

          <div
            v-else-if="
              !this.$store.getters.isExpeditedOrders &&
              !this.$store.getters.hideOrders
            "
            class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3"
          >
            <div class="card feature-card">
              <a href="/listings" class="orange-bg tc">
                <img
                  class="card-img-top"
                  src="@/assets/img/locker-orders.svg"
                  alt
                />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">
                  <!--
                  <span
                    v-if="this.$store.state.expeditedOrderCount !== null"
                    class="badge badge-orange"
                    style="margin-right: 5px; font-size: inherit"
                    >{{ this.$store.state.expeditedOrderCount }}</span
                  >
                  -->
                  My Orders
                </h5>
                <p class="small card-text" style="min-height: 38px">
                  View My Order Listings
                </p>
                <a class="link" href="/listings">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>

          <!--

          <div
            v-if="!this.$store.getters.hideOrders "
            class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3"
          >
            <div class="card feature-card">
              <a href="/expedited-orders" class="orange-bg tc">
                <img
                  class="card-img-top"
                  src="@/assets/img/locker-orders.svg"
                  alt
                />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">
                  <span
                    v-if="this.$store.state.expeditedOrderCount !== null"
                    class="badge badge-orange"
                    style="margin-right: 5px; font-size: inherit"
                    >{{ this.$store.state.expeditedOrderCount }}</span
                  >
                  Expedited Orders
                </h5>
                <p class="small card-text">
                  Order status related to Spacesaver parts requests.
                </p>
                <a class="link" href="/expedited-orders">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>
          -->

          <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="card feature-card">
              <a href="/collaborate" class="blue-bg tc">
                <img
                  class="card-img-top"
                  src="@/assets/img/social-chat.svg"
                  alt
                />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">
                  <span
                    v-if="
                      this.$store.state.newPostCount !== null &&
                      this.$store.state.newPostCount > 0 &&
                      this.$store.state.collaborateView != true
                    "
                    class="alert-badge"
                    >{{ this.$store.state.newPostCount }}</span
                  >Collaborate
                </h5>
                <p class="small card-text" style="min-height: 38px">
                  Join in the discussion with the Spacesaver group!
                </p>
                <a class="link" href="/collaborate">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>

          <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
            <div class="card feature-card">
              <a href="/marketing-materials" class="teal-bg tc">
                <img
                  class="card-img-top"
                  src="@/assets/img/marketing-materials.svg"
                  alt
                />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">Marketing Materials</h5>
                <p class="small card-text" style="min-height: 38px">
                  View product images, documents, and videos.
                </p>
                <a class="link" href="/marketing-materials">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>

          <div
            v-if="this.$store.getters.hideOrders"
            class="col col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3"
          >
            <div class="card feature-card">
              <a href="/calendar" class="orange-bg tc">
                <img class="card-img-top" src="@/assets/img/calendar.svg" alt />
              </a>
              <div class="card-body">
                <h5 class="card-title my-2">Spacesaver Events</h5>
                <p class="small card-text" style="min-height: 38px">
                  Bringing the Spacesaver group together to connect and
                  collaborate!
                </p>
                <a class="link" href="/calendar">
                  GO
                  <i class="material-icons">keyboard_arrow_right</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END -->

    <!-- From The Fort -->
    <section id="v-step-8" class="py-5 second-banner fromfortbg fort">
      <img class="bottom-angle" src="@/assets/img/bottom-angle.svg" />
      <div class="container">
        <img class="mw300" src="@/assets/img/FromTheFort_Spacesaver.png" />
        <h4 class="text-white">Monthly Insights for the Spacesaver Group</h4>
        <div
          v-if="fortLoading == true || !fromTheFortPosts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="
            min-height: 302px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(100, 115, 128, 0.1);
            margin-bottom: 3rem !important;
          "
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#fff'"
              />
              <p style="color: #fff">Loading......</p>
            </div>
          </div>
        </div>

        <div v-else class="row row-eq-height">
          <div class="col-12 col-sm-12 col-md-12">
            <div class="row row-eq-height">
              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[0].images.medium"
                    alt="test image"
                  />
                  -->
                  <div class="fortcardimage fortbg1">
                    <div class="fortimagewrap">
                      <img src="@/assets/img/fort-icon.png" />
                      <h3
                        class
                        v-html="
                          truncateTitle(fromTheFortPosts[0].title.rendered)
                        "
                      ></h3>
                    </div>
                  </div>
                  <div class="card-content">
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[0].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[0].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <i class="material-icons">keyboard_arrow_right</i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[1].images.medium"
                    alt="test image"
                  />
                  -->
                  <div class="fortcardimage fortbg2">
                    <div class="fortimagewrap">
                      <img src="@/assets/img/fort-icon.png" />
                      <h3
                        class
                        v-html="
                          truncateTitle(fromTheFortPosts[1].title.rendered)
                        "
                      ></h3>
                    </div>
                  </div>
                  <div class="card-content">
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[1].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[1].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <i class="material-icons">keyboard_arrow_right</i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[1].images.medium"
                    alt="test image"
                  />
                  -->
                  <div class="fortcardimage fortbg3">
                    <div class="fortimagewrap">
                      <img src="@/assets/img/fort-icon.png" />
                      <h3
                        class
                        v-html="
                          truncateTitle(fromTheFortPosts[2].title.rendered)
                        "
                      ></h3>
                    </div>
                  </div>
                  <div class="card-content">
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[2].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[2].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <i class="material-icons">keyboard_arrow_right</i>
                    </a>
                  </div>
                </div>
              </div>

              <div class="col col-12 col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card fortcard">
                  <!--
                  <img
                    v-if="fromTheFortPosts"
                    class="fortcardimage"
                    :src="fromTheFortPosts[1].images.medium"
                    alt="test image"
                  />
                  -->
                  <div class="fortcardimage fortbg4">
                    <div class="fortimagewrap">
                      <img src="@/assets/img/fort-icon.png" />
                      <h3
                        class
                        v-html="
                          truncateTitle(fromTheFortPosts[3].title.rendered)
                        "
                      ></h3>
                    </div>
                  </div>
                  <div class="card-content">
                    <p
                      v-html="
                        truncateExcerpt(fromTheFortPosts[3].excerpt.rendered)
                      "
                      class="small card-text"
                      style="margin-bottom: 15px"
                    ></p>
                    <a
                      class="link"
                      :href="fromTheFortPosts[3].link"
                      target="_blank"
                      rel="noopener"
                    >
                      Read More
                      <i class="material-icons">keyboard_arrow_right</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col col-12">
                <div class="card-deck">
                  <div class="card">
                    <div class="card-strip-top fortbg3">
                      <h5>Previous Articles</h5>
                    </div>
                    <div v-if="!fromTheFortPosts" class="card-scroll">
                      Loading
                    </div>
                    <div v-else class="card-scroll">
                      <div
                        v-for="post in fromTheFortPosts"
                        :key="post.title.rendered"
                      >
                        <a :href="post.link" target="_blank" rel="noopener">
                          <div class="prev-forts">
                            <p>{{ post.title.rendered }}</p>
                            <i class="material-icons">keyboard_arrow_right</i>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
      <img class="faq-top-angle" src="@/assets/img/faq-top.svg" />
    </section>

    <section class="py-5 faq-tease">
      <div class="container">
        <h2 id="v-step-9" class="mb-3 text-center" data-config-id="header">
          Frequently Asked Questions
        </h2>
        <div
          v-if="faqLoading == true || !faqPosts.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="
            min-height: 302px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          "
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#333'"
              />
              <p style="color: #333">Loading......</p>
            </div>
          </div>
        </div>

        <div v-else class="accordion" id="faq-01" data-config-id="faq">
          <div
            v-for="(faq, index) in faqPosts"
            :key="faq.index"
            class="mb-2 border-bottom bgwhite bs-shadow"
          >
            <h5 id="faq-heading1-01 faq-heading">
              <button
                class="btn btn-link btn-block text-left d-flex collapsed"
                type="button"
                data-toggle="collapse"
                :data-target="'#faq-collapse1-' + index"
                aria-expanded="false"
                aria-controls="faq-collapse1-01"
                style="white-space: normal !important; word-wrap: break-word"
              >
                <span v-html="faq.title.rendered"></span>
                <i class="p-1 ml-auto my-auto fas fa-arrow-circle-down"></i>
              </button>
            </h5>
            <div
              class="collapse answer"
              :id="'faq-collapse1-' + index"
              aria-labelledby="faq-heading1-01"
              data-parent="#faq-01"
              style="padding: 0 10px"
            >
              <p id="answer" v-html="faq.content.rendered"></p>
            </div>
          </div>
        </div>
        <div v-if="!faqLoading || faqPosts.length" class="text-center mt20">
          <router-link to="/faq" class="mb0 lh1 mlauto list-btn"
            >See All</router-link
          >
        </div>
      </div>
    </section>
    <!--
    <section class="home-footer-top">
      <img class="faq-bottom-angle" src="@/assets/img/faq-bottom.svg" />
    </section>
    -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import HomeTour from "../components/Tours/HomeTour";
// import HomeTourNoOrders from "../components/Tours/HomeTourNoOrders";
import Carousel from '../components/carousel/Carousel.vue';
import CarouselSlide from '../components/carousel/CarouselSlide.vue';
//import * as Sentry from "@sentry/vue";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer thisisanexampleofunauthorized"
  },
};

export default {
  name: "Home",
  components: {
    AtomSpinner,
    // HomeTour,
    // HomeTourNoOrders,
    Carousel : Carousel,
    CarouselSlide : CarouselSlide,
  },
  data() {
    return {
      server: import.meta.env.VITE_APP_SERVER_URL,
      fromthefort: import.meta.env.VITE_APP_FROM_THE_FORT,
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      media: [],
      newsLoading: false,
      fortLoading: false,
      faqLoading: false,
      loading: false,
      fromTheFortPosts: [],
      newsPosts: [],
      faqPosts: [],
      searchValue: "",
      listings: null,
      acks: null,
      invs: null,
    };
  },
  methods: {
    firstLoad: function () {
      this.$store.commit("setActivePage", "home");
      //this.$refs.checkbox.checked = false;
      let windowWidth = window.innerWidth;
      if (Number(windowWidth) < 992) {
        //console.log("mobile");
        this.$store.commit("hideTour");
      }
      //Sentry.setUser({ email: this.$store.state.user.Email });
      this.newsSync();
      this.fortSync();
      this.faqSync();
      //this.listingSync();
      //this.getAllAcks();
      //this.getAllInvs();
      //this.$tours['HomeTour'].start()
    },

    truncateTitle: function (value) {
      if (value.length > 35) {
        value = value.substring(0, 55) + "...";
      }
      return value;
    },
    truncateExcerpt: function (value) {
      if (value.length > 85) {
        value = value.substring(0, 70) + "...";
      }
      return value;
    },
    newsSync: function () {
      this.newsLoading = true;
      if (this.$store.state.announcements.announcementHomePosts.length) {
        this.newsPosts = this.$store.state.announcements.announcementHomePosts;
        this.newsLoading = false;
      } else {
        return axios
          .get(
            `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/news?per_page=30`
          )
          .then((response) => {
            //console.log(response);
            this.newsPosts = response.data;
            this.newsLoading = false;
            this.$store.commit("UPDATE_ANNOUNCEMENTS_HOME", this.newsPosts);
          });
      }
    },
    fortSync: function () {
      this.fortLoading = true;
      if (this.$store.state.fromthefort.fromTheFortPosts.length) {
        this.fromTheFortPosts = this.$store.state.fromthefort.fromTheFortPosts;
        this.fortLoading = false;
      } else {
        return axios
          .get(`${this.fromthefort}/wp-json/wp/v2/posts?per_page=6`)
          .then((response) => {
            //console.log(response);
            this.fromTheFortPosts = response.data;
            this.fortLoading = false;
            this.$store.commit(
              "UPDATE_FROM_THE_FORT_HOME",
              this.fromTheFortPosts
            );
          });
      }
    },
    faqSync: function () {
      this.faqLoading = true;
      if (this.$store.state.faq.faqHomePosts.length) {
        this.faqPosts = this.$store.state.faq.faqHomePosts;
        this.faqLoading = false;
      } else {
        return axios
          .get(
            `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/spacenet_faqs?per_page=6`
          )
          .then((response) => {
            //console.log(response);
            this.faqPosts = response.data;
            this.faqLoading = false;
            this.$store.commit("UPDATE_FAQS_HOME", this.faqPosts);
          });
      }
    },
    showHelp: function () {
      this.$store.commit("showTour");
      this.$noty.success(
        "The help tour has been enabled, you can disable it by clicking 'Skip tour' or 'Hide Help' in the menu under your name! Help is only available on desktop devices."
      );
    },
    searchOrder: function(){
        this.$router.push({ path: '/listings', query: { search: this.searchValue, promised: false }})
        //this.$router.push("listings?search=this.searchValue&promised=false") 
        ///listings?search=S%20%26%20C%20Electric&promised=false
    },
    getAllAcks: function () {
      return axios
        .get(
          `${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=ack`,
          auth
        )
        .then((response) => {
          this.acks = response.data;
          console.log("acks " + response.data);
        });
    },
        
    getAllInvs: function () {
        return axios
            .get(`${this.ordermanageapi}/unprinted?ac=${this.$store.state.user.ACId[0]}&type=inv`, auth)
            .then((response) => {
            this.invs = response.data;
            console.log("invs " + response.data);
            });
    },
    listingSync: function () {


      if (!this.$store.state.listings.listings.length) {
        //console.log("getting listings");
        return axios
          .get(`${this.ordermanageapi}/order`, auth)
          .then((response) => {
            //fix null estinated dates so sorting will work
            response.data.forEach(function (o) {
              if (o.estimated_Date === null) {
                o.estimated_Date = "";
              }
            });
            this.listings = response.data;
            this.$store.commit("UPDATE_LISTINGS", this.listings);
          });
      } else {
        //console.log("vuex has length");
        this.listings = this.$store.state.listings.listings;
      }
    },
  },
  computed: {
    firstName() {
      return this.$store.state.user.FirstName;
    },
    calcNewsPosts: function () {
      if (this.loading == false && this.newsPosts.length) {
        //return this.newsPosts.ACF.exclude_from_feed === "No";
        //let filteredNews = this.newsPosts;
        //return filteredNews;

        let filteredNews = this.newsPosts.filter(function (el) {
          return el.ACF.exclude_from_feed != "Yes";
        });

        return filteredNews;
      }
    },
    calcBanners: function () {
      if (this.loading == false && this.newsPosts.length) {
        //return this.newsPosts.ACF.exclude_from_feed === "No";
        //let filteredNews = this.newsPosts;
        //return filteredNews;

        let filteredBanners = this.newsPosts.filter(function (el) {
          return el.ACF.show_in_banner == "Yes";
        });

        return filteredBanners;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    this.firstLoad();
    //this.$store.commit("SET_LISTINGS");
    if (
        this.$store.getters.isACKSnINVS &&
        !this.$store.getters.hideOrders
    ) {
        this.$store.commit("SET_EO_COUNT");
    }
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.slider-banner {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23004033'/%3E%3Cstop offset='0.2' stop-color='%23008066'/%3E%3Cstop offset='0.5' stop-color='%239FF'/%3E%3Cstop offset='0.8' stop-color='%23008066'/%3E%3Cstop offset='1' stop-color='%23004033'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2309F'/%3E%3Cstop offset='0.5' stop-color='%230FC'/%3E%3Cstop offset='1' stop-color='%2309F'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  /*new*/
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23005a5a'/%3E%3Cstop offset='0.2' stop-color='%2300b4b4'/%3E%3Cstop offset='0.5' stop-color='%239FF'/%3E%3Cstop offset='0.8' stop-color='%2300b4b4'/%3E%3Cstop offset='1' stop-color='%23005a5a'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%2300b4b4'/%3E%3Cstop offset='0.5' stop-color='%230FC'/%3E%3Cstop offset='1' stop-color='%2300b4b4'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  /*newer*/
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3ClinearGradient id='a' gradientUnits='objectBoundingBox' x1='0' y1='0' x2='1' y2='1'%3E%3Cstop offset='0' stop-color='%23363e45'/%3E%3Cstop offset='0.2' stop-color='%236b7c8a'/%3E%3Cstop offset='0.5' stop-color='%236b7c8a'/%3E%3Cstop offset='0.8' stop-color='%236b7c8a'/%3E%3Cstop offset='1' stop-color='%23363e45'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='objectBoundingBox' x1='1' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%236b7c8a'/%3E%3Cstop offset='0.5' stop-color='%236b7c8a'/%3E%3Cstop offset='1' stop-color='%236b7c8a'/%3E%3C/linearGradient%3E%3Crect fill='url(%23a)' width='1600' height='900'/%3E%3Cpolygon fill='%23ffffff' opacity='.5' points='0 0 1670 -1670 1670 1670'/%3E%3Crect fill='url(%23b)' opacity='.5' width='1600' height='900'/%3E%3Cg fill='%23ffffff'%3E%3Cpolygon opacity='.25' points='0 900 1670 -770 1670 2570'/%3E%3Cpolygon opacity='.25' points='450 450 2050 -1150 2050 2050'/%3E%3Cg fill-opacity='.4'%3E%3Cpath d='M700 900 l1650-1650l0 3300Z'/%3E%3Cpath d='M700 0 l1650-1650l0 3300Z'/%3E%3C/g%3E%3Cpath d='M1150 450 l1600-1600l0 3200z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

/*
.carousel-item {
  border: 2px solid #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
*/
.carousel {
  border: 2px solid #fff;
  border-radius: 0.25rem !important;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .carousel {
    box-shadow: 0 25px 25px -12px rgba(0, 0, 0, 0.45);
  }
}
@media screen and (min-width: 991px) {
  .carousel {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  }
}

.carousel-item img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  
}

.controls {
  font-size: 2em;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}
.second-banner {
  background-color: #f7fafc;

  padding-top: 30px;

  padding-bottom: 20px;

}
.welcome-text {
  padding: 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.bgwhite {
  background: #fff;
}
.faq-heading {
  margin-bottom: 0 !important;
}
.collapse {
  padding: 0px 10px !important;
}
.collapse.show {
  padding: 0px 10px !important;
}
.faq-01 .btn {
  text-decoration: none !important;
}
.faq-01 .btn:hover {
  text-decoration: none !important;
}

.btn-link {
  color: #333;
}

.btn-link i {
  color: rgba(92, 109, 126, 0.9);
}

.btn-link:hover {
  color: #000;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus {
  color: #000;
  text-decoration: none !important;
}

.accordion h5 {
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 1.1em !important;
  line-height: 1.2;
}

.accordion div {
  padding: 10px;
}

.see-all-btn {
  background: #00b4b4;
  color: #fff;
}
.fromfortbg {
  background-color: rgba(92, 109, 126, 0.9);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 1600 800'%3E%3Cg fill-opacity='0.2'%3E%3Cg fill='%23262626'%3E%3Cpolygon points='1600 800 1400 800 1600 0'/%3E%3Cpolygon points='800 800 1600 600 1600 800'/%3E%3C/g%3E%3Cg fill='%23262626'%3E%3Cpolygon points='0 0 200 0 0 800'/%3E%3Cpolygon points='800 0 0 200 0 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.mw300 {
  max-width: 300px;
  margin-bottom: 5px;
}
.fortcardimage {
  width: 100%;
  height: auto;
  min-height: 175px;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fortbg1 {
  background: #2aaae2;
  background-color: #2aaae2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23296e90'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231d3747'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg2 {
  background: #3f5364;
  background-color: #3f5364;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%232c3843'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231a1f24'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg3 {
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23282e32'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23181a1c'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.fortbg4 {
  background-color: rgba(92, 109, 126, 1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='.06'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23282e32'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23181a1c'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.card-content {
  padding: 10px;
}
.fortcard {
  -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  border-radius: 0.25rem !important;
  overflow: hidden;
  margin-bottom: 30px;
}
.fortimagewrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fortimagewrap img {
  max-width: 100px;
}
.more-link {
  display: inline-block !important;
  justify-content: center;
}
.more-link i {
  font-size: 14px;
}
.post-title {
  font-size: 2.2em;
}
.post-excerpt {
  padding-bottom: 0;
  margin-bottom: 0;
}
.feature-card {
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.45) !important;
  border-radius: 4px !important;
  overflow: hidden;
  /*height: calc(100% - 30px);*/
  position: relative;
  box-sizing: border-box;
}
.feature-card img {
  /*background: #f7fafc;*/
  max-width: 70%;
  text-align: center;
  padding: 40px;
  -webkit-filter: drop-shadow(3px 3px 2px rgba(134, 134, 134, 0.1));
  filter: drop-shadow(3px 3px 2px rgba(134, 134, 134, 0.1));
}
.orange-bg {
  background: #f16232;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: #f16232;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%239b4324'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%234d2517'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  /*
  background: linear-gradient(to right, #f16232 50%, #c24b23 50%);
*/
  /*
    background: linear-gradient(
    to top,
    rgba(223, 80, 46, 1),
    rgba(223, 80, 46, 0.9)
  );
  background-color: #e05b2f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%23c9512a' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.blue-bg {
  background: #4d5967;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background-color: #4d5967;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%23343c45'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%231d2125'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.gray-bg {
  background: rgba(92, 109, 126, 0.9);
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  background-color: #6b7c8a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%2347525a'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23262b2e'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;

  /*
    background: linear-gradient(
    to top,
    rgba(92, 109, 126, 0.9),
    rgba(92, 109, 126, 0.6)
  );
  background-color: #5c6976;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%234c5661' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.teal-bg {
  background: #00b4b4;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cg fill-opacity='0.08'%3E%3Cpath transform='rotate(0 636 600)' d='M1324 485c-81-58-192-40-282-73a722 722 0 0 1-319-252C652 68 562-56 429-39l-5 1a185 185 0 0 0-155 200c5 46 19 91 23 137 10 115-73 172-172 203-61 19-124 37-172 82-57 56-74 143-59 221a573 573 0 0 0 413 419c120 25 247 3 341-74 108-88 221-175 351-229 108-45 264-67 346-159 64-72 67-218-16-277z' fill='%231c7474'/%3E%3Cpath transform='rotate(0 1212 210)' d='M1864 416c-32-58-99-107-112-174-11-58 24-111 58-153 85-104 95-218 3-298s-202-83-261-110c-76-35-125-91-189-141-62-47-139-79-217-53-76 26-124 121-145 193-25 83 18 169-2 252-21 86-92 121-158 168-68 49-144 85-206 142-47 43-134 138-112 208 23 76 93 129 160 164 92 49 201 77 302 52 112-28 243-24 293 73 45 88 112 189 218 201a382 382 0 0 0 409-329c6-67-9-136-41-195z' fill='%23183a3a'/%3E%3Cpath transform='rotate(0 420 165)' d='M887-281c-175-247-488-265-666-89C31-181-453 618 24 803c231 90 338-283 709-346 370-63 328-490 154-738z' fill='%23000'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;

  /*
  background-color: #00b4b4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' width='100%25' height='200' viewBox='0 0 1600 100'%3E%3Cpath fill='%2300a1a1' d='M1600 100 0 0 1600 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 200px;
  background-position: top center;
  */
}
.feature-card .card-body {
  padding: 10px;
  flex: 1 1 100%;
}
.link {
  width: 100%;
  display: flex;
  border: none;
  /* text-align: center!important; */
  justify-content: center;
  background: #f1f2f3;
  color: #575757;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.5s ease;
  border-radius: 4px !important;
  overflow: hidden;
  padding: 5px;
}
.link:hover {
  background: rgba(223, 224, 224, 0.9);
}
.link:active {
  background: rgba(223, 224, 224, 0.9);
}

@media screen and (min-width: 992px) {
  .banner-wrap {
    box-sizing: border-box;
    margin-bottom: 30px;
  }
}
/*.top-angle
@media screen and (max-width: 991px) {
  .banner-wrap {
    background: rgba(255, 255, 255, 0) !important;
    padding: 0 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
*/
.banner-wrap {
  box-sizing: border-box;
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .banner-wrap {
    margin-bottom: 10px;
  }
}
.tc {
  text-align: center;
}
.top-angle {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: -1px;
}
.faq-top-angle {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: -1px;
}
.faq-bottom-angle {
  width: 100%;
  position: relative;
  top: 0;
  /* background: #eef0f1; */
  border: 0;
  margin-top: -79px;
}
.bottom-angle {
  width: 100%;
  position: absolute;
  top: 0;
}
@media screen and (max-width: 575px) {
  .faq-top-angle {
    display: none;
  }
  .faq-bottom-angle {
    display: none;
  }
  .top-angle {
    display: none;
  }
  .bottom-angle {
    display: none;
  }
}
.fort {
  position: relative;
}
.faq-tease {
  background-color: #eef0f1;
  position: relative;
}
.home-footer-top {
  position: relative;
  background-color: #4d5967;
}
.card-strip-top {
  color: #fff;
  padding: 10px;
}
.card-strip-top h5 {
  margin-bottom: 0;
}
.card-scroll {
  padding: 10px;
  /*height: 100%;*/
  overflow: scroll;
}
.card-deck {
  /*height: 100%;*/
  padding-bottom: 1.5rem !important;
}
.card-deck .card {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}
.h100 {
  height: 100%;
}
.prev-forts {
  background: #f1f2f3;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.5s ease;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}
.prev-forts i {
  margin-left: auto;
}
.prev-forts:hover {
  background: rgba(223, 224, 224, 0.9);
}
.prev-forts:active {
  background: rgba(223, 224, 224, 0.9);
}

.prev-forts p {
  margin-bottom: 0;
  line-height: 1;
}
.card-scroll a {
  text-decoration: none;
  color: #575757;
}
.accordion p {
  font-weight: 300;
}
.top-news-item {
  margin-bottom: 5px;

  padding: 5px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  background: rgba(46, 54, 62, 80%);
  padding: 10px;
  border-radius: 4px;
  line-height: 1.4;

  color: #fff;
  font-size: 90%;
  font-weight: 300;
  cursor: pointer;
  transition: background 0.5s ease;
  transition: all .2s ease-in-out;
}

.top-news-item:hover {
  /*background: #f1f2f3;*/
  /*background: rgba(223, 224, 224, 0.9);*/
  background: rgba(46, 54, 62, 100%);
  
}
.top-news-item:active {
  /*background: #f1f2f3;*/
  /*background: rgba(223, 224, 224, 0.9);*/
  background: rgba(46, 54, 62, 100%);
 
}
.announcement-banner {
  display: flex;
  flex-direction: column;
}
.announcement-link {
  margin-top: auto;
  background: #00b4b4;
  color: #fff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
}

.announcement-link:hover {
  background: #429da8;
}
.announcement-link:active {
  background: #429da8;
}
/*
@media screen and (max-width: 991px) {
	.announcement-banner {
		height: 200px;
	}
}
*/
@media screen and (min-width: 992px) {
  .announcement-banner {
    height: 214.32px;
  }
}
@media screen and (min-width: 1200px) {
  .announcement-banner {
    height: 256.02px;
  }
}
.news-scroll {
  overflow: scoll;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.news-scroll a:hover {
  text-decoration: none;
}
.news-scroll a:active {
  text-decoration: none;
}
.badge-orange {
  background-color: #00b4b4;
  background-color: #3e4651;
  background-color: #f16232;
  color: #fff;
  font-size: 0.7em !important;
  font-weight: 500;
}
/*
.faq-tease:after {
  content: url(../assets/img/faq-bottom.svg);
}
*/
@media screen and (max-width: 575px) {
  .news-scroll {
    min-height: 180px;
  }
  .card-title {
    min-height: 50px;
  }
  #v-step-6 {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
  }
  #v-step-8 {
    padding-top: 30px !important;
    padding-bottom: 0px !important;
  }
  .faq-tease {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (min-width: 992px) {
  .orange-bg {
    background-attachment: fixed;
  }
  .teal-bg {
    background-attachment: fixed;
  }
  .gray-bg {
    background-attachment: fixed;
  }
  .blue-bg {
    background-attachment: fixed;
  }
  .fortbg1 {
    background-attachment: fixed;
  }
  .fortbg2 {
    background-attachment: fixed;
  }
  .fortbg3 {
    background-attachment: fixed;
  }
  .fortbg4 {
    background-attachment: fixed;
  }
}
.dashboard-card {
  display: flex;
  flex-direction: row;
}
.dashboard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-card {
  background: rgba(46, 54, 62, 80%);
  transition: background 0.5s ease;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.dashboard-card:hover,
.dashboard-card:active {
  background: rgba(46, 54, 62, 100%);
}
.search-area{
  display: flex;
  margin-bottom: 0px;
}
.search-btns {
  /*display: flex;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-btns .clear {
  margin-right: 0;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.new-search-btn1{
    background: #00b4b4;
    color: #fff;
    border: none;
    transition: background-color 0.5s ease;
    cursor:pointer;
}
.new-search-btn1:hover{
    background:#429da8;
}
.new-search-btn2{
  background: #33848e;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn2:hover{
  background: #2d767f;
}
.badge{
  padding: 0.4em 0.4em!important;
}
.news-date{
  color: #f16232;
}
.alert-badge{
  font-size: inherit;
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 4px;
    background: #f16232;
    color: #fff;
    font-size: 13px;
    vertical-align: middle;
}
@media screen and (max-width: 991px) {
  .hero-wrap {
    width: 100%;
    margin: 0;
  }
}
/* carousel */
.carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 94px;

        /*z-index:10;*/
    }

    .carousel-slider {
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
    }
    .carousel-slider img {
        width:100%;
        height:100%;
    }
   
    @media screen and (min-width: 424px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 129px;
      }
}
@media screen and (min-width: 479px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 148px;
      }
}
@media screen and (min-width: 559px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 176px;
      }
}
@media screen and (min-width: 669px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 169px;
      }
}
    @media screen and (min-width: 767px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 216px;
      }
}
@media screen and (min-width: 991px) {
  .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        /*height: 214px;*/
      }
}

    @media screen and (min-width: 1200px) {
      .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        height: 256px;
      }
    }


</style>
