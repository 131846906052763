<template>
      <div
              v-if="media.length >= 4"
              class="
                banner
                d-flex
                align-items-center
                p-3
                mb1r
                rounded
                bs-shadow
              "
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  {{ this.media.length + " " }}<small>Results</small>
                </p>
              </div>

              <div>
                <div class="nav-btns">
                  <button v-if="this.offsetVal == 0" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>
                  <button v-else @click="offsetValMinus()">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.media.length < 100" disabled>
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                  <button v-else @click="offsetValPlus()">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
</template>

<script>
export default {
name: "MediaBottomPagination",
props: {
media: Array,
offsetVal: Number,
offsetValMinus: {
type: Function,
},
offsetValPlus: {
type: Function,
},

},
computed: {

},
};
</script>

<style scoped></style>
