<template>
  <div class="images">
    
    <service-install-tour v-if="$store.state.tour & !loading" />
  

    <transition name="menu-popover">
      <div v-if="lightboxImage" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <h2 class="downloadImage">
          <a :href="lightboxImage" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-download fa-lg" aria-hidden="true"></i>
          </a>
        </h2>
        <img :src="lightboxImage" @click="closeLightbox()" />
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo"></div>
        <!-- <img :src="lightboxImage" @click="closeLightbox()"/> -->
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxConfidential" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()" style="top: 0">
          <a>&#215;</a>
        </h2>

        <iframe
          width="90%"
          height="800"
          :src="lightboxConfidential"
          frameborder="0"
          allowfullscreen
          @click="closeLightbox()"
        ></iframe>
      </div>
    </transition>

    <section class="pt-5 pb-3 bg8 mobile-top">
      <div id="si-step-1" class="container">
        <input
          class="top-search"
          type="text"
          placeholder="Search for service & installation media in all categories"
          v-model="searchValue"
          @keyup.enter="searchQuery"
          style="padding: 15px;"
        />
        <div class="search-btns">
          <div class="left-side">
            <h6
              v-if="search.length"
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Service & Installation > {{ catClean(search) }}
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
              {{ catType != "" ? " > " + catClean(catType) : "" }}
            </h6>
            <h6
              v-else
              class="mb0 d-flex"
              style="color: #fff; text-transform: uppercase"
            >
              <i class="material-icons crumb" style="margin-right: 2px"
                >category</i
              >
              Service & Installation > All Categories
              {{
                fileType ==
                "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                  ? " > Documents"
                  : ""
              }}
              {{ fileType == "MPEG4" ? " > Videos" : "" }}
            </h6>
          </div>
          <div class="right-side">
            <button
              v-if="searchValue.length"
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>

            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <!--
            <button
            v-if="
                searchValue.length ||
                this.fileType !=
                  'PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)'
              "
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            -->
          </div>
        </div>
      </div>
    </section>

    <!-- scroll target -->
    <div id="pag"></div>

    <div :class="loading == false && !media.length ? 'message-bg' : ''">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayMobile" class="d-flex droplink" id="si-step-4">
                <h5 class="mb0" style="margin-top: 0px!important; margin-bottom: 0;">Mobile</h5>
                <span
                  class="material-icons mlauto"
                  :class="showMobile == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>
              <div v-if="showMobile">
                <ul class="flexwrap mt10">
                  <li
                    v-for="product in mobile"
                    :key="product.title"
                    class="mktBtns"
                  >
                    <button
                      class="btn-primary"
                      :class="{ active: product.search == search }"
                      @click="changefieldServiceCat(product.search)"
                    >
                      {{ product.title }}
                    </button>
                  </li>
                </ul>

                <h6
                  @click="displayActivRAC"
                  class="mt20 mb10 vert-center droplink"
                >
                  <span
                    class="material-icons"
                    :class="
                      showActivRAC == true ? 'active-tri' : 'inactive-tri'
                    "
                    >sort</span
                  >
                  ActivRAC
                </h6>

                <div v-if="showActivRAC">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in activeRAC"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changefieldServiceCat(subcat.search)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>
                <h6
                  @click="displayEclipse"
                  class="mt20 mb10 vert-center droplink"
                >
                  <span
                    class="material-icons"
                    :class="showEclipse == true ? 'active-tri' : 'inactive-tri'"
                    >sort</span
                  >
                  Eclipse
                </h6>

                <div v-if="showEclipse">
                  <ul class="flexwrap">
                    <li
                      v-for="subcat in eclipseCats"
                      :key="subcat.title"
                      class="mktBtns"
                    >
                      <button
                        class="btn-primary"
                        :class="{ active: subcat.search == search }"
                        @click="changefieldServiceCat(subcat.search)"
                      >
                        {{ subcat.title }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="card-block bs-shadow">
              <div @click="displayShelving" class="d-flex droplink">
                <h5 style="margin-top: 0px !important; margin-bottom: 0;">Shelving</h5>
                <span
                  class="material-icons mlauto"
                  :class="showShelving == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showShelving" class="flexwrap mt10">
                <li
                  v-for="product in shelving"
                  :key="product.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: product.search == search }"
                    @click="changefieldServiceCat(product.search)"
                  >
                    {{ product.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow">
              <div @click="displayStorage" class="d-flex droplink">
                <h5 style="margin-top: 0px !important; margin-bottom: 0;">Storage</h5>
                <span
                  class="material-icons mlauto"
                  :class="showStorage == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showStorage" class="flexwrap mt10">
                <li
                  v-for="product in storage"
                  :key="product.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: product.search == search }"
                    @click="changefieldServiceCat(product.search)"
                  >
                    {{ product.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayCats" class="d-flex droplink">
                <h5 style="margin-top: 0px !important; margin-bottom: 0;">Training</h5>
                <span
                  class="material-icons mlauto"
                  :class="showCats == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showCats" class="flexwrap mt10">
                <li
                  v-for="fieldServiceCat in fieldServiceCats"
                  :key="fieldServiceCat.title"
                  class="mktBtns"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: fieldServiceCat.search == search }"
                    @click="changefieldServiceCat(fieldServiceCat.search)"
                  >
                    {{ fieldServiceCat.title }}
                  </button>
                </li>
              </ul>
            </div>
            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displaySoftware" class="d-flex droplink">
                <h5 style="margin-top: 0px !important; margin-bottom: 0;">Installation Software</h5>
                <span
                  class="material-icons mlauto"
                  :class="showSoftware == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showSoftware" class="mb0 mt10">
                <li
                  v-for="fieldServiceCat in fieldServiceInstalls"
                  :key="fieldServiceCat.title"
                  class="mktBtns"
                  style="margin-left: 0"
                >
                  <button
                    class="btn-primary"
                    :class="{ active: fieldServiceCat.search == search }"
                    @click="changefieldServiceCat(fieldServiceCat.search)"
                  >
                    {{ fieldServiceCat.title }}
                  </button>
                </li>
              </ul>
            </div>

            <div class="card-block bs-shadow" style="margin-top: 15px">
              <div @click="displayMSDS" class="d-flex droplink">
                <h5 id="si-step-3" style="margin-top: 0px!important; margin-bottom: 0;">Material Safety</h5>
                <span
                  class="material-icons mlauto"
                  :class="showMSDS == true ? 'active-tri' : 'inactive-tri'"
                >
                  filter_list
                </span>
              </div>

              <ul v-if="showMSDS" class="flexwrap mt10">
                <li class="mktBtns">
                  <a
                    class="btn-primary"
                    style="text-decoration: none; display: block"
                    href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                    target="_blank"
                    rel="noopener nooreferrer"
                  >
                    MSDS
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            v-if="loading == true"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
          <media-pagination 
            id="mm-step-7"
            :media="media" 
            :loading="loading" 
            :sortView="sortView" 
            :dateView="dateView" 
            :listView="listView"
            :offsetVal="offsetVal" 
            :sortSwitch="sortSwitch" 
            :dateSwitch="dateSwitch"
            :viewSwitch="viewSwitch" 
            :offsetValPlus="offsetValPlus" 
            :offsetValMinus="offsetValMinus"          
            />


            <media-top-controls 
            :fileType="fileType"
            :catType="catType"
            :loading="loading"
            :fileTypes="fileTypes" 
            :Types="Types"
            :changeFile="changeFile"
            :changeCatType="changeCatType"         
            />

            <div style="margin-top: 100px">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />

                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !media.length"
            class="col-xs-12 col-sm-12 col-12 col-lg-8"
          >
          <media-pagination 
            :media="media" 
            :loading="loading" 
            :sortView="sortView" 
            :dateView="dateView" 
            :listView="listView"
            :offsetVal="offsetVal" 
            :sortSwitch="sortSwitch" 
            :dateSwitch="dateSwitch"
            :viewSwitch="viewSwitch" 
            :offsetValPlus="offsetValPlus" 
            :offsetValMinus="offsetValMinus"          
            />

            <media-top-controls 
            :fileType="fileType"
            :catType="catType"
            :loading="loading"
            :fileTypes="fileTypes" 
            :Types="Types"
            :changeFile="changeFile"
            :changeCatType="changeCatType"         
            />
            <div>
              <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
                Sorry, I coudn't find the media that you requested...
              </h2>
              <img src="@/assets/img/userAction.svg" style="width: 100%" />
            </div>
          </div>

          <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-8">


            <media-pagination 
            :media="media" 
            :loading="loading" 
            :sortView="sortView" 
            :dateView="dateView" 
            :listView="listView"
            :offsetVal="offsetVal" 
            :sortSwitch="sortSwitch" 
            :dateSwitch="dateSwitch"
            :viewSwitch="viewSwitch" 
            :offsetValPlus="offsetValPlus" 
            :offsetValMinus="offsetValMinus"          
            />


            <media-top-controls 
            :fileType="fileType"
            :catType="catType"
            :loading="loading"
            :fileTypes="fileTypes" 
            :Types="Types"
            :changeFile="changeFile"
            :changeCatType="changeCatType"         
            />

            <!-- ###################### if grid view ######################### -->
            <div class="row" v-if="!listView">
              <div
                v-for="(el, index) in media"
                :key="el.external_id"
                class="col-md-4 col col-sm-6 col-xs-12 col-12"
              >
              <media-grid-card
                    :index="index"
                    :el="el"
                    :copyLink="copyLink"
                    :showLightbox="showLightbox"
                    :showLightboxVideo="showLightboxVideo"
                    :showLightboxConf="showLightboxConf"
                    :listView="listView"
                    :showCopy="showCopy"
                  />
              </div>
            </div>

            <!-- #################### if list view #################### -->

            <div class="row" v-else>
              <div
                v-for="(el, index) in media"
                :key="el.external_id"
                class="col-xs-12 col-12 list-view"
              >
              <media-list-card
                    :index="index"
                    :el="el"
                    :copyLink="copyLink"
                    :showLightbox="showLightbox"
                    :showLightboxVideo="showLightboxVideo"
                    :showLightboxConf="showLightboxConf"
                    :listView="listView"
                    :showCopy="showCopy"
                  />
              </div>
            </div> 
            <media-bottom-pagination v-if="media.length >= 4" :media="media" :offsetVal="offsetVal" :offsetValMinus="offsetValMinus" :offsetValPlus="offsetValPlus"/>
          </div>
          <!-- end other column -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Eula from "../components/shared/Eula.js";
import MediaPagination from "@/components/Pagination/MediaPagination.vue";
import MediaBottomPagination from "@/components/Pagination/MediaBottomPagination.vue";
import MediaTopControls from "@/components/Buttons/MediaTopControls.vue";
import MediaGridCard from "@/components/cards/MediaGridCard.vue";
import MediaListCard from "@/components/cards/MediaListCard.vue";
import ServiceInstallTour from "../components/Tours/ServiceInstallTour.vue";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/a59d39c4db9c288752c13e9c1196c9cf",
  },
};

export default {
  name: "FieldService",
  components: {
    AtomSpinner,
    MediaPagination,
    MediaBottomPagination,
    MediaTopControls,
    MediaGridCard,
    MediaListCard,
    ServiceInstallTour,
  },
  data() {
    return {
      pageSlug: "",
      msg: "",
      offsetVal: 0,
      search: this.$route.query.cat
        ? this.$route.query.cat
        : "Service%20and%20Installation/",
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      media: [],
      loading: true,
      imagesInPresentation: [],
      selectedImage: "",
      selectedVid: null,
      fileType:
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",

      allMedia: [
        {
          title: "All Media",
          search:
            "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
          active: false,
        },
      ],
      
      Types: [
        {
          title: "Installation Manual",
          search: "/Installation Guide",
          active: false,
        },
        {
          title: "Engineering Drawing",
          search: "/Engineering Drawing",
          active: false,
        },
        { title: "Owner's Manual", search: "/Owner's Manual", active: false },
        {
          title: "Operation Instructions",
          search: "/Operation Instructions",
          active: false,
        },
        {
          title: "Wire Diagram",
          search: "/Wire Diagram",
          active: false,
        },
      ],
      catList: [],
      catType: this.$route.query.type ? this.$route.query.type : "",

      //cat: 'ActivStor',
      cat: "Service%20and%20Installation",
      software: [
        {
          name: "SSC Installation Manual Viewer",
          link: "https://tinyurl.com/5ks36sew",
          link2:
            "http://spacenet2.spacesaver.com/Documents/SSCCommercialInstallationManualViewerSetup.exe",
        },
        {
          name: "Eclipse Firmware Flash",
          link: "https://tinyurl.com/kavawy4p",
          link2: "http://spacenet2.spacesaver.com/Documents/Eclipse.exe",
        },
      ],

      lightboxImage: "",
      lightboxVideo: "",
      lightboxConfidential: "",
      eulaAccepted: false,
      listView: false,
      sortView: false,
      dateView: false,
      showFiles: true,
      showCats: false,
      showShelving: true,
      showStorage: true,
      showMobile: true,
      showActivRAC: true,
      showEclipse: true,
      showMobileGeneral: false,
      showSoftware: false,
      showMSDS: false,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "media");

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        let view = localStorage.getItem("SSCview");
        if (view == "list") {
          this.listView = true;
        } else {
          this.listView = false;
        }
        this.loading = false;
      });
    },
    catClean: function (cat) {
      // console.log(cat);
      //remove everything before the last /
      let a = cat.replace(/%20/g, " ").split("/").pop();
      return a;
    },

    openInNewTab: function (url) {
      window.open(url, "_new").focus();
    },

    downloadFile: function (data, name = "file", type = "text/plain") {
      const { createElement } = document;
      const {
        URL: { createObjectURL, revokeObjectURL },
        setTimeout,
      } = window;

      const blob = new Blob([data], { type });
      const url = createObjectURL(blob);

      const anchor = createElement("a");
      anchor.setAttribute("href", url);
      anchor.setAttribute("download", name);
      anchor.click();

      setTimeout(() => {
        revokeObjectURL(url);
      }, 100);
    },

    viewSwitch: function () {
      this.listView = !this.listView;
      if (this.listView) {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "list");
      } else {
        localStorage.removeItem("SSCview");
        localStorage.setItem("SSCview", "grid");
      }
    },
    sortSwitch: function () {
      if (this.sortView == false) {
        this.media.sort((a, b) => (a.filename > b.filename ? 1 : -1));
      } else {
        this.media.sort((a, b) => (a.filename < b.filename ? 1 : -1));
      }
      this.sortView = !this.sortView;
    },
    dateSwitch: function () {
      if (this.dateView == false) {
        this.media.sort((a, b) =>
          a.last_update_date > b.last_update_date ? 1 : -1
        );
      } else {
        this.media.sort((a, b) =>
          a.last_update_date < b.last_update_date ? 1 : -1
        );
      }
      this.dateView = !this.dateView;
    },

    copyLink: function () {
      this.$toast.success("Successfully copied link to clipboard!");
    },

    /*
    copyData(link) {
      this.makeData();
      this.$clipboard(this.invite_code);
      alert("Copied to clipboard");
    },

    addImageToPresentation(name) {
      let image = {
        thumb: name,
        large: "large image",
      };
      this.$store.commit("addToImages", image);
      this.$toast.success("Successfully added image to presentation!");
      //this.$noty.error("Oooops! Something went wrong");
    },
    */

    offsetValMinus() {
      this.offsetVal -= 100;
      this.loading = true;
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    offsetValPlus() {
      this.offsetVal += 100;
      this.loading = true;
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    changefieldServiceCat(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.searchValue = "";

      this.search = name;

      if (
        this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}`
        );
      } else if (this.fileType == "JPEG") {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=image`
        );
      } else if (this.fileType == "MPEG4") {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=video`
        );
      } else if (
        this.fileType ==
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=document`
        );
      } else if (this.catType.length && this.search.length) {
        //history.pushState({}, '', `/marketing-materials?type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=${this.catType}`
        );
      } else {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}`
        );
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    changeFile(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.fileType = name;
      this.catType = "";

      if (
        this.fileType ==
          "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&file=document`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG" && this.search.length) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=image`
        );
      } else if (this.fileType == "JPEG" && this.searchValue.length) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=image`
        );
      } else if (this.fileType == "MPEG4" && this.search.length) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=video`
        );
      } else if (this.fileType == "MPEG4" && this.searchValue.length) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=video`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.search.length
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=document`
        );
      } else if (
        this.fileType ==
          "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)" &&
        this.searchValue.length
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=document`
        );
      } else {
        history.pushState({}, "", `/service-and-installation`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    changeCatType(name) {
      /*
				# Can Change to All, Webinar etc. this.catType = name;
				# Can have market (search)
				# can’t have fileType (this.fileType)
			*/
      this.offsetVal = 0;
      this.loading = true;
      this.catType = name;
      this.fileType =
        "PDF}%20or%20{GIF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";

      // has cat type filter, search bar is filled in and searching products
      if (
        this.catType.length &&
        this.searchValue.length
        // this.search == "Products/"
      ) {
        /*console.log(
          "has category filter, search bar filled in, searching products"
        );*/

        //history.pushState({}, '', `/marketing-materials?cat=${this.search}&type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=${this.catType}`
        );
        //https://api.widencollective.com/v2/assets/search?query=ff%3A({PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)})%20eclipse&limit=100&&offset=0&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity
      } else if (
        this.catType.length &&
        this.search.length
        //this.search != "Products/"
      ) {
        //history.pushState({}, '', `/marketing-materials?type=${this.catType}`);
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}&type=${this.catType}`
        );
      } else if (
        !this.catType.length &&
        this.search.length
        //this.search != "Products/"
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?cat=${this.search}`
        );
      } else {
        history.pushState({}, "", `/service-and-installation`);
      }

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    clearSearch: function () {
      //this.searchStarted = false;
      this.searchValue = "";
      this.search = "";
      this.cat = "Service%20and%20Installation";
      this.fileType =
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
      this.catType = "";
      this.loading = true;
      history.pushState({}, "", `/service-and-installation`);
      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });
    },

    searchQuery(search) {
      this.search = "";
      this.catType = "";
      this.offsetVal = 0;
      this.loading = true;
      if (
        this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}`
        );
      } else if (this.fileType == "JPEG") {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=image`
        );
      } else if (this.fileType == "MPEG4") {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=video`
        );
      } else if (
        this.fileType ==
        "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
      ) {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}&type=document`
        );
      } else {
        history.pushState(
          {},
          "",
          `/service-and-installation?search=${this.searchValue}`
        );
      }

      return (
        axios
          /*
        .get(
          `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
          auth
        )
        */
          //https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AService%20and%20Installation%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails
          .get(this.endPointCompute, auth)
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
            //const el = document.getElementById("pag");
            //el.scrollIntoView({ behavior: "smooth" });
          })
      );
    },

    showLightbox(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "preview");
        //this.lightboxImage = media;
      } else {
        //this.$store.commit("UPDATE_LIGHTBOX_IMAGE_FIELD_SERVICE", media);
        this.lightboxImage = media;
      }
    },
    showLightboxVideo(media) {
      let file = media;
      // console.log(media);
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confvid");
        //this.lightboxVideo = file;
      } else {
        //this.$store.commit("UPDATE_LIGHTBOX_VIDEO_FIELD_SERVICE", media);
        this.lightboxVideo = media;
      }
    },
    showLightboxConf(media) {
      if (this.$store.state.eulaAccepted == false) {
        this.showEula(media, "confdoc");
        //this.lightboxConfidential = media;
      } else {
        this.lightboxConfidential = media;
      }
      /*
			document.addEventListener('contextmenu', function(event) {
			event.preventDefault();
			}, true); 
			*/
    },

    closeLightbox() {
      //this.$store.commit("CLEAR_LIGHTBOX_IMAGE_FIELD_SERVICE", "");
      //this.$store.commit("CLEAR_LIGHTBOX_VIDEO_FIELD_SERVICE", "");
      //this.$store.commit("CLEAR_LIGHTBOX_CONF_FIELD_SERVICE", "");
      this.lightboxImage = "";
      this.lightboxVideo = "";
      this.lightboxConfidential = "";
      //document.removeEventListener("contextmenu");
    },

    isActiveButton: function (text) {
      return text;
    },

    displayFiles() {
      this.showFiles = !this.showFiles;
    },
    displayCats() {
      this.showCats = !this.showCats;
    },
    displayShelving() {
      this.showShelving = !this.showShelving;
    },
    displayStorage() {
      this.showStorage = !this.showStorage;
    },
    displayMobile() {
      this.showMobile = !this.showMobile;
    },
    displayMobileGeneral() {
      this.showMobileGeneral = !this.showMobileGeneral;
    },
    displayActivRAC() {
      this.showActivRAC = !this.showActivRAC;
    },
    displayEclipse() {
      this.showEclipse = !this.showEclipse;
    },
    displayMSDS() {
      this.showMSDS = !this.showMSDS;
    },
    displaySoftware() {
      this.showSoftware = !this.showSoftware;
    },
    /*
    showCats() {
      // https://api.widencollective.com/v2/categories/{category}/{subcategory}/{...}
    }
    */
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    shouldButtonBeActive: function () {
      return (
        this.fieldServiceCats.filter((el) => this.isActiveButton(el)).length ===
        0
      );
    },

    myOffsetVal() {
      return this.offsetVal.valueOf();
    },

    endPointCompute() {
      if (this.searchValue.length) {
        //using the search bar
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.search}${this.cat}%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
        //return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&search_document_text=true&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
      } else {
        // clicking the category buttons
        return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3A${this.cat}%2f${this.search}${this.catType}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails%2Cmetadata%2Csecurity`;
      }
    },

    //https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AService%20and%20Installation%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails
    cart() {
      return this.$store.state.cart;
    },

    currentMarket() {
      return this.$store.state.fieldServiceCat;
    },

    fieldServiceCats() {
      return this.$store.state.fieldServiceCats;
    },

    fileTypes() {
      return this.$store.state.limitedFileTypes;
    },
    shelving() {
      return this.$store.state.fieldServiceShelving;
    },
    storage() {
      return this.$store.state.fieldServiceStorage;
    },
    mobile() {
      return this.$store.state.fieldServiceMobile;
    },
    fieldServiceMobileGeneral() {
      return this.$store.state.fieldServiceMobileGeneral;
    },
    activeRAC() {
      return this.$store.state.fieldServiceActivRAC;
    },
    eclipseCats() {
      return this.$store.state.fieldServiceEclipse;
    },
    fieldServiceInstalls() {
      return this.$store.state.fieldServiceInstalls;
    },
    showCopy() {
      return this.$store.state.eulaAccepted;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    this.showEula = Eula.showEula; // now you can call this.showEula() (in your functions/template)
    this.triggerLightbox = Eula.triggerLightbox;
    /*
    if (this.$route.params.slug) {
      history.pushState({}, '', `/pagepath/path?query=${this.myQueryParam}`);
      this.this.pageSlug = this.$route.params.slug;
    }
    */
    // console.log(this.$route.path);
  },

  mounted() {
    if (this.$route.query.type == "document") {
      this.fileType =
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    } else if (this.$route.query.type == "image") {
      this.fileType == "JPEG";
    } else if (this.$route.query.type == "video") {
      this.fileType == "MPEG4";
    } else {
      this.fileType ==
        "PDF}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)";
    }
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.docwrap {
  background-repeat: no-repeat;
  background-position: center center;
}
.docwrap img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.card {
  margin-bottom: 15px;
}
.word-wrap {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadImage a {
  color: #fff;
  transition: color 0.5s ease;
}
.downloadImage a:hover {
  color: #42b983;
}
.mr5 {
  margin-right: 5px;
}
.img-responsive-4by3 {
  display: block;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}
.img-responsive-4by3 img {
  width: 100%;
}
.img-responsive-16by9 {
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.img-responsive-16by9 img {
  width: 100%;
}
span.file-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}
.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent #000000 transparent transparent;
  line-height: 0px;
  _border-color: #ffffff #000000 #ffffff #ffffff;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
}
.flexwrap {
  margin-bottom: 0;
}

.software {
  align-items: center;
  justify-content: center;
}
.software .material-icons {
  font-size: 1.2em !important;
}

.softwareli {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  margin: 0 0 5px;
}
.lh12 {
  line-height: 1.2;
}
.software-link a {
  color: #429da8;
  cursor: pointer;
}

.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
  height: 40px;
}
.inactive-tri {
  /*transition: transform 0.2s ease-in-out;*/
  transition: transform 0.4s ease-in-out;
  transform-style: preserve-3d;
  display: flex;
  align-items: center!important;
}
.active-tri {
  transition: transform 0.4s ease-in-out;
  display: flex;
  display: flex;
  align-items: center!important;
  /*transform: rotate(180deg);*/
  transform: rotateX(180deg);
}
.badge-orange {
  background-color: #00b4b4;
  background-color: #3e4651;
  background-color: #f16232;
  color: #fff;
  font-size: 0.7em !important;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .hide-mobile {
    display: none;
  }
}
.btn-primary:disabled {
  color: #fff;
  background-color: #dedede;
  border-color: #dedede;
}
</style>
