<template>
  <div class="page-listings">
    <section
      v-if="loading || !leadtimes"
      data-v-5a90ec03
      class="py-5 bg9 bs-shadow mobile-top"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Loading Historical Lead Times....</h1>
        <hr class="header-divide" />
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <input
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search By Product Name..."
          v-model="search"
          @click="searchReset()"
          style="padding: 15px;"
        />
        <div class="search-btns">
          <div class="left-side" style="margin-top: 5px">
            <div class="dates" id="dates">

              <!--
              <router-link
                :to="{ path: $store.state.route.from.fullPath }"
                class="clear"
                style="padding-right: 10px"
              >
                <i class="material-icons">keyboard_arrow_left</i>
                Back
              </router-link>
            -->
            </div>
          </div>

          <button
            v-if="search.length"
            @click="clearSearch"
            class="clear"
            style="margin-top: 5px"
          >
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
          <button v-else disabled class="clear" style="margin-top: 5px">
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
        </div>

        <!--
        <div class="date-area">
          <vc-calendar mode="range" v-model="attributes" :max-date="new Date()" is-inline />
        </div>
        -->
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap pt150 pb150">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredLeadTimes.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div class="sorry-box">
            <img src="@/assets/img/sad.svg" />
            <h3>Sorry, no historical lead time was found...</h3>
            <button class="btn-primary" @click="clearSearch">
              Show me some lead times
            </button>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">

          <div
            class="
              leadtime-header
              d-flex
              align-items-center
              p-3
              mt-3
              mb10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredLeadTimes.length / 25) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredLeadTimes.length / 25)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>

              <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              -->
            </div>
          </div>

          <div>
            <div class="listing">
              <!--
          <div v-for="leadtime in leadtimes" :key="leadtime.Tid">
              -->
              <div class="d-flex">
                <h2 class data-config-id="header">Lead Time History</h2>
                <download-csv
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                  :data="leadtimes"
                  :name="'historical-lead-times' + '.csv'"
                  style="align-self: center"
                  >Download CSV</download-csv
                >
              </div>

              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Date Updated</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Previous Lead Time</th>
                      <th scope="col">Category</th>
                      <th scope="col">Family</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadtime.OrderNumber"
                      v-for="(leadtime, index) in filteredLeadTimes.slice(
                        pageStart,
                        pageStart + countOfPage
                      )"
                    >
                      <td>
                        {{ $moment(leadtime.LastUpdated).format("MM-DD-YYYY") }}
                      </td>
                      <td>
                        <p class="mb5">{{ leadtime.ProductDescription }}</p>
                      </td>
                      <td>
                        <p class="mb5">{{ leadtime.LeadTime + " Weeks" }}</p>
                      </td>
                      <td>{{ leadtime.ProductCategory }}</td>
                      <td>{{ leadtime.ProductFamily }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- card -->
            </div>
          </div>

          <div
            class="
              leadtime-header
              d-flex
              align-items-center
              p-3
              mb-3
              mt10
              rounded
              box-shadow
            "
          >
            <p class="mrauto mb0">
              Page {{ currPage }} of
              {{ Math.ceil(this.filteredLeadTimes.length / 25) }}
            </p>
            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredLeadTimes.length / 25)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>

              <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              -->
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import JsonCSV from "vue-json-csv";


const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "LeadTimeHistory",
  components: {
    AtomSpinner,
    JsonCSV,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: null,
      endDate: null,
      searchStarted: false,
      acs: [],
      leadtimes: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noRGAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = "";
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.backend}/api/v1/historical-lead-times/`, auth)
        .then((response) => {
          //console.log(response);
          this.leadtimes = response.data;
          this.loading = false;
        });
    },

    searchReset: function () {
      this.selectedCat = "Show All";
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },
    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.leadtimes;
      }

      this.search = "";
      this.loading = true;
      this.searching = true;

      //console.log(event);

      //console.log(`${this.ordermanageapi}/orders?search=${this.searchValue}`);
      /*
			return axios;
				.get(`${this.ordermanageapi}/order?search=${this.searchValue}`, auth)
				.then((response) => {*/
      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        this.leadtimes = response.data;
        //console.log(response);
        this.loading = false;
        this.searching = false;
      });
    },
    clearSearch() {
      if (this.searchStarted == true) {
        this.leadtimes = this.storedListings;
        this.searchStarted = false;
        this.searchRender = !this.searchRender;
      }

      //this.listings = this.storedListings;

      this.searchValue = "";
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredLeadTimes: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.leadtimes.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.ProductDescription.toLowerCase().indexOf(
              this.search.toLowerCase()
            ) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.leadtimes.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.leadtimes;
      }
    },

    foundNoRGA: function () {
      if (this.search.length > 1 && this.filteredLeadTimes.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.leadtimes.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.rfrs
      ) {
        //("fired off");
        this.rfrs = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has order number, no start, no end, no ac
      if (
        this.searchValue.length &&
        !this.startDate &&
        !this.endDate &&
        !this.acFilter
      ) {
        return `${this.ordermanageapi}/leadtime?search=${this.searchValue}`;
      } else if (
        //has order number, and start, no end, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        return `${this.ordermanageapi}/leadtime?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}`;
      } else if (
        //has order number, and end, no start, no ac
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?search=${
          this.searchValue
        }&end=${this.formatDate(this.endDate)}`;
      } else if (
        // has three, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?search=${
          this.searchValue
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has end
        !this.searchValue &&
        this.startDate === null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has start
        !this.searchValue &&
        this.startDate != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/leadtime?start=${this.formatDate(
          this.startDate
        )}`;
      } else if (
        //has start and end
        !this.searchValue &&
        this.startDate != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}`;
      } else if (
        //has all 4
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?ac=${this.acFilter}`;
      } else if (
        //has only ac
        !this.searchValue &&
        this.startDate === null &&
        this.endDate === null &&
        this.acFilter != null
      ) {
        return `${this.ordermanageapi}/leadtime?ac=${this.acFilter}`;
      } else if (
        //has ac and start
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/leadtime?start=${this.formatDate(
          this.startDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and end
        !this.searchValue &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?end=${this.formatDate(
          this.endDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and number
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        return `${this.ordermanageapi}/leadtime?search=${this.searchValue}&ac=${this.acFilter}`;
      } else if (
        //has ac and start and end
        !searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        return `${this.ordermanageapi}/leadtime?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else {
        return `${this.ordermanageapi}/leadtime`;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.leadtime {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.leadtime-bottom {
  align-items: flex-end;
}
.leadtime-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns .clear span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns .clear:hover {
  background: #586575;
  text-decoration: none;
}
.search-btns .clear:active {
  background: #586575;
  text-decoration: none;
}
.search-btns .clear:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns .clear:disabled:hover {
  background: #4d5967;
}
.search-btns .clear:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
</style>
