<template>
    <div>

      <transition name="menu-popover">
      <lightbox-gallery v-if="lightboxView" :imageArray="project[0].PictureUrls" :projectId="projectId" :closeLightbox="closeLightbox"/>
      </transition>
    


      <section data-v-5a90ec03 class="py-4 b-white-bottom bg11 mobile-top">
        <div data-v-5a90ec03 class="container">
          <div data-v-5a90ec03 class="row">
            <div class="col-12 col-sm-12">
              <h1 v-if="loading" class="text-white">Loading...</h1>
              <h1 v-else class="text-white">{{ project[0].Title }}</h1>
              <hr class="header-divide"/>
              <h5 v-if="!loading" class="text-white text-bold">{{ project[0].PrimaryProduct }}<span v-if="project[0].SecondaryProduct && project[0].SecondaryProduct !== 'null'">, {{ project[0].SecondaryProduct }}</span></h5>
            </div>
          </div>
        </div>
      </section>


      <section v-if="!loading" style="margin-bottom: 15px; padding-top: 15px;">
        <div data-v-5a90ec03 class="container">
          <button class="lh1 mlauto list-btn dib my3"
              style="margin-bottom: 15px;" @click="goBack">Back</button>
           
            <div class="project-detail-card">
          <div data-v-5a90ec03 class="row">
            <div
              class="
                col-12 col-sm-12 col-lg-6
             
              "
            >
          
            <div @click="openLightbox" v-if="!loading" class="grid-item" :style="{ backgroundImage: 'url(' + server + '/images/' + projectId + '/' + project[0].PictureUrls[0] + ')' }">
                <!-- No need for img tag here -->
            </div>
            
            <div v-if="!loading && project[0].PictureUrls.length >= 2" class="small-grid-container">
            <div @click="openLightbox" class="small-grid-item" v-for="image in project[0].PictureUrls.slice(1)" :key="image" :style="{ backgroundImage: 'url(' + server + '/images/' + projectId + '/' + image + ')' }">
           
            </div>
         
            </div>
        
            </div>

            <div
              class="
                col-12 col-sm-12 col-lg-6
          
        
             
              "
            >
            <div class="dflex">
                      <avatar
                        v-once
                        class="mr5"
                        color="#fff"
                        v-bind:username="
													project[0].FirstName + ' ' + project[0].LastName
												"
                        v-bind:backgroundColor="
													colorGetter[
														Math.floor(Math.random() * colorGetter.length)
													]
												"
                      ></avatar>
                      <div class="dflex vcenter">
                        <div>
                          <h6 class="mb0">
                            <b>
                              {{
                              project[0].FirstName + " " + project[0].LastName
                              }}
                            </b>
                          </h6>
                          <p class="lh1 mb0">
                            <small>{{ project[0].Name }}</small>
                          </p>
                        </div>
                      </div>
                    </div>

                <div class="projcard-tagbox" style="margin-bottom: 10px; margin-top: 12px; display: flex;">
                    <span class="projcard-tag">{{ project[0].Market }}</span>
                </div>
                <hr/>        
                <h3>{{ project[0].Title }}</h3>
                <p style="margin-bottom: 10px"><small><b v-if="project[0].PrimaryProduct && !project[0].SecondaryProduct">Product: </b><b v-else-if="project[0].PrimaryProduct && project[0].SecondaryProduct">Products: </b></small>{{ project[0].PrimaryProduct }}<span v-if="project[0].SecondaryProduct && project[0].SecondaryProduct !== 'null'">, {{ project[0].SecondaryProduct }}</span></p>
                <p style="margin-bottom: 10px"><small><b v-if="project[0].MaterialStored && (!project[0].MaterialStoredSecondary || project[0].MaterialStoredSecondary == 'null')">Material Stored: </b><b v-else-if="project[0].MaterialStored && project[0].MaterialStoredSecondary">Materials Stored: </b></small>{{ project[0].MaterialStored }}<span v-if="project[0].MaterialStoredSecondary && project[0].MaterialStoredSecondary !== 'null'">, {{ project[0].MaterialStoredSecondary }}</span></p>
                <p style="margin-bottom: 10px">
                  <small><b>Overview: </b></small>

                  <span v-if="showFullDescription || project[0].Description.length < 600">
                    {{ project[0].Description }}
                  </span>

                  <span v-else>
                    {{ truncatedDescription }}
                    <button @click="showFullDescription = true" style="border: none; background: none; padding: 0; color: #429da8;">Click to view more</button>
                  </span>
                  
                </p>
                <div class="coolInstall-bottom dflex mt20" style="align-items: center;">

                  <Likes 
                  :LikeCount="project[0].LikedUsers && project[0].LikedUsers.length" 
                  :isLiked="project[0].iLiked == 0 ? false : true"
                  :LikedUsers="project[0].LikedUsers"
                  :PostID="project[0].ID"
                  />
                </div>             
                </div>         
          </div>
        </div>
        </div>
      </section>




      <div class="container">
        <div class="row">
          <div
            class="
              col-12 col-sm-12 col-md-10
              offset-md-1
              col-xs-8 col-sm-8 col-8 col-lg-8
              offset-lg-2
              mb20
            
            "
          > 
            <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12">
              <div class="flex-box-wrap">
                <div class="flex-internal">
                  <atom-spinner
                    :animation-duration="1000"
                    :size="40"
                    :color="'#575757'"
                  />
                  <p>Loading......</p>
                </div>
              </div>
            </div>

          <div v-if="comments.length && !commentLoading">

            <div v-for="(comment, index) in comments" :key="index">
              <div class="question" style="margin-bottom: 15px;">
                <div class="top-wrap">
                  <div class="dflex mb10">
                    <avatar
                      v-once
                      class="mr5"
                      color="#fff"
                      v-bind:username="
                        comment.FirstName + ' ' + comment.LastName
                      "
                      v-bind:backgroundColor="
                        colorGetter[
                          Math.floor(Math.random() * colorGetter.length)
                        ]
                      "
                    ></avatar>
                    <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                    -->
                    <div class="dflex vcenter">
                      <div>
                        <h6 class="mb0">
                          <b>{{
                            comment.FirstName + " " + comment.LastName
                          }}</b>
                        </h6>
                        <p class="lh1 mb0">
                          <small>{{ comment.Name }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mlauto dflex vcenter">
                    <i class="material-icons mr2 fs20">schedule</i>
                    <p v-if="comment.DateCommented" class="lh1 mb0">
                      {{
                        $moment(comment.DateCommented)
                          .subtract(1, "minutes")
                          .fromNow()
                      }}
                    </p>
                    <p v-else class="lh1 mb0">Unknown date</p>
                  </div>
                </div>

                <div
                  class="mb20 wbbw question-message"
                  style="font-weight: 300; font-size: 1.25rem; line-height: 1.2"
                  v-html="linkify2(comment.Comment)"
                ></div>

                <div class="question-bottom dflex"></div>
              </div>
              <!-- card -->
            </div>
          </div>

          <div v-if="!loading && !commentLoading" class="question">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">
                  Join in the conversation,
                  {{ getUsername.FirstName }}!
                </label>
                <textarea
                  v-model="postContent"
                  class="form-control"
                  id="reply-box"
                  rows="8"
                ></textarea>
                <button
                  v-if="!postContent.length"
                  disabled
                  class="mb20 lh1 mlauto list-btn dib"
                  style="
                    margin-top: 20px;
                    cursor: default;
                    background: rgb(222, 222, 222);
                    border: none;
                    padding: 5px 10px;
                    cursor: pointer;
                  "
                >
                  Submit
                </button>
                <button
                  v-else
                  @click.prevent="postNewComment"
                  class="mb20 lh1 mlauto list-btn dib"
                  style="margin-top: 20px; border: none; padding: 5px 10px; cursor: pointer;"
                >
                  Submit
                </button>
              </div>
            </div>
            <!--
            <div v-else v-html="project"></div>
            -->
          </div>
        </div>
      </div>
    </div>
  </template>




  
  <script>
  import axios from "axios";
  import { AtomSpinner } from "epic-spinners";
  import Likes from '../components/Organisms/Likes.vue';
  import LightboxGallery from '../components/Organisms/LightboxGallery.vue';
  import Avatar from '../components/Atoms/Avatar.vue'
  
  const auth = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    },
  };
  
  export default {
    name: "ETOProjectDetails",
    components: {
      AtomSpinner,
      Avatar,
      Likes,
      LightboxGallery
    },
    data() {
      return {
        server: import.meta.env.VITE_APP_SERVER_URL,
        projectId: this.$route.params.id,
        search: "",
        searchValue: "",
        project: [],
        comments: [],
        postContent: "",
        loading: true,
        commentLoading: false,
        lightboxView: false,
        reply: "",
        countOfPage: 25,
        currPage: 1,
        noQuestionFound: false,
        userID: this.$store.state.user.UserId,
        userName: this.$store.state.user.UserName,
        userEmail:this.$store.state.user.Email,
        showFullDescription: false,
        fullName:
          this.$store.state.user.FirstName +
          " " +
          this.$store.state.user.LastName,
      };
    },
    methods: {

      firstLoad: function () {
        this.loading = true;
        // this.$store.commit("setActivePage", "collaborate");
  
        // console.log(`${this.server}/api/v1/cool-installs/details/${this.userID}/${this.projectId}`)

        //http://localhost:5002/api/v1/cool-installs/details/CBE6A557-97A5-46EE-A223-E6862769A688/1024
        return axios
          .get(`${this.server}/api/v1/cool-installs/details/${this.userID}/${this.projectId}`, auth)
          .then(
            (response) => {
              if (response.status === 401) {
                this.$router.push("/");
                // console.log(response);
              } else {
                // console.log(response);
                this.project = response.data;
                this.loading = false;
                document
                  .getElementById("mobileScrollTarget")
                  .scrollIntoView({ behavior: "smooth" });
              }
              this.getDiscussion();
            },
            (error) => {
              // console.log(error);
              this.$router.push("/");
            }
          );
  
  
      },
      getDiscussion: function(){
        this.commentLoading = true;
        // /cool-installs/comments/:id
        return axios
          .get(`${this.server}/api/v1/cool-installs/comments/${this.projectId}`, auth)
          .then(
            (response) => {
              if (response.status === 401) {
                this.$router.push("/");
                // console.log(response);
              } else {
                // console.log(response);
                this.comments = response.data;
                this.commentLoading = false;
                // this.loading = false;
              }
            },
            (error) => {
              // console.log(error);
              this.$router.push("/");
            }
          );

      },
      getNewComments: function(){
        return axios
          .get(`${this.server}/api/v1/cool-installs/comments/${this.projectId}`, auth)
          .then(
            (response) => {
              if (response.status === 401) {
                this.$router.push("/");
                // console.log(response);
              } else {
                // console.log(response);
                this.comments = response.data;
              }
            },
            (error) => {
              // console.log(error);
              this.$router.push("/");
            }
          );

      },
      postNewComment: function () {

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };

      let postData = {
        Comment: this.sanitizeInput(this.postContent),
        UserID: this.userID,
        Email: this.project[0].LoweredEmail,
        FullName: this.fullName,
        CommenterEmail: this.userEmail,
      };
      
      return axios
        .post(`${this.server}/api/v1/cool-installs/comments/${this.projectId}`, postData, axiosConfig)
        .then(
          (response) => {
            if (response.status === 401) {
              window.location = "/logout";
              // console.log(response);
            } else {
              this.getNewComments();
              this.postContent = "";
              this.$noty.success("Successfully added your post!");

            }
          },
          (error) => {
            // console.log(error)
            this.$noty.error("There seems to be an issue posting your content");
            // this.$router.push("/");
          }
        );
    },
    sanitizeInput: function(input) {
      // Escape single quotes
      const escapedInput = input.replace(/'/g, "''");

      // Escape double quotes
      // Note: If you're using MySQL, you may want to escape double quotes with a backslash instead: input.replace(/"/g, '\\"')
      const escapedInputWithDoubleQuotes = escapedInput.replace(/"/g, '""');

      return escapedInputWithDoubleQuotes;
    },
    openLightbox(){
      this.lightboxView = true;
    },
    closeLightbox() {
      this.lightboxView = false;
    },
      linkify: function (text) {
      var urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      // console.log("replaced");
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="body-link" href="' +
          url.replace('&#x2F;&#x2F;', '//') +
          '" target="_blank">' +
          url.replace('&#x2F;&#x2F;', '//') +
          "</a>"
        );
      });
    },
    linkify2: function (text) {
      text.replace('&#x2F;','/');
      text.replace('%2F','/');
      var urlRegex =
        /(\b(https?|http|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

      //var entityScape = urlRegex.replace(/\x2f/g, "/");

      return text.replace(urlRegex, function (url) {
        //return '<a href="' + url.replace('&#x2F;&#x2F;', '//') + '">' + url.replace('&#x2F;&#x2F;', '//') + "</a>";
        return '<a href="' + url + '">' + url + "</a>";
      });
    },

    setPage: function (idx) {
        this.search = "";
        if (idx <= 0 || idx > this.totalPage) {
          return;
        }
        this.currPage = idx;
    },

    goBack() {
      this.$router.go(-1);
    }
  
      /*
      postNewResponse: function () {
        //this.loading = true;
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
          },
        };
        let postData = {
          Message: this.reply,
          EditedBy: this.userName,
          Topic: this.projectId,
          UserId: this.userID,
          notify: this.questions[0].LoweredEmail,
          fullName: this.fullName,
        };
        return axios
          .post(`${this.server}/api/v1/questions/comment`, postData, axiosConfig)
          .then(
            (response) => {
              if (response.status === 401) {
                window.location = "/logout";
              } else {
                this.$noty.success("Successfully added your comment!");
                this.questions = response.data;
                //this.loading = false;
                this.reply = "";
              }
            },
            (error) => {
              console.log(error);
              this.$noty.error("There seems to be an issue posting your comment");
              this.$router.push("/");
            }
          );
      },
  
      setPage: function (idx) {
        this.search = "";
        if (idx <= 0 || idx > this.totalPage) {
          return;
        }
        this.currPage = idx;
      },
  
      catSwitch: function (catID) {
        return this.questions.filter((e) => {
          return e.Fid.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
      },
      linkify: function (text) {
        var urlRegex =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        console.log("replaced");
        return text.replace(urlRegex, function (url) {
          return (
            '<a class="body-link" href="' +
            url.replace('&#x2F;&#x2F;', '//') +
            '" target="_blank">' +
            url.replace('&#x2F;&#x2F;', '//') +
            "</a>"
          );
        });
      },
      linkify2: function (text) {
        text.replace('&#x2F;','/');
        text.replace('%2F','/');
        var urlRegex =
          /(\b(https?|http|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  
        //var entityScape = urlRegex.replace(/\x2f/g, "/");
  
        return text.replace(urlRegex, function (url) {
          //return '<a href="' + url.replace('&#x2F;&#x2F;', '//') + '">' + url.replace('&#x2F;&#x2F;', '//') + "</a>";
          return '<a href="' + url + '">' + url + "</a>";
        });
      },
      decodeHtml: function (html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      },
      */
    },
    computed: {
      activeNav() {
        return this.$store.state.activePage;
      },
  
      colorGetter() {
        return this.$store.state.colors;
      },
      truncatedDescription() {
        if (this.project[0].Description.length > 600) {
          return this.project[0].Description.slice(0, 600) + '...';
        } else {
          return this.project[0].Description;
        }
      },
  
      filteredQuestions: function () {
        this.currPage = 1;
  
        if (this.search.trim() !== "") {
          return this.questions.filter((e) => {
            return (
              e.Subject.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
          });
        } else {
          return this.questions;
        }
      },
  
      foundNoQuestion: function () {
        if (this.search.length > 1 && this.filteredQuestions.length === 0) {
          //this.noUserFound = true;
          return true;
        }
      },
      pageStart: function () {
        return (this.currPage - 1) * this.countOfPage;
      },
      totalPage: function () {
        return Math.ceil(this.questions.length / this.countOfPage);
      },
      getUsername() {
        return this.$store.state.user;
      },
      USERID(){
        return this.$store.state.user.UserId;
      }
    },
    created() {
      this.$store.commit("showNavigation");
      // console.log(this.$route.path);
    },
    mounted() {
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.firstLoad();
      
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @media all and (min-width: 992px) {
    .flex-box-wrap {
      height: 200px;
      padding: 150px 0 125px 0;
      margin: 0;
    }
  }
  
  @media all and (max-width: 991px) {
    .flex-box-wrap {
      height: 100px;
      padding: 75px 0 50px 0;
      margin: 0;
      justify-content: center;
      align-items: center;
    }
  }
  .question {
    background-color: #fff;
    line-height: 44px;
    padding: 20px;
    margin-bottom: 5px;
    border-top: 0;
  }
  .top-wrap {
    display: flex;
    align-items: flex-start;
  }
  .question-bottom {
    align-items: flex-end;
  }
  .question-header {
    background: #fff;
    color: #575757;
    display: flex !important;
  }
  .question-message a {
    color: #00b4b4 !important;
  }
  .flex-column {
            max-width: 260px;
        }
        
        img {
            margin: 5px;
        }
        
        .scale {
            transform: scaleY(1.05);
            padding-top: 5px; 
        }




.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px; /* Adjust the gap between grid items as needed */
  margin: 10px;
}

.grid-item {
  width: 100%;
  height: 400px; /* Set height of grid item */
  background-size: cover; /* Ensure the background image covers the entire grid item */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}

.small-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px; /* Adjust the gap between grid items as needed */
  margin: 10px 0;
}

.small-grid-item {
  width: 100%;
  height: 100px; /* Set height of grid item */
  background-size: cover; /* Ensure the background image covers the entire grid item */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}
.project-detail-card{
    padding: 20px;
    background: #fff;
}

.projcard-tagbox {
  font-size: 14px;
  cursor: default;
  user-select: none;
  pointer-events: none;
  margin-bottom: 15px;
}
.projcard-tag {
  display: inline-block;
  background: #e6edf2;
  color: #777;
  border-radius: 3px 0 0 3px;
  line-height: 26px;
  padding: 0 10px 0 13px;
  position: relative;
  margin-right: 20px;
  cursor: default;
  user-select: none;
  transition: color 0.2s;
}
.projcard-tag::after {
  content: '';
  position: absolute;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #e6edf2;
  border-top: 13px solid transparent;
  right: -10px;
  top: 0;
}
.header-divide {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}
#reply-box{
  padding: 15px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background: rgb(230, 237, 242);
}

</style>
  