<template>
  <div>
    <!--
    <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div id="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div id="v-step-2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
-->
    <v-tour name="HomeTourNoOrders" :steps="steps" :callbacks="myCallbacks" v-bind:style="{ zIndex: tourZIndex }" style="position: relative;">
      <template v-slot="{ tour }">
      
          <v-step
            v-if="tour && tour.steps && tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template v-if="tour.currentStep === 9">
              <div>
                <button
                  @click="tour.skip"
                  class="v-step__button v-step__button-previous"
                >
                  Skip tour
                </button>
                <button
                  @click="tour.previousStep"
                  class="v-step__button v-step__button-previous"
                >
                  Previous step
                </button>
                <button
                  @click="tour.stop"
                  class="v-step__button v-step__button-skip"
                >
                  Got it!
                </button>
              </div>
            </template>
          </v-step>
      
      </template>
    </v-tour>
  </div>
</template>

<script>
export default {
  name: "home-tour-no-orders",
  data() {
    return {
      tourZIndex: 1,
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          header: {
            title: "Discover Spacenet 3!",
          },
          content: `<p style="font-weight: 300;">The Collaborate section is for asking questions, and sharing items with the Spacesaver Group. Future releases will include photo uploads and sharing of Cool Installs!</p>`,
          params: {
            placement: "left-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-1",
          header: {
            title: "The Document Library",
          },
          content: `
            <p style="font-weight: 300;">Marketing Materials contains marketing collateral, photos, videos and PDFs.
            Service & Installation contains documentation and install manuals, while
            Sales & Training Support houses general documents related to customer support, contracts etc.</p>
            `,
          params: {
            placement: "left-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-3",
          header: {
            title: "Tools",
          },
          content:
            '<p style="font-weight: 300;">This section contains useful tools like an interactive map of area contractor regions, as well as price lists and an FAQ.</p>',
          params: {
            placement: "left-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-4",
          header: {
            title: "News",
          },
          content:
            '<p style="font-weight: 300;">This section contains announcements and product-related news.</p>',
          params: {
            placement: "left-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-5",
          header: {
            title: "User menu",
          },
          content:
            '<p style="font-weight: 300;">The help tour can be enabled and disabled in this section. Alerts for expedited orders and collaborate posts also appear here.</p>',
          params: {
            placement: "left-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-6",
          header: {
            title: "News",
          },
          content:
            '<p style="font-weight: 300;">News and recent announcements for the Spacesaver Group are located in the section. This content can also be accessed under NEWS in the top navigation.</p>',
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            offset: [10, 20],
          },
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [10, 20],
              },
            },
          ],
        },
        {
          target: "#v-step-7",
          header: {
            title: "Helpful Links",
          },
          content:
            '<p style="font-weight: 300;">This section contains a few Helpful links to quickly navigate the site.</p>',
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-8",
          header: {
            title: "Newsletter",
          },
          content:
            '<p style="font-weight: 300;">You can access news and updates from the newsletter here, as well as under NEWS in the top navigation.</p>',
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: "#v-step-9",
          header: {
            title: "FAQ",
          },
          content:
            '<p style="font-weight: 300;">This section contains helpful Frequently Asked Questions. It can also be accessed under TOOLS in the top navigation.</p><p>You can continue the tour by exploring other pages, or click "Skip tour" to disable the help.</p>',
          params: {
            placement: "right-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
      ],
      myCallbacks: {
        onSkip: this.customSkipStepCallback,
      },
    };
  },
  methods: {
    customSkipStepCallback: function () {
      this.$store.commit("hideTour");
      this.$toast.success("The help tour has been disabled.", { hideProgressBar: false, icon: false });
    },
  },
  mounted: function () {
    this.$tours["HomeTourNoOrders"].start();
  },
};
</script>
<style>
.v-step {
  /*background: #F16231!important;*/
  background: #50596c;
  background: #5f6d7b !important;
  color: #fff;
  max-width: 320px;
  border-radius: 3px;
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  padding: 1rem;
  text-align: center;
  z-index: 2000;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.28);
}
.v-step__header {
  margin: -1rem -1rem 0.5rem;
  padding: 0.5rem;
  background-color: #454d5d;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
