<template>
<div>
     
    <exorderdetails-tour v-if="$store.state.tour && !loading" />
    
    <section
      v-if="loading"
      class="py-5 bg9 bs-shadow mobile-top"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Project....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>
    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div class="container">
        <h1
          v-if="loading == false && project && project.orderNo != null"
          class="text-white mb0 lh1"
        >
          {{ "Parts request for Order " + project.orderNo }}
        </h1>
        <h1 v-else class="text-white mb0 lh1">Loading.....</h1>
        <p v-if="!loading && project" class="text-white lh1 mb0">
          {{ project.expectations }}
        </p>
      </div>
    </section>
    <div v-if="loading || !project" class="row">
      <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#575757'"
            />
            <p>Loading......</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container mt30 mb20">
      <!-- col -->
      <div class="row">
        <!--
          This structure is this way due to a request from order entry, they want the WYSIWYG editor to be at the same height as the info soo they didn't have to scroll two inches. This page looks one way to a spacesaver employee, and different to an AC
          -->
        <div :class="employee ? 'col-lg-7' : 'col-lg-9'">
          <div
            id="exorderdetails-step-1"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <div class="question-header d-flex align-items-center mb5 rounded">
              <div class="d-flex number-showing w100">
                <back-button/>
                <!--
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mrauto list-btn dib d-flex center"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
                -->

                <div class="status ml">
                  <p
                    v-if="project && project.newOrderNo !== null"
                    class="badge badge-success"
                  >
                    Completed
                  </p>
                  <p v-else class="badge badge-success">Not Completed</p>
                </div>
              </div>

              <div class="pag-wrapper mlauto">
                <h2 v-if="project && project.requestReason">{{ project.requestReason }}</h2>
              </div>
            </div>
            <hr />

            <div class="info-top">
              <div class="row">
                <div class="col col-6">
                  <h4>Shipping</h4>
                
                  <h6 class="mt20"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">corporate_fare</span><span style="margin-left: 5px;"> Contact Company</span>
                      </h6>
                  <p class="text-muted mb5 lh1">
                    <small v-if="project && project.shippingCompany" class="text-muted">
                      {{ project.shippingCompany + " " }}
                      <br />
                    </small>
                    <small v-else class="text-muted">n/a</small>
                    <small v-if="project && project.address1" class="text-muted">
                      {{ project.address1 }}
                    </small>
                    <br />
                    <small v-if="project && project.address2" class="text-muted">
                      {{ project.address2 }}
                    </small>
                    <br v-if="project && project.address2" />
                    <small v-if="project && project.address1" class="text-muted">
                      {{
                        project.city + ", " + project.state + " " + project.zip
                      }}
                    </small>
                    <small v-else class="text-muted">n/a</small>
                  </p>
      
                  <h6 class="mt20"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">local_shipping</span><span style="margin-left: 5px;"> Carrier</span>
                      </h6>
                  <p class="text-muted mb0 lh1">
                    <small v-if="project && project.shippingCarrier" class="text-muted">
                      {{ project.shippingCarrier }}
                    </small>
                    <small v-else class="text-muted">n/a</small>
                  </p>
                </div>
                <div class="col col-6">
                  <div class="top-row">
                    <div v-if="project">
                      <h4>Job Information</h4>
                  
                      <h6 class="mt20"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">person</span><span style="margin-left: 5px;"> Submitted By:</span>
                      </h6>
                      <p class="text-muted mb5 lh1">
                        <small v-if="project.whoAdded">
                          {{ project.whoAdded }}
                        </small>
                        <small v-else>n/a</small>
                      </p>
                    </div>
                  </div>

      
                  <h6 class="mt10"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">corporate_fare</span><span style="margin-left: 5px;"> Area Contractor Submitted</span>
                      </h6>
                  <p class="text-muted mb5 lh1">
                    <small v-if="project && project.acName" class="text-muted">
                      {{ project.acName }}
                    </small>
                    <small v-else class="text-muted">n/a</small>
                  </p>
                </div>
              </div>

              <div v-if="employee">
                <hr />
                <!-- employee view -->
                <div class="row">
                  <div class="col col-6">
                    <div class="mb10">
                      <h6 class="my-0" style="display: flex; align-items: center;"><span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Parts Request ID
                      </h6>
               

                      <div v-if="!loading && project && project.id">
                        <small class="text-muted">{{ project.id }}</small>
                      </div>
                    </div>
                  <!--
                    <div v-if="project.newOrderNo" class="mb10">
                      <h6 class="my-0">
                        <i class="fas fa-file-alt"></i> New Order #
                      </h6>

                      <div v-if="!loading">
                        <small class="text-muted">
                          <router-link
                            :to="/order-details/ + project.newOrderNo"
                            >{{ project.newOrderNo }}</router-link
                          >
                        </small>
                      </div>
                    </div>
                  -->
                    <div class="mb10">
                      <h6 class="my-0"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Order #</span>
                      </h6>

                      <div v-if="!loading">
                        <small class="text-muted">
                          <router-link
                            :to="/order-details/ + project.orderNo"
                            >{{ project.orderNo }}</router-link
                          >
                        </small>
                      </div>
                    </div>
                    <div class="mb10">
                      <h6 class="my-0" style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">calendar_month</span> <span style="margin-left: 5px;">Submitted Date</span>
                      </h6>

                      <div v-if="!loading">
                        <small class="text-muted">{{
                          $moment(project.whenAdded).format("MM-DD-YYYY")
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col col-6">
                    <div class="mb10">
                      <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Salesforce Case #</span>  
                    </h6>
          

                      <div v-if="!loading && project.caseNumber">
                        <small class="text-muted">
                          {{ project.caseNumber }}
                        </small>
                      </div>
                      <div v-else>
                        <small class="text-muted"> NA </small>
                      </div>
                    </div>
                    <div class="mb10">
                      <h6 class="my-0" style="display: flex; align-items: center;">
                        <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">calendar_month</span> Expectations 
                    </h6>
                     

                      <div v-if="!loading && project.expectations">
                        <small class="text-muted">{{
                          project.expectations
                        }}</small>
                      </div>
                      <div v-else>
                        <small class="text-muted">NA</small>
                      </div>
                    </div>
                    <div class="mb10">
                     
                      <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">attach_file</span><span style="margin-left: 5px;"> Documents</span>  
                    </h6>
                      <div v-if="!loading && project && project.eoDocuments.length">
                        <small
                          v-for="doc in docs"
                          :key="doc.id"
                          class="text-muted"
                        >
                          <div>
                            <a v-if="doc.documentURL.includes('.om')"
                              v-on:click.left = "rightClickWarn"
                              :href="doc.documentURL"                        
                              style="cursor: pointer"
                              target="_blank"
                              rel="noopener noreferrer"
                            
                              data-toogle="tooltip"
                            title="If the document is an .om file, please right click to download the file."
                              >{{ doc.documentName }}
                            </a>
                            <a
                              v-else
                              :href="doc.documentURL"                        
                              style="cursor: pointer"
                              target="_blank"
                              rel="noopener noreferrer"
                          
                              data-toogle="tooltip"
                            title="If the document is an .om file, please right click to download the file."
                              >{{ doc.documentName }}</a
                            >
                          </div>
                        </small>
                      </div>
                      <div v-else>
                        <small class="text-muted">NA</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            
              <h6 class="my-0"  style="display: flex; align-items: center;">
                        <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Notes</span>
                      </h6>
              <p class="text-muted mb5 lh1">
                <small
                  v-if="project.notes"
                  class="text-muted"
                  v-html="project.notes"
                ></small>
                <small v-else class="text-muted">n/a</small>
              </p>
            </div>
          </div>

          <!-- new card -->
          <div
            id="exorderdetails-step-2"
            class="card-back mb10 rounded bs-shadow p-3"
          >
            <h4>Parts</h4>

            <!-- line content -->

            <div v-if="parts !== null" class="tab-pane fade show active">
              <table class="table table-striped table-bordered lines-table mb0">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%">Part #</th>
                    <th scope="col" style="width: 8%">Qty</th>
                    <th scope="col" style="width: 15%">Color</th>
                    <th scope="col" style="width: 20%">Reason</th>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(line, index) in parts" v-bind:key="index">
                    <th scope="row">
                      <small class="text-muted">
                        {{ line.partNumber }}
                      </small>
                    </th>
                    <td>
                      <small class="text-muted">
                        {{ line.quantity }}
                      </small>
                    </td>
                    <td>
                      <small class="text-muted">
                        {{ line.color }}
                      </small>
                    </td>
                    <td>
                      <small class="text-muted">
                        {{ line.reason }}
                      </small>
                    </td>
                    <td>
                      <small class="text-muted">
                        {{ line.notes }}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <small v-else class="text-muted">No Parts</small>
          </div>
        </div>

        <div v-if="!employee" class="col-lg-3">
          <div class="card-back mb10 rounded bs-shadow p-3">
            <ul class="list-group mb-3">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> PARTS REQUEST ID
                  </h6>

                  <div v-if="!loading && project && project.id">
                    <small class="text-muted">{{ project.id }}</small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0"><i class="fas fa-file-alt"></i> Order #</h6>

                  <div v-if="!loading && project && project.orderNo">
                    <small class="text-muted">
                      <router-link :to="/order-details/ + project.orderNo">{{
                        project.orderNo
                      }}</router-link>
                    </small>
                  </div>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> Salesforce Case #
                  </h6>

                  <div v-if="!loading && project && project.caseNumber">
                    <small class="text-muted">
                      {{ project.caseNumber }}
                    </small>
                  </div>
                  <div v-else>
                    <small class="text-muted"> NA </small>
                  </div>
                </div>
              </li>

              <li
                v-if="project && project.newOrderNo"
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> New Order #
                  </h6>

                  <div v-if="!loading && project">
                    <small class="text-muted">
                      <router-link :to="/order-details/ + project.newOrderNo">{{
                        project.newOrderNo
                      }}</router-link>
                    </small>
                  </div>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-table"></i> SUBMITTED DATE
                  </h6>

                  <div v-if="!loading && project">
                    <small class="text-muted">{{
                      $moment(project.whenAdded).format("MM-DD-YYYY")
                    }}</small>
                  </div>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-business-time"></i> EXPECTATIONS
                  </h6>

                  <div v-if="!loading && project && project.expectations">
                    <small class="text-muted">{{ project.expectations }}</small>
                  </div>
                  <div v-else>
                    <small class="text-muted">NA</small>
                  </div>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-alt"></i> DOCUMENTS
                  </h6>

                  <div v-if="!loading && project && project.eoDocuments.length">
                    <small v-for="doc in docs" :key="doc.id" class="text-muted">
                      <div>
      
            
                       <a v-if="doc.documentURL.includes('.om')"
                              v-on:click.left = "rightClickWarn"
                              :href="doc.documentURL"                        
                              style="cursor: pointer"
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              data-toogle="tooltip"
                            title="If the document is an .om file, please right click to download the file."
                              >{{ doc.documentName }}
                            </a>
                            <a
                              v-else
                              :href="doc.documentURL"                        
                              style="cursor: pointer"
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              data-toogle="tooltip"
                            title="If the document is an .om file, please right click to download the file."
                              >{{ doc.documentName }}</a
                            >
                      
                      </div>
                    </small>
                  </div>
                  <div v-else>
                    <small class="text-muted">NA</small>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="employee" class="col-lg-5">
  <!-- new card -->
  <div class="card-back mb10 rounded bs-shadow p-3">
    <div class="edit-notes">
      <h4>SSC Notes</h4>
      <!--
      <button
        @click="turnonEdit"
        class="mb0 lh1 mlauto list-btn dib d-flex center router-link-active"
      >
        Edit
      </button>
      -->
    </div>

    <form>
      <div class="form-group">
        <label for="ordernum">Order Number</label>
        <input
          v-if="project.newOrderNo"
          disabled
          id="ordernum"
          type="text"
          class="form-control"
          :value="project.newOrderNo"
        />
        <input
          v-else
          id="ordernum"
          type="text"
          class="form-control"
          placeholder="Enter new order number"
          v-model="tempOrderNo"
        />
      </div>

      <div class="form-group">
        <label for="exampleFormControlTextarea1">Notes</label>
        <div id="app">
          <div class="document-editor__toolbar"></div>
          <div class="document-editor__editable-container">
            <ckeditor
              v-model="project.sscNotes"
              :editor="editor"
              :config="editorConfig"
              @ready="onEditorReady"
            ></ckeditor>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="custom-checkbox">
          <input
            type="checkbox"
            v-model="project.isComplete"
          />
          <span class="checkmark"></span>
          Mark as Complete
        </label>
      </div>

      <button
        @click.prevent="updateNotes()"
        class="
          mb0
          lh1
          mlauto
          list-btn
          dib
          d-flex
          center
          router-link-active
        "
      >
        Submit
      </button>
    </form>
  </div>
</div>

      </div>
    </div>


    
</div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import BackButton from '@/components/Buttons/BackButton.vue';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import exorderdetailsTour from "../components/Tours/exorderdetailsTour.vue";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + 'eyJhbGci7yJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIyREQyQzk4NC1BM0RELTQ5MjMtQUM1Mi01QjA0MzgyOTg5QUQiLCJVc2VyTmFtZSI6ImpvYXMiLCJFbWFpbCI6ImpvYXNAc3BhY2VzYXZlci5jb20iLCJGaXJzdE5hbWUiOiJKb3NodWEiLCJMYXN0TmFtZSI6Ik9hcyIsIkNvbXBhbnkiOiJTcGFjZXNhdmVyIENvcnBvcmF0aW9uIiwiRGVwYXJ0bWVudCI6IklTIiwiUGhvbmVOdW1iZXIiOiI5MjA1NjM2MzYyIiwiQ2VsbFBob25lIjoiIiwiRW1haWxEb21haW4iOiJzcGFjZXNhdmVyLmNvbSIsIkFDSWQiOlswXSwiUmVwSWQiOjAsIlJvbGVzIjpbInNzYyIsImRvY2xpYmFkbWluIiwiYWRtaW4iXSwiYWRtaW4iOnRydWUsInNzY2VtcGxveWVlIjp0cnVlLCJkb2NsaWJhZG1pbiI6dHJ1ZSwiTGFzdEFjdGl2aXR5IjoiMjAyMS0wNC0yMlQxMzo1MzoxNy4yMTNaIiwiaWF0IjoxNjE5MDk5NjM5LCJleHAiOjE2MjE2OTE2Mzl9.gWs9nahp7NAzljgV0jhS27setYqj-fo87lmaBkzj_D0'
  },
};

export default {
  name: "ExpeditedOrderDetails",
  components: {
    AtomSpinner,
    BackButton,
    ClassicEditor,
    exorderdetailsTour,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      projectId: this.$route.params.id,
      editor: ClassicEditor,
      project: null,
      parts: null,
      docs: null,
      loading: false,
      processing: false,
      preprod: false,
      inprod: false,
      shipped: false,
      open: "",
      pdfPreview: "",
      requestedDoc: "",
      sscNotesStripped: null,
      editing: false,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.

        toolbar: [
          { name: "basicstyles", items: ["Bold", "Italic"] },
          { name: "paragraph", items: ["NumberedList", "BulletedList"] },
          { name: "tools", items: ["Maximize", "-", "About"] },
        ],
      },
      tempOrderNo: null,
    };
  },
  methods: {


    firstLoad: function () {
      console.log(this.$route.params.id);
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/expeditedorders/${this.projectId}`, auth)
        .then((response) => {
          console.log(response)
          //console.log(response.data[0]);
          this.project = response.data[0];
          this.parts = response.data[0].eoParts;
          this.docs = response.data[0].eoDocuments;
          /*
          let regex = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;
          if (this.project != null) {
            let newNotes = this.project.sscNotes.replace(regex, "");
            console.log("new notes " + newNotes);
            this.sscNotesStripped = newNotes;
          }
          */
          this.loading = false;

          document
            .getElementById("mobileScrollTarget")
            .scrollIntoView({ behavior: "smooth" });
        });
    },


    onReady(editor) {
          const toolbarContainer = document.querySelector(  '.document-editor__toolbar' );
          toolbarContainer.appendChild( editor.ui.view.toolbar.element );
    },

    onEditorReady () {
      const myClass = 'beef-tips'
      const addCustomClass = () => this.$el.nextSibling
        .querySelector('.ck-content')
        .classList
        .add(myClass)

      this.$nextTick(addCustomClass)
    },

    downloadWithJS(href){
      //window.open(file, '_blank');
      var anchor = document.createElement('a');
      anchor.href = href;
      anchor.download = href;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    rightClickWarn(){
      this.$toast.success("Please right click to download .om files");
      /*
      this.$noty.success(
        "Please right click to download .om files"
      );
      */
      
    },

    forceDownload(blob, filename) {
      var a = document.createElement('a');
      a.download = filename;
      a.href = blob;
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a);
      a.click();
      a.remove();
    },


    downloadWithAxios(url, title) {
      //console.log(url);
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          this.forceFileDownload(response, title)
        })
        .catch(() => console.log('error occured'))
    },

    makeActive(item) {
      //console.log(item);
      if (this.open === item) {
        this.open = "";
      } else {
        this.open = item;
      }
    },
    turnonEdit() {
      this.editing = true;
    },
    getDocument(link) {
      //console.log("link: " + link);
      /*
      window.open(
        `http://spacenet2.spacesaver.com/ordermanage/${link}`,
        "_blank"
      );
      */
      window.open(`{link}`, "_blank");
    },
    updateNotes() {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let theDATA = [
        {
          op: "replace",
          path: "/newOrderID",
          value: this.project.newOrderNo
            ? this.project.newOrderNo
            : this.tempOrderNo,
        },
        {
          op: "replace",
          path: "/NewIDEnteredBy",
          value: this.project.newIdEnteredBy,
        },
        {
          op: "replace",
          path: "/SSCNotes",
          value: this.project.sscNotes,
        },
        {
          op:"replace",
          path:"/IsComplete",
          value: this.project.isComplete,
        }
      ];

      return axios
        .patch(
          `${this.ordermanageapi}/expeditedorders/${this.projectId}`,
          theDATA,
          axiosConfig
        )
        .then((response) => {
          //console.log(response);
          if (this.tempOrderNo) {
            this.project.newOrderNo = this.tempOrderNo;
          }
          this.$toast.success("Successfully updated expedited order!");
          //console.log(response.data.thisStream);
          //window.open(response.data.url, "_blank");

          //console.log(response.data[0]);
          /*
          this.project = response.data[0];
          this.parts = response.data[0].eoParts;
          this.docs = response.data[0].eoDocuments;
          this.loading = false;
*/
        })
        .catch((err) => {
          /*
          this.$noty.error(
            "Sorry, there was an issue updating the expedited order"
          );
          */
          this.$toast.error("Sorry, there was an issue updating the expedited order");
        });
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    strippedContent() {
      //let regex = /(<([^>]+)>)/gi;
      let regex = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g;
      if (this.project != null) {
        let newNotes = this.project.sscNotes.replace(regex, "");
        //console.log("new notes " + newNotes);
        this.sscNotesStripped = newNotes;
      }
    },
    employee() {
      return this.$store.getters.isSpacesaverEmployee;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    getUserID() {
      this.project.newIdEnteredBy = this.$store.state.user.UserId;
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad().then(function () {});
  },
};
</script>
<style>

.cke_editable p {
  margin: 0 0!important;
  padding: 0 0!important;
}

.beef-tips{
  background: pink;
}
body > .cke_editable {
  background: pink;
}

.ck-content p {
  margin: 0!important;
  padding: 0!important;
}
.ck-content .text-tiny {
    margin: 0!important;
  padding: 0!important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
   margin: 0!important;
  padding: 0!important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    margin: 0!important;
  padding: 0!important;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    margin: 0!important;
  padding: 0!important;
}
#cke_editor1 p {
  margin: 0;
}
.document-editor .ck-content p {
        font-size: 1em;
        line-height: 1.63em;
        padding-top: 0;
        margin-bottom: 0;
        color: pink!important;
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
 * CKEditor 5 (v28.0.0) content styles.
 * Generated on Mon, 07 Jun 2021 06:33:15 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-html-embed/theme/htmlembed.css */
.ck-content .raw-html-embed {
  margin: 1em auto;
  min-width: 15em;
  font-style: normal;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.card-back {
  background: #fff;
}
.article {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.tab-container {
  background: #f2f2f3;
  padding: 10px;
  border-radius: 4px;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
}
#nav-tabContent {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.d-flex {
  display: flex;
}
.ml {
  margin-left: auto;
}
/*
.comments:nth-child(even) {
  background: #efefef;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
}
*/
.comments {
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
}
.comments p {
  margin-bottom: 10px;
}
.comments:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments:last-child p {
  margin-bottom: 0;
}
.tab-container a {
  color: #429da8;
}
.mt30 {
  margin-top: 30px;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.8em;
}
.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
}

.progtrckr[data-progtrckr-steps="2"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="3"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="4"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="5"] li {
  width: 25%;
}

.progtrckr li.progtrckr-done {
  color: #fff;
  border-bottom: 4px solid #fff;
}
.progtrckr li.progtrckr-todo {
  color: #3e4651;
  border-bottom: 4px solid #3e4651;
}

.progtrckr li:after {
  content: "\00a0\00a0";
  margin-left: 28px;
  /* this is the text*/
}
.progtrckr li:before {
  position: relative;
  bottom: -2.5em;
  float: left;
  left: calc(50% - 20px);
  line-height: 1em;
}
.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: #3e4651;
  background-color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  border-radius: 40px;
}
.progtrckr li.progtrckr-todo:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: #3e4651;
  background-color: #3e4651;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}
/* trackker titles */
.tracker-title-wrap {
  margin-top: 15px;
}
.trckr-title {
  padding-left: 0;
}
.trckr-title li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
  width: 25%;
  color: #fff;
}
.trckr-title-done {
  color: #fff !important;
}
.trckr-title-todo {
  color: #3e4651 !important;
}
.badge-success {
  color: #fff;
  background-color: #3e4651;
}
@media all and (max-width: 700px) {
  .trckr-title li {
    font-size: 12px;
  }
}
.up {
  text-transform: uppercase;
}
.lines-table td,
.lines-table th {
  padding: 5px;
}
.small,
small {
  font-size: 90% !important;
}

.table td .small,
small {
  font-size: 90% !important;
}
.bol {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  list-style-type: none;
  /*border: 1px solid #dee2e6;*/
  background: #efefef;
  margin-bottom: 5px;
  transition: background 0.8s linear;
  border-radius: 4px;
  cursor: pointer;
}
.bol:hover {
  background: #e6e5e5;
}
.parent {
  display: flex;
  align-items: center;
}
.parent i {
  transition: color 0.8s linear;
}
.parent:hover i {
  color: #000;
}
.parent:hover {
  color: #000;
}
.parent:active i {
  color: #00b4b4;
}

.child-list {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /*transition: height 0.8s linear;*/
  height: 100%;
  max-height: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
.child-list li {
  position: relative;
  list-style-type: none;
  margin-left: 0;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}
.child-list li:last-of-type {
  border-bottom: none;
}
.is-closed {
  max-height: 0;
}
.show {
  /*height: 100% !important;*/
  max-height: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
  height: auto;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: height 0.5s;
  height: auto;
}
.height-enter,
.height-leave-to {
  height: 0;
}
.ship-head {
  display: flex;
  width: 100%;
}
.pallet {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-number {
  width: calc(50% + 3px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 700px) {
  .pallet {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ship-number {
    width: calc(60% + 3px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-arrow-circle-down {
  margin-left: auto;
}

.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.info {
  border-top: 1px solid #dedede;
  padding-top: 5px;
  margin-top: 5px;
}
.truck-no {
  margin-left: -5px;
}
.closeX {
  top: 0;
}
.pdfLink {
  color: #429da8;
  cursor: pointer;
  margin-bottom: 0;
}
.pdfLink:hover {
  color: #00b4b4;
}
.pdfLink:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10003 !important;
}
.text-muted a {
  color: #00b4b4;
}
.doc-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.edit-notes {
  display: flex;
}
.edit-notes button {
  margin-left: auto;
}

#cke_17 {
  display: none !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f8f8;
  opacity: 1;
}

.custom-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 2px solid #dcdcdc; /* Neutral border color when unchecked */
  margin-right: 8px;
  transition: border-color 0.3s, background-color 0.3s;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #00b4b4; /* Checkbox background color when checked */
  border-color: #00b4b4; /* Border color changes to 00b4b4 when checked */
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}







/*
.doc-wrapper:last-child {
  margin-bottom: 0 !important;
}
*/
</style>
