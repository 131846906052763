<template>
          <div
            class="
              question-header
              d-flex
              align-items-center
              p-3
    
              mb20
              rounded
              bs-shadow
            "
          >
            <!--
            {{"Filtered Page length: " + filteredQuestions.length / 25}}
            -->

            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(filteredPosts.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div id="trailblazer-step-2" class="nav-btns">
                <button v-if="currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    currPage === totalPage ||
                    currPage == Math.ceil(filteredPosts.length / 25)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
</template>

<script>
export default {
  name: "SimplePagination",
  props: {
    currPage: Number,
    totalPage: Number,
    countOfPage: Number,
    filteredPosts: Array,
    setPage: {
      type: Function,
    },
   
  },
  computed: {
    /*
    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function () {
      return Math.ceil(this.posts.length / this.countOfPage);
    },
    filteredPosts: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.posts.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.title.rendered.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
          );
        });
      } else {
        //if the search box is not filled in
        return this.posts;
      }
    },
    */
  },
};
</script>

<style scoped></style>
