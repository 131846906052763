import axios from "axios";

const state = {
    eos: [],
    endpoint: "https://ordermanage.spacesaver.com",
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    geteoList: (state) => {
        return state.eos;
    }
};

const actions = {};

const mutations = {
    SET_EOS(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started Vuex eos");
        return axios
            .get( process.env.VUE_APP_ORDER_MANAGE_API + '/expeditedorders?pending=true', axiosConfig)
            .then((response) => {
                //console.log(response);
                //console.log("finished Vuex eos " + response);
                state.eos = response.data;
            });
    },
    UPDATE_EOS(state, data) {      
        state.eos = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}