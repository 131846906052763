<template>
  <div class="page-product-marketing">
    <announcements-tour v-if="$store.state.tour && !loading" />

    <section class="py-5 bg11 mobile-top">
      <div class="container">
        <div class="input-wrap">
          <input
            id="trailblazer-step-1"
            class="top-search mt0"
            type="text"
            placeholder="Search Trailblazer"
            style="padding: 15px;"
            v-model="search"
          />
          <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="search-btns">
          <div class="right-side">
            <button
              v-if="search.trim().length && !searchStarted"
              @click="searchPosts"
              class="thesearchbutton"
              style="margin-right: 0;"
            >
              <span class="material-icons">search</span>
              Search
            </button>
            <button v-else class="thesearchbutton" disabled style="margin-right: 0;">
              <span class="material-icons">search</span>
              Search
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row mt20">
        <Loading v-if="loading" />
        <NothingFound v-else-if="!loading && !paginatedPosts.length" />
        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <SimplePagination
            v-if="!searchStarted"
            :currPage="currPage"
            :totalPage="totalPage"
            :filteredPosts="filteredPosts"
            :setPage="setPage"
          />
          <div class="row row-eq-height">
            <div
              v-for="(post, index) in paginatedPosts"
              :key="post.id"
              class="col-xs-12 col-sm-12 col-12 col-lg-4 mb20"
            >
              <div class="post br4 bs-shadow">
                <h6 class="post-title" v-html="truncateTitle(post.title.rendered)" style="min-height: 44px"></h6>
                <div class="excerpt-wrap">
                  <p
                    v-html="truncateExcerpt(post.excerpt.rendered)"
                    class="post-excerpt"
                    style="margin-bottom: 0 !important"
                  ></p>
                </div>
                <div :id="index === 0 ? 'trailblazer-step-3' : ''" class="btn-wrap">
                  <router-link
                    :to="{ name: 'trailblazorPost', params: { slug: post.slug } }"
                    class="list-btn"
                  >
                    Read More
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <SimplePagination
            v-if="!searchStarted"
            :currPage="currPage"
            :totalPage="totalPage"
            :filteredPosts="filteredPosts"
            :setPage="setPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "@/components/feedback/Loading.vue";
import NothingFound from "@/components/feedback/NothingFound.vue";
import SimplePagination from "@/components/Pagination/SimplePagination.vue";
import AnnouncementsTour from "@/components/Tours/announcementsTour.vue";

const auth = {
  headers: {
    Authorization: "Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6",
  },
};

export default {
  name: "Trailblazor",
  components: {
    Loading,
    NothingFound,
    SimplePagination,
    AnnouncementsTour,
  },
  data() {
    return {
      search: this.$route.query.search || "",
      posts: [],
      trailblazor: import.meta.env.VITE_APP_TRAILBLAZOR,
      loading: true,
      countOfPage: 18,
      currPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      searchStarted: !!this.$route.query.search,
    };
  },
  methods: {
    async firstLoad() {
      this.loading = true;
      this.$store.commit("setActivePage", "initiatives");
      const response = await axios.get(`${this.trailblazor}/wp-json/wp/v2/posts/?per_page=100`)
      this.posts = response.data;
      this.loading = false;
    },
    truncateTitle(value) {
      return value.length > 35 ? value.substring(0, 55) + "..." : value;
    },
    truncateExcerpt(value) {
      return value.length > 85 ? value.substring(0, 85) + "..." : value;
    },
    setPage(idx) {
      if (idx <= 0 || idx > this.totalPage) return;
      this.currPage = idx;
      this.$router.push({ query: { ...this.$route.query, page: idx } });
    },
    updateURL() {
      const query = { ...this.$route.query };

      if (this.searchStarted && this.search.trim()) {
        query.search = this.search.trim();
      } else {
        delete query.search;
      }

      query.page = 1; // Reset to the first page on new search
      this.$router.push({ query });
    },
    clearSearch() {
      this.searchStarted = false;
      this.search = "";
      this.updateURL();
    },
    searchPosts() {
      this.searchStarted = true;
      this.updateURL();
    },
  },
  computed: {
    showButton() {
      return this.search.trim().length > 0;
    },
    pageStart() {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage() {
      return Math.ceil(this.posts.length / this.countOfPage);
    },
    filteredPosts() {
      if (this.searchStarted && this.search.trim().length > 0) {
        return this.posts.filter((e) =>
          e.title.rendered.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.posts;
    },
    paginatedPosts() {
      return this.searchStarted
        ? this.filteredPosts
        : this.filteredPosts.slice(this.pageStart, this.pageStart + this.countOfPage);
    },
  },
  created() {
    this.$store.commit("showNavigation");
  },
  mounted() {
    this.firstLoad().then(() => {
      if (this.$route.query.page && !this.searchStarted) {
        this.setPage(parseInt(this.$route.query.page));
      }
    });
  },
};
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.post {
  background: #fff;
  padding: 20px;
  /*
  margin-bottom: 20px;
  */
  height: 100%;
  
  /*
  border-top: 2px solid #dedede;
  */
  display: flex;
  flex-direction: column;
}
.post a {
}
.post-excerpt {
  font-size: 14px;
}
.post button {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #00b4b4;
  color: #fff;
  border-radius: 4px;
}

.post .list-btn {
  display: inline-block;
}

.flex-box-wrap {
  margin-top: 100px;
}
.post-title {
  font-size: 1.2em;
}

.post-excerpt {
  padding-bottom: 0;
  margin-bottom: 0;
}
.post-excerpt p {
  margin-bottom: 0 !important;
}
.excerpt-wrap {
  min-height: 79px !important;
  margin-bottom: 1rem;
}
.post-top {
  display: flex;
}
.post-top h6 {
  margin-left: 10px;
}
.post-icon {
  color: rgba(44, 41, 61, 0.2);
  font-size: 1.3em;
}
.btn-wrap {
  margin-top: auto;
}
.question-header {
  background: #fff;
  color: #575757;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.search{
  margin-left: auto;
}
.search-btns {
  display: flex;
  margin-top: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 10px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}
.right-side{
  display: flex;
}
.thesearchbutton{
  margin-left: auto;
}
</style>