<template>
  <section class="pt-5 pb-3 bg8 bs-shadow mobile-top">
    <div id="mm-step-1" class="container">
      <div class="input-group mb-3 bs-large">
        <input
          type="text"
          class="top-search"
          placeholder="Search for marketing media in all categories"
          :searchValue="searchValue" @input="updateValue($event.target.value)"
          @keyup.enter="searchQuery"
          style="padding: 20px !important"
        />
      </div>
      <div class="search-btns">
        <div class="left-side">
          <h6
            v-if="search.length && search != 'Products/'"
            class="mb0 d-flex"
            style="color: #fff; text-transform: uppercase"
          >
            <i class="material-icons crumb" style="margin-right: 2px"
              >category</i
            >
            Marketing Materials > {{ catClean(search) }}
            {{
              fileType ==
              "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                ? " > Documents"
                : ""
            }}
            {{ fileType == "MPEG4" ? " > Videos" : "" }}
            {{ fileType == "JPEG" ? " > Images" : "" }}
            {{ catType != "" ? " > " + catClean(catType) : "" }}
          </h6>
          <h6
            v-else-if="searchValue.length"
            class="mb0 d-flex"
            style="color: #fff; text-transform: uppercase"
          >
            <i class="material-icons crumb" style="margin-right: 2px"
              >category</i
            >
            Marketing Materials > Searching > All Categories
          </h6>
          <h6
            v-else
            class="mb0 d-flex"
            style="color: #fff; text-transform: uppercase"
          >
            <i class="material-icons crumb" style="margin-right: 2px"
              >category</i
            >
            Marketing Materials > All Categories
            {{
              fileType ==
              "PDF}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)"
                ? " > Documents"
                : ""
            }}
            {{ fileType == "MPEG4" ? " > Videos" : "" }}
            {{ fileType == "JPEG" ? " > Images" : "" }}
            {{ catType != "" ? " > " + catClean(catType) : "" }}
          </h6>
        </div>
        <div class="right-side">
          <button
            v-if="searchValue.length"
            @click.prevent="searchQuery"
            class="search"
          >
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>

          <button v-else class="search" disabled>
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>
          <button
            v-if="
              searchValue.length ||
              this.search != 'Products/' ||
              this.catType != '' ||
              this.fileType !=
                'PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Zip Archive}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)'
            "
            @click="clearSearch"
            class="clear"
          >
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
          <button v-else disabled class="clear">
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MediaHero",
  props: {
    placeholder: String,
    searchValue: String,
    search: String,
    fileType: String,
    catType: String,
    searchQuery: {
      type: Function,
    },
    clearSearch: {
      type: Function,
    },
  },
  methods: {
    updateValue(newValue) {
      // Emit the input event with the new value
      this.$emit("update:searchValue", newValue);
    },
    catClean: function (cat) {
      console.log(cat);
      //remove everything before the last /
      let a = cat.replace(/%20/g, " ").split("/").pop();
      return a;
    },
  },
};
</script>

<style scoped></style>
