<template>
  <div>
      <!--
    <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div id="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div id="v-step-2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
-->
    <v-tour name="ListingsTour" :steps="steps" :callbacks="myCallbacks" style="position: relative;" v-bind:style="{ zIndex: tourZIndex }">


      <template v-slot="{ tour }">


      <v-step
      v-if="tour && tour.steps && tour.steps[tour.currentStep]"
      :key="tour.currentStep"
      :step="tour.steps[tour.currentStep]"
      :previous-step="tour.previousStep"
      :next-step="tour.nextStep"
      :stop="tour.stop"
      :skip="tour.skip"
      :is-first="tour.isFirst"
      :is-last="tour.isLast"
      :labels="tour.labels"
      >
        <template v-if="tour.currentStep === 4">
          <div>
            <button @click="tour.skip" class="v-step__button v-step__button-previous">Skip tour</button>
            <button @click="tour.previousStep" class="v-step__button v-step__button-previous">Previous step</button>
            <button @click="tour.stop" class="v-step__button v-step__button-skip">Got it!</button>
          </div>
        </template>
      </v-step>

  </template>
    </v-tour>
  </div>
</template>

<script>
  export default {
    name: 'listings-tour',
    data () {
      return {
        tourZIndex: 1,
        steps: [
          {
            target: '#listings-step-1', 
            header: {
              title: 'Search for orders',
            },
            content: `<p style="font-weight: 300;">This area will allow you to search listings by order number, PO number, or order name.</p>`,
            params: {
              placement: 'bottom',
              enableScrolling: false
            },
          },
          {
            target: '#listings-step-2',
            header: {
              title: 'Search orders by area contractor',
            },
            content: `
            <p style="font-weight: 300;">This area will allow you to search orders by a specific area contractor.</p>
            `,
             params: {
              placement: 'right-end', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#listings-step-3',
            header: {
              title: 'Search orders by date',
            },
            content: '<p style="font-weight: 300;">This will enable you to choose a date to search orders from, or specify a date to search until.</p>',
            params: {
              placement: 'left-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#listings-step-4',
            header: {
              title: 'Explore previous orders',
            },
            content: '<p style="font-weight: 300;">Quickly view pages of previous orders. if you need an order that is older than what is available here, then use the search functionality above!</p>',
            params: {
              placement: 'left-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
        ],
        myCallbacks: {
				  onSkip: this.customSkipStepCallback
			  },
      }
    },
    methods: {
      customSkipStepCallback: function () {
			  // console.log('Hello');
        this.$store.commit("hideTour");
        this.$toast.success("The help tour has been disabled.", { hideProgressBar: false, icon: false });		  },
    },
    mounted: function () {
      this.$tours['ListingsTour'].start();
      
      // console.log(this.steps);
      // this.ListingsTour.start()
    }

  }
</script>
<style>
.v-step {
    /*background: #F16231!important;*/
    background: #50596c;
    background: #5f6d7b!important;
    color: #fff;
    max-width: 320px;
    border-radius: 3px;
    -webkit-filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    padding: 1rem;
    text-align: center;
    z-index: 1;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.18);
}
.v-step__header {
    margin: -1rem -1rem .5rem;
    padding: .5rem;
    background-color: #454d5d;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

</style>
