<template>
  <!--
    <header style="position: absolute; top: 0; left: 0;">Login Layout</header>
  -->
    <main>
      <router-view />
    </main>
</template>

<script>
export default {
    name: "LoginLayout",
}
</script>
