<template>
    <div class="Install bs-shadow" style="margin-bottom: 20px;">
      <div class="top-wrap">
        <div class="dflex mb10">
          <avatar
            v-once
            class="mr5"
            color="#fff"
            v-bind:username="Install.FirstName + ' ' + Install.LastName"
            style="width: 40px; height: 40px; font-size: 16px;"
            v-bind:backgroundColor="
              colorGetter[Math.floor(Math.random() * colorGetter.length)]
            "
          ></avatar>
          <div class="dflex vcenter">
            <div>
              <h6 class="mb0">
                <b>
                  {{ Install.FirstName + " " + Install.LastName }}
                </b>
              </h6>
              <p class="lh1 mb0">
                <small>{{ Install.Name }}</small>
              </p>
            </div>
          </div>
        </div>
     
        <div class="mlauto dflex vcenter" style="font-size: 14px;">
          <i class="material-icons mr2 fs20">schedule</i>
          <p class="lh1 mb0">
            {{ $moment(Install.AddedDate).fromNow() }}
          </p>
        </div>
  
      </div>
  
      <div
        class="thumb"
        :style="{
          backgroundImage: `url( ${'https://spacenet3api.spacesaver.com/images/' +
            Install.ID +
            '/' +
            Install.Thumbnail})`,
        }"
        style="position:relative; display: block;"
      >
        <span v-if="Install.ETO == 1" class="eto-tag">ETO</span>
      </div>
      <div
        class="projcard-tagbox"
        style="margin-bottom: 10px; margin-top: 22px; display: flex;"
      >
        <span class="projcard-tag">{{ Install.Market }}</span>
        <p class="mb0 lh1 mlauto">
          <small
            >{{ Install.PictureCount }} Image{{
              Install.PictureCount > 1 ? "s" : ""
            }}</small
          >
        </p>
      </div>
  
      <h5 class="dflex mb5 single-line">
        <a
          :href="/eto-and-inspiring-installs/ + Install.ID"
          style="color: #429da8;"
          >{{ truncateTitle(Install.Title) }}</a
        >
      </h5>
  
      <div
        class="small-lh cool-desc double-line"
        v-html="$options.filters.truncate(Install.Description)"
      ></div>
  
      <div class="Install-bottom dflex mt20" style="align-items: center;">
        <Likes
          v-once
          :LikeCount="
            Install.LikedUsers && Install.LikedUsers.length
              ? Install.LikedUsers.length
              : 0
          "
          :isLiked="Install.iLiked == 0 ? false : true"
          :LikedUsers="Install.LikedUsers"
          :PostID="Install.ID"
        />
  
        <router-link
          :to="/eto-and-inspiring-installs/ + Install.ID"
          class="mb0 lh1 mlauto list-btn"
          style="font-size: 12px;"
          ><span v-if="Install.MessageCount == 1"
            >{{ Install.MessageCount }} Comment</span
          ><span v-else-if="Install.MessageCount > 1"
            >{{ Install.MessageCount }} Comments</span
          ><span v-else>View Post</span></router-link
        >
      </div>
    </div>
  </template>
  
  <script>
import Avatar from '../Atoms/Avatar.vue'
  import Likes from "./Likes.vue";
  export default {
    name: "InstallCard",
    components: {
      Avatar,
      Likes,
    },
    data() {
      return {};
    },
    props: {
      Install: {
        type: Object, // Specify the type of the prop
        required: true, // Optional: specify if the prop is required
      },
    },
    methods: {
      truncateTitle: function(value) {
        if (value.length > 50) {
          value = value.substring(0, 50) + "...";
        }
        return value;
      },
    },
    filters: {
      truncate: function(value) {
        if (value.length > 64) {
          value = value.substring(0, 40) + "...";
        }
        return value;
      },
      truncateTitle: function(value) {
        if (value.length > 50) {
          value = value.substring(0, 50) + "...";
        }
        return value;
      },
    },
    computed: {
      colorGetter() {
        return this.$store.state.colors;
      },
    }
  };
  </script>
  <style scoped>
  .badge-light {
    color: #ffffff !important;
    background-color: #575757 !important;
  }
  
  .projcard-tagbox {
    font-size: 14px;
    cursor: default;
    user-select: none;
    pointer-events: none;
    margin-bottom: 15px;
  }
  .projcard-tag {
    display: inline-block;
    background: #e6edf2;
    color: #777;
    border-radius: 3px 0 0 3px;
    line-height: 26px;
    padding: 0 10px 0 13px;
    position: relative;
    margin-right: 20px;
    cursor: default;
    user-select: none;
    transition: color 0.2s;
  }
  /*
  .projcard-tag::before {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    height: 6px;
    left: 10px;
    width: 6px;
    top: 10px;
  }
  */
  .projcard-tag::after {
    content: "";
    position: absolute;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #e6edf2;
    border-top: 13px solid transparent;
    right: -10px;
    top: 0;
  }
  .not-liked:hover {
    fill: red; /* Change this to the desired color */
  }
  
  .single-line {
    /* Limit the text to one line */
    overflow: hidden;
    white-space: nowrap;
  
    /* Clamp the text size */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  
    /* Add ellipsis to indicate text overflow */
    text-overflow: ellipsis;
  }
  
  .eto-tag {
    position: absolute;
    top: 0px;
    right: 0px;
    color: #fff;
    background: #5f6e7b;
    padding: 10px 20px;
    line-height: 1;
  }
  .list-btn {
    font-size: 13px !important;
    padding: 5px 7px;
  }
  .cool-install-thumb {
    max-width: 300px;
    height: auto;
  }
  
  .thumb {
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 15px;
  }
  .cool-desc {
    margin-top: 5px;
    min-height: 72px;
  }
  .coolInstall {
    background-color: #fff;
    line-height: 44px;
    padding: 15px;
    margin-bottom: 5px;
    border-top: 0;
    height: 100%;
    border-radius: 4px;
  }
  .top-wrap {
    display: flex;
    align-items: flex-start;
  }
  .Install{
      background: #fff;
      padding: 20px;
  }
  </style>
  