<template>
  <div v-html="compiledMarkdown" class="md" />
</template>

<script>

import { marked } from 'marked';
export default {
  name: "MarkdownDisplay",
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },
  computed: {
    compiledMarkdown() {
      return marked(this.markdown);
    },
  },
};
</script>

<style>
.md {
  font-weight: 300;
}
h5 {
  margin-top: 10px !important;
}
.md hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
}
.md summary {
  margin-bottom: 10px;
}
.md p {
  line-height: 1.2;
}
.md a {
  color: #00b4b4;
}
.md h5 {
  background: #f5f5f5;
  padding: 10px;
  line-height: 1;
  display: flex;
  align-items: center;
}
.md h5 i {
  line-height: 1;
  font-size: 16px;
  margin-right: 10px;
}
.md details {
  padding: 10px;
}
.md h6 {
  font-size: 1.15rem;
  margin-bottom: 5px;
  margin-top: 20px;
  font-weight: 400;
}
.md .fa {
  margin-right: 5px;
}
</style>