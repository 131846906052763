<template>
  <div class="page-listings">
  
    <itproj-tour
      v-if="$store.state.tour & !loading && filteredProjects.length"
    />
 
    <section
      v-if="loading == true"
      class="pt-5 pb-3 bg9 bs-shadow mobile-top"
      style="min-height: 262px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">
          Loading Interterritorial Projects....
        </h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section v-else class="pt-5 pb-3 bg9 bs-shadow mobile-top">
      <div class="container">


        <div class="input-wrap">
        <input
          id="itproj-step-1"
          class="top-search mt0"
          type="text"
          placeholder="Search Interterritorial Projects by job name or ID..."
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
          style="padding: 15px;"
        />
        <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>

      </div>




        <div class="search-btns">
          <div class="left-side">
            <div id="itproj-step-2" class="ac-select">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option :value="null" selected class="all-contractors">
                  Area Contractor
                </option>
                <option v-for="ac in acs" :key="ac.acid" :value="ac.acid">
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>
            <!--
            <div v-if="!spacesaverEmployee" class="dates" id="dates">
              <button
                v-if="searchValue || acFilter"
                disabled
                class="clear"
                style="margin: 0 5px 10px 0"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} In My Territory
              </button>

              <button v-else @click="inTerritoryQuery" class="clear" style="margin: 0 5px 10px 0">
                <span class="material-icons">find_replace</span>
                {{ " " }} In My Territory
              </button>

              <button
                v-if="searchValue || acFilter"
                disabled
                class="clear"
                style="margin: 0 5px 10px 0"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} Out Of Territory
              </button>
              <button v-else @click="outTerritoryQuery" class="clear" style="margin: 0 5px 10px 0">
                <span class="material-icons">find_replace</span>
                {{ " " }} Out Of Territory
              </button>
            </div>
            -->
            <button @click="addProject" id="itproj-step-3">
              <span class="material-icons">playlist_add</span>
              {{ " " }} Register a Project
            </button>
          </div>

          <div class="right-side">
            <button
              v-if="(searchValue || acFilter)"
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <!--
            <button
              v-if="searchValue || acFilter || searchStarted"
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            -->
          </div>
        </div>

        <!--
        <div class="search-btns">
          <div class="left-side">
            <div class="dates" id="dates">
              <button @click="clearSearch" class="clear" style="margin: 0 5px 10px 0">
                <span class="material-icons">find_replace</span>
                {{ " " }} In My Territory
              </button>
              <button class="clear" style="margin: 0 5px 10px 0">
                <span class="material-icons">find_replace</span>
                {{ " " }} Out Of Territory
              </button>
            </div>
          </div>

          <button @click="addProject" class="clear">
            <span class="material-icons">playlist_add</span>
            {{ " " }} Register a Project
          </button>
        </div>
        -->
      </div>
    </section>

    <div
      :class="loading == false && !filteredProjects.length ? 'message-bg' : ''"
    >
      <div class="container">
        <div class="row">
          <div
            v-if="loading == true"
            class="col-xs-12 col-sm-12 col-12 col-lg-12"
          >
            <div class="flex-box-wrap">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />
                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !filteredProjects.length ||  typeof filteredProjects == 'undefined'"
            class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
          >
            <div>
              <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
                It looks like there are no IT Projects to show...
              </h2>
              <img src="@/assets/img/noData.svg" style="width: 100%" />
            </div>
          </div>

          <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
            <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredProjects" :setPage="setPage"/>
            <div>
              <div class="listing">
                <!--
          <div v-for="project in projects" :key="project.Tid">
              -->
              <div class="d-flex" style="margin-bottom: 20px">
                <h2 class data-config-id="header">Interterritorial Projects</h2>
                <download-csv
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                  :data="filteredProjects"
                  :name="'interterritorial-projects' + '.csv'"
                  >Download CSV</download-csv
                >
                </div>
                <div class="rfr table-responsive">
                  <table class="table-striped table-bordered">
                    <thead>
                      <tr>
                        <th
                          @click="sortCol('projName')"
                          scope="col"
                          class="thirty table-link"
                          :class="
                            columnSort == 'projName' ? 'column-active' : ''
                          "
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Job Name </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'projName'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('id')"
                          scope="col"
                          class="ten table-link"
                          :class="columnSort == 'id' ? 'column-active' : ''"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>ID </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'id' ? 'carrot-icon-active' : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('submitDate')"
                          scope="col"
                          class="twenty table-link"
                          :class="
                            columnSort == 'submitDate' ? 'column-active' : ''
                          "
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Submitted </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'submitDate'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('installationAC')"
                          scope="col"
                          class="twenty table-link"
                          :class="
                            columnSort == 'installationAC'
                              ? 'column-active'
                              : ''
                          "
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Installation AC </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'installationAC'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('submitterAC')"
                          scope="col"
                          class="twenty table-link"
                          :class="
                            columnSort == 'submitterAC' ? 'column-active' : ''
                          "
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Submitter AC </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'submitterAC'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-bind:key="project.id"
                        v-for="(project, index) in filteredProjects.slice(
                          pageStart,
                          pageStart + countOfPage
                        )"
                      >
                        <td>{{ project.projName }}</td>
                        <td>
                          <p class="mb5">
                            <router-link
                              :to="/interterritorial/ + project.id"
                              :id="index === 0 ? 'itproj-step-5' : ''"
                              >{{ project.id }}</router-link
                            >
                          </p>
                        </td>
                        <td>
                          {{ $moment(project.submitDate).format("MM-DD-YYYY") }}
                        </td>
                        <td>{{ project.installationAC }}</td>
                        <td>{{ project.submitterAC }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- card -->
              </div>
            </div>

            <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredProjects" :setPage="setPage"/>

          </div>
        </div>
      </div>
      <!-- container -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
// import Datepicker from "vuejs-datepicker";
import itprojTour from "../components/Tours/itprojTour.vue";
import JsonCSV from "vue-json-csv";
import ListingsPagination from '@/components/Pagination/ListingsPagination.vue';

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "Interterritorial",
  components: {
    AtomSpinner,
    ListingsPagination,
    // Avatar,
    // Datepicker,
    itprojTour,
    JsonCSV,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      searchStarted: false,
      acs: [],
      projects: [],
      companyIDs: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noRGAFound: false,
      colSort: false,
      columnSort: "",
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = "";
      this.acFilter = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      this.acListSync();
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/itproj/`, auth)
        .then((response) => {
          // console.log(response);
          if(response.data){
            this.projects = response.data.projList;
            this.loading = false;
          } else {
            this.loading = false;
          }
                   
        },(error) => {
            // console.log(error);
            localStorage.removeItem("SSCUSER");
            this.$router.push("/");
        });
    },
    acListSync: function () {
      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;

            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;

            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
      }
    },
    addProject: function () {
      window.open("/interterritorial/add", "_self");
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },
    backLink: function () {
      router.back();
    },

    searchReset: function () {
      this.selectedCat = "Show All";
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },
    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.projects;
      }

      this.search = "";
      this.loading = true;
      this.searching = true;
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        if(response.data){
          //console.log(response);
          this.projects = response.data.projList;
          this.searchValue = "";
          this.acFilter = null;
          this.loading = false;
        } else {
          //console.log(response);
          this.projects = [];
          this.loading = false;
        }
        
      });
    },
    clearSearch() {
      if (this.searchStarted == true) {
        this.projects = this.storedListings;
        this.searchStarted = false;
        this.searchRender = !this.searchRender;
      }

      //this.listings = this.storedListings;
      this.acSwitch = "";
      this.searchValue = "";
      this.acFilter = null;
      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      //console.log(COLUMN)
      if (this.colSort) {
        this.filteredProjects.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredProjects.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    showButton() {
      if (this.searchValue.trim().length > 0 || this.acFilter || this.searchStarted) {
        // console.log('true')
        return true;
      } else {
        // console.log('false')
        return false;
      }
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredProjects: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "" && this.acSwitch === "") {
        return this.projects.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All" && this.acSwitch === "") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.projects.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.projects;
      }
    },

    foundNoRGA: function () {
      if (this.search.length > 1 && this.filteredProjects.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.projects.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.projects
      ) {
        //console.log("fired off");
        this.projects = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has search, no ac
      if (this.searchValue.length && !this.acFilter) {
        return `${this.ordermanageapi}/itproj?search=${this.searchValue.trim()}`;
      } else if (
        //has search and ac
        this.searchValue.length &&
        this.acFilter
      ) {
        return `${this.ordermanageapi}/itproj?search=${this.acFilter}`;
      } else if (
        //has only AC
        !this.searchValue.length &&
        this.acFilter
      ) {
        return `${this.ordermanageapi}/itproj?ac=${this.acFilter}`;
      } else {
        return `${this.ordermanageapi}/itproj`;
      }
    },
    userCompany: function () {
      return this.$store.state.user.ACId;
    },
    spacesaverEmployee: function () {
      this.companyIDs = this.userCompany;
      if (this.companyIDs.includes(0)) {
        return true;
      } else {
        return false;
      }
      //if(this.userCompany).includes('0'));
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.project {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.project-bottom {
  align-items: flex-end;
}
.project-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
  padding: 23px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding: 23px 15px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.right-side {
  text-align: right;
  flex: 1;
}
@media all and (max-width: 991px) {
  table {
    width: 900px !important;
    overflow: scroll;
  }
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}
</style>
