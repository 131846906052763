




import axios from 'axios';

const auth = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    },
};

export const fetchCollaborateHomeData = async (store) => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_APP_SERVER_URL}/api/v1/questions-home`, auth);
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};



/*
async collaborateHomeSync() {
    // console.log(auth);
    
    if (!this.$store.state.collaborate.questionsHome.length) {
      this.collabLoading = true;
       return axios
        .get(`${import.meta.env.VITE_APP_SERVER_URL}/api/v1/questions-home`, auth)
        .then((response) => {
          console.log(response);
          this.questions = response.data;
          this.collabLoading = false;
          this.$store.commit("UPDATE_COLLABORATE_HOME", this.questions);
        });
      //this.collabLoading = false;
      //this.$store.commit("UPDATE_COLLABORATE_HOME", this.questions);
    } else {
      this.questions = this.$store.state.collaborate.questionsHome.slice(0, 10);
      this.collabLoading = false;
    }
  },

*/