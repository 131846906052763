<template>
  <div>
    <!--
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="rgaId"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">

        <section class="pdf-item" style="padding: 30px">
          <div
            class="jumbotron"
            style="margin: 0 auto; background: #efefef; padding: 20px"
          >
            <div class="row">
              <div class="col col-10">
                <h3>RGA # {{ " " + rgaId }}</h3>
                <h3 class="my-0">
                  Order #
                  {{ " " + rga.rga[0].orderNo }}
                </h3>
              </div>
              <div class="col col-2" style="text-align: left">
                <div class="status ml" style="display: flex">
                  <p
                    style="margin-left: auto"
                    class="badge badge-success"
                    v-if="rga != null"
                  >
                    {{ rga.rga[0].status }}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col col-4">
                <div>
                  <h6 class="mt20">
                    <i class="fas fa-building"></i> Area Contractor
                  </h6>
                  <p class="text-muted mb20 lh1">
                    <small v-if="rga != null" class="text-muted">
                      {{ rga.rga[0].acName }}
                    </small>
                  </p>
                </div>
                <div>
                  <h6 class><i class="fas fa-user"></i> Representatives</h6>
                  <p class="text-muted mb20 lh1">
                    <small>
                      {{ rga.orderDetails[0].salesman }}
                    </small>
                  </p>
                </div>
                <div>
                  <h6><i class="fas fa-file-alt"></i> Requestor Name</h6>
                  <p class="text-muted mb20">
                    <small v-if="rga != null" class="up lh1">
                      {{ rga.rga[0].requestedByUser }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="col col-4">
                <h6 class="mt20">
                  <i class="fas fa-file-alt"></i> Requestor Email
                </h6>
                <p class="text-muted mb20">
                  <small v-if="rga != null" class="up lh1">
                    {{ rga.rga[0].requestorEmail }}
                  </small>
                </p>

                <h6><i class="fas fa-file-alt"></i> Requestor Phone</h6>
                <p class="text-muted mb20">
                  <small v-if="rga != null" class="up lh1">
                    {{ rga.rga[0].requestorPhone }}
                  </small>
                </p>
                <div>
                  <h6><i class="fas fa-file-alt"></i> Customer PO Number</h6>
                  <p class="text-muted mb20">
                    <small v-if="rga != null" class="up lh1">
                      {{ rga.orderDetails[0].customerPoNo }}
                    </small>
                  </p>
                </div>
              </div>
              <div class="col col-4">
                <div>
                  <h6 class="mt20">
                    <i class="fas fa-table"></i> Date Requested
                  </h6>

                  <p class="text-muted mb20">
                    <small v-if="rga != null" class="up lh1">
                      {{ moment(rga.rga[0].submitDate).format("MM-DD-YYYY") }}
                    </small>
                  </p>
                </div>
                <div class>
                  <h6 class="my-0">
                    <i class="fas fa-file-download"></i> INVOICE(S)
                  </h6>

                  <div v-if="rga != null">
                    <div v-if="rga.invoice.length">
                      <small
                        v-for="invoice in rga.invoice"
                        :key="invoice.pdfid"
                        class="text-muted"
                      >
                        <p class="mb0">
                          {{
                            invoice.pdfid +
                            " - " +
                            moment(invoice.pdfdate).format("MM-DD-YYYY")
                          }}
                        </p>
                      </small>
                    </div>
                  </div>
                  <div v-else>
                    <small class="text-muted">
                      <p>Not Available</p>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div style="margin-left: auto"></div>
            </div>
          </div>
        </section>

        <section class="pdf-item" style="padding: 30px 30px">
          <table
            class="table table-striped table-bordered lines-table mb0"
            style="margin-bottom: 20px !important"
          >
            <thead>
              <tr>
                <th scope="col">Reason Employee</th>
                <th scope="col">Req Part#</th>
                <th scope="col">Req Color</th>
                <th scope="col">Req Qty</th>
         
              </tr>
            </thead>
            <tbody v-if="rga.rga[0].returnParts.length">
              <tr
                v-for="part in rga.rga[0].returnParts"
                :key="part.requestedPartNum"
              >
                <th scope="row">
                  <small class="text-muted">{{ part.reason }}</small>
                </th>
                <td>
                  <small class="text-muted">{{ part.requestedPartNum }}</small>
                </td>
                <td>
                  <small class="text-muted">{{ part.requestedColor }}</small>
                </td>
                <td>
                  <small class="text-muted">{{ part.requestedQuantity }}</small>
                </td>
      
              </tr>
            </tbody>
          </table>
        </section>
   

        <section class="pdf-item" style="padding: 30px 30px">
          <div v-if="rga.comment.length">
            <div
              v-for="(comment, index) in rga.comment"
              v-bind:key="index"
              class="comments"
            >
              <div>
                <p class="d-flex mb0">
                  <small class="text-muted">
                    <i class="fas fa-comment"></i>
                    {{ " " }}{{ comment.senderUser }}
                  </small>
                  <small class="ml text-muted" style="display: flex">
                    <i class="material-icons mr2 fs20">schedule</i>
                    {{ " "
                    }}{{
                      moment(comment.dateSent).subtract(1, "minutes").fromNow()
                    }}
                  </small>
           
                </p>
                <h6 class="my-0" style="max-width: 80%">
                  {{ comment.message }}
                </h6>
              </div>
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  -->

  
    <rgadetails-tour v-if="$store.state.tour && !loading" />
  
    <transition name="menu-popover" v-if="!loading && rga != null">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
        />
      </div>
    </transition>

    <section
      v-if="loading && rga == null"
      data-v-5a90ec03
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 178px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Loading RGA....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section
      v-else
      data-v-5a90ec03
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 178px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 v-if="rga != null && rgaId" class="text-white mb0 lh1">
          {{ "RGA #" + rgaId }}
        </h1>
        <h2 class="text-white lh1"></h2>
      </div>
    </section>

    <div v-if="loading" class="row">
      <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#575757'"
            />
            <p>Loading......</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container mt30 mb30">
      <!-- col -->
      <div class="row">
        <div class="col-lg-8">
          <div
            id="rgadetails-step-1"
            class="card-back mb30 rounded bs-shadow p-3"
          >
            <div class="question-header d-flex align-items-center mb5 rounded">
              <div class="d-flex number-showing">
                <BackButton />
                <!--
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mlauto list-btn dib d-flex center"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
              -->
              </div>

              <div class="pag-wrapper mlauto">
                <h2 data-v-7fd5d72e="">RGA</h2>
                <!--
                <h2 v-if="rga != null">
                  {{ rga.rga[0].disposition }}
                </h2>
                -->

                <!--
                <button
                  class="
                    mb0
                    lh1
                    mlauto
                    list-btn
                    dib
                    d-flex
                    center
                    number-showing
                  "
                  @click="generateReport"
                >
                  <i class="material-icons" style="margin-right: 5px">
                    print
                  </i>
                  {{ " " }}PRINT
                </button>
              -->
              </div>
            </div>
            <hr />
            <div class="info-top">
              <div class="top-row d-flex">
                <div v-if="rga != null">
                  <h6 style="display: flex; align-items: center;"><span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">group</span> Representatives</h6>
                  <p class="text-muted mb5 lh1">
                    <small>
                      {{ rga.orderDetails[0].salesman }}
                    </small>
                  </p>

                  <!--
                  <p v-for="rep in rga.itReps" :key="rep.repId" class="text-muted mb5 lh1">
                    <small class="up">
                      {{
                      rep.lastname + ", " + rep.firstname
                      }}
                    </small>
                  </p>
                  -->
                </div>
                <div class="status ml">
                  <p class="badge badge-success" v-if="rga != null">
                    {{ rga.rga[0].status }}
                  </p>
                </div>
              </div>

              <h6 class="mt20" style="display: flex; align-items: center;">
                <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">corporate_fare</span> Area Contractors
              </h6>
              <p class="text-muted mb5 lh1">
                <small v-if="rga != null" class="text-muted">
                  {{ rga.rga[0].acName }}
                </small>
              </p>
              <!--
              <div v-if="rga != null">
                <p v-for="ac in rga.itac" :key="ac.acid" class="text-muted mb5 lh1">
                  <small class="up lh1">{{ ac.acName }}</small>
                </p>
              </div>
              -->
            </div>
            <hr />
            <h6 class="my-0" style="display: flex; align-items: center;"><span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Requestor Name</h6>
            <p class="text-muted mb20">
              <small v-if="rga != null" class="up lh1">
                {{ rga.rga[0].requestedByUser }}
              </small>
            </p>

            <h6 class="my-0" style="display: flex; align-items: center;"><span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Requestor Email
            </h6>
            <p class="text-muted mb20">
              <small v-if="rga != null" class="up lh1">
                {{ rga.rga[0].requestorEmail }}
              </small>
            </p>

            <h6 class="my-0" style="display: flex; align-items: center;"><span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Requestor Phone
            </h6>
            <p class="text-muted mb20">
              <small v-if="rga != null" class="up lh1">
                {{ rga.rga[0].requestorPhone }}
              </small>
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            id="rgadetails-step-2"
            class="card-back mb5 rounded bs-shadow p-3"
          >
            <ul class="list-group mb-3">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div v-if="rga != null">
                  <h6 class="my-0" style="display: flex; align-items: center;">
                    <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Order Number
                  </h6>
                  <router-link
                    :to="/order-details/ + rga.rga[0].orderNo"
                    class="pdfLink mb0"
                    style="text-decoration: none"
                    ><small>{{ rga.rga[0].orderNo }}</small></router-link
                  >
                  <!--
                  <p class="text-muted mb0">
                    <small v-if="rga != null" class="up lh1">
                      {{ rga.rga[0].orderNo }}
                    </small>
                  </p>
                  -->
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div>
                  <h6 class="my-0" style="display: flex; align-items: center;">
                    <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">text_snippet</span> Customer PO Number
                  </h6>
                  <p class="text-muted mb0">
                    <small v-if="rga != null" class="up lh1">
                      {{ rga.orderDetails[0].customerPoNo }}
                    </small>
                  </p>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div>
                  <h6 class="my-0" style="display: flex; align-items: center;">
                    <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">calendar_month</span> Date Requested
                  </h6>

                  <p class="text-muted mb0">
                    <small v-if="rga != null" class="up lh1">
                      {{ $moment(rga.rga[0].submitDate).format("MM-DD-YYYY") }}
                    </small>
                  </p>
                </div>
              </li>
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  lh-condensed
                "
              >
                <div class>
                  <h6 class="my-0" style="display: flex; align-items: center;">
                    <span data-v-75905a36="" class="material-icons" style="font-size: 18px; margin-right: 5px;">download</span> INVOICE(S)
                  </h6>

                  <div v-if="rga != null">
                    <div v-if="rga.invoice.length">
                      <small
                        v-for="invoice in rga.invoice"
                        :key="invoice.pdfid"
                        class="text-muted"
                      >
                        <p @click="showPDF(invoice.pdfblog)" class="pdfLink">
                          {{
                            invoice.pdfid +
                            " - " +
                            $moment(invoice.pdfdate).format("MM-DD-YYYY")
                          }}
                        </p>
                      </small>
                    </div>
                  </div>
                  <div v-else>
                    <small class="text-muted">
                      <p>Not Available</p>
                    </small>
                  </div>
                </div>
                <!--
                <span class="text-success">-$5</span>
                -->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-lg-12">
          <div
            id="rgadetails-step-3"
            class="card-back mb30 rounded bs-shadow p-3"
          >
            <div class="tab-container">
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    class="nav-item nav-link active"
                    id="nav-comments-tab"
                    data-toggle="tab"
                    href="#nav-comments"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="false"
                    >Comments</a
                  >

                  <a
                    class="nav-item nav-link"
                    id="nav-comments-tab"
                    data-toggle="tab"
                    href="#nav-parts"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="false"
                    >Parts</a
                  >
                </div>
              </nav>

              <div class="tab-content" id="nav-tabContent">
                <!-- comment content -->

                <div
                  v-if="rga != null"
                  class="tab-pane fade show active"
                  id="nav-comments"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div v-if="rga.comment.length">
                    <div
                      v-for="(comment, index) in rga.comment"
                      v-bind:key="index"
                      class="comments"
                    >
                      <div>
                        <p class="d-flex mb0">
                          <small class="text-muted">
                            <i class="fas fa-comment"></i>
                            {{ " " }}{{ comment.senderUser }}
                          </small>
                          <small class="ml text-muted" style="display: flex">
                            <i class="material-icons mr2 fs20">schedule</i>
                            {{ " "
                            }}{{
                              $moment(comment.dateSent)
                                .subtract(1, "minutes")
                                .fromNow()
                            }}
                          </small>
                          <!--
                          <small class="ml text-muted">
                            <i class="fas fa-calendar-check"></i>
                            {{ " "
                            }}{{
                              moment(comment.dateSent).format("MM-DD-YYYY")
                            }}
                          </small>
                          -->
                        </p>
                        <h6 class="my-0" style="max-width: 80%">
                          {{ comment.message }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <h6 v-else class="my-0">No Comments</h6>

                  <br />
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Message</label>
                    <textarea
                      v-model="commentString"
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>

                  <div class="flex-bottom">
                    <button
                      v-if="!commentString.length"
                      disabled
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                    >
                      Submit
                    </button>
                    <button
                      v-else
                      @click.prevent="insertComment()"
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div
                  v-if="rga != null"
                  class="tab-pane fade show"
                  id="nav-parts"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <table
                    v-if="employee"
                    class="table table-striped table-bordered lines-table mb0"
                    style="margin-bottom: 20px !important"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Reason Employee</th>
                        <th scope="col">Req Part#</th>
                        <th scope="col">Req Color</th>
                        <th scope="col">Req Qty</th>
                        <!--
                        <th scope="col">Aprvd part#</th>
                        <th scope="col">Aprvd Color</th>
                        <th scope="col">Aprvd Qty</th>
                        <th scope="col">Restocking</th>
                        <th scope="col">Wrnty</th>
                        -->
                      </tr>
                    </thead>
                    <tbody v-if="rga.rga[0].returnParts.length">
                      <tr
                        v-for="part in rga.rga[0].returnParts"
                        :key="part.requestedPartNum"
                      >
                        <td scope="row">
                          <small class="text-muted">{{ part.reason }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedPartNum
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedColor
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedQuantity
                          }}</small>
                        </td>
                        <!--
                        <td>
                          <input v-model="part.partId" type="text" />
                        </td>
                        <td>
                          <input v-model="part.requestedColor" type="text" />
                        </td>
                        <td>
                          <input v-model="part.approvedQuantity" type="text" />
                        </td>

                        <td>
                          <input v-model="part.restockingFee" type="text" />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            v-model="part.warranty"
                            true-value="true"
                            false-value="false"
                          />
                        </td>
                        -->
                      </tr>
                    </tbody>
                  </table>
                  <table
                    v-else
                    class="table table-striped table-bordered lines-table mb0"
                    style="margin-bottom: 20px !important"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Reason</th>
                        <th scope="col">Req Part#</th>
                        <th scope="col">Req Color</th>
                        <th scope="col">Req Qty</th>
                        <!--
                        <th scope="col">Aprvd part#</th>
                        <th scope="col">Aprvd Color</th>
                        <th scope="col">Aprvd Qty</th>
                        <th scope="col">Restocking</th>
                        <th scope="col">Wrnty</th>
                        -->
                      </tr>
                    </thead>
                    <tbody v-if="rga.rga[0].returnParts.length">
                      <tr
                        v-for="part in rga.rga[0].returnParts"
                        :key="part.requestedPartNum"
                      >
                        <th scope="row">
                          <small class="text-muted">{{ part.reason }}</small>
                        </th>
                        <td>
                          <small class="text-muted">{{
                            part.requestedPartNum
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedColor
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedQuantity
                          }}</small>
                        </td>
                        <!--
                        <td>
                          <small class="text-muted">{{ part.partId }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.requestedColor
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{
                            part.approvedQuantity
                          }}</small>
                        </td>

                        <td>
                          <small class="text-muted">{{
                            part.restockingFee
                          }}</small>
                        </td>
                        <td>
                          <small class="text-muted">{{ part.warranty }}</small>
                        </td>
                        -->
                      </tr>
                    </tbody>
                  </table>

                  <div v-if="employee" class="approve-form">
                    <div class="row">
                      <div class="col col-12 col-md-6">
                        <div v-if="rga != null" class="form-group">
                          <label for="exampleFormControlSelect1">Status</label>
                          <select
                            v-model="rga.rga[0].status"
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                    -->
                            <option value="Pending">Pending</option>
                            <option value="Needs Approval">
                              Needs Approval
                            </option>
                            <option value="Approved">Approved</option>
                            <option value="Product Received">
                              Product Received
                            </option>
                            <option value="Pending Inspection">
                              Pending Inspection
                            </option>
                            <option value="Credit Approved">
                              Credit Approved
                            </option>
                            <option value="Credit Issued">
                              Credit Issued
                            </option>
                            <option value="Denied">Denied</option>
                          </select>
                        </div>
                      </div>
                      <div class="col col-12 col-md-6">
                        <!--
                        <div v-if="rga != null" class="form-group">
                          <label for="exampleFormControlSelect1"
                            >Disposition</label
                          >
                          <select
                            v-model="rga.rga[0].disposition"
                            class="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option value="Pending Decision">
                              Pending Decision
                            </option>
                            <option value="Full Credit">Full Credit</option>
                            <option value="Credit Less Restocking Fee">
                              Credit Less Restocking Fee
                            </option>
                            <option value="Credit Less Freight">
                              Credit Less Freight
                            </option>
                            <option value="Credit Less Restocking Fee/Freight">
                              Credit Less Restocking Fee/Freight
                            </option>
                            <option value="No Credit Allowed">
                              No Credit Allowed
                            </option>
                            <option value="Repair-Bill Material & Labor">
                              Repair-Bill Material & Labor
                            </option>
                            <option value="Non-repairable">
                              Non-repairable
                            </option>
                            <option value="Contact Area Contractor">
                              Contact Area Contractor
                            </option>
                            <option value="Scrap">Scrap</option>
                          </select>
                        </div>
                        -->
                      </div>
                    </div>

                    <button
                      @click="updateRGA()"
                      class="
                        mb0
                        lh1
                        mlauto
                        list-btn
                        dib
                        d-flex
                        center
                        router-link-active
                        submit-btn
                      "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
import rgadetailsTour from "../components/Tours/rgadetailsTour.vue";
// import VueHtml2pdf from "vue-html2pdf";
import BackButton from '@/components/Buttons/BackButton.vue';

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "RGADetails",
  components: {
    AtomSpinner,
    // Avatar,
    rgadetailsTour,
    // VueHtml2pdf,
    BackButton
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      server: import.meta.env.VITE_APP_SERVER_URL,
      rgaId: this.$route.params.id,
      userID: this.$store.state.user.UserId,
      rga: null,
      loading: false,
      processing: false,
      preprod: false,
      inprod: false,
      shipped: false,
      open: "",
      pdfPreview: "",
      commentString: "",
      commentString4Email: "",
      rgaStates: [
        {
          id: 1,
          state: "Pending",
        },
        {
          id: 2,
          state: "Needs Approval",
        },
        {
          id: 3,
          state: "Approved",
        },
        {
          id: 4,
          state: "Credit Issued",
        },
        {
          id: 5,
          state: "Denied",
        },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/rga/${this.rgaId}`, auth)
        .then((response) => {
          //console.log(response);
          this.rga = response.data;
          this.loading = false;

          document
            .getElementById("mobileScrollTarget")
            .scrollIntoView({ behavior: "smooth" });
        });
    },
    makeActive(item) {
      //console.log(item);
      if (this.open === item) {
        this.open = "";
      } else {
        this.open = item;
      }
    },
    showPDF(doc) {
      this.pdfPreview = doc;
    },
    closeLightbox() {
      this.pdfPreview = "";
    },

    getDocument(link) {
      //console.log("link: " + link);
      window.open(
        `http://spacenet2.spacesaver.com/ordermanage/${link}`,
        "_blank"
      );
    },
    updateRGA() {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let theDATA = [
        {
          op: "replace",
          path: "/status",
          value: this.rga.rga[0].status,
        },
      ];

      return axios
        .patch(`${this.ordermanageapi}/rga/${this.rgaId}`, theDATA, axiosConfig)
        .then((response) => {
          //console.log(response);
          this.$toast.success("Successfully updated expedited order!");
         
        })
        .catch((err) => {
          this.$toast.error(
            "Sorry, there was an issue updating the expedited order"
          );
        });
    },
    insertComment() {
      this.commentString4Email = this.commentString;
      var thePostDate = new Date();
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let theDATA = {
        parentId: this.rgaId,
        commentType: "RGA",
        senderUserID: this.userID,
        dateSent: thePostDate,
        message: this.commentString,
        subject: `RGA Comment: ${this.rgaId}`,
      };

      let rgaData = {
        rgaNum: this.rgaId,
        name: this.getName,
        emailAddress: this.getEmail,
        comment: this.commentString4Email,
      };

      let customerNotifydata = {
        message: this.commentString4Email,
        sender: this.getName,
        linkID: `rga-details/${this.rgaId}`,
        notify: this.rga.rga[0].requestorEmail,
      };

      return axios
        .post(`${this.ordermanageapi}/comments`, theDATA, axiosConfig)
        .then((response) => {
          //console.log(response);
          this.$toast.success("Successfully added comment!");
          //this.rga.comment.push(theDATA);
          this.commentString = "";
          this.rga.comment = response.data;
        })
        .then(() => {
          //console.log("Send the comment");
          //if there is no comments and not an employee
          if (!this.employee) {
            //console.log("Sending an email");
            return axios
              .post(
                `${this.server}/api/v1/rga-comment-email`,
                rgaData,
                axiosConfig
              )
              .then(() => {
                //console.log(response);
                this.commentString4Email = "";
                //console.log("Successfully created a new email notification");
                this.$toast.success(
                  "Successfully created a new email notification!"
                );
              })
              .catch((err) => {
                //console.log(err);
                this.$toast.error(
                  "Sorry, there was an issue making a new email notification"
                );
              });
          } else {
            //console.log("Send customer email");
            return axios
              .post(
                `${this.server}/api/v1/generic-comment-email`,
                customerNotifydata,
                axiosConfig
              )
              .then(() => {
                //console.log(response);
                this.commentString4Email = "";
                //console.log("Successfully created a new email notification");
                this.$toast.success(
                  "Successfully created a new email notification!"
                );
              })
              .catch((err) => {
                console.log(err);
                this.$toast.error(
                  "Sorry, there was an issue making a new email notification"
                );
              });
          }
        })
        .catch((err) => {
          //console.log(err);
          this.$toast.error("Sorry, there was an issue posting your comment");
        });
    },
    /*
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    */

    /*
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            );
          }
        })
        .save();
    },
    */
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    employee() {
      return this.$store.getters.isSpacesaverEmployee;
    },
    getEmail() {
      return this.$store.state.user.Email;
    },
    getName() {
      return (
        this.$store.state.user.FirstName + " " + this.$store.state.user.LastName
      );
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad().then(function () {});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.card-back {
  background: #fff;
}
.article {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.tab-container {
  background: #f2f2f3;
  padding: 10px;
  border-radius: 4px;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
}
#nav-tabContent {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.d-flex {
  display: flex;
}
.ml {
  margin-left: auto;
}
/*
.comments:nth-child(even) {
  background: #efefef;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
}
*/
.comments {
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}
.comments p {
  margin-bottom: 10px;
}
.comments:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments:last-child p {
  margin-bottom: 0;
}
.tab-container a {
  color: #429da8;
}
.mt30 {
  margin-top: 30px;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.8em;
}
.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
}

.progtrckr[data-progtrckr-steps="2"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="3"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="4"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="5"] li {
  width: 25%;
}

.progtrckr li.progtrckr-done {
  color: #fff;
  border-bottom: 4px solid #fff;
}
.progtrckr li.progtrckr-todo {
  color: #3e4651;
  border-bottom: 4px solid #3e4651;
}

.progtrckr li:after {
  content: "\00a0\00a0";
  margin-left: 28px;
  /* this is the text*/
}
.progtrckr li:before {
  position: relative;
  bottom: -2.5em;
  float: left;
  left: calc(50% - 20px);
  line-height: 1em;
}
.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: #3e4651;
  background-color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  border-radius: 40px;
}
.progtrckr li.progtrckr-todo:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: #3e4651;
  background-color: #3e4651;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}
/* trackker titles */
.tracker-title-wrap {
  margin-top: 15px;
}
.trckr-title {
  padding-left: 0;
}
.trckr-title li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
  width: 25%;
  color: #fff;
}
.trckr-title-done {
  color: #fff !important;
}
.trckr-title-todo {
  color: #3e4651 !important;
}
.badge-success {
  color: #fff;
  background-color: #3e4651;
}
@media all and (max-width: 700px) {
  .trckr-title li {
    font-size: 12px;
  }
}
.up {
  text-transform: uppercase;
}
.lines-table td,
.lines-table th {
  padding: 5px;
}
.small,
small {
  font-size: 90% !important;
}

.table td .small,
small {
  font-size: 90% !important;
}
.bol {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  list-style-type: none;
  /*border: 1px solid #dee2e6;*/
  background: #efefef;
  margin-bottom: 5px;
  transition: background 0.8s linear;
  border-radius: 4px;
  cursor: pointer;
}
.bol:hover {
  background: #e6e5e5;
}
.parent {
  display: flex;
  align-items: center;
}
.parent i {
  transition: color 0.8s linear;
}
.parent:hover i {
  color: #000;
}
.parent:hover {
  color: #000;
}
.parent:active i {
  color: #00b4b4;
}
.parent i {
  /*margin-left: auto;*/
}
.child-list {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /*transition: height 0.8s linear;*/
  height: 100%;
  max-height: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
.child-list li {
  position: relative;
  list-style-type: none;
  margin-left: 0;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}
.child-list li:last-of-type {
  border-bottom: none;
}
.is-closed {
  max-height: 0;
}
.show {
  /*height: 100% !important;*/
  max-height: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
  height: auto;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: height 0.5s;
  height: auto;
}
.height-enter,
.height-leave-to {
  height: 0;
}
.ship-head {
  display: flex;
  width: 100%;
}
.pallet {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-number {
  width: calc(50% + 3px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 700px) {
  .pallet {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ship-number {
    width: calc(60% + 3px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-arrow-circle-down {
  margin-left: auto;
}

.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.info {
  border-top: 1px solid #dedede;
  padding-top: 5px;
  margin-top: 5px;
}
.truck-no {
  margin-left: -5px;
}
.closeX {
  top: 0;
}
.pdfLink {
  color: #429da8;
  cursor: pointer;
  margin-bottom: 0;
}
.pdfLink:hover {
  color: #00b4b4;
}
.pdfLink:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10003 !important;
}
.flex-bottom {
  display: flex;
}
.submit-btn {
  border: none !important;
  padding: 7px 12px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}
.mb30 {
  margin-bottom: 30px;
}

td input {
  width: 100%;
  border: none;
}
input[type="checkbox"] {
  margin-top: -30px;
}

input[type="checkbox"] {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  width: 13px;
  height: 13px;
  margin: 0;
  margin-right: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  cursor: pointer;
  margin-top: 5px;
}

input[type="checkbox"]:after {
  content: "";
  vertical-align: middle;
  text-align: center;
  line-height: 13px;
  position: absolute;
  cursor: pointer;
  height: 13px;
  width: 13px;
  left: 0;
  top: 0;
  font-size: 10px;
  -webkit-box-shadow: inset 0 1px 1px #00b4b4, 0 1px 0 #00b4b4;
  -moz-box-shadow: inset 0 1px 1px #00b4b4, 0 1px 0 #00b4b4;
  box-shadow: inset 0 1px 1px #00b4b4, 0 1px 0 #00b4b4;
  background: #00b4b4;
}

input[type="checkbox"]:hover:after,
input[type="checkbox"]:checked:hover:after {
  background: #00b4b4;
  content: "\2714";
  color: #fff;
}

input[type="checkbox"]:checked:after {
  background: #00b4b4;
  content: "\2714";
  color: #fff;
}
input[type="checkbox"]:after {
  background: #00b4b4 !important;
  color: #fff !important;
}
.table-striped tbody tr:nth-of-type(odd) input[type="text"] {
  background-color: rgba(0, 0, 0, 0.05);
  /*border: 1px solid #dee2e6;*/
  background-color: #fff;
  padding: 5px;
}
.table-striped tbody tr:nth-of-type(even) input[type="text"] {
  background-color: rgba(0, 0, 0, 0.05);
  /*border: 1px solid #dee2e6;*/
  padding: 5px;
}
/* table fix */

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
  /*background-color: #fff;*/
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
</style>

