<template>
<button @click="goBack" class="list-btn mb10" style="display: flex;"><i class="material-icons">keyboard_arrow_left</i> Back</button>
</template>
  
  <script>
  export default {
    name: "BackButton",
    methods: {
        goBack() {
        this.$router.go(-1);
        }
    },

  };
  </script>
  
  <style scoped></style>