<template>
  <div>
    <!--
    <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div id="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div id="v-step-2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
-->
    <v-tour name="SalesTrainingTour" :steps="steps" :callbacks="myCallbacks" v-bind:style="{ zIndex: tourZIndex }" style="position: relative;">
      <template v-slot="{ tour }">
      
          <v-step
            v-if="tour && tour.steps && tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template v-if="tour.currentStep === 5">
              <div>
                <button
                  @click="tour.skip"
                  class="v-step__button v-step__button-previous"
                >
                  Skip tour
                </button>
                <button
                  @click="tour.previousStep"
                  class="v-step__button v-step__button-previous"
                >
                  Previous step
                </button>
                <button
                  @click="tour.stop"
                  class="v-step__button v-step__button-skip"
                >
                  Got it!
                </button>
              </div>
            </template>
          </v-step>
       
      </template>
    </v-tour>
  </div>
</template>

<script>
export default {
  name: "sales-training-tour",
  data() {
    return {
      tourZIndex: 1,
      steps: [
        {
          target: "#st-step-1", // We're using document.querySelector() under the hood
          header: {
            title: "Search For Documents",
          },
          content: `<p style="font-weight: 300;">This area will allow you to search through images, videos, and documents by keyword.</p>`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
        {
          target: "#st-step-2",
          header: {
            title: "Filter by File Type",
          },
          content: `
            <p style="font-weight: 300;">Easily filter the results of the search above, or the category selected below by file type.</p>
            `,
          params: {
            placement: "right-end", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
        {
          target: "#st-step-3",
          header: {
            title: "Explore Documents By Category",
          },
          content:
            '<p style="font-weight: 300;">You can quickly return results for a specific category here.</p>',
          params: {
            placement: "right-end", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },

        {
          target: "#st-step-5",
          header: {
            title: "View Document",
          },
          content:
            '<p style="font-weight: 300;">Once the media agreement is accepted, the controls in this area will allow you to easily preview, download, email, or copy the document location to your clipboard for future use!</p>',
          params: {
            placement: "left-top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            enableScrolling: false,
          },
        },
      ],
      myCallbacks: {
        onSkip: this.customSkipStepCallback,
      },
    };
  },
  methods: {
    customSkipStepCallback: function () {
      this.$store.commit("hideTour");
      this.$toast.success("The help tour has been disabled.", { hideProgressBar: false, icon: false });
    },
  },
  mounted: function () {
    this.$tours["SalesTrainingTour"].start();
  },
};
</script>
<style>
.v-step {
  /*background: #F16231!important;*/
  background: #50596c;
  background: #5f6d7b !important;
  color: #fff;
  max-width: 320px;
  border-radius: 3px;
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  padding: 1rem;
  text-align: center;
  z-index: 1;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.18);
}
.v-step__header {
  margin: -1rem -1rem 0.5rem;
  padding: 0.5rem;
  background-color: #454d5d;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.v-tour__target--highlighted {
  -webkit-box-shadow: 0 0 0 4px rgb(255 255 255 / 40%);
  box-shadow: 0 0 0 4px rgb(255 255 255 / 40%);
  pointer-events: auto;
  z-index: 9999;
}
</style>
