<template>
  <div>
    <transition name="menu-popover" v-if="xlPreview.length">
      <div v-if="xlPreview" class="lightbox" style="z-index: 10003">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <iframe
          :src="
            'https://view.officeapps.live.com/op/embed.aspx?src=' + xlPreview
          "
          width="90%"
          height="80%"
          frameborder="0"
        >
        </iframe>
        <!--
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this Excel file, your browser security settings are set to download filess instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.xlPreview"
          style="background: #fff"
        />
        -->
      </div>
    </transition>
    <section style="background: #fff; display: flex; padding: 0px 10px" class="noprint">
      <div
        class="desktoplogo"
        style="margin-right: auto; padding: 8px 0px 8px 0px"
      >
        <router-link
          to="/home"
          :class="{ active: activeNav === 'home' }"
          class="no-dropdown"
        >
          <img src="@/assets/spacenet-logo.svg" alt style="max-width: 130px" />
        </router-link>
      </div>
      <div class="nav-container">
        <!-- START - MINIMAL CSS3 MENU -->
        <label class="minimal-menu-button" for="mobile-nav">
          <img 
          src="@/assets/img/menu-3-line.svg"
          style="    height: 25px;
    display: block;
    width: 25px;
    margin-top: 20px;
    margin-right: 20px;"/></label>
        <input
          class="minimal-menu-button"
          type="checkbox"
          id="mobile-nav"
          name="mobile-nav"
          ref="checkbox"
          :checked="showMobile"
          @click="() => {showMobile = !showMobile}"
        />

        <div class="minimal-menu clr-white">
          <ul>
             <li class="home-link">
              <router-link
                to="/home"
                class="no-dropdown"
              >
                <!-- <i class="material-icons">help</i> -->
                Home
              </router-link>
            </li>

            <!--
            <li id="v-step-0" class="current">
              <router-link
                to="/collaborate"
                :class="{ active: activeNav === 'collaborate' }"
                class="no-dropdown"
              >
               
                Collaborate
              </router-link>
            </li>
          -->
          <li id="v-step-0" class="submenu">
              <a href="#">Collaborate</a>
              <input
                class="show-submenu"
                type="checkbox"
                id="show-submenu-1"
                name="show-submenu-1"
              />
              <ul class="left">
                <li>
                  <router-link
                    to="/eto-and-inspiring-installs"
                    :class="{ active: activeNav === 'collaborate' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> ETO & Inspiring Installs
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/questions"
                    :class="{ active: activeNav === 'collaborate' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Questions
                  </router-link>
                </li>
                
                <!--
               
              -->
              
              
                <!--
                <li>
                  <router-link
                    to="/sales-and-training"
                    :class="{ active: activeNav === 'training-videos' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Cool Installs
                  </router-link>
                </li>
              -->
              </ul>
            </li>
            <li id="v-step-1" class="submenu">
              <a href="#">MEDIA CENTER</a>
              <input
                class="show-submenu"
                type="checkbox"
                id="show-submenu-1"
                name="show-submenu-1"
              />
              <ul class="left">
                <li>
                  <router-link
                    to="/marketing-materials"
                    :class="{ active: activeNav === 'media' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Marketing
                    Materials
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/service-and-installation"
                    :class="{ active: activeNav === 'media' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Service
                    &amp; Installation
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/sales-and-training"
                    :class="{ active: activeNav === 'training-videos' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Sales
                    &amp; Training
                  </router-link>
                </li>
                <li>
                  <a @click="calcURLNew" style="cursor: pointer">
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Media
                    Room
                  </a>
                </li>
              </ul>
            </li>
            <li
              id="v-step-2"
              class="submenu"
              v-if="!this.$store.getters.hideOrders"
            >
              <a href="#" :class="{ active: activeNav === 'order-manage' }"
                >ORDER MANAGEMENT</a
              >
              <input
                class="show-submenu"
                type="checkbox"
                id="show-submenu-2"
                name="show-submenu-2"
              />
              <ul class="left">
                <li>
                  <router-link
                    to="/listings"
                    :class="{ active: activeNav === 'listings' }"
                  >
                    <img style="width: 20px;" src="@/assets/img/arrow-right-s-line.svg"/> Order
                    Listings
                  </router-link>
                </li>
                <li v-if="this.$store.getters.isExpeditedOrders">
                  <router-link
                    to="/expedited-orders"
                    :class="{ active: activeNav === 'listings' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Expedited
                    Orders
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/interterritorial"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> IT
                    Projects
                  </router-link>
                </li>
                <li v-if="this.$store.getters.isReports">
                  <router-link
                    to="/reports"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Reports
                  </router-link>
                </li>
                <li v-if="this.$store.getters.isCashManagement">
                  <router-link
                    to="/cash-management"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Cash
                    Management
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/lead-times"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Lead
                    Times
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/warranty"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    Warranties
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/rfrs"
                    :class="{ active: activeNav === 'rfrs' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> RFR
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/rgas"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> RGA
                  </router-link>
                </li>
                <li v-if="this.$store.getters.isGSA">
                  <router-link
                    to="/gsas"
                    :class="{ active: activeNav === 'rgas' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> GSA
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/ads"
                    :class="{ active: activeNav === 'ads' }"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> ADS
                  </router-link>
                </li>
                
                <li style="cursor: pointer">
                  <a @click="showXL($store.state.incomingReport)">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> SALES REP
                    RANKING BY INCOMING
                  </a>
                </li>
                <li style="cursor: pointer">
                  <a @click="showXL($store.state.invoicedReport)">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> SALES REP
                    RANKING BY INVOICED</a
                  >
                </li>

                <!--
							<li class="submenu">
								<a href="#">Development</a>
								<input class="show-submenu" type="checkbox" id="show-submenu-3" name="show-submenu-3" />
								<ul>
									<li><a href="#">HTML</a></li>
									<li><a href="#">CSS</a></li>
									<li><a href="#">PHP</a></li>
									<li><a href="#">MySQL</a></li>
								</ul>
							</li>
							<li class="submenu"><a href="#">Marketing</a>
								<input class="show-submenu" type="checkbox" id="show-submenu-4" name="show-submenu-4" />
								<ul>
									<li class="submenu">
										<a href="#">First submenu</a>
										<input class="show-submenu" type="checkbox" id="show-submenu-5" name="show-submenu-5" />
										<ul>
											<li><a href="#">Level 3 - submenu 1</a></li>
											<li><a href="#">Level 3 - submenu 2</a></li>
											<li><a href="#">Level 3 - submenu 3</a></li>
										</ul>
									</li>
									<li><a href="#">Second submenu</a></li>
								</ul>
							</li>
							-->
              </ul>
            </li>
            <li id="v-step-3" class="submenu">
              <a href="#" :class="{ active: activeNav === 'tools' }">TOOLS</a>
              <input
                class="show-submenu"
                type="checkbox"
                id="show-submenu-2"
                name="show-submenu-2"
              />

              <ul class="right">
                <li aria-haspopup="true">
                  <router-link
                    to="/cet-extension-curriculum"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    CET EXTENSION CURRICULUM
                  </router-link>
                </li>
                <!--
                <li aria-haspopup="true">
                  <router-link
                    to="/basic-installation-course"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    Basic Install Course
                  </router-link>
                </li>
                -->
                <li>
                  <router-link
                    to="/ac-list"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> AC
                    LOCATOR
                  </router-link>
                </li>
                <li>
                  <a
                    href="/sales-and-training?search=Area%20Contractor%20Directory"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> AC
                    DIRECTORY
                  </a>
                </li>
                <li>
                  <a
                    href="/sales-and-training?search=contact%20list"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> SSC
                    DIRECTORY
                  </a>
                </li>
                <li>
                  <router-link
                    to="/major-project-registration"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> MAJOR
                    PROJECT REGISTRATION
                  </router-link>
                </li>
                <li v-if="!this.$store.getters.hideOrders">
                  <router-link
                    to="/price-lists"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> PRICE
                    LISTS
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/faq"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> FAQ
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/calendar"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Events
                    Calendar
                  </router-link>
                </li>
                <li>
                  <a
                    href="https://tradeshow.spacesaver.com"
                    target="_blank"
                    rel="noopener"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Tradeshow
                    Requests
                  </a>
                </li>
                <li>
                  <a
                    href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                    target="_blank"
                    rel="noopener"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> Material
                    Safety Data Sheets
                  </a>
                </li>
                <li>
                  <a
                    href="https://productiq.ulprospector.com/en/search?term=E87096"
                    target="_blank"
                    rel="noopener"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    UL Listing
                  </a>
                </li>
                <li>
                  <a @click="SSCstoreLink" style="cursor: pointer">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    Spacesaver Store
                  </a>
                </li>
                 <li>
                  <a 
                    href="https://spacenet3.s3.amazonaws.com/files/Configura_License_Report_Summary.pdf"
                    target="_blank"
                    rel="noopener">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    Configura License Report
                  </a>
                </li>

                <!--
							<li class="submenu">
								<a href="#">Development</a>
								<input class="show-submenu" type="checkbox" id="show-submenu-3" name="show-submenu-3" />
								<ul>
									<li><a href="#">HTML</a></li>
									<li><a href="#">CSS</a></li>
									<li><a href="#">PHP</a></li>
									<li><a href="#">MySQL</a></li>
								</ul>
							</li>
							<li class="submenu"><a href="#">Marketing</a>
								<input class="show-submenu" type="checkbox" id="show-submenu-4" name="show-submenu-4" />
								<ul>
									<li class="submenu">
										<a href="#">First submenu</a>
										<input class="show-submenu" type="checkbox" id="show-submenu-5" name="show-submenu-5" />
										<ul>
											<li><a href="#">Level 3 - submenu 1</a></li>
											<li><a href="#">Level 3 - submenu 2</a></li>
											<li><a href="#">Level 3 - submenu 3</a></li>
										</ul>
									</li>
									<li><a href="#">Second submenu</a></li>
								</ul>
							</li>
							-->
              </ul>
            </li>
            <li id="v-step-4" class="submenu">
              <a href="#">NEWS</a>
              <input
                class="show-submenu"
                type="checkbox"
                id="show-submenu-6"
                name="show-submenu-6"
              />

              <ul class="right">
                <li>
                  <router-link
                    to="/announcements"
                    :class="{ active: activeNav === 'news' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> ANNOUNCEMENTS
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/from-the-fort"
                    :class="{ active: activeNav === 'news' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> FROM THE FORT
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/trailblazer"
                    :class="{ active: activeNav === 'news' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> TRAILBLAZER
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/release-notes"
                    :class="{ active: activeNav === 'tools' }"
                    class="d-flex"
                  >
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/> SOFTWARE RELEASE NOTES
                  </router-link>
                </li>
              </ul>
            </li>
            <li id="v-step-5" class="submenu">
				<a href="#">
					{{ $store.state.user.FirstName }}
                <span
                  v-if="
                    this.$store.state.expeditedOrderCount !== null &&
                    this.$store.state.newPostCount !== 0 &&
                    this.$store.state.collaborateView != true &&
                    !this.$store.getters.hideOrders &&
                    this.$store.getters.isExpeditedOrders
                  "
                  class="badge badge-dark"
                  style="margin-left: 5px"
                  >{{
                    this.$store.state.expeditedOrderCount +
                    this.$store.state.newPostCount
                  }}</span
                >

                <span
                  v-else-if="
                    this.$store.state.expeditedOrderCount !== null &&
                    this.$store.state.collaborateView != true &&
                    !this.$store.getters.hideOrders &&
                    this.$store.getters.isExpeditedOrders
                  "
                  class="badge badge-dark"
                  style="margin-left: 5px"
                  >{{ this.$store.state.expeditedOrderCount }}</span
                >

                <span
                  v-else-if="this.$store.state.newPostCount !== 0"
                  class="badge badge-dark"
                  style="margin-left: 5px"
                  >{{ this.$store.state.newPostCount }}</span
                >
                <span v-else style="margin-left: 5px"></span>

                <span class="wsarrow"></span>

				</a>
					<input
					class="show-submenu"
					type="checkbox"
					id="show-submenu-6"
					name="show-submenu-6"
				/>
				   <ul class="right">
                    <li
                  v-if="
                    this.$store.state.expeditedOrderCount !== null &&
                    this.$store.getters.isExpeditedOrders &&
                    !this.$store.getters.hideOrders
                  "
                  aria-haspopup="true"
                  style="cursor: pointer"
                >
                  <router-link to="/expedited-orders">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    {{
                      this.$store.state.expeditedOrderCount +
                      " Expedited Orders"
                    }}
                  </router-link>
                </li>
                <li
                  v-if="
                    this.$store.state.newPostCount !== null &&
                    this.$store.state.newPostCount > 0 &&
                    this.$store.state.collaborateView != true
                  "
                  aria-haspopup="true"
                  style="cursor: pointer"
                >
                  <router-link to="/questions">
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>
                    {{ this.$store.state.newPostCount + " New Posts" }}
                  </router-link>
                </li>

                <li aria-haspopup="true" style="cursor: pointer">
                  <a
                    href="mailto:spacenet3feedback@spacesaver.com?subject=SpaceNET 3 Support"
                  >
				  
				  <img class="icons" style="max-width: 18px;
    margin-right: 5px;" src="@/assets/img/feedback-line.svg"/>
	<!--
                    <img style="width: 20px" src="@/assets/img/arrow-right-s-line.svg"/>--> SpaceNET
                    3 Feedback
                  </a>
                </li>

                <li
                  v-if="this.$store.state.tour"
                  @click="hideHelp"
                  aria-haspopup="true"
                  style="cursor: pointer"
                  class="tourBtns"
                >
                  <a> <i class="material-icons" style="margin-right:5px; color: #575757;">help_outline</i> Hide Help </a>
                </li>

                <li
                  v-else
                  @click="showHelp"
                  aria-haspopup="true"
                  style="cursor: pointer"
                  class="tourBtns"
                >
                  <a> <i class="material-icons" style="margin-right:5px; color: #575757;">help_outline</i> Show Help </a>
                </li>
                 <li aria-haspopup="true" style="cursor:pointer;">

<!--
                <a v-if="this.$store.state.isDashboard" @click="setLayout('website')">
                  <img class="icons" style="max-width: 18px;
            margin-right: 5px;" src="@/assets/img/window-line.svg"/>
                  <span>Classic Layout</span>
                </a>
                <a v-else @click="setLayout('dashboard')">
                  <img class="icons" style="max-width: 18px;
            margin-right: 5px;" src="@/assets/img/layout-masonry-line.svg"/>
                  <span>Dashboard Layout</span>
                </a>
              -->


                </li>

                <li
                  @click="logout"
                  aria-haspopup="true"
                  style="cursor: pointer"
                >
                  <a> 
					  <img src="@/assets/img/logout-circle-line.svg" style="max-width: 18px;
    margin-right: 5px;" />
					 <!-- <i class="material-icons" style="margin-right:5px">view_carousel</i> -->
					  Logout </a>
                </li></ul>
			</li>
          </ul>
        </div>
        <!-- END - MINIMAL CSS3 MENU -->
      </div>
    </section>
  </div>
</template>
<script>
import md5 from "md5";

export default {
  name: "MainNavbarDEV",
  components: {},
  data() {
    return {
      open: false,
      showMenu: false,
      showMobile: false,
      extraNavClasses: "",
      currentScrollValue: 0,
      widensecret: import.meta.env.VITE_APP_WIDEN_SECRET,
      widenaddress: import.meta.env.VITE_APP_WIDEN_ADDRESS,
      computedURL: "",
      alerts: 0,
      xlPreview: "",
    };
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    email() {
      return this.$store.state.user.Email;
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    userName() {
      return this.$store.state.user.UserName;
    },
    userGUID() {
      return this.$store.state.user.UserId;
    },
    updateURLonINpersonate() {
      this.computedURL = this.calcURL();
    },
    isGSA() {
      return this.$store.getters.isGSA;
    },

    /*
    email: "joas@spacesaver.com",
		first_name: "Joshua",
		guid: "2DD2C984-A3DD-4923-AC52-5B04382989AD",
		last_name: "Oas",
    timestamp: new Date().toUTCString(),
    */
  },
  methods: {
    /*
      activeNav(tab) {
        this.activeTab = tab;
        // this.showModal = true;
      },
      */
    firstLoad: function () {
      //document.getElementById("mobile-nav").checked = true;
      this.$refs.checkbox.checked = false;
      return axios
        .get(`${this.ordermanageapi}/eo?pending=true`, auth)
        .then((response) => {
          console.log(response);
          let number = response.eo.length;
          this.alerts = number;
        });
    },
    closeMobile: function(){
      this.showMobile = false;
    },
    logout() {
      localStorage.removeItem("SSCUSER");
      this.$router.push({
        name: "Login",
      });
    },
	setLayout(layout) {
      localStorage.removeItem("SSCview");
      localStorage.setItem("SSCview", layout);
      this.$store.commit("SET_LAYOUT", layout);
      console.log('Switched');
      //this.$forceUpdate();
      this.$router.go(0);
    },
    showHelp: function () {
      this.$store.commit("showTour");
      /*
      this.$noty.success(
        "The help tour has been enabled, you can disable it by clicking 'Skip tour' or 'Hide Help' in the menu under your name! Help is only available on desktop devices."
      );
      */
    },
    hideHelp: function () {
      this.$store.commit("hideTour");
      /*
      this.$noty.success(
        "The help tour has been disabled, if you need it again, you can enable it in the top menu under your name!"
      );
      */
    },
    /*
    bodyToggleNew() {
      const el = document.body;
      if (el.classList.contains("wsactive")) {
        el.classList.remove("wsactive");
      } else {
        el.classList.add("wsactive");
      }
    },
    bodyRemove() {
      const el = document.body;
      el.classList.remove("wsactive");
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
        this.open = true;
      } else {
        el.classList.remove(className);
        this.open = false;
      }
    },
	*/
    calcURL() {
      //this function creates the actual URL
      const queryParams = Object.entries(this.calcWidenEndpoint())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      //console.log(`${endpoint}?${queryParams}`);

      return `${this.widenaddress}?${queryParams}`;
    },

    calcURLNew() {
      const queryParams = Object.entries(this.calcWidenEndpoint())
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      
      console.log(`${this.widenaddress}?${queryParams}`);
      window.open(`${this.widenaddress}?${queryParams}`, "_blank");
      
    },

    calcWidenEndpoint() {
      //this function preps the values for the widen SSO
      let fields = {
        email: this.email,
        first_name: this.firstName,
        guid: this.userGUID,
        last_name: this.lastName,
        timestamp: new Date().toUTCString(),
      };
      let fieldString = Object.keys(fields)
        .sort()
        .map((k) => fields[k])
        .join("");

      let fieldStringWithSharedSecret = fieldString + this.widensecret;

      // Calculate md5 hash of the field String with shared secret
      let signature = md5(fieldStringWithSharedSecret);

      fields.signature = signature;

      return fields;
    },
    myEventHandler(e) {
      // your code for handling resize...
      let windowWidth = window.innerWidth;
      if (Number(windowWidth) < 992) {
        //console.log("mobile");
        this.$store.commit("hideTour");
      } else if (Number(windowWidth) > 991 && this.$store.state.tour) {
        this.$store.commit("showTour");
      }
    },
    incoming(link) {
      window.open(
        "http://spacenet2.spacesaver.com/documents/Incoming%20Net%20Revenue%20by%20Market%20and%20Salesperson%20(Ranking)%20YTD.xlsx",
        "_blank"
      );
    },
    showXL(doc) {
      this.xlPreview = doc;
    },
    closeLightbox() {
      this.xlPreview = "";
    },
    invoiced(link) {
      window.open(`http://spacenet2.spacesaver.com/${link}`, "_blank");
    },
    getDocument(link) {
      //console.log("link: " + link);
      window.open(`http://spacenet2.spacesaver.com/${link}`, "_blank");
    },
    SSCstoreLink() {
      let url =
        "https://portal.mypropago.com/Security/Spacesaver?companyGuid=ae007963-e6dc-47cc-92c5-898fc8add77a&email=" +
        this.email +
        "&username=" +
        this.userName +
        "&fName=" +
        this.firstName +
        "&lName=" +
        this.lastName;
      window.open(url, "_blank");
    },
  },
  mounted() {
    //this.toggleBodyClass("removeClass", "wsactive");
    this.computedURL = this.calcURL();
  },
  beforeDestroy() {},
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>
<style>
/* ====================================================================================================
	MINIMAL CSS3 MENU
==================================================================================================== */
.minimal-menu {
  position: relative;
  display: block;
  text-decoration: none;
  font-size: 0;
  line-height: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smooth: subpixel-antialiased;
}

.minimal-menu h3 {
  font-weight: normal;
}

.minimal-menu a {
  border-color: transparent;
}

.minimal-menu a:hover {
  -webkit-transition: border-color 0.3s, background 0.3s;
  -moz-transition: border-color 0.3s, background 0.3s;
  transition: border-color 0.3s, background 0.3s;
}

.minimal-menu ul,
.minimal-menu ul li,
.minimal-menu ul li a {
  position: relative;
}

.minimal-menu ul {
  z-index: 2;
}

.minimal-menu ul li {
  padding-bottom: 15px;
}

.minimal-menu ul li a {
  display: block;
  padding: 10px 18px;
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
}

.minimal-menu > ul > li > a {
  text-transform: uppercase;
  border: 1px solid transparent;
  color: #fff;
}

.minimal-menu > ul > li:hover > a,
.minimal-menu > ul > li.current > a {
  /*border: 1px solid #fff;*/
}

.minimal-menu ul li.submenu > a:before,
.minimal-menu ul li.submenu > a:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 1px;
  bottom: 0;
  left: 50%;
  background: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

/*
.minimal-menu ul li.submenu > a:after {
	border-left: 4px solid rgba(0, 0, 0, 0);
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-top: 4px solid;
    content: "";
    float: right;
    right: 17px;
    height: 0;
    margin: 0 0 0 14px;
    position: absolute;
    text-align: right;
    top: 33px;
    width: 0;
}
*/

.minimal-menu ul li.submenu > a:before {
  margin-left: -4px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.minimal-menu ul li.submenu > a:after {
  margin-left: -1px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* RESPONSIVE
================================================== */
@media only screen and (max-width: 991px) {
  .desktoplogo {
    display: block;
  }
  .home-link{
    display: block;
  }
}
@media only screen and (min-width: 992px) {
  /* White
	----------------*/
  .minimal-menu.clr-white {
    background: #fff;
  }

  .minimal-menu.clr-white > ul > li > a {
    color: #000;
    padding: 11px 22px 11px 22px;
  }

  .minimal-menu.clr-white > ul > li:hover > a,
  .minimal-menu.clr-white > ul > li.current > a {
    /*border-color: #aaa;*/
   /* border-bottom: solid 1px #ccc;*/ 
  }

  .minimal-menu.clr-white ul ul li:hover > a,
  .minimal-menu.clr-white ul ul li.current > a {
    /*border-color: #999;*/
  }

  .minimal-menu.clr-white > ul > li.submenu > a:before,
  .minimal-menu.clr-white > ul > li.submenu > a:after {
    background: #999;
    /*display: none;*/
  }

   .home-link{
    display: none;
  }

  .minimal-menu-button {
    display: none;
  }



  .minimal-menu.pos-fixed {
    position: fixed;
    top: -2px;
    left: -2px;
    right: -2px;
    z-index: 9999;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .minimal-menu a {
    white-space: nowrap;
  }

  .minimal-menu > ul {
    margin-bottom: -15px !important;
    padding: 5px;
  }

  .minimal-menu ul li {
    display: inline-block;
  }

  .minimal-menu ul li.submenu:hover > a:before,
  .minimal-menu ul li.submenu:hover > a:after {
    bottom: -20px;
    opacity: 0;
  }

  /*** Submenu ***/
  .minimal-menu ul ul,
  .minimal-menu ul .menu-wrapper {
    position: absolute;
    display: block;
    visibility: hidden;
    z-index: 2;
    height: auto;
    left: 3px;
    top: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
    border-bottom-width: 3px;
    opacity: 0;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  .minimal-menu ul li:hover > ul,
  .minimal-menu ul li:hover > .menu-wrapper {
    -webkit-transition: opacity 0.3s, padding 0.3s;
    -moz-transition: opacity 0.3s, padding 0.3s;
    transition: opacity 0.3s, padding 0.3s;
    visibility: visible;
    padding: 10px 0;
    opacity: 1;
  }

  .minimal-menu ul ul li {
    display: block;
    overflow: hidden;
    height: auto;
    padding: 10px;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
  }

  .minimal-menu ul ul li a {
    font-size: 12px;
    color: #666;
    border: 1px solid transparent;
  }

  .minimal-menu ul ul li:hover > a,
  .minimal-menu ul ul li.current > a {
    border-style: solid;
    border-width: 1px;
  }

  .minimal-menu ul li:hover ul li {
    overflow: visible;
    padding: 0 10px;
  }

  .minimal-menu ul ul li.submenu > a:before,
  .minimal-menu ul ul li.submenu > a:after {
    left: auto;
    top: 50%;
    right: 0;
    background: #ccc;
  }

  .minimal-menu ul ul li.submenu > a:before {
    margin-top: -1px;
  }

  .minimal-menu ul ul li.submenu > a:after {
    margin-top: 2px;
  }

  .minimal-menu ul ul li.submenu > a:hover:before,
  .minimal-menu ul ul li.submenu > a:hover:after {
    right: -13px;
  }

  .minimal-menu ul ul ul {
    left: 100%;
    top: -11px;
  }

  /* Megamenu */
  .minimal-menu ul li.megamenu {
    position: static;
  }

  .minimal-menu ul .menu-wrapper {
    position: absolute;
    left: -1px;
    top: 100%;
    margin-top: -5px;
    width: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }

  .minimal-menu ul .menu-wrapper a {
    white-space: inherit;
  }

  .minimal-menu ul .menu-wrapper .menu-col {
    position: relative;
    float: left;
    width: 23%;
    margin: 0 1%;
  }

  .minimal-menu ul .menu-wrapper h3 {
    margin: 20px 15px;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  .minimal-menu ul .menu-wrapper ul {
    position: relative;
    visibility: hidden;
    height: 100%;
    border: 0;
    opacity: 1;
    background: transparent;
  }

  .minimal-menu ul li:hover > .menu-wrapper ul {
    visibility: visible;
  }

  .minimal-menu ul .menu-wrapper ul li {
    padding: 0 10px;
  }

  .minimal-menu .show-submenu {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  /* White
	----------------*/
  .minimal-menu.clr-white {
    background: #fff;
    background: #111212;
  }

  .minimal-menu.clr-white h3,
  .minimal-menu.clr-white ul li a {
    color: #575757;
    color: #fff;
	font-size: 12px;
    border-bottom-color: rgba(0, 0, 0, 0.08);
  }

  .minimal-menu.clr-white h3 {
    background: rgba(0, 0, 0, 0.05);
  }

  .minimal-menu.clr-white ul li:hover a,
  .minimal-menu.clr-white ul li.current a {
    background: #ebf0f3;
    background: #212323;
  }

  .minimal-menu.clr-white ul li li:hover a,
  .minimal-menu.clr-white ul li li.current a {
    background: #dbe0e3;
    background: #252828;
  }

  .minimal-menu.clr-white ul li li li:hover a,
  .minimal-menu.clr-white ul li li li.current a {
    background: rgba(0, 0, 0, 0.3);
  }

  .minimal-menu {
    border-color: rgba(100%, 100%, 100%, 0.3);
  }

  .minimal-menu-button,
  .minimal-menu {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
  }

  input.minimal-menu-button {
    display: none;
  }

  label.minimal-menu-button {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-transform: uppercase;
    color: #000;
    cursor: pointer;
    text-indent: -9999px;
  }


  label.minimal-menu-button:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 5px;
    left: 10px;
    bottom: 13px;
    /*border-top: 13px double #000;*/
    z-index: 2;
  }

  .minimal-menu {
    overflow: auto;
    left: -100%;
    top: 0;
    width: 30%;
    height: 100%;
    opacity: 0;
    -webkit-transition: left 0.3s, opacity 0.5s;
    -moz-transition: left 0.3s, opacity 0.5s;
    transition: left 0.3s, opacity 0.5s;
	box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  }

  #mobile-nav:checked + .minimal-menu {
    visibility: visible;
    left: 0;
    opacity: 1;
    min-width: 320px;
  }

  .minimal-menu h3,
  .minimal-menu ul li a {
    border-top: 1px solid transparent;
    border-bottom: 1px solid rgba(100%, 100%, 100%, 0.2);
    color: #fff;
  }

  .minimal-menu h3 {
    font-size: 18px;
    line-height: 1em;
    margin: 0;
    padding: 20px 0 20px 40px;
    color: #fff;
    background: rgba(100%, 100%, 100%, 0.1);
  }

  .minimal-menu ul {
    margin: 0;
    padding: 0;
  }

  .minimal-menu li,
  .minimal-menu a {
    display: block;
  }

  .minimal-menu li {
    position: relative;
  }

  .minimal-menu li:hover > a,
  .minimal-menu li.current > a {
    background: rgba(100%, 100%, 100%, 0.2);
  }

  .minimal-menu li li:hover > a,
  .minimal-menu li li.current > a {
    background: rgba(100%, 100%, 100%, 0.4);
  }

  .minimal-menu li li li:hover > a,
  .minimal-menu li li li.current > a {
    background: rgba(100%, 100%, 100%, 0.6);
  }

  .minimal-menu ul > li {
    padding-bottom: 0;
  }

  .minimal-menu > ul > li:hover > a,
  .minimal-menu > ul > li.current > a {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .minimal-menu ul ul a {
    padding-left: 40px;
  }

  .minimal-menu ul ul ul a {
    padding-left: 55px;
  }

  .minimal-menu ul ul ul ul a {
    padding-left: 70px;
  }

  .minimal-menu ul ul li a {
    font-size: 14px;
  }

  .minimal-menu ul li.submenu > a:before,
  .minimal-menu ul li.submenu > a:after {
    top: 50%;
    right: 5%;
  }

  .minimal-menu.clr-white ul li.submenu a:before,
  .minimal-menu.clr-white ul li.submenu a:after {
    background: #999;
    display: none;
  }

  .minimal-menu ul li.submenu > a:before {
    margin-top: -2px;
  }

  .minimal-menu ul li.submenu > a:after {
    margin-top: 1px;
  }

  .minimal-menu .show-submenu {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 40px;
    min-width: 100%;
    min-height: 40px;
    margin: 0;
    opacity: 0;
    -webkit-appearance: none;
  }

  .minimal-menu .show-submenu + ul,
  .minimal-menu .show-submenu + .menu-wrapper {
    display: none;
    visibility: hidden;
  }

  .minimal-menu .show-submenu:checked + ul,
  .minimal-menu .show-submenu:checked + .menu-wrapper {
    display: block;
    visibility: visible;
  }
}

@media only screen and (max-width: 480px) {
  .minimal-menu {
    width: 70%;
  }
}
.right {
  /*right: 3px!important;*/
}
.minimal-menu ul ul li a {
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) {
  .minimal-menu ul ul li a {
    font-size: 12px;
    color: #666;
    border: 1px solid transparent;
    line-height: 22px;
    padding: 8px 12px;
    display: flex;
    cursor: pointer;
  }
  .minimal-menu ul ul li a:hover {
    background: #ecf1f4;
  }
  .minimal-menu ul li:hover > .right {
    right: 0 !important;
    left: unset !important;
  }
   .minimal-menu ul li:hover > ul {
    left: 0 !important;
    left: unset !important;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
}
.minimal-menu ul ul li a i {
  font-size: 20px;
}
@media screen and (max-width: 991px){
	.sidebar{
		display: none;
	}
	.content{
		margin-left: 0!important;
	}
}
@media screen and (min-width: 992px){
	.content {
		margin-left: 250px!important;
	}
	#check:checked ~ .content {
    margin-left: 60px!important;
  }
  .home-link{
    display: none!important;
  }
  .minimal-menu.clr-white ul li.submenu a:before,
  .minimal-menu.clr-white ul li.submenu a:after {
    display: block;
  }
}
</style>

