<template>
    <div class="carousel-container">
      <carousel :settings="settings">
        <slide v-for="(slide, index) in slides" :key="index">
          <div class="slide-content">
            <img :src="slide.image" :alt="slide.alt" />
            <p class="slide-caption">{{ slide.caption }}</p>
          </div>
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </template>
  
  <script>
  import 'vue3-carousel/dist/carousel.css'; // Import carousel styles
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
  
  export default {
    name: 'SliderV3',
    components: {
      Carousel,
      Slide,
      Pagination,
      Navigation,
    },
    data() {
      return {
        slides: [
          {
            image: 'https://via.placeholder.com/800x400?text=Slide+1',
            alt: 'Slide 1',
            caption: 'This is the first slide.',
          },
          {
            image: 'https://via.placeholder.com/800x400?text=Slide+2',
            alt: 'Slide 2',
            caption: 'This is the second slide.',
          },
          {
            image: 'https://via.placeholder.com/800x400?text=Slide+3',
            alt: 'Slide 3',
            caption: 'This is the third slide.',
          },
        ],
        settings: {
          itemsToShow: 1, // Show one slide at a time
          autoplay: true, // Enable autoplay
          autoplayTimeout: 3000, // Set autoplay interval (ms)
          pauseAutoplayOnHover: true, // Pause autoplay on hover
          transition: 0.5, // Slide transition duration (seconds)
          loop: true, // Enable looping
        },
      };
    },
  };
  </script>
  
  <style scoped>
  .carousel-container {
    max-width: 800px;
    margin: auto;
    position: relative;
  }
  
  .slide-content {
    text-align: center;
  }
  
  .slide-content img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .slide-caption {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
  }
  </style>
  