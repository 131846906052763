export function formatCurrency(amount) {
  // console.log('Original Input:', amount);

  if (typeof amount === 'string') {
    amount = parseFloat(amount.replace(/\./g, '').replace(',', '.'));
    // console.log('Normalized Input:', amount);
  }

  /*
  if (typeof amount !== 'number') {
    throw new Error('Amount must be a number');
  }
  */

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const formatted = formatter.format(amount);
  // console.log('Formatted Output:', formatted);

  return formatted;
}

/*
export function formatCurrency(amount) {
  // Ensure the amount is a valid number
  if (typeof amount !== 'number') {
    throw new Error('Amount must be a number');
  }

  // Use the built-in Intl.NumberFormat object to format the currency
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  // Format the currency and return the string
  return formatter.format(amount);
}
*/



export function Logout() {
  localStorage.removeItem("SSCUSER");
  this.$router.push("/");
  console.log("401")
}