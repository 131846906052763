<template>
  <div
    v-if="loading"
    class="banner d-flex align-items-center p-3 my-3 rounded bs-shadow"
  >
    <div class="d-flex number-showing">
      <p class="mb0"><small>Searching</small></p>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button v-if="sortView" disabled>
                  <span class="material-icons">
keyboard_arrow_up
</span>

        {{ " " }}Sort
      </button>
      <button v-else disabled>
        <span class="material-icons">
keyboard_arrow_down
</span>
        {{ " " }}Sort
      </button>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button v-if="dateView" disabled>

        <span class="material-icons">
keyboard_arrow_up
</span>
        {{ " " }}Date
      </button>
      <button v-else disabled>
        <span class="material-icons">
keyboard_arrow_down
</span>
        {{ " " }}Date
      </button>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button v-if="listView == false" disabled>
        <span class="material-icons" style="margin-right: 5px">
      view_list
      </span>
        {{ " " }}View
      </button>
      <button v-else disabled>
        <span class="material-icons" style="margin-right: 5px">
apps
</span>
        {{ " " }}View
      </button>
    </div>

    <div>
      <div id="mm-step-7" class="nav-btns">
        <button disabled>
          <i class="material-icons">keyboard_arrow_left</i> Back
        </button>

        <button disabled>
          Next
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="banner d-flex align-items-center p-3 my-3 rounded bs-shadow"
  >
    <div class="d-flex number-showing">
      <p class="mb0">{{ this.media.length + " " }}<small>Results</small></p>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button v-if="sortView" @click="sortSwitch" style="cursor: pointer">
        <span class="material-icons">
keyboard_arrow_up
</span>
        {{ " " }}Sort
      </button>
      <button v-if="!sortView" @click="sortSwitch" style="cursor: pointer">
        <span class="material-icons">
keyboard_arrow_down
</span>
        {{ " " }}Sort
      </button>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button v-if="dateView" @click="dateSwitch" style="cursor: pointer">
        <span class="material-icons">
keyboard_arrow_up
</span>
        {{ " " }}Date
      </button>
      <button v-else @click="dateSwitch" style="cursor: pointer">
        <span class="material-icons">
        keyboard_arrow_down
        </span>
        {{ " " }}Date
      </button>
    </div>

    <div class="view-switch switch-desktop hide-mobile">
      <button
        v-if="listView == false"
        @click="viewSwitch"
        style="cursor: pointer"
      >
      <span class="material-icons" style="margin-right: 5px">
      view_list
      </span>
      
      {{
          " "
        }}View
      </button>
      <button v-else @click="viewSwitch" style="cursor: pointer">
        <span class="material-icons" style="margin-right: 5px">
apps
</span>
        {{ " " }}View
      </button>
    </div>

    <div>
      <div id="mm-step-7" class="nav-btns">
        <button v-if="this.offsetVal == 0" disabled>
          <i class="material-icons">keyboard_arrow_left</i> Back
        </button>
        <button v-else @click="offsetValMinus()">
          <i class="material-icons">keyboard_arrow_left</i> Back
        </button>

        <button v-if="this.media.length < 99" disabled>
          Next
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
        <button v-else @click="offsetValPlus()">
          Next
          <i class="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaPagination",
  props: {
    loading: Boolean,
    media: Array,
    sortView: Boolean,
    dateView: Boolean,
    listView: Boolean,
    offsetVal: Number,
    sortSwitch: {
      type: Function,
    },
    dateSwitch: {
      type: Function,
    },
    viewSwitch: {
      type: Function,
    },
    offsetValPlus: {
      type: Function,
    },
    offsetValMinus: {
      type: Function,
    },
  },
  computed: {},
};
</script>

<style scoped></style>
