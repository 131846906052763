<template>
<div>
    <div class="row">
            <!-- Column -->
            <div class="col-lg-3 col-md-6">
                
              <div class="card mb20">
                  <router-link
              :to="{
                path: '/reportdetails',
                query: {
                  status: 'In Scheduling',
                  //acid: reports.backlog[0].acid,
                },
              }">
                <div class="card-body">
                  <div class="d-flex p-10 no-block">
                    <div class="align-slef-center">
                      <h4 v-if="homeReports && homeReports.length" class="m-b-0">{{formatPrice(homeReports[3].sales)}}</h4>
                      <h3 v-else class="m-b-0">...</h3>
                       <div class="progress" style="background: #fff">
                       <div v-if="homeReports && homeReports[3].sales == 0" class="progress-bar bg-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 20%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                        <div v-else class="progress-bar bg-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                      </div>
                      <h6 class="text-muted m-b-0">In Scheduling</h6>
                     
                    </div>
                    <div class="align-self-center display-6 ml-auto">
                        <img class="db-image" src="@/assets/img/calendar-todo-line.svg"/>
                        <!--
                      <i class="text-success icon-Target-Market"></i>
                      -->
                    </div>
                  </div>
                </div>
               
                </router-link>
              </div>
              
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="col-lg-3 col-md-6">

              <div class="card mb20">
                  <router-link
              :to="{
                path: '/reportdetails',
                query: {
                  status: 'In Production',
                  //acid: reports.backlog[0].acid,
                },
              }">
                <div class="card-body">
                  <div class="d-flex p-10 no-block">
                    <div class="align-slef-center">
                      <h4 v-if="homeReports && homeReports.length" class="m-b-0">{{formatPrice(homeReports[2].sales)}}</h4>
                      <h3 v-else class="m-b-0">...</h3>
                      <div class="progress" style="background: #fff">
                       <div v-if="homeReports && homeReports[2].sales == 0" class="progress-bar bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 20%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                        <div v-else class="progress-bar bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                      </div>
                      <h6 class="text-muted m-b-0">In Production</h6>
                    </div>
                    <div class="align-self-center display-6 ml-auto">
                      <img class="db-image" src="@/assets/img/stack-line.svg"/>
                    </div>
                  </div>
                </div>
                
                </router-link>
              </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="col-lg-3 col-md-6">
              <div class="card mb20">
                  <router-link
              :to="{
                path: '/reportdetails',
                query: {
                  status: 'In Shipping',
                  //acid: reports.backlog[0].acid,
                },
              }">
                <div class="card-body">
                  <div class="d-flex p-10 no-block">
                    <div class="align-slef-center">
                      <h4 v-if="homeReports && homeReports.length" class="m-b-0">{{formatPrice(homeReports[4].sales)}}</h4>
                      <h3 v-else class="m-b-0">...</h3>
                       <div class="progress" style="background: #fff">
                        <div v-if="homeReports && homeReports[4].sales == 0" class="progress-bar bg-primary" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 20%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                        <div v-else class="progress-bar bg-primary" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                      </div>
                      <h6 class="text-muted m-b-0">In Shipping</h6>
                    </div>
                    <div class="align-self-center display-6 ml-auto">
                         
                      <img class="db-image" src="@/assets/img/truck-line.svg"/>
                    </div>
                  </div>
                </div>
               
                </router-link>
              </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="col-lg-3 col-md-6">
              <div class="card mb20">
                  <router-link
              :to="{
                path: '/reportdetails',
                query: {
                  status: 'Backlog',
                  //acid: reports.backlog[0].acid,
                },
              }">
                <div class="card-body">
                  <div class="d-flex p-10 no-block">
                    <div class="align-slef-center">
                      <h3 v-if="loading" class="m-b-0">...</h3>
                      <h4 v-else-if="!loading && homeReports && homeReports.length" class="m-b-0">{{formatPrice(homeReports[0].sales)}}</h4>                    
                       <div class="progress" style="background: #fff">
                        <div v-if="homeReports && homeReports[0].sales == 0" class="progress-bar bg-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 20%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                        <div v-else class="progress-bar bg-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width: 100%; height: 3px">
                          <span class="sr-only">50% Complete</span>
                        </div>
                      </div>
                      <h6 class="text-muted m-b-0">Backlog</h6>
                      <!--
                      <h6 class="text-muted m-b-0">{{homeReports[0].state}}</h6>
                      -->

                    </div>
                    <div class="align-self-center display-6 ml-auto">                   
                        <img class="db-image" src="@/assets/img/briefcase-line.svg"/>
                    </div>
                  </div>
                </div>
               
                  </router-link>
              </div>
            </div>
            <!-- Column -->
            <!-- Column -->
          </div>
          </div>
</template>
<script>
import { formatCurrency } from '../../utils/utils';
// used in joint projects
  export default {
        name: 'HomeReports',
        props: ['homeReports', 'loading'],
        methods: {
        formatPrice(amount) {
          return formatCurrency(amount);
        },
      }
  }
</script>
<style scoped>
.db-image{
    /*opacity: 44%;*/
    position: absolute;
    bottom: 22px;
    right: 20px;
}
a{
    text-decoration: none;
    color: #575757;
}
.bg-success{
    background: #008787!important;
}
.bg-info{
    background: #00d4d2!important;
    background: #00b4b4!important;
    
}
.bg-primary{
    background: #657583!important;
    background: #00D4D3!important;
    
}
.bg-danger{
    background: #f16232!important;
}
.card{
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  transition: all .2s ease-in-out;
}
.card:hover{
  box-shadow: 0 10px 20px -12px rgb(0 0 0 / 8%) !important;
}
.progress{
  border-radius: 0;
}
</style>