<template>
  <div>
    <section data-v-5a90ec03 class="py-4 b-white-bottom bg11 mobile-top">
      <div data-v-5a90ec03 class="container">
        <div data-v-5a90ec03 class="row">
          <div
            class="
              col-12 col-sm-12 col-md-10
              offset-md-1
              col-xs-8 col-sm-8 col-8 col-lg-8
              offset-lg-2
            "
          >
            <h1 v-if="loading" class="text-white">Loading...</h1>
            <h1 v-else class="text-white" v-html="questions[0].Subject"></h1>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div
          class="
            col-12 col-sm-12 col-md-10
            offset-md-1
            col-xs-8 col-sm-8 col-8 col-lg-8
            offset-lg-2
            mb20
            mt20
          "
        >
          <router-link
            :to="{ path: '/questions/' }"
            class="mb20 lh1 mlauto list-btn dib my3"
            >Back</router-link
          >
          <!--
      <Link to={`/mktginfo/`} className="backbtn"><img class="backsvg" src={backSvg} alt="backbtn"/> All Posts</Link>
          -->

          <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12">
            <div class="flex-box-wrap">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />
                <p>Loading......</p>
              </div>
            </div>
          </div>
          <div v-else class="article">
            <!--
      <h3 class="cap">{{ $route.params.id }}</h3>
            -->
            <div v-for="(question, index) in questions" :key="index">
              <div class="question">
                <div class="top-wrap">
                  <div class="dflex mb10">
                    <avatar
                      class="mr5"
                      color="#fff"
                      v-bind:username="
                        question.FirstName + ' ' + question.LastName
                      "
                      v-bind:backgroundColor="
                        colorGetter[
                          Math.floor(Math.random() * colorGetter.length)
                        ]
                      "
                    ></avatar>
                    <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                    -->
                    <div class="dflex vcenter">
                      <div>
                        <h6 class="mb0">
                          <b>{{
                            question.FirstName + " " + question.LastName
                          }}</b>
                        </h6>
                        <p class="lh1 mb0">
                          <small>{{ question.Name }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mlauto dflex vcenter">
                    <i class="material-icons mr2 fs20">schedule</i>
                    <p v-if="question.Posted[1]" class="lh1 mb0">
                      {{
                        $moment(question.Posted[1])
                          .subtract(1, "minutes")
                          .fromNow()
                      }}
                    </p>
                    <p v-else class="lh1 mb0">Unknown date</p>
                  </div>
                </div>

                <div
                  class="mb20 wbbw question-message"
                  style="font-weight: 300; font-size: 1.25rem; line-height: 1.2"
                  v-html="linkify2(question.Message)"
                ></div>

                <div class="question-bottom dflex"></div>
              </div>
              <!-- card -->
            </div>
            <!-- v-for -->
            <div class="question">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">
                  Join in the conversation,
                  {{ getUsername.FirstName }}!
                </label>
                <textarea
                  v-model="reply"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <button
                  v-if="!reply.length"
                  disabled
                  class="mb20 lh1 mlauto list-btn dib"
                  style="
                    margin-top: 20px;
                    cursor: default;
                    background: rgb(222, 222, 222);
                    border: none;
                  "
                >
                  Submit
                </button>
                <button
                  v-else
                  @click.prevent="postNewResponse"
                  class="mb20 lh1 mlauto list-btn dib"
                  style="margin-top: 20px; border: none"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from '../components/Atoms/Avatar.vue'
// import Avatar from "vue-avatar";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "QuestionsSingle",
  components: {
    AtomSpinner,
    Avatar,
  },
  data() {
    return {
      server: import.meta.env.VITE_APP_SERVER_URL,
      questionId: this.$route.params.id,
      search: "",
      searchValue: "",
      questions: [],
      loading: true,
      reply: "",
      countOfPage: 25,
      currPage: 1,
      noQuestionFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Sales" },
        { id: 2, name: "Products" },
        { id: 3, name: "Configura" },
        { id: 4, name: "Competitors" },
        { id: 5, name: "Other" },
        { id: 6, name: "Installation" },
        { id: 7, name: "Announcements" },
      ],
      userIP: null,
      userID: this.$store.state.user.UserId,
      userName: this.$store.state.user.UserName,
      fullName:
        this.$store.state.user.FirstName +
        " " +
        this.$store.state.user.LastName,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "collaborate");


      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.userIP = ip;
      });

/*

      fetch(`${this.server}/api/v1/questions/${this.questionId}`, auth)
        .then(function(response) {
          if (response.status === 401) {
            this.$router.push("/");
          } else {
            const data = response.json();
            console.log(data);
            this.questions = data;
            this.loading = false;
            document.getElementById("mobileScrollTarget").scrollIntoView({ behavior: "smooth" });
          }
        })
        .catch(function(error) {
            console.log(error)
      });
*/



      return axios
        .get(`${this.server}/api/v1/questions/${this.questionId}`, auth)
        .then(
          (response) => {
            if (response.status === 401) {
              this.$router.push("/");
            } else {
              //console.log(response);
              this.questions = response.data;
              this.loading = false;
              document
                .getElementById("mobileScrollTarget")
                .scrollIntoView({ behavior: "smooth" });
            }
          },
          (error) => {
            console.log(error);
            this.$router.push("/");
          }
        );


    },

    postNewResponse: function () {
      //this.loading = true;
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        UserIp: this.userIP,
        Message: this.reply,
        EditedBy: this.userName,
        Topic: this.questionId,
        UserId: this.userID,
        notify: this.questions[0].LoweredEmail,
        fullName: this.fullName,
      };
      return axios
        .post(`${this.server}/api/v1/questions/comment`, postData, axiosConfig)
        .then(
          (response) => {
            if (response.status === 401) {
              window.location = "/logout";
            } else {
              this.$toast.success("Successfully added your comment!");
              this.questions = response.data;
              //this.loading = false;
              this.reply = "";
            }
          },
          (error) => {
            console.log(error);
            this.$toast.error("There seems to be an issue posting your comment");
            this.$router.push("/");
          }
        );
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitch: function (catID) {
      return this.questions.filter((e) => {
        return e.Fid.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    linkify: function (text) {
      var urlRegex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      console.log("replaced");
      return text.replace(urlRegex, function (url) {
        return (
          '<a class="body-link" href="' +
          url.replace('&#x2F;&#x2F;', '//') +
          '" target="_blank">' +
          url.replace('&#x2F;&#x2F;', '//') +
          "</a>"
        );
      });
    },
    linkify2: function (text) {
      text.replace('&#x2F;','/');
      text.replace('%2F','/');
      var urlRegex =
        /(\b(https?|http|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

      //var entityScape = urlRegex.replace(/\x2f/g, "/");

      return text.replace(urlRegex, function (url) {
        //return '<a href="' + url.replace('&#x2F;&#x2F;', '//') + '">' + url.replace('&#x2F;&#x2F;', '//') + "</a>";
        return '<a href="' + url + '">' + url + "</a>";
      });
    },
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredQuestions: function () {
      this.currPage = 1;

      if (this.search.trim() !== "") {
        return this.questions.filter((e) => {
          return (
            e.Subject.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else {
        return this.questions;
      }
    },

    foundNoQuestion: function () {
      if (this.search.length > 1 && this.filteredQuestions.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },
    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function () {
      return Math.ceil(this.questions.length / this.countOfPage);
    },
    getUsername() {
      return this.$store.state.user;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.question {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.question-message a {
  color: #00b4b4 !important;
}
</style>
