import axios from "axios";

const state = {
    challengerinsights: "https://challengerinsights.spacesaver.com",
    challengerInsightsPosts: [],
};

const getters = {
    getChallengers: (state) => {
        return state.challengerInsightsPosts;
    },
};

const actions = {};

const mutations = {
    SET_CHALLENGER(state) {
        return axios
            .get(
                `http://challengerinsights.spacesaver.com/wp-json/wp/v2/posts/?per_page=100`
            )
            .then((response) => {
                state.challengerInsightsPosts = response.data;
            });
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}