<template>
  <div>
      
      <input type="checkbox" id="check" v-model="sideClosed" />
      <!--header area start-->
      <header style="z-index: 2; box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%)">
        <!--  
        <div v-if="navigationState == true">
        -->
            <div >
          <MainNavbarDEV v-cloak></MainNavbarDEV>
        </div>
        <!--
          </div>
        -->
      
        <label for="check">
          <img v-if="sideClosed" class="open-icon" src="@/assets/img/menu-unfold-line.svg" id="sidebar_btn"/>
           <img v-else class="open-icon" src="@/assets/img/menu-fold-line.svg" id="sidebar_btn"/>
          <!--
          <img class="closed-icon" src="@/assets/img/align-right.svg" id="sidebar_btn"/>
          -->
         <!-- <i class="fas fa-bars" id="sidebar_btn"></i> -->
        </label>
      </header>
      <!--header area end-->
      <!--mobile navigation bar start-->
      <!--
      <div class="mobile_nav">
        <div class="nav_bar">
          <avatar
            v-once
            class="mr5"
            color="#fff"
            v-bind:username="firstName + ' ' + lastName"
            v-bind:backgroundColor="
              colorGetter[Math.floor(Math.random() * colorGetter.length)]
            "
          ></avatar>
          <i class="fa fa-bars nav_btn"></i>
        </div>
        <div class="mobile_nav_items">
          <a href="#"
            ><i class="fas fa-desktop"></i
            ><span style="margin-left: 10px"> Dashboard</span></a
          >
          <a href="#"><i class="fas fa-cogs"></i><span>Components</span></a>
          <a href="#"><i class="fas fa-table"></i><span>Tables</span></a>
          <a href="#"><i class="fas fa-th"></i><span>Forms</span></a>
          <a href="#"><i class="fas fa-info-circle"></i><span>About</span></a>
          <a href="#"><i class="fas fa-sliders-h"></i><span>Settings</span></a>
          <a @click="setLayout()"
            ><i class="fas fa-sliders-h"></i><span>Web Layout</span></a
          >
        </div>
      </div>
      -->
      <!--mobile navigation bar end-->
      <!--sidebar start-->
      <div class="sidebar noprint" style="box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);">
        <div class="profile_info">
          
          <avatar
            v-once
            class="mr5"
            color="#fff"
            v-bind:username="firstName + ' ' + lastName"
            backgroundColor="#00d4d3"
          ></avatar>
        
          <h5 class="mb0" style="font-size: 1.2rem">{{ firstName + " " + lastName}}</h5>
          <h6 style="font-size: .9rem">{{ company }}</h6>
        </div>
  
        <div class="sidebar-links">
         <router-link
              to="/home"
              
              class="no-dropdown"
              title="Home"
            >
            
         
              <img alt="Home" class="icons" style="fill: #575757" src="@/assets/img/profile-fill.svg"/>
  
              <span style="margin-left: 10px">Home</span>
            </router-link>
        <router-link to="/listings" v-if="!this.$store.getters.hideOrders" title="Order Listings">
              <img class="icons" style="fill: #575757" src="@/assets/img/funds-box-fill.svg"/>
              <span style="margin-left: 10px">Order Listings</span>
        </router-link>
        <router-link to="/reports" v-if="this.$store.getters.isReports" title="Reports">
              <img class="icons" style="fill: #575757" src="@/assets/img/numbers-fill.svg"/>
              <span style="margin-left: 10px">Reports</span>
        </router-link>
         <router-link to="/cash-management" v-if="this.$store.getters.isCashManagement" title="Cash Management">
              <img class="icons" style="fill: #575757" src="@/assets/img/wallet-2-fill.svg"/>
              <span style="margin-left: 10px">Cash Management</span>
        </router-link>
         <router-link to="/lead-times" v-if="!this.$store.getters.hideOrders" title="Lead Times">
              <img class="icons" style="fill: #575757" src="@/assets/img/newspaper-fill.svg"/>
              <span style="margin-left: 10px">Lead Times</span>
        </router-link>
        <router-link to="/interterritorial" v-if="!this.$store.getters.hideOrders" title="IT Projects">
              <img class="icons" style="fill: #575757" src="@/assets/img/swap-box-fill.svg"/>
              <span style="margin-left: 10px">IT Projects</span>
        </router-link>
         <router-link to="/marketing-materials" title="Marketing Materials">
              <img class="icons" style="fill: #575757" src="@/assets/img/polaroid-2-fill.svg"/>
              <span style="margin-left: 10px">Marketing Materials</span>
        </router-link>
         <router-link to="/service-and-installation" title="Service &amp; Installation">
              <img class="icons" style="fill: #575757" src="@/assets/img/archive-drawer-fill.svg"/>
              <span style="margin-left: 10px">Service &amp; Installation</span>
        </router-link>
         <router-link to="/sales-and-training" title="Sales &amp; Training">
              <img class="icons" style="fill: #575757" src="@/assets/img/honour-fill.svg"/>
              <span style="margin-left: 10px">Sales &amp; Training</span>
        </router-link>
        <router-link
              to="/questions"
              
              class="no-dropdown"
              title="Collaborate"
            >
              <img class="icons" style="fill: #575757" src="@/assets/img/question-answer-fill.svg"/>
              <span style="margin-left: 10px">Questions</span>
            </router-link>
        <router-link to="/ac-list" title="AC Locator">
              <img class="icons" style="fill: #575757" src="@/assets/img/global-fill.svg"/>
              <span style="margin-left: 10px">AC Locator</span>
        </router-link>
  
        <router-link to="/announcements" v-if="this.$store.getters.hideOrders" title="Announcements">
          <img class="icons" style="fill: #575757" src="@/assets/img/article-fill.svg"/>
              <span style="margin-left: 10px">Announcements</span>
        </router-link>
         <router-link to="/from-the-fort" v-if="this.$store.getters.hideOrders" title="From The Fort">
            <img class="icons" style="fill: #575757" src="@/assets/img/book-read-fill.svg"/>
              <span style="margin-left: 10px">From The Fort</span>
        </router-link>
         <router-link to="/trailblazer" v-if="this.$store.getters.hideOrders" title="Trailblazer">
           <img class="icons" style="fill: #575757" src="@/assets/img/bill-fill.svg"/>
              <span style="margin-left: 10px">Trailblazer</span>
        </router-link>
  
                 
  
  
  <!--
         <a v-if="this.$store.state.isDashboard" @click="setLayout('website')"
         style="cursor: pointer"
         title="Classic Layout"
          >
          
          <img class="icons" style="fill: #575757" src="@/assets/img/window-fill.svg"/>
          <span style="margin-left: 10px">Classic Layout</span></a
        >
        <a v-else @click="setLayout('dashboard')"
         style="cursor: pointer"
         title="Classic Layout"
          >
          
          <img class="icons" style="fill: #575757" src="@/assets/img/layout-masonry-fill.svg"/>
          <span style="margin-left: 10px">Dashboard Layout</span></a
        >
  -->
        </div>
  
  <!--
        <a href="#"
          ><i class="fas fa-cogs"></i
          ><span style="margin-left: 10px">Order Listings</span></a
        >
        <a href="#"
          ><i class="fas fa-table"></i
          ><span style="margin-left: 10px">Marketing Materials</span></a
        >
        <a href="#"
          ><i class="fas fa-th"></i
          ><span style="margin-left: 10px">Service & Installation</span></a
        >
        <a href="#"
          ><i class="fas fa-info-circle"></i
          ><span style="margin-left: 10px">Collaborate</span></a
        >
        <a href="#"
          ><i class="fas fa-sliders-h"></i
          ><span style="margin-left: 10px">Reports</span></a
        >
         -->
       
       
      </div>
      <!--sidebar end-->
  
      <div class="pages-inner content" style="padding-top: 88px">
        <div style="background: #f1f6f9 !important">
         <div class="pages-inner">
  
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
  
        </div>
        <footer-new/>
  
  
  
  <!--
          <footer
            v-if="navigationState == true"
            class="footer-bs noprint"
            style="
              min-height: 300px;
              background-size: cover;
              background-position: right;
              background-repeat: no-repeat;
              background: #fff;
            "
          >
            <div class="container">
              <div class="row">
                <div class="col col-12 col-lg-6 footer-social animated fadeIn">
                  <h3>Tools & Resources</h3>
                  <div class="row">
                    <div class="col col-6 col-md-6 footer-social animated fadeIn">
                      <ul class="pages">
                        <li>
                          <img v-if="this.$store.state.isDashboard" class="icons" style="max-width: 20px;
                      margin-right: 5px;" src="@/assets/img/window-line.svg"/>
                          <a v-if="this.$store.state.isDashboard" @click="setLayout('website')" style="cursor:pointer;" class="layout-a">                   
                            Classic Layout
                          </a>
  
                          <img v-if="!this.$store.state.isDashboard" class="icons" style="max-width: 20px;
                      margin-right: 5px;" src="@/assets/img/layout-masonry-line.svg"/>
                          <a v-if="!this.$store.state.isDashboard" @click="setLayout('dashboard')" style="cursor:pointer;" class="layout-a">                        
                            Dashboard Layout
                          </a>
                        </li>
                        <li>
                          <img class="footer-icon" src="@/assets/img/profile-line.svg"/>
                          <a href="/home">Home</a>
                        </li>
                        <li v-if="!this.$store.getters.hideOrders"> 
                         <img class="footer-icon" src="@/assets/img/funds-box-line.svg"/> 
                          <a href="/listings">Order Listings</a>
                        </li>
                         <li v-if="!this.$store.getters.hideOrders"> 
                         <img class="footer-icon" src="@/assets/img/numbers-line.svg"/> 
                          <a href="/reports">Reports</a>
                        </li>                
                         <li v-if="!this.$store.getters.hideOrders"> 
                         <img class="footer-icon" src="@/assets/img/newspaper-line.svg"/> 
                          <a href="/lead-times">Lead Times</a>
                        </li>
                         <li v-if="!this.$store.getters.hideOrders"> 
                         <img class="footer-icon" src="@/assets/img/swap-box-line.svg"/> 
                          <a href="/interterritorial">IT Projects</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col col-6 col-md-6 footer-social animated fadeIn">
                      <ul class="pages">
                        <li>
                          
                          <img class="footer-icon" src="@/assets/img/polaroid-2-line.svg"/> 
                          <a href="/marketing-materials">Marketing Materials</a>
                        </li>
  
                        <li>
                          <img class="footer-icon" src="@/assets/img/archive-drawer-line.svg"/> 
                          <a href="/service-and-installation"
                            >Service &amp; Installation</a
                          >
                        </li>
                        <li>
                          <img class="footer-icon" src="@/assets/img/honour-line.svg"/> 
                          <a href="/sales-and-training">Sales &amp; Training</a>
                        </li>
                        <li>
                          <img class="footer-icon" src="@/assets/img/question-answer-line.svg"/> 
                          <a href="/collaborate">Collaborate</a>
                        </li>
                        <li>
                          <img class="footer-icon" src="@/assets/img/global-line.svg"/> 
                          <a href="/ac-list">AC Locator</a>
                        </li>
                        <li>
                          <img class="footer-icon" src="@/assets/img/article-line.svg"/>
                          <a href="/from-the-fort">From The Fort</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
  
                <div class="col col-6 col-md-6 col-lg-3 footer-social animated fadeIn">
                  <h3>Links</h3>
                  <ul class="pages">
                    <li>
                      <img class="footer-icon" src="@/assets/img/window-line.svg"/>
                      <a
                        target="blank"
                        rel="noopener noreferrer"
                        href="https://www.spacesaver.com"
                        >Spacesaver.com</a
                      >
                    </li>
  
                    <li>
                      
                      <img class="footer-icon" src="@/assets/img/calendar-event-line.svg"/>
                      <a href="http://www.spacesaverweb.com/show/request.html"
                        >Tradeshow Requests</a
                      >
                    </li>
                    <li>
                      <img class="footer-icon" src="@/assets/img/shield-check-line.svg"/>
                      <a
                        target="blank"
                        rel="noopener noreferrer"
                        href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                        >MSDS</a
                      >
                    </li>
  
                    <li>
                      <span
                        ><img
                          class="config-icon2"
                          src="@/assets/img/configura-dashboard.svg"
                      /></span>
                      <a
                        href="https://spacenet3.s3.amazonaws.com/files/ExtConfigura.zip"
                        target="_blank"
                        rel="noopener"
                        download
                        >Configura Download</a
                      >
                    </li>
  
                    <li>
                      <img class="footer-icon" src="@/assets/img/feedback-line.svg"/>
                      <a
                        href="mailto:spacenet3feedback@spacesaver.com?subject=SpaceNET 3 Support"
                        >SpaceNET 3 Feedback</a
                      >
                    </li>
  
                    <li>
                      <img class="footer-icon" src="@/assets/img/store-3-line.svg"/>
                      <a style="cursor: pointer">
                        Spacesaver Store Broken
                      </a>
                    </li>
                    </ul>
                </div>
  
                <div class="col col-6 col-md-6 col-lg-3 footer-social animated fadeIn">
                  <h3>Connect</h3>
                  <ul class="pages">
                    <li>
                      <a
                        href="https://www.facebook.com/SpacesaverCorporation?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211093447"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/facebook-box-line.svg"/>
   Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/spacesaver?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211111802"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/twitter-line.svg"/>
   Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/spacesavercorp/?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211203545"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/instagram-line.svg"/>
   Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHCWdnxP_QUcwAAAW2X3UnIF4XrPtAON3R9A6hA7CjHPfV0jZGifaH7nFR9LWPnaTVb3rGXagr0-c8rGUshhvE4ra5U5TjGJbrC0YWHsq445CgOOOole1Nss1E6bDgUq1s1ntU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F56990%3Ftrk%3Dtyah%26ss_uuid%3Dngy2y2fj-ea-5205164%26ss_tmidx%3D1570211044945"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/linkedin-box-line.svg"/>
   Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/user/SpacesaverCorp"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/youtube-line.svg"/>
  Youtube
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/spacesaver/"
                        target="_blank"
                        rel="noopener"
                      >
                      <img class="footer-icon" src="@/assets/img/pinterest-line.svg"/>
   Pinterest
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
  
              <hr style="border-top: solid 1px rgba(255, 255, 255, 0.1)" />
              <div class="text-center">
                <ul class="ls-none">
                  <li>
                    <small class="terms">
                      <a
                        href="https://spacesaver.widen.net/view/pdf/jnqqq9qjk4/spacesaver-terms-and-conditions.pdf?t.download=true&u=sphzbh"
                        target="_blank"
                        rel="nooopener noreferrer"
                        >Order Terms &amp; Conditions</a
                      >
                    </small>
                  </li>
                  <li>
                    <small class="terms">
                      <a
                        href="https://spacesaver.widen.net/view/pdf/ookgx9pq3c/spacesaver-statement-of-warranty.pdf?t.download=true&u=sphzbh"
                        target="_blank"
                        rel="nooopener noreferrer"
                        >Statement of Warranty</a
                      >
                    </small>
                  </li>
                  <li>
                    <small class="terms">
                      <a
                        href="https://spacesaver.widen.net/view/pdf/7qmnznhs7r/spacesaver-return-goods-policy.pdf?t.download=true&u=sphzbh"
                        target="_blank"
                        rel="nooopener noreferrer"
                        >Return Goods Policy</a
                      >
                    </small>
                  </li>
                </ul>
              </div>
  
              <div class="text-center copyright">
                <p>
                  <small>
                    Copyright © {{ getyear }} Spacesaver Corporation, All Rights
                    Reserved
                  </small>
                </p>
              </div>
            </div>
          </footer>
          -->
        </div>
      </div>
    </div>
</template>

<script>
import MainNavbarDEV from "@/components/MainNavbarDEV.vue";
import FooterNew from '../Footers/FooterNew.vue';
import Avatar from '../Atoms/Avatar.vue'

export default {
    name: "DefaultLayout",
    components: {
    Avatar,
    MainNavbarDEV,
    FooterNew
  },
  props: [],
  data() {
    return {
      sideClosed: false,
    };
  },
  methods: {
    setLayout(layout) {
      localStorage.removeItem("SSCview");
      localStorage.setItem("SSCview", layout);
      this.$store.commit("SET_LAYOUT", layout);
      console.log('Switched');
      //this.$forceUpdate();
      this.$router.go(0);
    },
     beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
  computed: {
    colorGetter() {
      return this.$store.state.colors;
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    company() {
      return this.$store.state.user.Company;
    },
    navigationState() {
      return this.$store.state.nav;
    },
     getyear() {
      return new Date().getFullYear();
    },
  },
};

</script>




<style scoped>
/* start sidebar */
header {
  z-index: 1;
  position: fixed;
  width: calc(100% - 0%);
  top: 0;
  height: 30px;
  height: 70px;
  background: #fff;
}

.left_area h3 {
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
}

.left_area span {
  color: #19b3d3;
}

.logout_btn {
  padding: 5px;
  background: #19b3d3;
  text-decoration: none;
  float: right;
  margin-top: -30px;
  margin-right: 40px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: 0.5s;
  transition-property: background;
}

.logout_btn:hover {
  background: #0b87a6;
}

.sidebar {
  z-index: .5;
  top: 0;
  background: #fff;
  margin-top: 70px;
  padding-top: 10px;
  position: fixed;
  left: 0;
  width: 250px;
  height: 100%;
  transition: 0.5s;
  transition-property: left;
  overflow-y: auto;
  font-weight: 300;
}

.profile_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar .profile_info .profile_image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-bottom: 10px;
}

.sidebar .profile_info h4 {
  color: #575757;
  margin-top: 0;
  margin-bottom: 20px;
}

.sidebar a {
  color: #575757;
  display: block;
  width: 100%;
  line-height: 50px;
  text-decoration: none;
  padding-left: 40px;
  box-sizing: border-box;
  transition: 0.5s;
  transition-property: background;
}

.sidebar a:hover {
  background: #f1f6f9;
}

.sidebar i {
  margin-right: 10px;
}

label #sidebar_btn {
  z-index: 1;
  color: #000;
  position: fixed;
  cursor: pointer;
  left: 214px;
  top: 16px;
  font-size: 20px;
  margin: 5px 0;
  transition: 0.5s;
  transition-property: color;
}

label #sidebar_btn:hover {
  color: #19b3d3;
}

#check:checked ~ .sidebar {
  left: -185px;
}

#check:checked ~ .sidebar a span {
  display: none;
}

#check:checked ~ .sidebar a {
  font-size: 20px;
  margin-left: 165px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content {
  /*
  width: calc(100% - 250px);
  margin-top: 60px;
  */
  padding: 20px;
  margin-left: 250px;
  transition: 0.3s;
  background: #e7edf2;
}

#check:checked ~ .content {
  margin-left: 60px;
}

#check:checked ~ .sidebar .profile_info {
  display: none;
}

#check {
  display: none;
}

.mobile_nav {
  display: none;
}
.sidebar-links{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px)
}

#check:checked ~ .sidebar a:hover{
  background: #fff;
}

/* conditionally show the sidebar controls */
/*
#check:checked .open-icon {
  display: none;
}
*/

/* Responsive CSS */

@media screen and (max-width: 991px) {
  .sidebar {
    display: none;
  }

  #sidebar_btn {
    display: none;
  }

  .content {
    margin-left: 0;
    margin-top: 0;
    padding: 10px 20px;
    transition: 0s;
  }

  #check:checked ~ .content {
    margin-left: 0;
  }

  .mobile_nav {
    display: block;
    width: calc(100% - 0%);
  }

  .nav_bar {
    background: #222;
    width: calc(100% - 0px);
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .nav_bar .mobile_profile_image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .nav_bar .nav_btn {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s;
    transition-property: color;
  }

  .nav_bar .nav_btn:hover {
    color: #19b3d3;
  }

  .mobile_nav_items {
    background: #2f323a;
    display: none;
  }

  .mobile_nav_items a {
    color: #fff;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 60px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
  }

  .mobile_nav_items a:hover {
    background: #19b3d3;
  }

  .mobile_nav_items i {
    padding-right: 10px;
  }

  .active {
    display: block;
  }
}

.footer-bs .footer-social a {
  color: #575757;
}
.pages span {
  color: #575757;
  margin-right: 5px;
}
.sidebar .icons{
  max-width: 20px;
}
.footer-icon{
    max-width: 20px;
    margin-right: 5px;
}
.db-tooltip {
  position:relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom:1px dashed #000; /* little indicater to indicate it's hoverable */
}
.db-tooltip:before {
  content: attr(data-text); /* here's the magic */
  position:absolute;
  
  /* vertically center */
  top:50%;
  transform:translateY(-50%);
  
  /* move to right */
  left:100%;
  margin-left:15px; /* and add a small left margin */
  
  /* basic styles */
  width:200px;
  padding:10px;
  border-radius:10px;
  background:#000;
  color: #fff;
  text-align:center;

  display:none; /* hide by default */
}
.db-tooltip:hover:before {
  display:block;
}
.db-tooltip:before {
  /* other styles */

  /*  display:none; */

  opacity:0;
  transition:.3s opacity;   
}
.db-tooltip:hover:before {
  opacity:1;
}
.config-icon2{
    max-width: 19px;
    margin-right: 0px;
}
.terms a:hover, .terms a:active {
    color: #575757;
    text-decoration: none;
}
.footer-social a:hover, .footer-social a:active{
  color: #b5b5b5;
}
.layout-a:hover, .layout-a:active{
  color: #b5b5b5!important;
}
.sidebar{
  z-index: .5; 
}
</style>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>

