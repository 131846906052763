<template>
  <div class="page-listings">
    
    <leadtimes-tour v-if="$store.state.tour & !loading" />
    
    <banner-2 
      title="Spacesaver Lead Times" 
      linkTarget="/lead-time-history"
      linkText="Lead Time History"
      loadingText="Loading Lead Times...."
      :loading="loading"
      desc="Lead times are approximate and subject to change, based on order volume and the exact product mix on your order. Please use them as a guide for planning purposes only."
    />
    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredLeadTimes.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div class="sorry-box">
            <img src="@/assets/img/sad.svg" />
            <h3>Sorry, no Lead Time was found...</h3>
            <button class="btn-primary" @click="firstLoad">
              Show me some Lead Times
            </button>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div class="mt-3 mb-3">
            <div class="listing">
              <h5>
                IMPORTANT: Orders with special product will have additional lead
                time to allow for custom design and part setup in our system.
              </h5>
              <hr />
              <p>
                Please verify the quote you received from your Sales Support Rep
                for the amount of additional lead time to be added to your
                order. Orders with custom or special colors will have additional
                lead time to allow for color matching and paint manufacturing.
              </p>
              <p>
                Custom colors please plan for an additional 3 weeks in addition
                to standard lead time. Special Colors please plan for an
                additional 4 – 6 weeks in addition to standard lead time.
                Spacesaver will issue a formal order acknowledgment and/or
                confirmation in writing when the final ship date has been
                assigned to your order.
              </p>
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- mobile lead times -->
              <h2 class data-config-id="header">ActivRAC</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Lead Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadTime.OrderNumber"
                      v-for="(leadTime, index) in ActiveRACLeadTimes"
                    >
                      <td :id="index === 0 ? 'lead-step-1' : ''">
                        {{ leadTime.productDescription }}
                      </td>
                      <td
                        v-if="leadTime.callForLeadTime === false"
                        :id="index === 0 ? 'lead-step-2' : ''"
                      >
                        {{
                          leadTime.leadTime +
                          " Weeks, " +
                          leadTime.startDate +
                          " - " +
                          leadTime.endDate
                        }}
                      </td>
                      <td v-else>Call Customer Service for Availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- footnotes -->
              <div style="margin-top: 10px">
                <div
                  v-bind:key="leadTime.OrderNumber"
                  v-for="leadTime in ActiveRACLeadTimes"
                >
                  <p class="mb5">{{ leadTime.footnote }}</p>
                </div>
              </div>
              <!-- end footnotes -->
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- mobile lead times -->
              <h2 class data-config-id="header">Mobile</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Lead Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadTime.OrderNumber"
                      v-for="(leadTime, index) in mobileLeadTimes"
                    >
                      <td>{{ leadTime.productDescription }}</td>
                      <td v-if="leadTime.callForLeadTime === false">
                        {{
                          leadTime.leadTime +
                          " Weeks, " +
                          leadTime.startDate +
                          " - " +
                          leadTime.endDate
                        }}
                      </td>
                      <td v-else>Call Customer Service for Availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- footnotes -->
              <div style="margin-top: 10px">
                <div
                  v-bind:key="leadTime.OrderNumber"
                  v-for="leadTime in mobileLeadTimes"
                >
                  <p class="mb5">{{ leadTime.footnote }}</p>
                </div>
              </div>
              <!-- end footnotes -->
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- Shelving -->
              <h2 class data-config-id="header">Shelving</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Lead Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadTime.OrderNumber"
                      v-for="(leadTime, index) in shelvingLeadTimes"
                    >
                      <td>{{ leadTime.productDescription }}</td>
                      <td v-if="leadTime.callForLeadTime === false">
                        {{
                          leadTime.leadTime +
                          " Weeks, " +
                          leadTime.startDate +
                          " - " +
                          leadTime.endDate
                        }}
                      </td>
                      <td v-else>Call Customer Service for Availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- footnotes -->
              <div style="margin-top: 10px">
                <div
                  v-bind:key="leadTime.OrderNumber"
                  v-for="leadTime in shelvingLeadTimes"
                >
                  <p class="mb5">{{ leadTime.footnote }}</p>
                </div>
              </div>
              <!-- end footnotes -->
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- Storage -->
              <h2 class data-config-id="header">Storage</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Lead Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadTime.OrderNumber"
                      v-for="(leadTime, index) in storageLeadTimes"
                    >
                      <td>{{ leadTime.productDescription }}</td>
                      <td v-if="leadTime.callForLeadTime === false">
                        {{
                          leadTime.leadTime +
                          " Weeks, " +
                          leadTime.startDate +
                          " - " +
                          leadTime.endDate
                        }}
                      </td>
                      <td v-else>Call Customer Service for Availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- footnotes -->
              <div style="margin-top: 10px">
                <div
                  v-bind:key="leadTime.OrderNumber"
                  v-for="leadTime in storageLeadTimes"
                >
                  <p class="mb5">{{ leadTime.footnote }}</p>
                </div>
              </div>
              <!-- end footnotes -->
            </div>
          </div>

          <div v-if="miscLeadTimes.length" class="mt-3 mb-3">
            <div class="listing">
              <!-- Storage -->
              <h2 class data-config-id="header">Misc.</h2>
              <div class="rfr table-responsive">
                <table class="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Lead Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-bind:key="leadTime.OrderNumber"
                      v-for="(leadTime, index) in miscLeadTimes"
                    >
                      <td>{{ leadTime.productDescription }}</td>
                      <td v-if="leadTime.callForLeadTime === false">
                        {{
                          leadTime.leadTime +
                          " Weeks " +
                          leadTime.startDate +
                          " - " +
                          leadTime.endDate
                        }}
                      </td>
                      <td v-else>Call Customer Service for Availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- footnotes -->
              <div style="margin-top: 10px">
                <div
                  v-bind:key="leadTime.OrderNumber"
                  v-for="leadTime in miscLeadTimes"
                >
                  <p class="mb5">{{ leadTime.footnote }}</p>
                </div>
              </div>
              <!-- end footnotes -->
            </div>
          </div>

          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- mobile lead times -->
              <h2 class data-config-id="header">
                QUICKSHIP LEAD TIME GUIDELINES:
              </h2>
              <hr />

              <h6>Shelving (4 Post/Case only)</h6>
              <ul>
                <li>10 day turn-around (orders up to $10,000 List)</li>
                <ul style="margin-top: 5px; margin-bottom: 5px">
                  <li>Contact Customer Service for lead time on larger orders. Multiple orders may increase lead time pending part availability.</li>
                </ul>
                <li>Available in Tan, Frost, and Furniture White only.</li>
              </ul>
              <hr />
              <h6>General</h6>
              <ul>
                <li>
                  Configura will detail specific parts on this program when
                  QuickShip is selected on the Account 2 of 2 Tab in the Order
                  Information Screens
                </li>
                <li>
                  Orders MUST be submitted to Spacesaver by 11am CST. (ex: Case
                  Shelving order submitted by 11 a.m. on Monday will ship the
                  following Monday. Same order received at 12pm will ship the
                  following Tuesday). The day the order is received is not
                  included in the lead time days.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import leadtimesTour from "../components/Tours/leadtimesTour.vue";
import LeadTimes from "../components/shared/LeadTimes.js";
import Banner2 from "../components/Banners/banner2.vue";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "LeadTimes",
  components: {
    AtomSpinner,
    // Avatar,
    // Datepicker,
    leadtimesTour,
    Banner2
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: null,
      endDate: null,
      searchStarted: false,
      acs: [],
      leadtimes: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noRGAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = "";
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      this.showDisclaimer();
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/leadtimes/`, auth)
        .then((response) => {
          //console.log(response);
          this.leadtimes = response.data;
          this.loading = false;
        },(error) => {
            console.log(error);
            localStorage.removeItem("SSCUSER");
            this.$router.push("/");
        })
        .then(() => {
          return axios

            .get(`${this.ordermanageapi}/aclist?active=true`, auth)
            .then((response, acs) => {
              //console.log(response);
              this.acs = response.data;
              this.loading = false;
            });
        });
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredLeadTimes: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.leadtimes.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.leadtimes.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.leadtimes;
      }
    },

    foundNoRGA: function () {
      if (this.search.length > 1 && this.filteredLeadTimes.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.leadtimes.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.rfrs
      ) {
        //console.log("fired off");
        this.rfrs = this.storedListings;
      }
      return;
    },


    mobileLeadTimes: function () {
      var filteredData = this.leadtimes.filter(function (el) {
        return el.productCategory === "Mobile" && el.isViewable === true;
      });
      let sorted = filteredData.sort((a, b) =>
        a.productDescription.localeCompare(b.productDescription)
      );
      return sorted;
    },

    
    shelvingLeadTimes: function () {
      var filteredData = this.leadtimes.filter(function (el) {
        return el.productCategory === "Shelving" && el.isViewable === true;
      });
      let sorted = filteredData.sort((a, b) =>
        a.productDescription.localeCompare(b.productDescription)
      );
      return sorted;
    },
    storageLeadTimes: function () {
      var filteredData = this.leadtimes.filter(function (el) {
        return el.productCategory === "Storage" && el.isViewable === true;
      });
      let sorted = filteredData.sort((a, b) =>
        a.productDescription.localeCompare(b.productDescription)
      );
      return sorted;
    },
    miscLeadTimes: function () {
      var filteredData = this.leadtimes.filter(function (el) {
        return el.productCategory === "" && el.isViewable === true;
      });
      let sorted = filteredData.sort((a, b) =>
        a.productDescription.localeCompare(b.productDescription)
      );
      return sorted;
    },
    ActiveRACLeadTimes: function () {
      var filteredData = this.leadtimes.filter(function (el) {
        return el.productCategory === "ActivRAC" && el.isViewable === true;
      });
      let sorted = filteredData.sort((a, b) =>
        a.productDescription.localeCompare(b.productDescription)
      );
      return sorted;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    this.showDisclaimer = LeadTimes.showDisclaimer; // now you can call this.showEula() (in your functions/template)
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.leadTime {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.leadTime-bottom {
  align-items: flex-end;
}
.leadTime-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: none;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.table-striped td:last-of-type {
  text-align: right;
}
.table-striped th:last-of-type {
  text-align: right;
}
.table-bordered td:first-of-type {
  border-right: none !important;
}
.table-bordered th:first-of-type {
  border-right: none !important;
}
.table-bordered td:last-of-type {
  border-left: none !important;
}
.table-bordered th:last-of-type {
  border-left: none !important;
}

.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search {
  margin-left: 5px;
}
.search span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search:hover {
  background: #586575;
  text-decoration: none;
}
.search:active {
  background: #586575;
  text-decoration: none;
}
.search:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search:disabled:hover {
  background: #4d5967;
}
.search:disabled:active {
  background: #4d5967;
}
@media all and (max-width: 991px) {
  .listing {
    overflow: scroll;
  }
  table {
    width: 800px !important;
    overflow: scroll;
  }
}
</style>
