<template>
	<div class="coolInstall-header d-flex align-items-center p-3 mt1r rounded bs-shadow">
		<form enctype="multipart/form-data">
			<div class="file is-boxed is-primary" style="text-align: center">
				<!--
				<input
					multiple
					type="file"
					ref="files"
					class="file-input"
					aria-describedby="upload files"
					placeholder="Upload Files"
					@change="selectFile"
					style="display: none"
					id="img"
					accept="image/png, image/jpeg"
				/>
                -->
				<input
					v-if="files.length > 10"
					disabled
					multiple
					type="file"
					ref="files"
					class="file-input"
					aria-describedby="upload files"
					placeholder="Upload Files"
					@change="selectFile"
					style="display: none"
					id="img"
				/>
				<input
					v-else
					multiple
					type="file"
					ref="files"
					class="file-input"
					aria-describedby="upload files"
					placeholder="Upload Files"
					@change="selectFile"
					style="display: none"
					id="img"
					accept="image/heic,
				image/jpeg, image/png"
				/>

				<label for="img" class="label file-input">
					<span class="file-cta">
						<span class="material-icons"> insert_photo </span>

						<div class="file-label">Choose Files</div>
					</span>
				</label>
			</div>

			<button
				v-if="fileCountError.length"
				disabled
				type="submit"
				class="btn btn-primary flex-btn"
                style="align-items: center; display: flex;"
			>
				<span class="material-icons"> publish </span>
				Upload
			</button>
			<button
				v-else-if="files.length && fileCountError == ''"
				@click.prevent="sendFile()"
				type="submit"
				class="btn btn-primary flex-btn"
				style="align-items: center; display: flex;"
			>
				<span class="material-icons"> publish </span>
				Upload
			</button>
			<div
				v-if="message && fileCountError == ''"
				:class="`message ${error ? 'text-danger' : 'text-success'}`"
				style="font-style: italic"
			>
				{{ message }}
			</div>
			<div
				v-else-if="fileCountError.length"
				:class="`message ${error ? 'text-danger' : 'text-success'}`"
				style="font-style: italic"
			>
				{{ fileCountError }}
			</div>
			<div v-if="files.length && !sending">
				<hr />
				<div
					v-for="(file, index) in files"
					:key="index"
					:class="`edit-list ${file.invalidMessage && 'text-danger'}`"
				>
					<div class="file-left">
						<div class="file-item">
							{{ file.name
							}}<span v-if="file.invalidMessage"
								>&nbsp;- <small>{{ file.invalidMessage }}</small></span
							>
						</div>
					</div>
					<div class="file-right">
						<div class="file-item">
							<button @click.prevent="removeFromList(index)">
								<span class="material-icons"> clear </span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="sending">
				<h5>Please be patient, we are processing your files</h5>
			</div>

			<div v-if="uploadedImages.length" class="image-wrap">
				<div
					v-for="image in uploadedImages"
					:key="image.file"
					class="image-box"
					v-bind:style="{
						backgroundImage:
							'url(https://spacenet3.s3.amazonaws.com/' +
							image.file +
							')',
					}"
					style="background-size: cover; background-position: center;"
				>
					<!--
					<img
						v-bind:src="
							'https://spacenet-v3-images.s3.us-east-2.amazonaws.com/' +
								image.file
						"
					/>
                    -->
				</div>
			</div>
			<!--
			<div v-if="file" class="file-name">{{ file.name }}</div>
            -->
		</form>
	</div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
	name: "MultipleUploadAws",
	components: {},
	data() {
		return {
			server: process.env.VUE_APP_SERVER_URL,
			files: [],
			uploadFiles: [],
			message: "",
			error: false,
			fileCountError: "",
			allowFileCount: 15,
			sending: false,
			uploadedImages: [],
		};
	},
	methods: {
		selectFile() {
			this.disabled = false;
			this.message = "";
			//
			const files = this.$refs.files.files;
			if (files.length > this.allowFileCount) {
				this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
			} else {
				this.fileCountError = "";
			}

			//set files to files that are already there, and add the files that the user just selected
			//the actual javascript files object to be uploaded
			this.uploadFiles = [...this.uploadFiles, ...files];

			this.files = [
				...this.files,
				..._.map(files, (file) => ({
					name: file.name,
					size: file.size,
					type: file.type,
					invalidMessage: this.validate(file),
				})),
			];
		},
		validate(file) {
			const MAX_SIZE = 12 * 1024 * 1024; // 4 MB
			const allowedTypes = [
				"image/jpeg",
				"image/png",
				"image/gif",
				"image/heic",
			];
			if (file.size > MAX_SIZE) {
				this.error = true;
				this.message = "Problematic files will be ignored";
				return `The max file size is 7mb`;
			}

			if (file.name.includes(".exe") || file.name.includes(".dmg")) {
				this.error = true;
				this.message = "Problematic files will be ignored";
				return `An executable file, really?`;
			}

			if (!allowedTypes.includes(file.type)) {
				this.error = true;
				this.message = "Problematic files will be ignored";
				return `Sorry, but this file isn't an image`;
			}
			return "";
		},
		async sendFile() {
			// the address "https://spacenet-v3-images.s3.us-east-2.amazonaws.com/1601659175930-42792795_1946666015413774_7693230761595371520_n.jpg"
			this.sending = true;
			this.message = "Please be patient, we are processing your files";
			const formData = new FormData();



			console.log("form data " + formData);
			//formData.append("file", this.file);
			_.forEach(this.uploadFiles, (file) => {
				if (this.validate(file) === "") {
					formData.append("files", file);
				}
			});

			axios
				.post(`${this.server}/api/v1/cool-installs/upload`, formData)
				.then((response) => {
					this.sending = false;
					console.log(response);
					this.uploadedImages = response.data.files;
					this.message = "Files have been uploaded!";
					this.files = [];
					this.uploadFiles = [];
					this.error = false;
					console.log("posting");
				})
				.catch((err) => {
					this.sending = false;
					console.log(err);
					this.message = err.response.data.error;
					this.message = "something went wrong";
					this.error = true;
				});

			/*
			try {
				await axios.post("http://localhost:3344/multiple", formData);
				this.message = "Files have been uploaded!";
				this.files = [];
				this.uploadFiles = [];
				this.error = false;
				//console.log("posting");
			} catch (err) {
				console.log(err);
				this.message = err.response.data.error;
				this.message = "something went wrong";
				this.error = true;
      }
      */
		},
		removeFromList(index) {
			this.files.splice(index, 1);
			this.uploadFiles.splice(index, 1);
			if (this.files.length > this.allowFileCount) {
				this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
			} else {
				this.fileCountError = "";
				/*
				_.forEach(this.uploadFiles, (file) => {
					if (this.validate(file) !== "") {
						this.message = "Problematic files will be ignored";
					} else {
						this.message = "Good to go!";
					}
                });
                */
			}
		},
	},
	computed: {
		watchFileCount: function() {
			if (this.uploadFiles.length > this.allowFileCount) {
				this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
			} else {
				this.fileCountError = "";
			}
		},
	},
};
</script>
<style>
.file-input {
	background: #00c7c7;
	padding: 20px;
	border-radius: 4px;
	color: #fff;
}
.file-input .material-icons {
	font-size: 2em;
}
.flex-btn {
	display: flex;
	justify-content: center;
	align-content: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0.5rem;
}
.text-success {
	color: #00c7c7 !important;
}
.edit-list {
	display: flex;
	padding: 5px 10px;
	background: #f5f5f5;
	margin-bottom: 5px;
	border-radius: 4px;
}
.edit-list .file-right {
	margin-left: auto;
}
.edit-list .file-left {
	margin-right: 20px;
	text-align: left;
}
.file-item button {
	border: none;
	border-radius: 50%;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #00c7c7;
	color: #fff;
	cursor: pointer;
	border: none !important;
	outline: none !important;
}
.file-item .material-icons {
	line-height: 1;
	font-size: 16px;
}
button:disabled {
	background: rgba(0, 0, 0, 0.2) !important;
	border: none !important;
}
.image-wrap {
	display: flex;
	flex-wrap: wrap;
}
.image-box {
	width: 150px;
	height: 150px;
	overflow: hidden;
	margin: 0 10px 10px 0;
}
.image-box img {
	width: 100%;
	height: auto;
}
</style>
