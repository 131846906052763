<template>
  <div class="page-listings">
    
    <eoadd-tour v-if="$store.state.tour" />
    
    <div>
      <section class="py-5 bg9 bs-shadow mobile-top">
        <div class="container">
          <h1 class="text-white mb0 lh1">Add an Expedited Order</h1>
          <h5 class="text-white lh1 mb0">Order #: {{ orderId }}</h5>
        </div>
      </section>
      <div class="container">
        <div class="d-flex number-showing mt20">
          <back-button/>
          <!--
          <router-link
            :to="{ path: $store.state.route.from.fullPath }"
            class="mb0 lh1 mrauto list-btn dib d-flex center"
          >
            <i class="material-icons">keyboard_arrow_left</i>
            Back to Order
          </router-link>
        -->
        </div>
      </div>
    </div>

    <section class="py-5 bs-shadow mobile-top">
      <div class="container">
        <div class="loader teal">
          <div
            
            v-bind:class="progressWidth"
            class="progress-bar"
          >
            <div class="progress-stripes"></div>
            <div class="percentage">{{ progressPercentage }}</div>
          </div>
        </div>

        <transition
          enter-active-class="animated fadeIn

"
          leave-active-class="animated fadeOut"
          :duration="500"
          mode="out-in"
          appear
        >
          <div v-if="step == 1" class="step1" key="step1">
            <div class="listing">
              <h5>
                <span class="teal">Step 1:</span> Shipping Information
                <span class="teal">(required)</span>
              </h5>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Company Name</label>
                    <input
                      id="EOadd-step-1"
                      type="text"
                      class="form-control"
                      aria-describedby="Company Name"
                      placeholder="Enter Company Name"
                      v-model="companyName"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Address</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter Address..."
                      v-model="address"
                      required
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="exampleInputEmail1">City</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="City"
                      placeholder="Enter City"
                      v-model="city"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">State</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="State"
                      placeholder="Enter State"
                      v-model="state"
                      required
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Zip Code"
                      placeholder="Enter Zip Code"
                      v-model="zipcode"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Expectations</label>
                    <!--
                    <select
                      v-model="expectations"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" class="all-contractors">
                        Select an expectation
                      </option>
                      <option value="Less than 10 days" class="all-contractors">
                        Less than 10 days
                      </option>
                      <option
                        value="Greater than 10 days"
                        class="all-contractors"
                      >
                        Greater than 10 days
                      </option>
                    </select>
                    -->

                    <select
                      v-model="expectations"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                      -->
                      <option
                        v-for="expectation in expectationTypes"
                        :key="expectation.id"
                      >
                        {{ expectation.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">Carrier</label>

                    <select
                      v-if="this.responsible == 'SSC responsible'"
                      disabled
                      v-model="carrier"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                      -->
                      <option>Carrier by Spacesaver</option>
                    </select>
                    <select
                      v-else
                      v-model="carrier"
                      class="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                      -->
                      <option v-for="carrier in carriers" :key="carrier.id">
                        {{ carrier.value }}
                      </option>
                    </select>
                  </div>

                  <div v-if="carrier == 'Other Carrier'" class="form-group">
                    <label for="exampleInputEmail1">Other Carrier</label>

                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Other Carrier Name"
                      placeholder="Enter Carrier Name"
                      v-model="otherCarrier"
                      required
                    />
                  </div>
                  <div class="custom-control custom-radio mb10">
                    <input
                      type="radio"
                      id="rd_2"
                      name="rd"
                      class="custom-control-input"
                      value="AC responsible"
                      v-model="responsible"
                      @click="unSetResponsible()"
                    />
                    <label class="custom-control-label red" for="rd_2"
                      >AC responsible for shipping costs for charge orders, or
                      to upgrade to next day service for no-charge orders</label
                    >
                  </div>
                  <div class="custom-control custom-radio mb10">
                    <input
                      type="radio"
                      id="rd_1"
                      name="rd"
                      class="custom-control-input"
                      value="SSC responsible"
                      v-model="responsible"
                      @click="setResponsible()"
                    />
                    <label class="custom-control-label green" for="rd_1"
                      >SSC responsible for shipping costs for no-charge
                      replacement parts via standard ground service</label
                    >
                  </div>
                  <small>{{ this.disclaimer }}</small>
                </div>
              </div>
              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                    <button v-if="this.step === 1" disabled class="mrauto">
                      <i class="material-icons">keyboard_arrow_left</i> Back
                    </button>

                    <button
                      v-else
                      @click.prevent="setPage(step - 1)"
                      class="mrauto"
                    >
                      <i class="material-icons">keyboard_arrow_left</i> Back
                    </button>
                    <button
                      v-if="
                        !this.companyName.length ||
                        !this.address.length ||
                        !this.city.length ||
                        !this.state.length ||
                        !this.zipcode.length ||
                        this.expectations == 'Select One' ||
                        this.carrier == 'Select One' ||
                        (this.carrier == 'Other Carrier' &&
                          !otherCarrier.length)
                      "
                      disabled
                      class="mlauto"
                    >
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button
                      v-else
                      @click.prevent="setPage(step + 1)"
                      class="mlauto"
                    >
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 2" class="step2 listing" key="step2">
            <h5>
              <span class="teal">Step 2:</span> Parts
              <span class="teal">(at least one required)</span>
            </h5>
            <hr />
            <div class="row">
              <div class="col-xs-12 col-md-6 col-12">
                <div class="form-group">
                  <label for="exampleInputEmail1">Part #</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="Part Number"
                    placeholder="Enter Part Number"
                    v-model="partNum"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Quantity</label>
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="Quantity"
                    placeholder="Enter Quantity"
                    v-model="quantity"
                    required
                  />
                </div>
              </div>
              <div class="col-xs-12 col-md-6 col-12">
                <div class="form-group">
                  <label for="exampleInputEmail1">Color</label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="Color"
                    placeholder="Enter Color"
                    v-model="color"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Reason</label>
                  <select
                    v-model="reason"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                    -->
                    <option v-for="reason in reasons" :key="reason.id">
                      {{ reason.value }}
                    </option>
                  </select>
                  <small class="text-muted"
                    >Need help deciding on a reason?
                    <span
                      @click="openLightbox()"
                      style="cursor: pointer"
                      class="teal"
                      >Show me the reason definitions</span
                    ></small
                  >
                </div>
              </div>
              <div class="col-xs-12 col-md-12 col-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Notes</label>

                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    v-model="notes"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1" style="opacity: 0"
                    >Add part
                  </label>
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      rounded
                      b-shadow
                    "
                  >
                    <button
                      v-if="
                        this.partNum.length &&
                        this.quantity &&
                        this.color.length &&
                        this.reason != 'Select One'
                      "
                      @click="submitTask"
                      class="mlauto"
                    >
                      Submit Part <i class="material-icons">add</i>
                    </button>
                    <button v-else disabled @click="submitTask" class="mlauto">
                      Submit Part
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="todos.length" class="row">
              <div class="col col-12">
                <hr v-if="todos.length" />
                <!--
								<ul>
									<li v-for="(todo, index) in todos" v-bind:key="index">
										<div>
											
							<input type="checkbox" v-model="todo.completed" />
                            
											{{ todo.partNumber }}
										</div>
										<button v-on:click="remove(index)">delete</button>
									</li>
								</ul>
                -->
                <div class="table-responsive">
                  <table
                    class="table-striped table-bordered"
                    style="display: table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Part #</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Color</th>
                        <th scope="col">Reason</th>
                        <th scope="col" class="col-1">Notes</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        scope="row"
                        v-for="(todo, index) in todos"
                        v-bind:key="index"
                      >
                        <td>
                          <input
                            type="text"
                            v-model="todo.partNumber"
                            style="padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            v-model="todo.quantity"
                            style="padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="todo.color"
                            style="padding: 10px"
                          />
                        </td>
                        <td style="min-width: 200px">
                          <select
                            v-model="todo.reason"
                            class="form-control"
                            id="exampleFormControlSelect1"
                            style="font-size: 13px"
                          >
                            <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                    -->
                            <option v-for="reason in reasons" :key="reason.id">
                              {{ reason.value }}
                            </option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            value="todo.partNumber"
                            v-model="todo.notes"
                            style="width: 100%; padding: 10px"
                          />
                        </td>
                        <td>
                          <button class="delete-btn" v-on:click="remove(index)">
                            <span class="material-icons"> delete </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr />
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <div
                  class="
                    nav-btns
                    listing-header
                    d-flex
                    align-items-center
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >
                  <button v-if="this.step === 1" disabled class="mrauto">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-else
                    @click.prevent="setPage(step - 1)"
                    class="mrauto"
                  >
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.todos.length < 1" disabled class="mlauto">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button
                    v-else
                    @click.prevent="setPage(step + 1)"
                    class="mlauto"
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 3" class="step3 listing" key="step3">
            <h5>
              <span class="teal">Step 3:</span> Notes
              <span class="teal">(optional)</span>
            </h5>
            <hr />
            <div class="row">
              <div class="col-xs-12 col-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >Please provide your salesforce.com case #</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="Salesforce case number"
                    placeholder="Enter Salesforce case number"
                    v-model="caseNumber"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >Notes on the parts request</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="8"
                    v-model="overallNotes"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <div
                  class="
                    nav-btns
                    listing-header
                    d-flex
                    align-items-center
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >
                  <button v-if="this.step === 1" disabled class="mrauto">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-else
                    @click.prevent="setPage(step - 1)"
                    class="mrauto"
                  >
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-if="this.step === 5" class="mlauto">
                    Submit
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button v-else @click.prevent="submitOrder" class="mlauto">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 4" class="step4 listing" key="step4">
            <form>
              <h5>
                <span class="teal">Step 4:</span> Attachments
                <span class="teal">(optional)</span>
              </h5>
              <hr />
              <div class="row">
                <div class="col col-12">
                  <form enctype="multipart/form-data">
                    <div
                      class="file is-boxed is-primary"
                      style="text-align: center"
                    >
                      <!--
				<input
					multiple
					type="file"
					ref="files"
					class="file-input"
					aria-describedby="upload files"
					placeholder="Upload Files"
					@change="selectFile"
					style="display: none"
					id="img"
					accept="image/png, image/jpeg"
				/>
                -->
                      <input
                        v-if="files.length > 5"
                        disabled
                        multiple
                        type="file"
                        ref="files"
                        class="file-input"
                        aria-describedby="upload files"
                        placeholder="Upload Files"
                        @change="selectFile"
                        style="display: none"
                        id="img"
                      />
                      <input
                        v-else
                        multiple
                        type="file"
                        ref="files"
                        class="file-input"
                        aria-describedby="upload files"
                        placeholder="Upload Files"
                        @change="selectFile"
                        style="display: none"
                        id="img"
                        accept=".txt,.pdf,.jpg,.png,.jpeg,.om,.msg"
                      />

                      <label
                        for="img"
                        class="label file-input"
                        style="text-align: center"
                      >
                        <span class="file-cta">
                          <span class="material-icons"> insert_photo </span>

                          <div class="file-label">Choose Files</div>
                        </span>
                      </label>
                    </div>

                    <div
                      v-if="message && fileCountError == ''"
                      :class="`message ${
                        error ? 'text-danger' : 'text-success'
                      }`"
                      style="font-style: italic; text-align: center"
                    >
                      {{ message }}
                    </div>
                    <div
                      v-else-if="fileCountError.length"
                      :class="`message ${
                        error ? 'text-danger' : 'text-success'
                      }`"
                      style="font-style: italic; text-align: center"
                    >
                      {{ fileCountError }}
                    </div>
                    <div v-if="files.length && !sending">
                      <hr />
                      <div
                        v-for="(file, index) in files"
                        :key="index"
                        :class="`edit-list ${
                          file.invalidMessage && 'text-danger'
                        }`"
                      >
                        <div class="file-left">
                          <div class="file-item">
                            {{ file.name
                            }}<span v-if="file.invalidMessage"
                              >&nbsp;-
                              <small>{{ file.invalidMessage }}</small></span
                            >
                          </div>
                        </div>
                        <div class="file-right">
                          <div class="file-item">
                            <button @click.prevent="removeFromList(index)">
                              <span class="material-icons"> clear </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--
										<div v-else-if="sending">
											<h5>Please be patient, we are processing your files</h5>
										</div>
										-->
                    <!--
			<div v-if="file" class="file-name">{{ file.name }}</div>
            -->
                  </form>
                </div>
              </div>
              <div
                v-if="
                  message == 'Please be patient, we are processing your files'
                "
                class="row"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
                      <div class="flex-box-wrap" style="padding: 30px 0">
                        <div class="flex-internal">
                          <atom-spinner
                            :animation-duration="1000"
                            :size="40"
                            :color="'#575757'"
                          />
                          <p>Uploading......</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                    <!--
										<button
											v-if="fileCountError.length"
											disabled
											type="submit"
											class="btn btn-primary flex-btn"
										>
											<span class="material-icons"> publish </span>
											Upload
										</button>
										<button
											v-else-if="files.length && fileCountError == ''"
											@click.prevent="sendFile()"
											type="submit"
											class="btn btn-primary flex-btn"
										>
											<span class="material-icons"> publish </span>
											Upload
										</button>
										-->
                    <button
                      v-if="
                        fileCountError.length ||
                        message ==
                          'Please be patient, we are processing your files'
                      "
                      disabled
                      class="mlauto"
                    >
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button
                      v-else-if="files.length && fileCountError == ''"
                      @click.prevent="sendFile()"
                      class="mlauto"
                    >
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button v-else @click.prevent="justSubmit" class="mlauto">
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </transition>
      </div>
    </section>
    <transition name="menu-popover">
      <div v-if="reasonPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="reason-wrap">
          <h3>SSC Expedited Order Reason Explanations</h3>
          <hr />
          <h5 class="mb0">Defective Part:</h5>
          <p>
            The component(s) does not work as it should due to incorrect or a
            missing subcomponent such as a rivet falling out of a shelf support,
            nonfunctional lock, or faulty electrical component. Please attach
            photos of the issue to the Expedited Order.
          </p>

          <h5 class="mb0">Incorrect Part:</h5>
          <p>
            The component(s) were not what was ordered. This can include the
            incorrect size or an entirely different part than what was ordered.
            Please attach photos of the issue to the Expedited Order.
          </p>

          <h5 class="mb0">Freight Damaged:</h5>
          <p>
            This should be used in the event damage is discovered when the
            shipment is received. To be eligible for this category, the damage
            should be noted to the carrier when received and a freight claim be
            put into motion. Replacement items are sent out on a charge basis
            unless shipment has been designated with Destination terms. If this
            is the case, then a copy of the signed freight bill with damage
            noted must be supplied to Spacesaver by attaching it to the
            Expedited Order.
          </p>

          <h5 class="mb0">Ordering More:</h5>
          <p>
            This is used when additional items are needed whether they were
            ordered on the original order or an entirely new part not previously
            on the order.
          </p>

          <h5 class="mb0">Damaged On Site:</h5>
          <p>
            This is used when damage has occurred after the shipment has been
            signed for as received. This can include pre, during, or after
            installation. This is considered a charge replacement.
          </p>

          <h5 class="mb0">Shortage:</h5>
          <p>
            This is used when it is believed Spacesaver fails to send the
            correct amount of product. This should not be used when not enough
            parts were ordered; Ordering More should be used for this. Shortage
            claims are investigated and may be disputed by Spacesaver in the
            event evidence points to the items being shipped.
          </p>

          <h5 class="mb0">Paint:</h5>
          <p>
            This is used when there is an unrepairable issue with the finish.
            Please attach photos of the issue to the Expedited Order.
          </p>

          <h5 class="mb0">Fabrication:</h5>
          <p>
            This should be used in the event a component will not work due
            things like not being formed correctly or holes will not line up.
            Please attach photos of the issue to the Expedited Order.
          </p>

          <h5 class="mb0">Estimation:</h5>
          <p>
            This is used when it is discovered that not enough product was
            ordered. Use this for orders that have not shipped yet. This is
            considered a charge request.
          </p>

          <h5 class="mb0">Joint Sales:</h5>
          <p>
            This is used by Spacesaver Project Management to order additional
            product on orders that are shared with Area Contractors that are to
            be charged back to the project.
          </p>

          <h5 class="mb0">Project Admin:</h5>
          <p>
            This is uses by Spacesaver Project Management to order additional
            product that are solely held by Spacesaver. This is considered a
            charge request.
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import BackButton from '@/components/Buttons/BackButton.vue';
// import Avatar from "vue-avatar";
// import Datepicker from "vuejs-datepicker";
// import Multiselect from "vue-multiselect";
import eoaddTour from "../components/Tours/eoaddTour.vue";
import _ from "lodash";
// import "vue-multiselect/dist/vue-multiselect.min.css";
// import "animate.css";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "ExpeditedOrderAdd",
  components: {
    AtomSpinner,
    BackButton,
    // Avatar,
    // Datepicker,
    // Multiselect,
    eoaddTour,
  },
  data() {
    return {
      orderId: this.$route.params.id,
      step: 1,
      totalPage: 5,
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      server: import.meta.env.VITE_APP_SERVER_URL,
      acs: [],
      carriers: [],
      reasons: [],
      expectationTypes: [],
      loading: true,
      percentage: "10%",
      order: null,
      companyName: "",
      userContactName: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      notes: "",
      overallNotes: "",
      carrier: "Select One",
      State: "",
      County: "",
      returnedParts: [
        {
          partNum: "",
          quantity: 0,
          color: null,
          reason: "Select One",
          notes: "",
        },
      ],
      todos: [],
      partNum: "",
      quantity: "",
      color: "",
      reason: "Select One",
      notes: "",
      returnedID: "",
      files: [],
      uploadFiles: [],
      message: "",
      error: false,
      fileCountError: "",
      allowFileCount: 5,
      sending: false,
      responsible: "AC responsible",
      expectations: "Select One",
      disclaimer: "",
      otherCarrier: "",
      caseNumber: "",
      reasonPreview: false,
    };
  },
  methods: {
    firstLoad: function () {
      //this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {

      return axios

        .get(`${this.ordermanageapi}/options?type=carrier`, auth)
        .then((response, carriers) => {
          console.log(response);
          this.carriers = response.data;
        })
        .then(() => {
          return axios
            .get(`${this.ordermanageapi}/options?type=reason`, auth)
            .then((response, reasons) => {
              console.log(response);
              this.reasons = response.data;
              //this.loading = false;
            });
        })
        .then(() => {
          return axios
            .get(`${this.ordermanageapi}/options?type=expectations`, auth)
            .then((response, expectations) => {
              console.log(response);
              this.expectationTypes = response.data;
            });
        })
        .then(() => {
          return axios
            .get(`${this.ordermanageapi}/order/${this.orderId}`, auth)
            .then((response) => {
              console.log(response);
              if (response.status == 204) {
                console.log("no content");
                this.loading = false;
              } else {
                this.order = response.data;
                this.loading = false;
              }
            });
        });
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    setPage: function (idx) {
      document.activeElement.blur();
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.step = idx;
    },
    submitTask() {
      this.todos.push({
        partNumber: this.partNum,
        completed: false,
        quantity: this.quantity,
        color: this.color,
        reason: this.reason,
        notes: this.notes,
      });

      this.partNum = "";
      this.quantity = "";
      this.color = "";
      this.reason = "Select One";
      this.notes = "";

      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
    },

    remove(index) {
      this.todos.splice(index, 1);
    },

    submitOrder() {
      document.activeElement.blur();
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      var thePostDate = new Date();
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        orderID: this.$route.params.id,
        whoAdded: this.UseridNumber,
        whenAdded: thePostDate,
        shippingCarrier: this.otherCarrier.length
          ? this.otherCarrier
          : this.carrier,
        shippingCompany: this.companyName,
        address1: this.address,
        city: this.city,
        state: this.state,
        zip: this.zipcode,
        notes: this.overallNotes,
        expectations: this.expectations,
        expeditedOrdersParts: this.todos,
        shippingCosts: this.responsible,
        caseNumber: this.caseNumber,
      };
      axios
        .post(`${this.ordermanageapi}/expeditedorders`, postData, axiosConfig)
        .then(
          (response) => {
            console.log(response);
            this.returnedID = response.data.id;
            this.step = 4;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    selectFile() {
      this.disabled = false;
      this.message = "";
      //
      const files = this.$refs.files.files;
      if (files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
      }

      //set files to files that are already there, and add the files that the user just selected
      //the actual javascript files object to be uploaded
      this.uploadFiles = [...this.uploadFiles, ...files];

      this.files = [
        ...this.files,
        ..._.map(files, (file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          invalidMessage: this.validate(file),
        })),
      ];
    },
    validate(file) {
      const MAX_SIZE = 4 * 1024 * 1024; // 4 MB
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic",
        "application/pdf",
        "application/octet-stream"
      ];
      if (file.size > MAX_SIZE) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `The max file size is 2mb`;
      }

      if (file.name.includes(".exe") || file.name.includes(".dmg")) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `An executable file, really?`;
      }

      if (!allowedTypes.includes(file.type) && !file.name.includes(".om")) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `Sorry, but this file type isn't accepted`;
      }
      return "";
    },
    async sendFile() {
      try {
        const axiosConfig = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
          },
        };

        this.sending = true;
        this.message = "Please be patient, we are processing your files";

        const formData = new FormData();
        formData.append("parent", this.returnedID);
        formData.append("attachtype", "partsrequests");

        _.forEach(this.uploadFiles, (file) => {
          if (this.validate(file) === "") {
            formData.append("files", file);
            console.log("File added:", file);
          } else {
            console.log("File validation failed:", file);
          }
        });

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axios.post(
          `${this.ordermanageapi}/attachments`,
          formData,
          axiosConfig
        );

        this.sending = false;
        console.log(response);
        this.message = "Files have been uploaded!";
        this.files = [];
        this.uploadFiles = [];
        this.error = false;
        this.$toast.success("Successfully added expedited order!");
        this.$router.push(`/expedited-order-details/${this.returnedID}`);
      } catch (err) {
        this.sending = false;
        console.error(err);
        this.message = err.response?.data?.error || "Something went wrong";
        this.error = true;
        this.$toast.error("Sorry, there was an issue uploading your files");
      }
    },
    removeFromList(index) {
      this.files.splice(index, 1);
      this.uploadFiles.splice(index, 1);
      if (this.files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
        /*
				_.forEach(this.uploadFiles, (file) => {
					if (this.validate(file) !== "") {
						this.message = "Problematic files will be ignored";
					} else {
						this.message = "Good to go!";
					}
                });
                */
      }
    },
    justSubmit() {
      ///expedited-order-details/27538
      this.$toast.success("Successfully added expedited order!");
      this.$router.push(`/expedited-order-details/${this.returnedID}`);
    },
    setResponsible() {
      this.carrier = "Carrier by Spacesaver";
      this.otherCarrier = "";
      this.disclaimer =
        "If SSC is responsible for shipping, the carrier will be chosen based on the size and weight of the shipment.";
    },
    unSetResponsible() {
      this.carrier = "Select One";
      this.otherCarrier = "";
      this.disclaimer = "";
    },
    openLightbox() {
      this.reasonPreview = true;
    },
    closeLightbox() {
      this.reasonPreview = false;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    UseridNumber() {
      return this.$store.state.user.UserId;
    },
    progressWidth: function () {
      let className = "first-step";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      } else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      }
    },
    progressWidthComplex: function () {
      let className = "zero";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      } else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      }
    },
    progressPercentage: function () {
      if (this.step == 1) {
        return "10%";
      } else if (this.step == 2) {
        return "30%";
      } else if (this.step == 3) {
        return "50%";
      } else if (this.step == 4) {
        return "90%";
      }
    },
    watchFileCount: function () {
      if (this.uploadFiles.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });

    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
};
</script>

<style scoped>
#pickDate1 input {
  border: 0 !important;
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.leadTime {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.leadTime-bottom {
  align-items: flex-end;
}
.leadTime-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: none;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.table-striped td:last-of-type {
  text-align: right;
}
.table-striped th:last-of-type {
  text-align: right;
}
.table-bordered td:first-of-type {
  border-right: none !important;
}
.table-bordered th:first-of-type {
  border-right: none !important;
}
.table-bordered td:last-of-type {
  border-left: none !important;
}
.table-bordered th:last-of-type {
  border-left: none !important;
}
.m50 {
  margin: 50px 0;
}
.nav-btns {
  display: flex;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.listing-header {
  background: #fff;
  color: #575757;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.loader {
  margin: 0 auto 20px;
  width: 100%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}

.percentage {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  font-size: 16px;
}
/* PURPLE */
.teal .progress-bar {
  background: #00b4b4;
}
.teal .progress-stripes {
  color: #00b4b4;
}
.teal .percentage {
  color: #eee;
}
.first-step {
  width: 10%;
}
.second-step {
  width: 30%;
}
.third-step {
  width: 50%;
}
.fourth-step {
  width: 80%;
}
.fifth-step {
  width: 90%;
}
.teal {
  color: #00b4b4;
}
.lookup-wrapper {
  text-align: center;
}
.lookup-svg {
  width: 300px;
  height: auto;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-right: auto;
  margin: 0 auto;
}
.show-all-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: rgb(124, 124, 129);

  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
}
.show-all-btn:hover {
  background: #4d5967;
}

.custom-radio .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #00c7c7;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #00c7c7;
  border: 1px solid #00c7c7;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #00c7c7;
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: none;
}

.custom-control-input:checked ~ .custom-control-label.green::before {
  background-color: #00c7c7;
}

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fff;
}
td input {
  border: none !important;
}
.table-striped td {
  background: #efefef;
}
td select {
  border: none;
}
.file-input {
  background: #00b4b4;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}
.file-input .material-icons {
  font-size: 2em;
}
.flex-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}
.text-success {
  color: #00c7c7 !important;
}
.edit-list {
  display: flex;
  padding: 5px 10px;
  background: #f5f5f5;
  margin-bottom: 5px;
  border-radius: 4px;
}
.edit-list .file-right {
  margin-left: auto;
}
.edit-list .file-left {
  margin-right: 20px;
  text-align: left;
}
.file-item button {
  border: none;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db5440;
  color: #fff;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}

.file-item button:hover {
  background: #cc4e3c;
}

.file-item button:active {
  background: #cc4e3c;
}
.file-item .material-icons {
  line-height: 1;
  font-size: 16px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}

.delete-btn {
  border-radius: 4px;
  background: #db5440;
  border: none;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}

.delete-btn button:hover {
  background: #cc4e3c;
}
.delete-btn button:active {
  background: #cc4e3c;
}
.delete-btn span {
  font-size: 18px;
}
.reason-wrap {
  display: block;
  position: relative;
  margin-top: 70px;
  background: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  padding: 20px;
  text-align: left;
  overflow: scroll;
  max-height: 80%;
}
.showme {
  color: #429da8;
}
.showme:hover {
  color: #00b4b4;
}
.showme:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10004;
}
/*
.custom-radio .custom-control-label::before {
	background: #429da8;
}
.custom-control-input:checked
	~ .custom-control-label.red[data-v-2ddd92a5]::before {
	border: 1px solid #429da8;
	background-color: #fff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	border: 1px solid #429da8;
	background-color: #fff;
	background-color: #fff;
	border-radius: 50%;
}
*/
</style>
