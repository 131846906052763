<template>
  <div class="page-admin">
    <!-- Delete Modal -->
    <div
      class="modal fade"
      :class="deleteUser.length ? 'show block' : 'none'"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Deleting User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">Are you sure you want to delete {{ deleteUser }}?</div>
          <div class="modal-footer">
            <button
              v-on:click="cancelModal()"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >Cancel</button>
            <button
              v-on:click="deleteFinal(deleteUser)"
              type="button"
              class="btn btn-primary"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="deleteUser.length" class="modal-backdrop fade show"></div>

    <section class="py-5 bg10 bs-shadow mobile-top">
      <div class="container">
        <input
          class="top-search"
          type="text"
          placeholder="Search users by first name, last name, or company name"
          style="padding: 15px;"
          v-model="search"
          @click="searchReset()"
        />

        <hr/>
        <div class="input-wrap">
        <input
          id="listings-step-1"
          class="top-search mt0"
          type="text"
          placeholder="Search listings by order number, PO number, or order name..."
          style="padding: 15px;"
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
        />

        <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="search-btns" style="display: flex; margin-bottom: 0">
          <div class="left-side" style="margin-right: 10px">
            <div id="listings-step-2" class="ac-select" style="margin-right: 0; margin-bottom: 10px;">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Area Contractor
                </option>
                <option
                  v-for="ac in acs"
                  :key="ac.acid"
                  :value="ac.acid"
                  id="text"
                >
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>
            <div class="dates" id="dates" style="display: flex; margin-bottom: 5px;">

              
            
               
              


               

            </div>

            <div class="radios">
              <div class="control-group">
                <label class="control control--radio">
                  Active
                  <input
                    type="radio"
                    name="radio"
                    checked="checked"
                    v-model="active"
                    value="true"
                  />
                  <div class="control__indicator"></div>
                </label>
                <label class="control control--radio">
                  Inactive
                  <input
                    type="radio"
                    name="radio"
                    v-model="active"
                    value="false"
                  />
                  <div class="control__indicator"></div>
                </label>
                <!--
                <label id="EO-step-3" class="control control--radio">
                  Completed
                  <input
                    type="radio"
                    name="radio"
                    v-model="picked"
                    value="completed"
                  />
                  <div class="control__indicator"></div>
                </label>
                -->
              </div>
            </div>
          </div>

          <div class="right-side" style="display: flex">
            <div
              class="search-btns"
              style="display: flex; margin-top: 0; margin-left: auto"
            >
              <button
                v-if="search"
                @click.prevent="searchQuery"
                class="search"
              >
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
              <button v-else class="search" disabled>
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>

              <!--
              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click="clearSearch"
                class="clear"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              <button v-else disabled class="clear">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <!--
        <div class="col-lg-4 d-none d-lg-block">
          <div class="card-block my-3 bs-shadow">
            <h5 class="dflex">
              <i class="material-icons">help</i> Using the Admin Section
            </h5>
            <hr />
            <div>
              <p class="info-box">
                You can search above by a user's first name, last name, or by
                company name of their employer.
              </p>
              <p class="info-box mb0">
                You can also filter your results by clicking on the company name
                in the user results area.
              </p>
            </div>
          </div>
        </div>
        -->

        <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12 col-lg-8">
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner :animation-duration="1000" :size="40" :color="'#575757'" />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredUsers.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div class="user-header d-flex align-items-center p-3 mb5 mt1r rounded bs-shadow">
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredUsers.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              v-bind:key="user.Tid"
              v-for="(user, index) in filteredUsers.slice(
								pageStart,
								pageStart + countOfPage
							)"
            >
              <!--
          <div v-for="user in users" :key="user.Tid">
              -->
              <div class="user bs-shadow br4" :class="user.IsActive == false ? 'inactive' : ''">
                <div class="top-wrap">
                  <div class="dflex mb0">
                    <avatar

                    class="mr5"
                    color="#fff"
                    :username="user.FirstName + ' ' + user.LastName"
                    :backgroundColor="
                      colorGetter[
                        Math.floor(Math.random() * colorGetter.length)
                      ]
                    "
                  ></avatar>
                  <!--
                    <avatar
                      class="mr10"
                      color="#fff"
                      v-bind:username="user.FirstName + ' ' + user.LastName"
                      v-bind:backgroundColor="
												colorGetter[
													Math.floor(Math.random() * colorGetter.length)
												]
											"
                    ></avatar>
                  -->
                    <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                    -->
                    <div class="dflex vcenter">
                      <div>
                        <h6 class="mb5">
                          <b>{{ user.FirstName + " " + user.LastName }}</b>
                        </h6>
                        <p v-if="user.Email" class="dflex mb5 l1 text-muted">
                          <small>
                            <i class="fas fa-envelope mr5"></i>
                            <b class="user-link">
                              <a v-bind:href="`mailto:${user.Email}`">
                                <span>{{ user.Email }}</span>
                              </a>
                            </b>
                          </small>
                        </p>
                        <p v-if="user.PhoneNumber" class="dflex mb0 l1 text-muted">
                          <small>
                            <i class="fas fa-phone mr5"></i>
                            {{ user.PhoneNumber }}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="company-link mlauto">
                    <p v-on:click="setCompany(user.Name)" class="lh1 mb0 company-button">
                      <small>{{ user.Name }}</small>
                    </p>
                  </div>
                </div>
                <div class="admin-btn-wrap">
                  <button
                    @click.prevent="impersonate(user.UserId, user.Email)"
                    v-if="user.IsActive"
                    class="admin-buttons"
                  >Impersonate</button>
                  <button v-else disabled class="admin-buttons disabled">Impersonate</button>
                  <button @click.prevent="setPage(currPage - 1)" class="admin-buttons">Edit</button>
                  <button @click.prevent="deleteModal(user.Email)" class="admin-buttons">Delete</button>
                </div>
                <!--
              <div class="user-info">
                <p v-if="user.Email" class="dflex mb10 l1"><i class="fas fa-envelope mr5"></i><b><span :href="`mailto:${user.Email}`">{{user.Email}}</span></b></p>
                <p v-if="user.PhoneNumber" class="dflex mb0 l1"><i class="fas fa-phone mr5"></i> {{user.PhoneNumber}}</p>
              </div>
                -->
              </div>
              <!-- card -->
            </div>
          </div>

          <!-- bottom pagination -->
          <div class="user-header d-flex align-items-center p-3 mb5 mt5 mb1r rounded box-shadow">
            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredUsers.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
										this.currPage === this.totalPage ||
											this.currPage == Math.ceil(this.filteredUsers.length / 25)
									"
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end bottom pagination -->
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from '../components/Atoms/Avatar.vue'


const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "Admin",
  components: {
    AtomSpinner,
    Avatar,
  },
  data() {
    return {
      server: import.meta.env.VITE_APP_SERVER_URL,
      search: "",
      selectedCompany: "",
      acs: [],
      users: [],
      selectedCat: 0,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noUserFound: false,
      deleteUser: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "Admin");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios.get(`${this.server}/api/v1/admin`, auth).then((response) => {
        // console.log(response);
        this.users = response.data.users;
        this.acListSync();
        this.loading = false;
      });

      

      
    },

    acListSync: function () {
      this.loading = true;

      if (!this.$store.state.acList.acList.length) {
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;
            //this.acs = alph;
            const alph = Array.isArray(data) 
            ? data.sort((a, b) => (a.acName > b.acName ? 1 : -1)) 
            : [];
            this.acs = alph;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        this.acs = this.$store.state.acList.acList;
        this.loading = false;
      }
    },

    searchReset: function () {
      this.selectedCat = 0;
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catID) {
      let category = catID;
      if (category !== 0) {
        this.selectedCat = category;
        this.search = "";
        console.log(category);
      } else {
        this.selectedCat = 0;
      }
    },

    setCompany(c) {
      this.search = "";
      let company = c;
      this.selectedCompany = company;
    },
    impersonate(id, email) {
      var postData = {
        id: id,
        email: email,
      };

      console.log(postData);

      this.$store
        .dispatch("impersonateUser", postData)
        .then(() => {
          // this.$noty.success("Successfully logged in!");
          this.$router.push("/Home");
        })
        .catch((err) => {
          //console.log(err)
          this.feedback = "There seems to be an issue with your credentials.";
        });
    },
    deleteFinal(email) {
      var postData = {
        email: email,
      };
      this.loading = true;
      console.log(postData);

      this.$store
        .dispatch("deleteUserFinal", postData)
        .then(() => {
          this.$noty.success("Successfully deleted user!");
          this.users = response.data.users;
          this.loading = false;
          //this.$router.push("/Home");
        })
        .catch((err) => {
          //console.log(err)
          this.feedback = "There seems to be an issue with your credentials.";
        });
    },
    deleteModal(email) {
      this.deleteUser = email;
      document.body.classList.add("modal-open");
    },
    cancelModal() {
      this.deleteUser = "";
      document.body.classList.remove("modal-open");
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      //return value.charAt(0).toUpperCase() + value.slice(1)
      return value.charAt(0) + value.slice(1).toLowerCase();
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredACs: function () {
      let b = this.acs.filter(function (e) {
        return e.ACID <= 200;
      });

      return b;
    },

    //the main v-for loop for the users
    filteredUsers: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.users.filter((e) => {
          return (
            e.FirstName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            e.LastName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            e.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );

          //filter the subject line by what is filled in the search box
          //return e.LastName.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        });
      } else if (this.selectedCompany !== "") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.users.filter((f) => {
          return f.Name == this.selectedCompany;
        });
      } else {
        //if the search box is not filled in
        return this.users;
      }
    },

    foundNoUser: function () {
      if (this.search.length > 1 && this.filteredUsers.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.users.length / this.countOfPage);
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.user {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.top-wrap {
  display: flex;
  align-items: flex-start;
}

.user-bottom {
  align-items: flex-end;
}

.user-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}

.capitalize {
  text-transform: capitalize;
}

.l1 {
  line-height: 1;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mlauto {
  margin-left: auto;
}

.user-link {
  color: #429da8;
  cursor: pointer;
}
.user-link a {
  color: #429da8;
  cursor: pointer;
}
.company-button {
  background: #efefef;
  color: #575757;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.company-button:hover {
  color: #fff;
  background: #00b4b4;
}

.company-button:active {
  color: #fff;
  background: #00b4b4;
}

.info-box {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 2px;
}
.inactive {
  background: #575757;
  color: #fff !important;
}
.admin-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #429da8;
  color: #fff;
  border-radius: 4px;
  line-height: 1;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}
.admin-btn-wrap {
  display: flex;
  margin-top: 10px;
}
.disabled {
  background-color: #000;
  color: gray;
}
.none {
  display: none;
}
.block {
  display: block;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.new-search-btn1{
    background: #4d5967;
    color: #fff;
    border: none;
    transition: background-color 0.5s ease;
    cursor:pointer;
}
.new-search-btn1:hover{
    background:#373f49;
}
.new-search-btn2{
  background: #6a7b8e;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn2:hover{
  background: #3e4b5a;
}
.dp-custom-input {
  padding: 10px 10px 10px 10px!important;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}

.dp__action_cancel {
    color: var(--dp-text-color);
    border: 1px solid var(--dp-border-color);
}

.dp__action_buttons .dp__action_select {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color);
}
</style>
