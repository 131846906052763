<template>
  <div class="page-listings">
   
    <listings-tour v-if="$store.state.tour & !loading" />
  
    <transition name="menu-popover" v-if="!loading">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
          style="background: #fff"
        />
      </div>
    </transition>
    <section
      v-if="loading == true"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 260px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Order Listings....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot" style="color: #fff;"></div>
          <div class="dot" style="color: #fff;"></div>
          <div class="dot" style="color: #fff;"></div>
        </div>
        <!--
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
        -->
      </div>
    </section>

    <section v-else class="pt-5 pb-4 bg9 bs-shadow mobile-top">
      <div class="container">

     
<!--
          <div class="new-search mb20">
          <div class="input-group">
            <input 
            v-model="searchValue" 
            @keyup.enter.prevent="searchOrder"
            type="text" 
            class="form-control" 
            aria-label="Search listings by order number, PO number, or order name.." 
            style="padding: 14px"
            placeholder="Search listings by order number, PO number, or order name..."
            >
            <div class="input-group-append">
              <span class="input-group-text new-search-btn2">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </span>

              <span @click="searchOrder" class="input-group-text new-search-btn1">
                <span class="material-icons">search</span>
                {{ " " }}Search
              </span>

            </div>
          </div>
        </div>
        -->
        <div class="input-wrap">
        <input
          id="listings-step-1"
          class="top-search mt0"
          type="text"
          placeholder="Search listings by order number, PO number, or order name..."
          style="padding: 15px;"
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
        />

        <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="search-btns" style="display: flex; margin-bottom: 0">
          <div class="left-side" style="margin-right: 10px">
            <div id="listings-step-2" class="ac-select" style="margin-right: 0; margin-bottom: 10px;">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option
                  :value="null"
                  selected
                  class="all-contractors"
                  id="firstSelector"
                  style="text-transform: capitalize !important"
                >
                  Area Contractor
                </option>
                <option
                  v-for="ac in acs"
                  :key="ac.acid"
                  :value="ac.acid"
                  id="text"
                >
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>
            <div class="dates" id="dates" style="display: flex; margin-bottom: 5px;">
            <!--   
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
                :typeable="true"
              ></datepicker>

            
              <datepicker
                id="listings-step-3"
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
                style="margin-right: 0"
                :typeable="true"
              ></datepicker>
            -->
              
            
               
              
              
              <VueDatePicker v-model="startDate" 
               
                :enable-time-picker="false"
                format="MMM dd yyyy"
                placeholder="From Date"
                id="listings-step-3"
                style="padding-right: 10px;"
              ></VueDatePicker>


              <VueDatePicker v-model="endDate" 
                @update:model-value="val => console.log('endDate:', val)"
                input-class-name="dp-custom-input"
                :enable-time-picker="false"
                format="MMM dd yyyy"
                placeholder="To Date"
                wrapper-class="pickthedate"
                :typeable="true"
              ></VueDatePicker>

               

            </div>

            <div class="radios">
              <div class="control-group">
                <label class="control control--radio">
                  Entered
                  <input
                    type="radio"
                    name="radio"
                    checked="checked"
                    v-model="promised"
                    value="false"
                  />
                  <div class="control__indicator"></div>
                </label>
                <label class="control control--radio">
                  Shipping
                  <input
                    type="radio"
                    name="radio"
                    v-model="promised"
                    value="true"
                  />
                  <div class="control__indicator"></div>
                </label>
                <!--
                <label id="EO-step-3" class="control control--radio">
                  Completed
                  <input
                    type="radio"
                    name="radio"
                    v-model="picked"
                    value="completed"
                  />
                  <div class="control__indicator"></div>
                </label>
                -->
              </div>
            </div>
          </div>

          <div class="right-side" style="display: flex">
            <div
              class="search-btns"
              style="display: flex; margin-top: 0; margin-left: auto"
            >
              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click.prevent="searchQuery"
                class="search"
              >
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>
              <button v-else class="search" disabled>
                <span class="material-icons">search</span>
                {{ " " }} Search
              </button>

              <!--
              <button
                v-if="searchValue || startDate || endDate || acFilter"
                @click="clearSearch"
                class="clear"
              >
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              <button v-else disabled class="clear">
                <span class="material-icons">find_replace</span>
                {{ " " }} Clear
              </button>
              -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div>
            <div>
              <div
                v-if="loading == true"
                class="col-xs-12 col-sm-12 col-12 col-lg-12"
              >
                <div class="flex-box-wrap">
                  <div class="flex-internal">
                    <atom-spinner
                      :animation-duration="1000"
                      :size="40"
                      :color="'#575757'"
                    />
                    <p>Loading......</p>
                  </div>
                </div>
              </div>

              <div
                v-else-if="loading == false && !filteredListings.length"
                class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
              >
                <div>
                  <h2
                    style="margin: 30px 20px; color: #429da8; font-weight: 400"
                  >
                    It looks like there are no recent orders to show...
                  </h2>
                  <h3
                    style="margin: 30px 20px; color: #429da8; font-weight: 400"
                  ></h3>
                  <img src="@/assets/img/noData.svg" style="width: 100%" />
                </div>
              </div>

              <div v-else class="page" :key="searchRender">


                <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredListings" :setPage="setPage"/>


                <div class="listing">
                  <div v-if="$store.getters.isACKSnINVS" class="tab-heading">
                    <router-link
                      to="/listings"
                      class="order-tab tab-head active-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Orders
                      </h5></router-link
                    >

                    <router-link
                      to="/acknowledgments"
                      class="ack-tab tab-head inactive-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Acknowledgments
                      </h5></router-link
                    >

                    <router-link
                      to="/invoices"
                      class="inv-tab tab-head inactive-tab"
                      ><h5 class="mb0" data-config-id="header" style="margin-top:0!important">
                        Invoices
                      </h5></router-link
                    >
                  </div>

                  <div v-else>
                    <h2 class="mb0" data-config-id="header">Order status</h2>
                    <p>
                      <small class="text-muted"
                        >Highlighted orders have new invoices or acknowledgments
                        that have been posted since your last login.</small
                      >
                    </p>
                  </div>

                  <div class="table-responsive">



                    
                    <table
                      class="table-striped table-bordered"
                      style="display: table"
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('status')"
                            :class="
                              columnSort == 'status' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Status</span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'status'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('orderNo')"
                            :class="
                              columnSort == 'orderNo' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Order</span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'orderNo'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link twenty"
                            @click="sortCol('customer_PO_No')"
                            :class="
                              columnSort == 'customer_PO_No'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">PO # </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'customer_PO_No'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>
                          <th
                            scope="col"
                            class="table-link thirty"
                            @click="sortCol('projName')"
                            :class="
                              columnSort == 'projName' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Name </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'projName'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('entered_Date')"
                            :class="
                              columnSort == 'entered_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Entered </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'entered_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('estimated_Date')"
                            :class="
                              columnSort == 'estimated_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Estimated </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'estimated_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="dateCol('promised_Date')"
                            :class="
                              columnSort == 'promised_Date'
                                ? 'column-active'
                                : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Promised </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'promised_Date'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="table-link ten"
                            @click="sortCol('order_Total')"
                            :class="
                              columnSort == 'order_Total' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span class="mr-auto">Total </span>
                              <span class="ml-auto">
                                <i
                                  :class="
                                    columnSort == 'order_Total'
                                      ? 'carrot-icon-active'
                                      : ''
                                  "
                                  class="material-icons carrot-icon"
                                >
                                  filter_list
                                </i>
                              </span>
                            </span>
                          </th>

                          <th
                            scope="col"
                            class="ten"
                            @click="sortCol('inv')"
                            :class="
                              columnSort == 'order_Total' ? 'column-active' : ''
                            "
                          >
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Inv </span>
                            </span>
                          </th>

                          <th scope="col" class="ten">
                            <span style="display: flex; flex: 0 0 100%">
                              <span>Ack </span>
                            </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                     
                        <tr
                          scope="row"
                          v-bind:key="index"
                          v-for="(listing, index) in filteredListings.slice(
                            pageStart,
                            pageStart + countOfPage
                          )"
                          :class="{
                            new:
                              (listing.confirmationPDF.length &&
                                $moment(
                                  listing.confirmationPDF[0].pdfdate
                                ).isAfter(
                                  $moment(
                                    $store.state.user.LastActivity
                                  ).subtract(1, 'd')
                                )) ||
                              (listing.invoicePdf.length &&
                                $moment(listing.invoicePdf[0].pdfdate).isAfter(
                                  $moment(
                                    $store.state.user.LastActivity
                                  ).subtract(1, 'd')
                                )),
                          }"
                        >
                          <td>
                            <span>{{ listing.status }}</span>
                          </td>
                          <td>
                            <router-link
                              @click="loadTop"
                              :id="index === 0 ? 'listings-step-5' : ''"
                              :to="/order-details/ + listing.orderNo"
                              >{{ listing.orderNo }}</router-link
                            >
                          </td>
                          <td>{{ listing.customer_PO_No }}</td>
                          <td
                            v-if="listing.projName"
                            v-html="listing.projName"
                          ></td>
                          <td v-else>Unavailable</td>

                          <td>
                            {{
                              $moment(listing.entered_Date).format("MM-DD-YYYY")
                            }}
                          </td>

                          <td v-if="listing.estimated_Date">
                            {{
                              $moment(listing.estimated_Date).format(
                                "MM-DD-YYYY"
                              )
                            }}
                          </td>
                          <td v-else></td>
                          <td v-if="listing.promised_Date">
                            {{
                              $moment(listing.promised_Date).format("MM-DD-YYYY")
                            }}
                          </td>
                          <td v-else></td>

                          <td>{{ formatPrice(listing.order_Total) }}</td>

                          <td>
                            <span
                              v-if="listing.invoicePdf.length"
                              @click="getPDF(listing.invoicePdf[0].blobPath)"
                              class="material-icons document-link"
                              >description</span
                            >
                          </td>
                          <td>
                            <span
                              v-if="listing.confirmationPDF.length"
                              @click="
                                getPDF(listing.confirmationPDF[0].blobPath)
                              "
                              class="material-icons document-link"
                              >description</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              
                <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredListings" :setPage="setPage"/>
     
              </div>
              <!-- page -->
            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import { HollowDotsSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
// import Datepicker from "vuejs-datepicker";
import ListingsTour from "../components/Tours/ListingsTour.vue";
import toUnicode from "../utils/toUnicode";
import { formatCurrency } from '../utils/utils';
// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'
import ListingsPagination from '@/components/Pagination/ListingsPagination.vue';
import { Logout } from '../utils/utils';
import { wrap } from "@sentry/vue";
import Datepicker from 'vue3-datepicker'

// import { ref } from 'vue';
// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css';

// const date = ref();


const auth = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    // Authorization: "Bearer " + '9a85ad843571fe2307afb0a8f2bb252a3e88f89265aeae9c1fdcf79e14ae5df55fb1b889ae78446a003d412a74022181dd9be832bf5de34acdfda3ec403f6c97cc981b02d0f598a1e759d15a3e205f99d926b1697e12b7168e07b08d825f813bb8edcdc22d6c8b46c729916c4a36beaabe1419702987249d103428d6f4364e70'
  },
};



export default {
  name: "Listings",
  components: {
    AtomSpinner,
    // Avatar,
    Datepicker,
    // VueDatePicker,
    ListingsPagination,
    ListingsTour,
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: true,
          dot: "red",
          dates: {
            start: null, // From the beginning of time
            end: new Date(), // Until today
          },
        },
      ],
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: this.$route.query.search ? this.$route.query.search : "",
      search: "",
      storedListings: [],
      searchRender: false,
      // startDate: ref(null),
      theDate: "",
      startDate: this.$route.query.start ? this.$route.query.start : null,
      endDate: this.$route.query.end ? this.$route.query.end : null,
      searchStarted: false,
      promised: false,
      acs: [],
      listings: [],
      acFilter: this.$route.query.ac ? this.$route.query.ac : null,
      selectedCat: "Show All",
      searching: false,
      loading: true,
      countOfPage: 100,
      currPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      noListingFound: false,
      colSort: false,
      columnSort: "",
      pdfPreview: "",
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      view: "orders",
    };
  },
  methods: {


    firstLoad() {
        this.loading = true;
        this.searchStarted = false;
        this.$store.commit("setActivePage", "order-manage");
        this.acListSync();

        // Return a promise for the method
        return new Promise((resolve) => {
            // Check for any filters; if filters exist, run searchQuery
            if (

                this.searchValue.length ||
                this.acFilter != null ||
                this.startDate != null ||
                this.endDate != null

            ) {

                this.searchQuery().then(() => {
                    resolve(); // Resolve after searchQuery completes
                }).catch(() => {
                    resolve(); // Ensure resolve even on error
                });

            } else {

                // Ensure listingSync returns a promise
                this.listingSync().then(() => {
                    resolve(); // Resolve after listingSync completes
                }).catch(() => {
                    resolve(); // Ensure resolve even on error
                });

            }
        });
    },
    formatPrice(amount) {
      return formatCurrency(amount);
    },


    listingSync() {
        this.loading = true;

        if (!this.$store.state.listings.listings.length) {
            return axios
                .get(`${this.ordermanageapi}/order/`, auth)
                .then((response) => {
                    if (response.status === 401) {
                        // this.Logout();
                        this.$router.push("/");
                    } else {
                        // Fix null estimated dates so sorting will work
                        response.data.forEach(function (o) {
                            if (o.estimated_Date === null) {
                                o.estimated_Date = "";
                            }
                        });
                        this.listings = response.data;
                        this.$store.commit("UPDATE_LISTINGS", this.listings);
                        this.loading = false;


                        const filteredListings = this.listings.map(({ orderNo, promised_Date }) => ({
                          orderNo,
                          promised_Date,
                        }));

                        console.log(JSON.stringify(filteredListings));
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    localStorage.removeItem("SSCUSER");
                    this.$router.push("/");
                    this.loading = false; // Ensure loading is false on error
                });
        } else {
            this.listings = this.$store.state.listings.listings;
            this.loading = false;
            return Promise.resolve(); // Ensure this returns a promise
        }
    },



    acListSync: function () {
      this.loading = true;

      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;

            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */

            //this.acs = alph;
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            //this.acs.sort((a, b) => (a.acName > b.acName) ? 1 : -1);
            //this.loading = false;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
        this.loading = false;
      }
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    searchReset: function () {
      this.selectedCat = "Show All";
      this.acFilter = 0;
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
      const newQuery = {
          ...this.$route.query, // Include existing query parameters
          page: idx, // Update or add the page parameter
          // Add any other parameters you want to preserve or modify here
          // For example: search: this.search, otherParam: this.otherValue
      };

      // console.log(newQuery);

      // Update the route with the new query parameters
      this.$router.push({ query: newQuery });

    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },


    searchQuery(event) {
        this.searchStarted = true;

        // Store current listings if search has started
        if (this.searchStarted) {
            this.storedListings = this.listings;
        }

        this.loading = true;
        this.searching = true;
        this.search = "";

        // Build the query parameters
        const queryParams = new URLSearchParams();

        // Add parameters based on their presence
        if (this.searchValue.length) {
            queryParams.append('search', toUnicode(this.searchValue.trim()));
        }
        if (this.acFilter != null) {
            queryParams.append('ac', this.acFilter);
        }
        if (this.startDate != null) {
            queryParams.append('start', this.startDate);
        }
        if (this.endDate != null) {
            queryParams.append('end', this.endDate);
        }
        if (this.promised != null) {
            queryParams.append('promised', this.promised);
        }
        if (this.currPage != null) {
            // queryParams.append('page', this.currPage);
        }

        // Add the page parameter if needed (replace `this.currPage` with your current page variable)
        /*
        if (this.currPage) {
            queryParams.append('page', this.currPage);
        }
        */

        // Push the state with the constructed URL
        history.pushState({}, "", `/listings?${queryParams.toString()}`);

        // Fetch the listings based on the search endpoint
        return axios.get(this.searchEndpointCompute, auth).then((response) => {
            this.listings = response.data;

            // console.log(response);
            this.loading = false;
            this.searching = false;
        });
    },

    clearSearch() {
      this.searchValue = "";
      this.promised = "false";
      this.searchRender = !this.searchRender;
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;

      if (this.searchStarted == true) {
        //this.listings = this.storedListings;
        this.listingSync();
        this.searchStarted = false;
      }

      history.pushState({}, "", `/listings`);

      //this.listings = this.storedListings;

      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    sortCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;



        //console.log(COLUMN)
        /*
        const distantFuture = new Date(8640000000000000);
        const firstSort = type;

        let sorted = filteredListings.sort((a, b) => {
          let dateA = a[firstSort] ? new Date(a[firstSort]) : distantFuture;
          let dateB = b[firstSort] ? new Date(b[firstSort]) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        });

        this.filteredListings = sorted;
        */


      if (this.colSort) {
        this.filteredListings.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredListings.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }


      this.colSort = !this.colSort;
    },

    dateCol: function (type) {
      console.log(type);
      this.columnSort = type;
      const COLUMN = type;
      const distantFuture = new Date(8640000000000000);

      if (this.colSort) {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA > dateB ? 1 : -1;
        });
      } else {
        this.filteredListings.sort((a, b) => {
          let dateA = a[COLUMN] ? new Date(a[COLUMN]) : distantFuture;
          let dateB = b[COLUMN] ? new Date(b[COLUMN]) : distantFuture;
          return dateA < dateB ? 1 : -1;
        });
      }
      this.colSort = !this.colSort;
    },

    loadTop() {
      window.scrollTo(0, 0);
    },

    calcProcessing: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "In Engineering" ||
          orderstate == "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          //console.log("true");
          // this.processing = true;
          return true;
        } else {
          // this.processing = false;
          //console.log("false");
          return false;
        }
      } else {
        return;
      }
    },

    calcPreProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },

    calcInProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },

    calcShipped: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "Partially Delivered" ||
          orderstate === "Delivered" ||
          orderstate === "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },

    getPDF(link) {
      return axios
        .get(`${this.ordermanageapi}/${link}`, auth)
        .then((response) => {
          console.log(response.data);
          this.pdfPreview = response.data[0].pdfblog;
        });
    },

    showPDF(doc) {
      this.pdfPreview = doc;
    },

    closeLightbox() {
      this.pdfPreview = "";
    },

  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    showButton() {
      if (this.searchValue.trim().length > 0 || this.acFilter || this.startDate || this.endDate || this.searchStarted) {
        // console.log('true')
        return true;
      } else {
        // console.log('false')
        return false;
      }
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredListings: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.listings.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.listings.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.listings;
      }
    },

    foundNoListing: function () {
      if (this.search.length > 1 && this.filteredListings.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.listings.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.listings
      ) {
        //console.log("fired off");
        this.listings = this.storedListings;
      }
      return;
    },
    searchEndpointCompute() {
      let url = `${this.ordermanageapi}/order`;
      const params = [];

      if (this.searchValue) {
        params.push(`search=${toUnicode(this.searchValue.trim())}`);
      }
      if (this.startDate) {
        params.push(`start=${this.formatDate(this.startDate)}`);
      }
      if (this.endDate) {
        params.push(`end=${this.formatDate(this.endDate)}`);
      }
      if (this.acFilter) {
        params.push(`ac=${this.acFilter}`);
      }
      if (this.promised) {
        params.push(`promised=${this.promised}`);
      }

      // Append parameters if there are any
      if (params.length) {
        url += `?${params.join('&')}`;
      }

      return url;
    }
  },
  created() {
    this.$store.commit("showNavigation");
  },

  mounted() {
    this.firstLoad().then(() => {
      // After initial load, set the page if there's a `page` query parameter
      if (this.$route.query.page) {
        this.setPage(parseInt(this.$route.query.page));
      }
      console.log(this.$tours);
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.listing-bottom {
  align-items: flex-end;
}
.listing-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
  /*background-color: #fff;*/
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.date-area {
  margin-top: 15px;
}
.pag-wrapper {
  display: flex;
  width: 100%;
}
.pag-wrapper p {
  margin-bottom: 0;
}
.mrauto {
  margin-right: auto;
}
.search-btns {
  /*display: flex;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 23px 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-btns .clear {
  margin-right: 0;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

.right-side {
  text-align: right;
  height: 57px;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
  .left-side {
    width: 500px;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
@media all and (min-width: 992px) {
  table {
    min-width: 100%;
  }
}

@media all and (max-width: 991px) {
  table {
    width: 800px !important;
    overflow: scroll;
  }
}
/*
flex-direction: column;
*/
.document-link {
  color: #429da8;
  cursor: pointer;
}
.document-link:hover {
  color: #00b4b4;
  cursor: pointer;
}
.document-link:active {
  color: #00b4b4;
  cursor: pointer;
}
/* row */
.new {
  background-color: rgba(0, 115, 219, 0.08) !important;
  /*background-color: rgba(77, 89, 103, 0.08) !important;*/
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.new-search-btn1{
    background: #4d5967;
    color: #fff;
    border: none;
    transition: background-color 0.5s ease;
    cursor:pointer;
}
.new-search-btn1:hover{
    background:#373f49;
}
.new-search-btn2{
  background: #6a7b8e;
  color: #fff;
  border: none;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.new-search-btn2:hover{
  background: #3e4b5a;
}
.dp-custom-input {
  padding: 10px 10px 10px 10px!important;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}

.dp__action_cancel {
    color: var(--dp-text-color);
    border: 1px solid var(--dp-border-color);
}

.dp__action_buttons .dp__action_select {
    background: var(--dp-primary-color);
    color: var(--dp-primary-text-color);
}

</style>
