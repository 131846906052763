<template>
  <div class="page-orders">
   
    <eo-tour v-if="$store.state.tour & !loading && filteredOrders.length" />
    
    <section
      v-if="loading"
      class="pt-5 pb-3 bg9 bs-shadow mobile-top"
      style="min-height: 243px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Expedited Orders....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>
<!--
    <section
      v-else-if="loading || (!orders && searching === true)"
      class="pt-5 pb-3 bg9 bs-shadow mobile-top"
      style="min-height: 243px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Searching Expedited Orders....</h1>
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Searching details</p>
      </div>
    </section>
-->
    <section v-else class="pt-5 pb-3 bg9 bs-shadow mobile-top">
      <div class="container">
        <div class="search-container">
          <div class="input-wrap">
        <input
          id="EO-step-1"
          class="top-search mt0"
          type="text"
          placeholder="Search orders by order number, PO number, or order name..."
          v-model="searchValue"
          style="padding: 15px;"
          @keyup.enter.prevent="searchQuery"
        />

        <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
      </div>
   
        </div>

        
        
        <div class="search-btns">
          <div class="left-side">
            <div id="EO-step-2" class="ac-select" style="margin-bottom: 5px;">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option :value="null" selected class="all-contractors">
                  Area Contractor
                </option>
                <option v-for="ac in acs" :key="ac.acid" :value="ac.acid">
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>

            <div class="radios">
              <div class="control-group">
                <label class="control control--radio">
                  All
                  <input
                    type="radio"
                    name="radio"
                    checked="checked"
                    v-model="picked"
                    value="all"
                  />
                  <div class="control__indicator"></div>
                </label>
                <label class="control control--radio">
                  Pending
                  <input
                    type="radio"
                    name="radio"
                    v-model="picked"
                    value="pending"
                  />
                  <div class="control__indicator"></div>
                </label>
                <label id="EO-step-3" class="control control--radio">
                  Completed
                  <input
                    type="radio"
                    name="radio"
                    v-model="picked"
                    value="completed"
                  />
                  <div class="control__indicator"></div>
                </label>
              </div>

              <!--
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
              ></datepicker>
              <datepicker
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
              ></datepicker>
              -->
            </div>
          </div>
          <div class="right-side">
            <button
              v-if="
                searchValue ||
                startDate ||
                endDate ||
                acFilter ||
                picked == 'all' ||
                picked == 'pending' ||
                picked == 'completed'
              "
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>

            <!--
            <button
              v-if="
                searchValue ||
                startDate ||
                endDate ||
                acFilter ||
                picked == 'all' ||
                picked == 'pending' ||
                picked == 'completed' ||
                searchStarted
              "
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear" id="EO-step-4">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            -->
          </div>
        </div>
        <p
          v-if="searchStarted && searchQueryString.length"
          class="text-white mb0"
        >
          You are searching via: {{ searchQueryString }}
        </p>

        <!--
        <div class="date-area">
          <vc-calendar mode="range" v-model="attributes" :max-date="new Date()" is-inline />
        </div>
        -->
      </div>
    </section>

    <div
      :class="loading == false && !filteredOrders.length ? 'message-bg' : ''"
    >
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
            <div>
              <div>
                <div
                  v-if="loading == true"
                  class="col-xs-12 col-sm-12 col-12 col-lg-12"
                >
                  <div class="flex-box-wrap">
                    <div class="flex-internal">
                      <atom-spinner
                        :animation-duration="1000"
                        :size="40"
                        :color="'#575757'"
                      />
                      <p>Loading......</p>
                    </div>
                  </div>
                </div>

                <div
                  v-else-if="
                    loading == false &&
                    !filteredOrders.length &&
                    searchStarted == true
                  "
                  class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
                >
                  <div>
                    <h2
                      style="
                        margin: 30px 20px;
                        color: #429da8;
                        font-weight: 400;
                      "
                    >
                      Sorry, It looks like no expedited orders were found...
                    </h2>
                    <img
                      src="@/assets/img/noListings.svg"
                      style="width: 100%"
                    />
                  </div>
                </div>

                <div
                  v-else-if="loading == false && !filteredOrders.length"
                  class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
                >
                  <div>
                    <h2
                      style="
                        margin: 30px 20px;
                        color: #429da8;
                        font-weight: 400;
                      "
                    >
                      It looks like there are no expedited orders to show....
                    </h2>
                    <img
                      src="@/assets/img/noListings.svg"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <!--
          <div v-for="order in orders" :key="order.Tid">
              -->
                <div v-else class="page pt-3" :key="searchRender">
                  <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredOrders" :setPage="setPage"/>
              

                  <div class="order mb10">
                    <div class="d-flex flexVHcenter">
                      <h2 class="mrauto">Expedited Orders</h2>

                      <!--
                  <div v-if="updatedDate != null" class="mlauto">
                    <small>Last Checked: {{moment(updatedDate).format("MM-DD-YYYY - h:mma")}}</small>
                  </div>
                  -->
                    </div>

                    <div class="table-responsive">
                      <table
                        class="table-striped table-bordered"
                        style="display: table; width: 100%"
                      >
                        <thead>
                          <tr>
                            <th
                              @click="sortCol('id')"
                              scope="col"
                              class="table-link ten"
                              :class="columnSort == 'id' ? 'column-active' : ''"
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>ID </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'id'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                            <th
                              @click="sortCol('orderNo')"
                              scope="col"
                              class="table-link ten"
                              :class="
                                columnSort == 'orderNo' ? 'column-active' : ''
                              "
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>Order # </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'orderNo'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                            <th
                              @click="sortCol('projName')"
                              scope="col"
                              class="table-link thirty"
                              :class="
                                columnSort == 'projName' ? 'column-active' : ''
                              "
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>Project </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'projName'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                            <th
                              @click="sortCol('acName')"
                              scope="col"
                              class="table-link thirty"
                              :class="
                                columnSort == 'acName' ? 'column-active' : ''
                              "
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>AC </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'acName'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                            <th
                              @click="sortCol('whenAdded')"
                              scope="col"
                              class="table-link twenty"
                              :class="
                                columnSort == 'whenAdded' ? 'column-active' : ''
                              "
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>Entered </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'whenAdded'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                            <th
                              @click="sortCol('isComplete')"
                              scope="col"
                              class="table-link twenty"
                              :class="
                                columnSort == 'isComplete' ? 'column-active' : ''
                              "
                            >
                              <span style="display: flex; flex: 0 0 100%">
                                <span>Completed </span>
                                <span class="ml-auto">
                                  <i
                                    :class="
                                      columnSort == 'isComplete'
                                        ? 'carrot-icon-active'
                                        : ''
                                    "
                                    class="material-icons carrot-icon"
                                  >
                                    filter_list
                                  </i>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            scope="row"
                            v-bind:key="index"
                            v-for="(order, index) in filteredOrders.slice(
                              pageStart,
                              pageStart + countOfPage
                            )"
                          >
                            <td>
                              <router-link
                                :to="/expedited-order-details/ + order.id"
                                :id="index === 0 ? 'EO-step-5' : ''"
                                >{{ order.id }}</router-link
                              >
                            </td>
                            <td>
                              <router-link
                                :to="/order-details/ + order.orderNo"
                                :id="index === 0 ? 'EO-step-6' : ''"
                                >{{ order.orderNo }}</router-link
                              >
                            </td>
                            <td v-html="order.projName"></td>
                            <td>
                              <span>{{ order.acName }}</span>
                            </td>

                            <td>
                              {{
                                $moment(order.whenAdded).format(
                                  "MM-DD-YYYY - h:mma"
                                )
                              }}
                            </td>
                            <td style="text-align: center;">
                              <span v-if="order.isComplete">&check;</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- card -->

                  <listings-pagination :currPage="currPage" :totalPage="totalPage" :filteredListings="filteredOrders" :setPage="setPage"/>
                 
                </div>
                <!-- page -->
              </div>
            </div>
          </div>
          <!-- end other column -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import ListingsPagination from '@/components/Pagination/ListingsPagination.vue';
import eoTour from "../components/Tours/eoTour.vue";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + 'eyJhbGci7yJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIyREQyQzk4NC1BM0RELTQ5MjMtQUM1Mi01QjA0MzgyOTg5QUQiLCJVc2VyTmFtZSI6ImpvYXMiLCJFbWFpbCI6ImpvYXNAc3BhY2VzYXZlci5jb20iLCJGaXJzdE5hbWUiOiJKb3NodWEiLCJMYXN0TmFtZSI6Ik9hcyIsIkNvbXBhbnkiOiJTcGFjZXNhdmVyIENvcnBvcmF0aW9uIiwiRGVwYXJ0bWVudCI6IklTIiwiUGhvbmVOdW1iZXIiOiI5MjA1NjM2MzYyIiwiQ2VsbFBob25lIjoiIiwiRW1haWxEb21haW4iOiJzcGFjZXNhdmVyLmNvbSIsIkFDSWQiOlswXSwiUmVwSWQiOjAsIlJvbGVzIjpbInNzYyIsImRvY2xpYmFkbWluIiwiYWRtaW4iXSwiYWRtaW4iOnRydWUsInNzY2VtcGxveWVlIjp0cnVlLCJkb2NsaWJhZG1pbiI6dHJ1ZSwiTGFzdEFjdGl2aXR5IjoiMjAyMS0wNC0yMlQxMzo1MzoxNy4yMTNaIiwiaWF0IjoxNjE5MDk5NjM5LCJleHAiOjE2MjE2OTE2Mzl9.gWs9nahp7NAzljgV0jhS27setYqj-fo87lmaBkzj_D0'
  },
};

export default {
  name: "ExpeditedOrders",
  components: {
    AtomSpinner,
    ListingsPagination,
    eoTour,
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: true,
          dot: "red",
          dates: {
            start: null, // From the beginning of time
            end: new Date(), // Until today
          },
        },
      ],
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedOrders: [],
      searchRender: false,
      picked: "pending",
      startDate: null,
      endDate: null,
      searchStarted: false,
      searchQueryString: "",
      acs: [],
      orders: [],
      acFilter: null,
      selectedCat: "Show All",
      searching: false,
      loading: false,
      countOfPage: 50,
      currPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      noOrderFound: false,
      updatedDate: null,
      colSort: false,
      columnSort: "",
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchStarted = false;
      this.acFilter = null;
      this.searchValue = "";
      this.startDate = null;
      this.endDate = null;
      //this.$store.commit("SET_EOS");

      this.$store.commit("setActivePage", "order-manage");
      /*
      this.acListSync().then(function() {
        this.eoSync();
      }).then(function() {
        this.checkLoop = setInterval(this.pullEOs, 60000);
      });
      */
      this.acListSync();
      this.eoSync();
      //this.checkLoop = setInterval(this.pullEOs, 60000);
    },

    eoSync: function () {
        this.loading = true;
        console.log("getting eos from component");
        return axios
          .get(`${this.ordermanageapi}/expeditedorders?pending=true`, auth)
          .then((response) => {
            console.log(response);
            this.orders = response.data;
            this.loading = false;
            this.$store.commit("UPDATE_EOS", this.orders);
          },(error) => {
            console.log(error);
            localStorage.removeItem("SSCUSER");
            this.$router.push("/");
          });
    },

    acListSync: function () {
      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;

            /*
            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
      }
    },

    /*
    pullEOs: function(){
      //console.log('checking for new expedited orders');
       return (
        axios
          .get(`${this.ordermanageapi}/expeditedorders?pending=true`, auth)
          //.get(`${this.ordermanageapi}/eo`, auth)
          .then((response) => {
            //console.log(response);
            let checked = new Date();
            this.updatedDate = checked;
            
            if(!this.storedOrders.length){
              this.orders = response.data;
            } else {
              this.storedOrders = response.data;
            }
            
            this.$store.commit("UPDATE_EOS", response.data);
            //if(this.storedOrders.length)
        })
       );
    },
    */

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    searchReset: function () {
      this.selectedCat = "Show All";
      this.acFilter = 0;
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
      const newQuery = {
          ...this.$route.query, // Include existing query parameters
          page: idx, // Update or add the page parameter
          // Add any other parameters you want to preserve or modify here
          // For example: search: this.search, otherParam: this.otherValue
      };

      console.log(newQuery);

      // Update the route with the new query parameters
      this.$router.push({ query: newQuery });
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },

    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedOrders = this.orders;
      }
      this.search = "";
      //this.loading = true;
      this.searching = true;

      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        if (!response.data.length) {
          console.log(response);
          this.noOrderFound = true;
          //this.loading = false;
          this.searchStarted == true;
          this.orders = response.data;
          this.searching = true;
        } else {
          this.orders = response.data;
          console.log(response);
          //this.loading = false;
          this.searching = false;
        }
      });
    },

    clearSearch() {
      if (this.searchStarted == true) {
        this.orders = this.storedOrders;
        this.storedOrders = [];
        this.searchStarted = false;
      }

      //this.orders = this.storedOrders;

      this.searchValue = "";
      this.picked = "pending";
      this.searchRender = !this.searchRender;
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.searchQueryString = "";
      this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.orders, this.storedOrders);
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      //console.log(COLUMN)
      if (this.colSort) {
        this.filteredOrders.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      } else {
        this.filteredOrders.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },

  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    showButton() {
      if (this.searchValue.trim().length > 0 || this.acFilter || this.picked !== "pending" || this.searchStarted) {
        // console.log('true')
        return true;
      } else {
        // console.log('false')
        return false;
      }
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredOrders: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.orders.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.orders.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.orders;
      }
    },

    foundNoOrder: function () {
      if (this.search.length > 1 && this.filteredOrders.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.orders.length / this.countOfPage);
    },

    orderRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedOrders.length >= this.orders
      ) {
        //console.log("fired off");
        this.orders = this.storedOrders;
      }
      return;
    },

    searchEndpointCompute: function () {
      //has 1
      //has no order number, picked = All , and no ac
      if (
        this.searchValue == "" &&
        this.picked == "all" &&
        this.acFilter === null
      ) {
        this.searchQueryString = "All expedited orders";
        return `${this.ordermanageapi}/expeditedorders`;
      } else if (
        //has 1
        //has no order number, picked = all, and has ac
        this.searchValue == "" &&
        this.picked == "all" &&
        this.acFilter != null
      ) {
        this.searchQueryString = `All expedited orders for area contractor #${this.acFilter}`;
        return `${this.ordermanageapi}/expeditedorders?ac=${this.acFilter}`;
      } else if (
        //has 1
        //has no order number, picked = completed, and ac set
        this.searchValue == "" &&
        this.picked == "completed" &&
        this.acFilter != null
      ) {
        this.searchQueryString = `All completed orders for area contractor #${this.acFilter}`;
        return `${this.ordermanageapi}/expeditedorders?ac=${this.acFilter}&completed=true`;
      } else if (
        //has 1
        //has no order number, picked = pending, and ac set
        this.searchValue == "" &&
        this.picked == "pending" &&
        this.acFilter != null
      ) {
        this.searchQueryString = `All pending orders for area contractor #${this.acFilter}`;
        return `${this.ordermanageapi}/expeditedorders?ac=${this.acFilter}&pending=true`;
      } else if (
        //has 1
        //has no order number, picked = completed, and no ac
        this.searchValue == "" &&
        this.picked == "completed" &&
        this.acFilter === null
      ) {
        this.searchQueryString = `all completed orders`;
        return `${this.ordermanageapi}/expeditedorders?completed=true`;
      } else if (
        //has 1
        //has no order number, picked = completed, and no ac
        this.searchValue != "" &&
        this.acFilter === null
      ) {
        this.searchQueryString = `order #${this.searchValue}`;
        return `${this.ordermanageapi}/expeditedorders?order=${this.searchValue.trim()}`;
      } else if (
        //has 1
        //has no order number, picked = completed, and no ac
        this.searchValue != "" &&
        this.acFilter != null
      ) {
        this.acFilter = null;
        this.searchQueryString = `order #${this.searchValue}`;
        return `${this.ordermanageapi}/expeditedorders?order=${this.searchValue.trim()}`;
      } else {
        return `${this.ordermanageapi}/expeditedorders?pending=true`;
      }
    },
  },

  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  beforeDestroy() {
    // Clear Timer
    clearInterval(this.checkLoop);
    //console.log("beforeDestroy");
  },

  /*
  destoyed() {
    window.clearInterval( this.pullEOs );
    console.log("Destroy");
  },
  */

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.order {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.order-bottom {
  align-items: flex-end;
}
.order-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.order {
  overflow: scroll;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.order {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.date-area {
  margin-top: 15px;
}
.pag-wrapper {
  display: flex;
  width: 100%;
}
.pag-wrapper p {
  margin-bottom: 0;
}
.mrauto {
  margin-right: auto;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
  padding: 23px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding: 23px 15px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.radios {
  color: #fff;
}

/* radios */
.control-group {
  margin-top: 5px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.control {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #4d5967;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4d5967;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.table-striped a:visited {
  color: #4d5967;
}
@media all and (max-width: 991px) {
  table {
    width: 800px !important;
    overflow: scroll;
  }
}
.input-clear{
  color: #5A7E8C;
  display: flex;
  position: absolute;
  right: 10px;
  top: 20px;
  text-align: center;
  padding-right: 3px;
  border: none;
  cursor: pointer;
  background: #fff;
}
.input-clear:hover{
  color: #1E3040;
}
.search-container{
  position: relative;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}
</style>
