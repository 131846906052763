
<template>
  <div>
    <banner-3 
    title="Spacenet Software Release Notes" 
    desc="Release notes are posted to inform the end-user of the
                continuous development of new features and bug fixes related to
                the Spacenet project."
    />
    <div class="container">
      <div
        class="listing bs-shadow rounded"
        style="min-height: 800px; margin-top: 20px; padding-top: 5px !important"
      >
        <h2 style="margin-top: 20px">What's New with Spacenet?</h2>

        <MarkdownDisplay :markdown="markdown" />
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownDisplay from "../components/MarkdownDisplay.vue";
import markdown from "../data/SpacenetReleaseData";
import Banner3 from "../components/Banners/banner3.vue";
export default {
  components: {
    MarkdownDisplay,
    Banner3
  },
  data() {
    return {
      markdown,
    };
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
};
</script>


<style scoped>
.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 20px;
  border-top: 0;
}

</style>