<template>
  <div class="carousel" @keydown="checkSlide($event)" tabindex="0">
    <transition-group name="fade" tag="div">
  
        <slot :index="currentIndex">

        </slot>
     
    </transition-group>
    <button @click.prevent="prev" class="btn btn-prev">
      <i class="fa fa-angle-left"></i>
    </button>
    <button @click.prevent="next" class="btn btn-next">
      <i class="fa fa-angle-right"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: ['slides'],
  data() {
    return {
      currentIndex: 0,
      timer: null,
    };
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    next() {
      console.log("changed");
      
      if (!this.slides || !Array.isArray(this.slides) || this.slides.length === 0) {
        console.error("Slides array is missing or empty!");
        return;
      }

      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
      this.startTimer();
    },
    prev() {
      this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
      this.startTimer();
    },
    checkSlide(event) {
      if (event.keyCode === 39) {
        this.next();
      } else if (event.keyCode === 37) {
        this.prev();
      }
    },
    startTimer() {
      clearInterval(this.timer);
      this.timer = setInterval(this.next, 5000);
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.carousel .active-slide ~ * {
  display: none;
}

.btn {
  padding: 5px 5px;
  border: 1px solid transparent;
  margin: 5px 10px;
  color: #fff;
  height: 50px;
  width: 50px;
  position: absolute;
  margin-top: -25px;
}
.btn i {
  font-size: 24px;
}
.btn:hover {
  cursor: pointer;
  color: #e0dfdfdb;
}
.btn:focus {
  outline: none;
}
.btn-next {
  top: 50%;
  right: 0;
}
.btn-prev {
  top: 50%;
  left: 0;
}
</style>
