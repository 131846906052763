<template>
  <div class="col-12 col-xs-12 col-sm-12 col-12 col-lg-12">
    <div class="flex-box-wrap" style="min-height: 400px;">
      <div class="flex-internal">
        <atom-spinner
          :animation-duration="1000"
          :size="40"
          :color="'#575757'"
        />
        <p>Loading......</p>
      </div>
    </div>
  </div>
</template>

<script>
import { AtomSpinner } from "epic-spinners";
export default {
  name: "Loading",
  methods: {},
  components: {
    AtomSpinner,
  },
};
</script>

<style scoped></style>
