<template>
  <div class="page-listings">
    <banner-1 title="Major Project Registration" desc="Please fill out this form to request new Major Project support on a project. The requested information is imperative to project strategy and will help Spacesaver better support you. Once the information is submitted, a Major Projects Sales Manager will follow up with you."/>

    <section id="ScrollTarget" class="py-5 bs-shadow mobile-top">
      
      <div data-v-5a90ec03 class="container">

        <!--
        {{productTypesSelected.join(', ')}}
        -->
        

       <!--  
        <h1 class="lh1">Interterritorial Projects</h1>
        -->
        
        <div class="loader teal">
          <div v-bind:class="progressWidth" class="progress-bar">
            <div class="progress-stripes"></div>
            <div class="percentage">{{ progressPercentage }}</div>
          </div>
        </div>
        
    

        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          :duration="500"
          mode="out-in"
          appear
        >
        <div>
          <div v-if="step == 1" class="step1" key="step1">
            <div class="listing">
          
              <h5>
                <span class="teal">Step 1:</span> Project Information
              </h5>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1"
                      >* Project Name <small v-if="check1 && !projectName" class="orange">This field is required.</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Project Name"
                      placeholder="Enter Project Name"
                      v-model="projectName"
                    />
                  </div>

                  <!--
                  <div class="form-group">
                    <label for="exampleInputEmail1"
                      >* Customer Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Customer Name"
                      placeholder="Enter Customer Name"
                      v-model="customerName"
                      required
                    />
                  </div>
                  -->
                    <div class="form-group">
                  <label for="exampleFormControlSelect1">* Project Market <small v-if="check1 && !MarketSelection" class="orange">This field is required.</small></label>
                  <select
                    v-model="MarketSelection"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option
                      disabled
                      v-bind:value="null"
                      class="all-contractors"
                    >
                      Select a market
                    </option>

                    <option value="Agriculture">Agriculture</option>
                    <option value="Business">Business</option>
                    <option value="Education">Education</option>
                    <option value="Education K-12">Education K-12</option>
                    <option value="Government">Government</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Library">Library</option>
                    <option value="Military">Military</option>
                    <option value="Museum">Museum</option>
                    <option value="Public Safety">Public Safety</option>             
                    <option value="Unknown">Unknown</option>
                  </select>
                </div>
                
           
               

              
          
        
         
                </div>
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                  <label for="exampleInputEmail1">* SSC Net Value <small v-if="check1 && !SSCNetValue.length" class="orange">This field is required.</small></label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="SSC Net Value"
                    placeholder="SSC Net Value"
                    v-model="SSCNetValue"
                  />
                </div> 
                  
                 <div class="form-group">
                  <label for="exampleFormControlSelect1">* Products <small>Please select all that apply <span v-if="check1 && !productTypesSelected.length" class="orange">This field is required.</span></small></label>
                  <v-select 
                  multiple 
                  label="productId"
                  v-model="productTypesSelected" 
                  :options="productTypes" 
                  :reduce="(option) => option.productId"
                  class="style-chooser"
                  placeholder="Choose Products"
                  />
                </div>

                            
                </div>
              </div>
                   <div class="row">
              <div class="col col-12">
                <div
                  class="
                    nav-btns
                    listing-header
                    d-flex
                    align-items-center
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >


                  <button
                    v-if="
                      !this.projectName.length ||
                      this.MarketSelection == null ||
                      !this.SSCNetValue.length ||
                      !this.productTypesSelected.length
                    "
                    class="mlauto"
                    @click="() => {this.check1 = true}"
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button
                    v-else-if="
                    this.projectName.length &&
                    this.MarketSelection &&
                    this.SSCNetValue.length &&
                    this.productTypesSelected.length"
                    @click.prevent="setPage(step + 1)"
                    class="mlauto"
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>

           <div v-if="step == 2" class="step1" key="step1">
            <div class="listing">
                  
              <h5>
                <span class="teal">Step 2:</span> Project Information
                <!-- <span class="teal">(required)</span> -->
              </h5>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">* Street Address <small v-if="check2 && !projectAddress.length" class="orange">This field is required.</small></label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="1"
                      placeholder="Enter Street Address..."
                      v-model="projectAddress"
                      required
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="exampleInputEmail1">* City <small v-if="check2 && !projectCity.length" class="orange">This field is required.</small></label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="City"
                      placeholder="Enter City"
                      v-model="projectCity"
                      required
                    />
                  </div>
                   <div class="form-group">
                    <label for="exampleInputEmail1"
                      >* State <small v-if="check2 && !projectState.length" class="orange">This field is required.</small>
                      </label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="State"
                      placeholder="Enter State"
                      :maxlength="statemax"
                      required
                      :value="projectState.toUpperCase()"
                      @input="projectState = $event.target.value.toUpperCase()"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">* Zip Code <small v-if="check2 && !projectZip.length" class="orange">This field is required.</small></label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Zip Code"
                      placeholder="Enter Zip Code"
                      v-model="projectZip"
                      required
                    />
                  </div>
                 
                </div>
                <div class="col-xs-12 col-md-6 col-12">
                   <div class="form-group">
                    <label for="exampleInputEmail1"
                      >* Project Contact Name <small v-if="check2 && !contactName.length" class="orange">This field is required.</small><small v-if="check2 && (!this.checkFirst ||
                      !this.checkLast)" class="orange"> Please enter a first and last name</small></label
                    >
                   
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Project Name"
                      placeholder="Enter Project Contact First and Last Name"
                      v-model="contactName"
                    />
                  </div>
                   <div class="form-group">
                    <label for="exampleInputEmail1"
                      >Project Contact Title</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Project Contact Title"
                      placeholder="Enter Project Contact Title"
                      v-model="contactTitle"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Project Contact Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Phone"
                      placeholder="Enter Project Contact Phone"
                      v-model="contactPhone"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Project Contact Email</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Email"
                      placeholder="Enter Project Contact Email"
                      v-model="contactEmail"
                      required
                    />
                  </div>
                 
                    
              
                          
                </div>
              </div>
              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                  <button v-if="this.step === 1" disabled class="mrauto">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-else
                    @click.prevent="setPage(step - 1)"
                    class="mrauto"
                  >
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>
 


                    <button
                      v-if="
                        !this.contactName.length ||
                        !this.projectAddress.length ||
                        !this.projectCity.length ||
                        !this.projectState.length ||
                        !this.projectZip.length ||
                        !this.checkFirst ||
                        !this.checkLast
                      "
                      @click="() => {this.check2 = true}"
                      class="mlauto"
                    >
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button
                      v-else-if="
                      this.contactName.length &&
                      this.projectAddress.length &&
                      this.projectCity.length &&
                      this.projectState.length &&
                      this.projectZip.length &&
                      this.checkFirst &&
                      this.checkLast"
                      @click.prevent="setPage(step + 1)"
                      class="mlauto"
                    >
                      Next
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <div v-if="step == 3" class="step1" key="step1">
            <div class="listing">
             
              <h5>
                <span class="teal">Step 3:</span> Project Information
                <!--<span class="teal">(required)</span>-->
              </h5>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-3 col-12">
                  <div id="pickDate3">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1"
                      >* Bid Due Date <small v-if="check3 && !bidDate" class="orange">This field is required.</small></label
                    >
                    <VueDatePicker 
                    v-model="bidDate"
                    input-class-name="dp-custom-input"
                    :enable-time-picker="false" 
                    format="MMM dd yyyy"
                    placeholder="Select Bid Date"
                    wrapperClass="datepicker1"
                    
                    :typeable="true"
                    style="padding-right: 10px;"
                ></VueDatePicker>


                <!--
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Bid Date"
                      v-model="bidDate"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                -->
                  </div>
                </div>
                   <div id="pickDate1">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1"
                      >Order Date</label
                    >
                    <!--
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Order Date"
                      v-model="orderDate"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                    -->


                    <VueDatePicker 
                    v-model="orderDate" 
                    input-class-name="dp-custom-input"
                    :enable-time-picker="false" 
                    format="MMM dd yyyy"
                    placeholder="Select Order Date"
                    
                    wrapperClass="datepicker1"
                    :typeable="true"
                ></VueDatePicker>
                  </div>
                </div>
                <div id="pickDate2">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Ship Date</label>
                    <!--
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Order Date"
                      v-model="shipDate"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                    -->
                    <VueDatePicker 
                    v-model="shipDate" 
                    input-class-name="dp-custom-input"
                    :enable-time-picker="false" 
                    format="MMM dd yyyy"
                    placeholder="Select Ship Date"
                    
                    wrapperClass="datepicker1"
                    :typeable="true"
                ></VueDatePicker>
                  </div>
                </div>

                
            
        
                </div>
                <div class="col-xs-12 col-md-9 col-12">
                  
                
               
                    
                 <div class="form-group">
                    <label for="exampleFormControlTextarea1">* Reason <small v-if="check3 && !projectReason" class="orange">This field is required.</small></label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="8"
                      placeholder="Why do you feel this would be best bid as a major project?"
                      v-model="projectReason"
                      required
                    ></textarea>
                  </div>           
                </div>
              </div>
              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                  <button
                    @click.prevent="setPage(step - 1)"
                    class="mrauto"
                  >
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                     <button
                      disabled
                      v-if="this.submitted"
                      class="mlauto"
                    >
                      Submitted
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>


                    <button
                      v-else-if="!this.bidDate || !this.projectReason.length"
                      @click="() => {this.check3 = true}"
                      class="mlauto"
                    >
                      Submit Project
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                    <button
                       v-else-if="
                        this.bidDate &&
                        this.projectReason.length"
                        @click.prevent="submitProject()"
                      class="mlauto"
                    >
                      Submit Project
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div v-if="step == 4" class="step1" key="step1">
            <div class="listing">    
             
              <div class="success-header">
                <img src="@/assets/img/success.svg"/>
                <h1>Awesome!</h1>
              </div>
              <div class="success-body">
                 <h4 style="color: #5a6877">
                Your Project has been submitted! <br/>A Major Projects Sales Manager will follow up with you.
                </h4>
              </div>
             
   
  
            </div>
          </div>
           <div v-if="step == 5" class="step1" key="step1">
            <div class="listing">    
             
              <div class="failure-header">
                <img src="@/assets/img/sad.svg"/>
                <h1>Sorry</h1>
              </div>
              <div class="failure-body">
                 <h4 style="color: #5a6877">
                There was an issue submitting your project.
                </h4>
                <p className="text-base">please reach out to <a className="text-teal-lightest dark:text-teal-lightest" href="mailto:hbare@spacesaver.com">hbare@spacesaver.com</a></p>
                <p className="text-base">or give us a call at <a className="text-teal-lightest dark:text-teal-lightest" href="tel:9205630611">920.563.0611</a></p>
                <p className="text-base">and we will get it fixed as soon as possible.</p>
              </div>
             
   
  
            </div>
          </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
// import Datepicker from "vuejs-datepicker";
import VueDatePicker from '@vuepic/vue-datepicker';
import Multiselect from "vue-multiselect";
import { HollowDotsSpinner } from "epic-spinners";
import Banner1 from "../components/Banners/banner1.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import "animate.css";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "MajorProjects",
  components: {
    AtomSpinner,
    // Avatar,
    // Datepicker,
    VueDatePicker,
    Multiselect,
    HollowDotsSpinner,
    Banner1,
    vSelect
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      server: import.meta.env.VITE_APP_SERVER_URL,
      backend: import.meta.env.VUE_APP_SERVER_URL,
      loading: true,
      projectName: "",
      customerName: "",
      MarketSelection:null,
      productTypesSelected: [],
      SSCNetValue: "",
      projectAddress: "",
      projectCity: "",
      projectState: "",
      projectZip: "",
      bidDate:"",
      orderDate: "",
      shipDate: "",
      productTypes: [],
      statemax: 2,
      contactName: "", 
      contactTitle: "",
      contactPhone: "",
      contactEmail: "",
      projectReason: "",
      step: 1,    
      userEmail: "",
      jobName: "",
      jobDesc: "",
      jobMarket: null,         
      leadSalesPerson: null,
      acList: null,
      State: "",
      County: "",
      AC: "",
      ACnotes: "",     
      acid: null,
      check1: false,
      check2: false,
      check3: false,
      submitted: false       
    };
  },
  methods: {
    removeTag(tagToBeRemove) {
      this.selected = this.selected.filter((tag) => tag.name !== tagToBeRemove.name);
      Bus.$emit('update:tags', this.selected);
    },

    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/aclist?all=yes&active=true`, auth)
        .then((response, acs) => {
          //console.log(response);
          this.acs = response.data;
          this.loading = false;
        })

        .then(() => {
          return axios

            .get(`${this.ordermanageapi}/salespeople`, auth)
            .then((response, salespeople) => {
              //console.log(response);
              this.salespeople = response.data.salesPeople;
              this.loading = false;
            });
        })
        .then(() => {
          return axios

            .get(`${this.ordermanageapi}/options?type=ptypes`, auth)
            .then((response, productTypes) => {
              //console.log(response);
              this.productTypes = response.data.productTypes;
              this.loading = false;
            });
        })
        .then(() => {
          return axios

            .get(`${this.server}/api/v1/ac-territories`, auth)
            .then((response) => {
              if (response.status === 401) {
                console.log("not allowed");
              } else {
                //console.log(response);
                this.acList = response.data.aCDistributors;
              }
            });
        });
    },
    submitProject(){
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };

      const fullName = this.contactName.split(' ');
      const firstName = fullName[0];
      const lastName = fullName[1];
      let repFirstName = this.$store.state.user.FirstName;
      let repLastName = this.$store.state.user.LastName;
      let repEmail = this.$store.state.user.Email;
      let repCompanyName = this.$store.state.user.Company;


      let postProjectData = {
          Company : this.projectName,
          StreetAddress: this.projectAddress,
          City: this.projectCity,
          State: this.projectState,
          Zip: this.projectZip,
          BDNotes: String("REASON: " + this.projectReason + ", PRODUCTS: " + this.productTypesSelected.join(', ').toLowerCase() + ", MARKET: " + this.MarketSelection + " SUBMITTED BY: " + repFirstName + "  " + repLastName + ", " + repEmail + " " + repCompanyName),
          LeadSource: "Major Projects Registration",
          FirstName: firstName,
          LastName: lastName,
          Title: this.contactTitle,
          Phone: this.contactPhone,
          Email: this.contactEmail,
          SSC_Net_Value: parseFloat(this.SSCNetValue.replace(/\$|,/g, '')),
          Market: this.MarketSelection,
          BidDate: this.bidDate,
          OrderDate: this.orderDate,
          ShipDate: this.shipDate

      };

      this.submitted = true;

      axios.post(`${this.server}/api/v1/create-major-project`, postProjectData, axiosConfig).then(
        (response) => {
          console.log(response);


          if (response.data && response.data.message == "success") {
            this.$toast.success("Successfully created your major project submission");
            this.step = this.step + 1;
          } else {
            // this.$router.push(`/interterritorial/${returnedID}`);
            this.$toast.error(
              "Sorry, there was an error creating your major project submission"
            );
            this.step = this.step + 2;
          }
          
        },
        (error) => {
          console.log(error);
        }
      );

    },

    removeDollarSign: function () {
      if (this.SSCNetValue != null) {
        //console.log("removing");
        this.SSCNetValue = this.SSCNetValue.replace("$", "");
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    setPage: function (idx) {
      document.activeElement.blur();
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      document
        .getElementById("ScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.step = idx;
    },
   
    submitITproj() {
      //console.log("submitted");
      var thePostDate = new Date();
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        isActive: true,
        submitDate: thePostDate,
        submitter: this.$store.state.user.UserId,
        hasSpecs: false,
        marketId: this.MarketSelection,
        installNotes: this.ACnotes,
        jobDescription: this.jobDesc,
        jobName: this.jobName,
        sscnetValue: this.SSCNetValue,
        orderDate: this.orderDate,
        shipDate: this.shipDate,
        endUserName: this.contactName,
        architectName: this.architectContactName,
        contractorName: this.contractorContactName,
        leadSalesPerson: this.leadSalesPerson,
        installationAcid: this.acFilter.acid ? this.acFilter.acid : null,
        submitterAcid: this.$store.state.user.ACId[0],
        itjobProducts: this.productTypesSelected,
      };
      axios.post(`${this.ordermanageapi}/itproj`, postData, axiosConfig).then(
        (response) => {
          //console.log(response);
          const returnedID = response.data.id;
          let emailData = {
            itProjNum: response.data.id,
            jobName: this.jobName,
            location: this.projectName,
            state: this.projectState,
            city: this.projectCity,
            comment: this.ACnotes,
            link: `http://spacenet3.spacesaver.com/interterritorial/${response.data.id}`,
            contact: this.acFilter.email,
          };

          if (this.acFilter.email) {
            return axios
              .post(
                `${this.server}/api/v1/new-itproj-email`,
                emailData,
                axiosConfig
              )
              .then(() => {
                //console.log("Successfully created a new email notification");
                this.$toast.success(
                  "Successfully created a new interterritorial project!"
                );
                this.$router.push(`/interterritorial/${returnedID}`);
              })
              .catch((err) => {
                //console.log(err);
                this.$toast.error(
                  "Sorry, there was an issue making a new email notification"
                );
              });
          } else {
            this.$router.push(`/interterritorial/${returnedID}`);
            this.$toast.error(
              "No email notification was sent due to lack of primary contact at Area Contractor"
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    colorGetter() {
      return this.$store.state.colors;
    },
     checkFirst: function(){
       if(this.contactName.length){
         let fullName = this.contactName.split(' ');
         return fullName[0]
       } else {
         return '';
       }
    },

    checkLast: function(){
       if(this.contactName.length){
         let fullName = this.contactName.split(' ');
         return fullName[1]
       } else {
         return '';
       }
    },
    progressWidth: function () {
      let className = "first-step";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      } else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      } else if (this.step == 5) {
        this.removeDollarSign();
        let className = "fifth-step";
        return className;
      }
    },
    progressWidthComplex: function () {
      let className = "zero";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      }  else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      } else if (this.step == 5) {
        let className = "fifth-step";
        return className;
      }
    },
     progressPercentage: function () {
      if (this.step == 1) {
        return "20%";
      } else if (this.step == 2) {
        return "60%";
      } else if (this.step == 3) {
        return "90%";
      } else if (this.step == 4) {
        return "100%";
      } else if (this.step == 5) {
        return "90%";
      } 
    },
    companyID() {
      let number = this.$store.state.user.ACId[0];
      this.acid = number;
    },
    states() {
      return new Set(this.acList.map((x) => x.State).sort());
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#pickDate1 input {
  border: 0 !important;
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.leadTime {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.leadTime-bottom {
  align-items: flex-end;
}
.leadTime-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: none;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.table-striped td:last-of-type {
  text-align: right;
}
.table-striped th:last-of-type {
  text-align: right;
}
.table-bordered td:first-of-type {
  border-right: none !important;
}
.table-bordered th:first-of-type {
  border-right: none !important;
}
.table-bordered td:last-of-type {
  border-left: none !important;
}
.table-bordered th:last-of-type {
  border-left: none !important;
}
.m50 {
  margin: 50px 0;
}
.nav-btns {
  display: flex;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.listing-header {
  background: #fff;
  color: #575757;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.loader {
  margin: 0 auto 20px;
  width: 100%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}

.percentage {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  font-size: 16px;
}
/* PURPLE */
.teal .progress-bar {
  background: #00bcbc;
}
.teal .progress-stripes {
  color: #00b4b4;
}
.teal .percentage {
  color: #eee;
}
.first-step {
  width: 20%;
}
.second-step {
  width: 60%;
}
.third-step {
  width: 90%;
}
.fourth-step {
  width: 100%;
}
.fifth-step {
  width: 90%;
}
.teal {
  color: #00b4b4;
}
.lookup-wrapper {
  text-align: center;
}
.lookup-svg {
  width: 300px;
  height: auto;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-right: auto;
  margin: 0 auto;
}
.show-all-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: rgb(124, 124, 129);

  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
}
.show-all-btn:hover {
  background: #4d5967;
}
.custom-control-label:before {
  background-color: #dedede;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00b4b4 !important;
}
input:focus {
  border-color: #00BCBC;
}
textarea:focus {
  border-color: #00BCBC;
}
</style>
<style>
.style-chooser .vs__selected{
      background-color: #00BCBC!important;
      color: #fff!important;
}
.style-chooser .vs__dropdown-option--highlight{
    background-color: #00BCBC!important;
    color: #fff!important;
}
.style-chooser .vs__selected-options{
  padding: 10px;
}
.style-chooser .vs__dropdown-option .vs__dropdown-option--highlight{
  background-color: #00BCBC!important;
  color: #fff!important;
}
.style-chooser .vs__deselect{
  fill: #fff;
}
.style-chooser .vs__selected{
  border: none;
  font-weight: 300;
  padding: 2px 10px;
}
.style-chooser .vs__dropdown-toggle{
  padding: 0;
}
.style-chooser .vs__search{
  margin: 0;
  padding: 0;
}

element.style {
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
textarea {
    overflow: auto;
    resize: vertical;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}
user agent stylesheet
textarea {
    box-sizing: border-box;
}
.success-header{
  background: #00b4b4;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  padding: 50px;
}
.success-header img{
    max-width: 200px;
    
}
.failure-header{
  background: #5a6877;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  padding: 50px;
}
.failure-header img{
    width: 200px;
    
}
.success-body{
  text-align: center;
  padding: 50px;
}
.failure-body{
  text-align: center;
  padding: 50px;
}
.failure-body a{
  color: #00BCBC;
}
.failure-body p {
  margin-bottom: 5px;
}
.orange{
  color:#f16232
}


</style>
