import { createRouter, createWebHistory } from "vue-router";
import Login from "../pages/login.vue";
import Forgot from "../pages/Forgot.vue";
import Reset  from "../pages/Reset.vue";
import Trailblazor from "../pages/Trailblazor.vue";
import TrailblazorSingle from "../pages/TrailblazorSingle.vue";
import Announcements from "../pages/Announcements.vue";
import AnnouncementSingle from "../pages/AnnouncementSingle.vue";
import FromFort from "../pages/FromFort.vue";

import Home from "../pages/Home.vue";
import HomeVids from "../pages/HomeVids.vue";
import HomeProjects from "../pages/HomeProjects.vue";
import ACList from "../pages/ACLookup.vue";
import FAQ from "../pages/faq.vue";
import Calendar from "../pages/Calendar.vue";
import Questions from "../pages/Questions.vue";
import QuestionsSingle from "../pages/QuestionsSingle.vue";
import Media from "../pages/Media2.vue";

import Listings from "../pages/Listings.vue";
import Acknowledgments from "@/pages/Acknowledgments.vue";
import Invoices from "@/pages/Invoices.vue";
import OrderDetails from "../pages/OrderDetails.vue";
import ExpeditedOrders from "../pages/ExpeditedOrders.vue";
import ExpeditedOrderDetails from "../pages/ExpeditedOrderDetails.vue";
import ExpeditedOrderAdd from "../pages/ExpeditedOrderAdd.vue";

import Interterritorial from "../pages/Interterritorial.vue";
import InterterritorialAdd from "../pages/InterterritorialAdd.vue";
import InterterritorialDetails from "../pages/InterterritorialDetails.vue";


import CashManagement from "../pages/CashManagement.vue";
import CashManagementSingle from "../pages/CashManagementSingle.vue";
import LeadTimes from "../pages/LeadTimes.vue";
import LeadTimesEdit from "../pages/LeadTimesEdit.vue";
import LeadTimeHistory from "../pages/LeadTimeHistory.vue";
import Warranty from "../pages/warranty.vue";
import GSAs from "../pages/GSAs.vue";
import ADS from "../pages/ADS.vue";


import RFRs from "../pages/RFRs.vue";
import RFRDetails from "../pages/RFRDetails.vue";
import RFRadd from "../pages/RFRadd.vue";

import RGAs from "../pages/RGAs.vue";
import RGADetails from "../pages/RGADetails.vue";
import RGAadd from "../pages/RGAadd.vue";

import ReportsNew from "../pages/reportsNew.vue";
import ReportDetails from "@/pages/reportDetails.vue";
import SalesReps from "@/pages/salesReps.vue";

import Parts from "@/pages/PriceLists.vue"; 
import MajorProjects from "@/pages/MajorProjectsRegistration.vue"; 
import ReleaseNotes from "@/pages/ReleaseNotes.vue";

// 
// import RGAs from "../pages/RGAs.vue";
// import RGAadd from "../pages/RGAadd.vue";




import FieldService from "../pages/FieldService.vue";
import SalesTraining from "../pages/SalesTraining.vue";
import TrainingEssentials from "../pages/TrainingEssentials.vue";
import ETOProjects from "../pages/ETOProjects.vue";
import ETOProjectDetails from "../pages/ETOProjectDetails.vue";

import CET from "../pages/CET.vue";
// import BasicInstallationCourse from "../pages/BasicInstallationCourse.vue";
import Admin from "../pages/Admin.vue";




import store from "../store";

// const  = () => import("../pages/login");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
	if (savedPosition) {
		// savedPosition is only available for popstate navigations.
		return savedPosition
	} else {
		const position = {}

		/*
		return new Promise(resolve => {
			// check if any matched route config has meta that requires scrolling to top
			if (to.matched.some(m => m.meta.scrollToTop)) {
				// coords will be used if no selector is provided,
				// or if the selector didn't match any element.
				position.x = 0
				position.y = 0
			}

			// wait for the out transition to complete (if necessary)
			this.app.$root.$once('triggerScroll', () => {
				// if the resolved position is falsy or an empty object,
				// will retain current scroll position.
				resolve(position)
			})
		})
		*/

		if (to.matched.some(m => m.meta.scrollToTop)) {
			// coords will be used if no selector is provided,
			// or if the selector didn't match any element.
			position.x = 0
			position.y = 0
			document.getElementById('mobileScrollTarget').scrollIntoView();
		} else {
			return { x: 0, y: 0 };
		}

	}
},
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: { layout: 'login' }
    },
    {
			path: "/forgot",
			name: "Forgot",
			component: Forgot,
      meta: { layout: 'login' }
		},
		{
			path: "/reset",
			name: "Reset",
			component: Reset,
      meta: { layout: 'login' }
		},
    {
      path: "/home",
      name: "Home",
      component: store.state.isDashboard ? HomeProjects : Home,
      meta: {
        requiresAuth: true,
        layout: 'default',
      },
    },
    {
			path: "/questions/:id",
			name: "QuestionSingle",
			component: QuestionsSingle,
			meta: {
				requiresAuth: true,
        layout: 'default',
			},
		},
		{
			path: "/questions",
			name: "Questions",
			component: Questions,
			meta: {
				requiresAuth: true,
			},
		},
    /*
    {
      path: "/collaborate/:id",
      name: "CollaborateSingle",
      component: CollaborateSingle,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/collaborate",
      name: "Collaborate",
      component: Collaborate,

      meta: {
        requiresAuth: true,
      },
    },
    */
	{
		path: "/marketing-materials",
		name: "Media",
		component: Media,
		meta: {
			requiresAuth: true,
		},
	},
  
	{
		path: "/service-and-installation",
		name: "FieldService",
		component: FieldService,
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: "/sales-and-training",
		name: "SalesTraining",
		component: SalesTraining,
		meta: {
			requiresAuth: true,
		},
	},

	{
		path: "/sales-and-training/training-essentials",
		name: "TrainingEssentials",
		component: TrainingEssentials,
		meta: {
			requiresAuth: true,
		},
	},

  {
    path: "/listings",
    name: "Listings",
    component: Listings,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/order-details/:id",
    name: "OrderDetails",
    component: OrderDetails,
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      requiresNotExternalPerson: true
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: Invoices,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/acknowledgments",
    name: "Acknowledgments",
    component: Acknowledgments,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/expedited-orders",
    name: "ExpeditedOrders",
    component: ExpeditedOrders,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/expedited-order-details/:id",
    name: "ExpeditedOrderDetails",
    component: ExpeditedOrderDetails,
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/expedited-order/add/:id",
    name: "ExpeditedOrderAdd",
    component: ExpeditedOrderAdd,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true,
    },
  },

  {
    path: "/interterritorial",
    name: "Interterritorial",
    component: Interterritorial,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/interterritorial/add",
    name: "InterterritorialAdd",
    component: InterterritorialAdd,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/interterritorial/:id",
    name: "InterterritorialDetails",
    component: InterterritorialDetails,
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/cash-management",
    name: "CashManagement",
    component: CashManagement,
    meta: {
      requiresAuth: true,
      requiresCashManagement: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/cash-management/:id",
    name: "CashManagementSingle",
    component: CashManagementSingle,
    meta: {
      requiresAuth: true,
      requiresCashManagement: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/lead-times",
    name: "LeadTimes",
    component: LeadTimes,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },

  {
    path: "/lead-times/edit",
    name: "LeadTimesEdit",
    component: LeadTimesEdit,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/lead-time-history",
    name: "LeadTimeHistory",
    component: LeadTimeHistory,
    meta: {
      requiresAuth: true,
      requiresSpacesaverEmployee: true,
    },
  },
  {
    path: "/cet-extension-curriculum",
    name: "CET",
    component: CET,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/price-lists",
    name: "Parts",
    component: Parts,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/warranty",
    name: "Warranty",
    component: Warranty,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },
  {
    path: "/gsas",
    name: "GSAs",
    component: GSAs,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },
  {
    path: "/ads",
    name: "ADS",
    component: ADS,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },
  {
    path: "/rfrs",
    name: "RFRs",
    component: RFRs,
    meta: {
      requiresAuth: true,
      requiresNotExternalPerson: true
    },
  },
  {
      path: "/rfr-details/:id",
      name: "RFRDetails",
      component: RFRDetails,
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        requiresNotExternalPerson: true
      },
    },
    {
			path: "/rfr/add/:id",
			name: "RFRadd",
			component: RFRadd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
    {
			path: "/rgas",
			name: "RGAs",
			component: RGAs,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
    
		{
			path: "/rga-details/:id",
			name: "RGADetails",
			component: RGADetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
   
		{
			path: "/rga/add/:id",
			name: "RGAadd",
			component: RGAadd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
  
    {
      path: "/announcements",
      name: "Announcements",
      component: Announcements,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/announcement/:slug",
      name: "AnnouncementSingle",
      component: AnnouncementSingle,

      meta: {
        requiresAuth: true,
      },
    },
    {
			path: "/major-project-registration",
			name: "MajorProjects",
			component: MajorProjects,
			meta: {
				requiresAuth: true,
			},
		},
    {
      path: "/from-the-fort",
      name: "FromFort ",
      component: FromFort,

      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/trailblazer",
      name: "trailblazor",
      component: Trailblazor,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/trailblazer/:slug",
      name: "trailblazorPost",
      component: TrailblazorSingle,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ac-list",
      name: "ACList",
      component: ACList,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: Calendar,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ,

      meta: {
        requiresAuth: true,
      },
    },
    {
			path: "/eto-and-inspiring-installs",
			name: "ETOProjects",
			component: ETOProjects,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/eto-and-inspiring-installs/:id",
			name: "ETOProjectDetails",
			component: ETOProjectDetails,
			meta: {
				requiresAuth: true,
			},
		},
    {
			path: "/reports",
			name: "ReportsNew",
			component: ReportsNew,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				requiresNotExternalPerson: true
			},
		},
    {
			path: "/reports/sales-reps",
			name: "SalesReps",
			component: SalesReps,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				requiresNotSales: true,
				requiresNotExternalPerson: true
			},
		},
    {
			path: "/reportdetails/",
			name: "ReportDetails",
			component: ReportDetails,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
    {
			path: "/release-notes",
			name: "ReleaseNotes",
			component: ReleaseNotes,
			meta: {
				requiresAuth: true,
			},
    },
    /*
    {
			path: "/basic-installation-course",
			name: "BasicInstallation",
      component: BasicInstallationCourse,
			meta: {
				requiresAuth: true,
        layout: "external"
			},
		},
    */
    {
			path: "/admin",
			name: "Admin",
			component: Admin,
			meta: {
				requiresAuth: true,
				requiresAdmin: true,
			},
		},

    { path: "/:catchAll(.*)", component: () => import("../pages/404.vue") },
  ],
});


router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			// Sentry.setUser({ email: store.state.user.Email });
			next();
			return;
		}

		//next("/");
		//const loginpath = window.location.pathname;
		const loginpath = window.location.url;
		//next({ name: "Login", query: { from: loginpath } });
		next({ name: "Login", query: { from: to.fullPath } });
		next();
		//this.$router.push({ name: "Login", query: { redirect: "/path" } });
	} else {
		next();
	}
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (store.getters.isLoggedIn) {
      //console.log("is logged in in store");
      next();
      return;

    } else {

      console.log("isn't logged in in store");
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });

    }
    //next("/");
  } else {

    next();
    
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (store.getters.isAdmin) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresSpacesaverEmployee)) {
    if (store.getters.isSpacesaverEmployee) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresCashManagement)) {
    if (store.getters.isCashManagement) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresReports)) {
    if (store.getters.isReports) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotSales)) {
    if (!store.getters.isSales) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotExternalPerson)) {
    if (!store.getters.isExternalPerson) {
      next();
      return;
    }

    next("/");
  } else {
    next();
  }
});

/*
navigation duplicated exception fix. This exception gets thrown when an individual is on a page. 
and tries to navigate to the same page
*/

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

/*
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		//console.log(err.name);
		if (err.name !== 'NavigationDuplicated') throw err
	});
}
*/

export default router;
