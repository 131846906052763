<template>
  <footer
    class="footer-bs noprint"
    style="
      min-height: 300px;
      background-size: cover;
      background-position: right;
      background-repeat: no-repeat;
      background: #fff;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6 footer-social animated fadeIn">
          <h3>Tools & Resources</h3>
          <div class="row">
            <div class="col col-6 col-md-6 footer-social animated fadeIn">
              <ul class="pages">
                <li>
                  <span class="material-icons">home</span>
                  <a href="/home">Home</a>
                </li>
                <li>
                  <span class="material-icons"> announcement </span>
                  <a href="/announcements">Announcements</a>
                </li>
                <li>
                  <span class="material-icons"> question_answer </span>
                  <a href="/collaborate">Ask a Question</a>
                </li>
                <li v-if="!$store.getters.hideOrders">
                  <span class="material-icons"> article </span>
                  <a href="/listings">My Orders</a>
                </li>
                <li v-if="!$store.getters.hideOrders">
                  <span class="material-icons"> article </span>
                  <a href="/expedited-orders">Expedited Orders</a>
                </li>
                <li v-if="!$store.getters.hideOrders">
                  <span class="material-icons"> point_of_sale </span>
                  <a href="/interterritorial/add">Create an IT Project</a>
                </li>
              </ul>
            </div>
            <div class="col col-6 col-md-6 footer-social animated fadeIn">
              <ul class="pages">
                <li>
                  <span class="material-icons">photo_library</span>
                  <a href="/marketing-materials">Marketing Materials</a>
                </li>

                <li>
                  <span class="material-icons">video_library</span>
                  <a href="/service-and-installation">Installation Docs</a>
                </li>
                <li>
                  <span class="material-icons"> assignment </span>
                  <a href="/lead-times">Lead Times</a>
                </li>
                <li>
                  <span class="material-icons"> public </span>
                  <a href="/ac-list">AC Territories</a>
                </li>
                <li>
                  <span class="material-icons">dynamic_feed</span>
                  <a href="/trailblazer">Trailblazer</a>
                </li>
                <li>
                  <span class="material-icons">dynamic_feed</span>
                  <a href="/from-the-fort">From The Fort</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col col-6 col-md-3 footer-social animated fadeIn">
          <h3>Links</h3>
          <ul class="pages">
            <li>
              <span class="material-icons"> domain </span>
              <a
                target="blank"
                rel="noopener noreferrer"
                href="https://www.spacesaver.com"
                >Spacesaver.com</a
              >
            </li>

            <li>
              <span class="material-icons">event_busy</span>
              <a href="http://www.spacesaverweb.com/show/request.html"
                >Tradeshow Requests</a
              >
            </li>
            <li>
              <span class="material-icons">support</span>
              <a
                target="blank"
                rel="noopener noreferrer"
                href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                >Material Safety Data Sheets</a
              >
            </li>

            <li>
              <span><img class="config-icon" src="/img/configura.svg" /></span>
              <a
                href="http://spacenet2.spacesaver.com/configuraredirect.html"
                target="_blank"
                rel="noopener"
                >Configura Download</a
              >
            </li>

            <li>
              <span class="material-icons">support</span>
              <a
                href="mailto:spacenet3feedback@spacesaver.com?subject=SpaceNET 3 Support"
                >SpaceNET 3 Feedback</a
              >
            </li>

            <li>
              <i class="material-icons">store</i>
              <a @click="SSCstoreLink" style="cursor: pointer">
                Spacesaver Store
              </a>
            </li>

            <!--
                <li>
                  <form
                    style="margin-bottom: 0"
                    target="_blank"
                    method="post"
                    action="https://www.delzer.com/spacesaver/sso.asp"
                  >
                    <input
                      id="FirstName"
                      name="FirstName"
                      :value="firstName"
                      type="hidden"
                    />
                    <input
                      id="LastName"
                      name="LastName"
                      :value="lastName"
                      type="hidden"
                    />
                    <input
                      id="Email"
                      name="Email"
                      :value="email"
                      type="hidden"
                    />
                    <button type="submit" class="storeBtn d-flex">
                      <i class="material-icons">store</i> Spacesaver Store
                    </button>
                  </form>
                </li>
                -->
          </ul>
        </div>

        <div class="col col-6 col-md-3 footer-social animated fadeIn">
          <h3>Connect</h3>
          <ul class="pages">
  <li>
    <a href="https://www.facebook.com/SpacesaverCorporation?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211093447"
       target="_blank"
       rel="noopener">
      <i class="fa fa-facebook-square"></i> Facebook
    </a>
  </li>
  <li>
    <a href="https://twitter.com/spacesaver?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211111802"
       target="_blank"
       rel="noopener">
      <i class="fa fa-twitter-square"></i> Twitter
    </a>
  </li>
  <li>
    <a href="https://www.instagram.com/spacesavercorp/?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211203545"
       target="_blank"
       rel="noopener">
      <i class="fa fa-instagram"></i> Instagram
    </a>
  </li>
  <li>
    <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHCWdnxP_QUcwAAAW2X3UnIF4XrPtAON3R9A6hA7CjHPfV0jZGifaH7nFR9LWPnaTVb3rGXagr0-c8rGUshhvE4ra5U5TjGJbrC0YWHsq445CgOOOole1Nss1E6bDgUq1s1ntU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F56990%3Ftrk%3Dtyah%26ss_uuid%3Dngy2y2fj-ea-5205164%26ss_tmidx%3D1570211044945"
       target="_blank"
       rel="noopener">
      <i class="fa fa-linkedin"></i> Linkedin
    </a>
  </li>
  <li>
    <a href="https://www.youtube.com/user/SpacesaverCorp"
       target="_blank"
       rel="noopener">
      <i class="fa fa-youtube"></i> Youtube
    </a>
  </li>
  <li>
    <a href="https://www.pinterest.com/spacesaver/"
       target="_blank"
       rel="noopener">
      <i class="fa fa-pinterest"></i> Pinterest
    </a>
  </li>
</ul>

        </div>
      </div>

      <hr style="border-top: solid 1px rgba(255, 255, 255, 0.1)" />
      <div class="text-center">
        <ul class="ls-none">
          <li>
            <small class="terms">
              <a
                href="https://spacesaver.widen.net/view/pdf/jnqqq9qjk4/spacesaver-terms-and-conditions.pdf?t.download=true&u=sphzbh"
                target="_blank"
                rel="nooopener noreferrer"
                >Order Terms &amp; Conditions</a
              >
            </small>
          </li>
          <li>
            <small class="terms">
              <a
                href="https://spacesaver.widen.net/view/pdf/ookgx9pq3c/spacesaver-statement-of-warranty.pdf?t.download=true&u=sphzbh"
                target="_blank"
                rel="nooopener noreferrer"
                >Statement of Warranty</a
              >
            </small>
          </li>
          <li>
            <small class="terms">
              <a
                href="https://spacesaver.widen.net/view/pdf/7qmnznhs7r/spacesaver-return-goods-policy.pdf?t.download=true&u=sphzbh"
                target="_blank"
                rel="nooopener noreferrer"
                >Return Goods Policy</a
              >
            </small>
          </li>
        </ul>
      </div>

      <div class="text-center copyright">
        <p>
          <small>
            Copyright © {{ getyear }} Spacesaver Corporation, All Rights
            Reserved
          </small>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DashboardFooter",
  props: {
    placeholder: String,
    searchReset: {
      type: Function,
    },
  },
  methods: {
    SSCstoreLink() {
      let url =
        "https://portal.mypropago.com/Security/Spacesaver?companyGuid=ae007963-e6dc-47cc-92c5-898fc8add77a&email=" +
        this.email +
        "&username=" +
        this.userName +
        "&fName=" +
        this.firstName +
        "&lName=" +
        this.lastName;
      window.open(url, "_blank");
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    userName() {
      return this.$store.state.user.UserName;
    },
    email() {
      return this.$store.state.user.Email;
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.pages i {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages button {
  color: #b5b5b5;
  list-style-type: none;
  padding: 0px;
  --webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 300 !important;
  transition: color 0.5s ease;
}
.pages button:hover {
  text-decoration: none;
  color: #fff;
}
.pages button:active {
  text-decoration: none;
  color: #fff;
}
.pages button:hover i {
  text-decoration: none !important;
}
.pages button:active i {
  text-decoration: none !important;
}

.pages span {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages a {
  transition: color 0.5s ease;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.table-striped a:visited {
  color: #4d5967 !important;
}
footer h3 {
  color: #b5b5b5;
}
.terms a {
  color: #b5b5b5;
  transition: color 0.5s ease;
}
.terms a:hover {
  color: #fff;
  text-decoration: none;
}
.terms a:active {
  color: #b5b5b5;
  text-decoration: none;
}
.ls-none {
  list-style-type: none;
  padding-left: 0;
}
.ls-none li {
  display: inline-block;
  margin-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 20px;
  line-height: 1;
}
.ls-none li:last-child {
  border-right: none;
}
@media all and (max-width: 767px) {
  .ls-none li:nth-child(2) {
    border-right: none;
  }
}

@media all and (max-width: 552px) {
  .ls-none li {
    display: block;
    margin-right: 0px;
    border-right: none;
    margin-left: 0;
    padding-right: 0px;
    padding-left: 0;
    margin-bottom: 10px;
  }
}




.footer-bs .footer-social a {
    color: #575757;
}
.pages span {
    color: #575757;
    margin-right: 5px;
}

</style>
