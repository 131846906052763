import axios from "axios";

const state = {
    fromthefort: "https://fromthefort.spacesaver.com",
    fromTheFortPosts: [],
    allfromTheFortPosts: [],
};

const getters = {
    getHomeFortPosts: (state) => {
        return state.fromTheFortPosts;
    },
};

const actions = {};

const mutations = {
    SET_FROM_THE_FORT_HOME(state) {
        return axios
            .get(
                `https://fromthefort.spacesaver.com/wp-json/wp/v2/posts?per_page=6`
            )
            .then((response) => {
                //console.log(response);
                state.fromTheFortPosts = response.data;
            });
    },
    SET_FROM_THE_FORT_ALL(state) {
        return axios
            .get(
                `https://fromthefort.spacesaver.com/wp-json/wp/v2/posts?per_page=100`
            )
            .then((response) => {
                //console.log(response);
                state.allfromTheFortPosts = response.data;
            });
    },
    UPDATE_FROM_THE_FORT_HOME(state, data) {      
        state.fromTheFortPosts = data;
    },
    UPDATE_FROM_THE_FORT_ALL(state, data) {      
        state.allfromTheFortPosts = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}