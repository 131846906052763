<template>
  <div style="position: relative; display: block">
    
    <orderdetails-tour v-if="$store.state.tour && !loading" />
    
    <transition name="menu-popover" v-if="!loading && order != null">
      <div v-if="pdfPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <embed
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.pdfPreview"
          style="background: #fff"
        />
      </div>
    </transition>

    <transition name="menu-popover" v-if="!loading && order != null">
      <div v-if="this.bundleSheet.length" class="lightbox">
        <h2 class="closeX" @click="closeBundleSheet()">
          <a>&#215;</a>
          <iframe
            style="
              width: 90%;
              height: 100%;
              poosition: relative;
              display: block;
            "
            :src="this.bundleSheet"
            title="Bundle Sheet"
          ></iframe>
        </h2>
      </div>
    </transition>

    <section
      v-if="loading && notFound == false"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 293px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Loading Order....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
        <!--
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">Loading details</p>
        -->
      </div>
    </section>

    <section
      v-else-if="!loading && notFound == true && order === null"
      class="py-5 bg9 bs-shadow mobile-top"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">Order Not Found</h1>
        <h2 class="text-white lh1"></h2>
        <p class="text-white lh1">
          Sorry, but we couldn't find that order number.
        </p>
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">



      <div class="container">


        
        <h1
          v-if="order != null && order.orderDetail[0].orderNo"
          class="text-white mb0 lh1"
        >
          {{ order.orderDetail[0].orderNo }}
        </h1>
        <h2 class="text-white lh1"></h2>
        <p
          v-if="order != null && order.orderDetail[0].projName"
          class="text-white lh1 mb0"
          v-html="order.orderDetail[0].projName"
        ></p>

        <div v-if="order != null" class="tracker-wrap">
          <ul class="progtrckr" data-progtrckr-steps="4">
            <li
              :class="calcProcessing ? 'progtrckr-done' : 'progtrckr-todo'"
            ></li>
            <li :class="calcPreProd ? 'progtrckr-done' : 'progtrckr-todo'"></li>
            <li :class="calcInProd ? 'progtrckr-done' : 'progtrckr-todo'"></li>
            <li :class="calcShipped ? 'progtrckr-done' : 'progtrckr-todo'"></li>
          </ul>
        </div>
        <div
          id="orderdetails-step-1"
          v-if="order != null"
          class="tracker-title-wrap"
        >
          <ul class="trckr-title mb0" data-progtrckr-steps="4">
            <li
              :class="calcProcessing ? 'trckr-title-done' : 'trckr-title-todo'"
            >
              Processing
            </li>

            <li :class="calcPreProd ? 'trckr-title-done' : 'trckr-title-todo'">
              Pre-Production
            </li>

            <li :class="calcInProd ? 'trckr-title-done' : 'trckr-title-todo'">
              In Production
            </li>

            <li :class="calcShipped ? 'trckr-title-done' : 'trckr-title-todo'">
              Shipped
            </li>
          </ul>
        </div>
      </div>


    </section>

    <div v-if="loading" class="row">
      <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
        <div class="flex-box-wrap">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#575757'"
            />
            <p>Loading......</p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      :class="
        !loading && notFound == true && order === null ? 'message-bg' : ''
      "
    >
      <!-- problem is not in the container -->
      <div
        v-if="!loading && notFound == true && order === null" class="pt30 container"
      >
        <div class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter">
          <div>
            <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
              It looks like there is no order to show...
            </h2>
            <img src="@/assets/img/noData.svg" style="width: 100%" />
          </div>
        </div>
      </div>

      <div
        v-else
        class="mt30 mb20 container"
        style="position: relative; display: block"
      >
        <!-- col -->
        <div class="row">
          <div class="col-lg-8">
            <div class="card-back mb30 rounded bs-shadow p-3">
              <div id="orderdetails-step-3">
                <div
                  class="question-header d-flex align-items-center mb5 rounded"
                >
                  <div class="d-flex number-showing">
                    <button
                      @click="backLink"
                      class="mb0 lh1 mlauto list-btn dib d-flex center"
                    >
                      <i class="material-icons">keyboard_arrow_left</i>
                      Back
                    </button>
                  </div>

                  <div class="pag-wrapper mlauto" @click="backLink">
                    <h2 v-if="order != null && order.orderDetail[0].amount">
                      {{ formatPrice(order.orderDetail[0].amount) }}
                    </h2>
                    <h2
                      v-if="order != null && order.orderDetail[0].amount == 0"
                    >
                      {{ formatPrice(order.orderDetail[0].amount) }}
                    </h2>
                  </div>
                </div>
                <hr />
                <div class="info-top">
                  <div class="top-row d-flex">
                    <div v-if="order">

                      

                      <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">group</span><span style="margin-left: 5px;"> Representatives</span>  
                      </h6>


                      <p class="text-muted mb5 lh1">
                        <small
                          class="fw300"
                          v-if="order != null && order.orderDetail[0].salesman"
                        >
                          {{ order.orderDetail[0].salesman }}
                        </small>
                        <small class="fw300" v-else
                          >No representative assigned</small
                        >
                      </p>
                      <div v-if="order.itReps">
                        <p
                          v-for="rep in order.itReps"
                          :key="rep.repId"
                          class="text-muted mb5 lh1"
                        >
                          <small class="up fw300">
                            {{ rep.lastname + ", " + rep.firstname }}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div id="orderdetails-step-2" class="status ml">
                      <p v-if="order" class="badge badge-success">
                        {{ order.orderStatus[0].status }}
                      </p>
                    </div>
                  </div>

               

                  <h6 class="mt20" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">corporate_fare</span><span style="margin-left: 5px;"> Area Contractors</span>  
                    </h6>



                  <p class="text-muted mb5 lh1">
                    <small
                      v-if="order != null && order.orderDetail[0].acName"
                      class="text-muted fw300"
                    >
                      {{ order.orderDetail[0].acName }}
                    </small>
                    <small v-else class="text-muted fw300"
                      >No area contractor assigned</small
                    >
                  </p>
                  <div v-if="order != null && order.itac">
                    <p
                      v-for="ac in order.itac"
                      :key="ac.acid"
                      class="text-muted mb5 lh1"
                    >
                      <small class="up lh1 fw300">{{ ac.acName }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <hr />


              <h6 class="mt-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Order Name</span>  
                    </h6>
              <p class="text-muted mb20">
                <small
                  v-if="order != null && order.orderDetail[0].projName"
                  class="up lh1 fw300"
                >
                  {{ order.orderDetail[0].projName }}
                </small>
                <small v-else class="up lh1 fw300"> Unavailable </small>
              </p>

         
              <h6 class="mt-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Order Number</span>  
                    </h6>
              <p class="text-muted mb20">
                <small
                  v-if="order != null && order.orderDetail[0].orderNo"
                  class="up lh1 fw300"
                >
                  {{ order.orderDetail[0].orderNo }}
                </small>
              </p>


              <h6 class="mt-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Customer PO Number</span>  
              </h6>
              <p class="text-muted mb20">
                <small
                  v-if="order != null && order.orderDetail[0].customerPoNo"
                  class="up lh1 fw300"
                >
                  {{ order.orderDetail[0].customerPoNo }}
                </small>
              </p>


              <h6 class="mt-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">calendar_month</span><span style="margin-left: 5px;"> Date Entered</span>  
                    </h6>
              <p class="text-muted mb20">
                <small
                  v-if="order != null && order.orderDetail[0].dateEntered"
                  class="up lh1 fw300"
                >
                  {{
                    $moment(order.orderDetail[0].dateEntered).format(
                      "MM-DD-YYYY"
                    )
                  }}
                </small>
              </p>

              <div
                v-if="order != null && order.shipmentLines.length"
                style="margin-bottom: 20px"
              >
                <hr />

                <h6 class="mt-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">local_shipping</span><span style="margin-left: 5px;"> Tracking</span> 
                      
                    </h6>
                    <h6><small style="font-weight: 300; font-size: 80% !important">Shipment skid and weight details are located below in the Bill of Lading (BOL)</small></h6>

<!--
                <h6 class="mt-0">
                  <i class="fas fa-shipping-fast"></i> Tracking<br />
                  <small style="font-weight: 300; font-size: 80% !important"
                    >Shipment skid and weight details are located below in the
                    Bill of Lading (BOL)</small
                  >
                </h6>
              -->

                <ul
                  v-for="(line, index) in order.shipmentLines"
                  v-bind:key="index"
                  style="margin-left: 0; padding-left: 0; margin-bottom: 0"
                >
                  <li
                    style="padding-top: 5px; list-style-type: none"
                    class="fw300"
                  >
                    <p class="ship-head" style="margin-bottom: 0">
                      <span class="ship-number">
                        {{ " " }}{{ line.shipViaCode + ": " }}
                        <span class="ship-date">
                          <span v-if="line.proNo">
                            <span
                              v-if="
                                [
                                  'FEDEX FREIGHT PRIORITY',
                                  'FEDEX Freight ECONOMY',
                                  'FEDEX Heavy 3 Day',
                                  'FEDEX Heavy 2 Day',
                                  'FEDEX Heavy Next Day',
                                  'FEDEX 2nd Day',
                                  'FEDEX Next Day',
                                  'FED EXP Saver',
                                  'FEDX SAVER',
                                  'FEDEX GROUND',
                                  'FedEx Supply Chain Logistics',
                                  'FED EX',
                                  'FEDEX',
                                  'FEDX',
                                  'FEDX GROUND',
                                  'FED EXP SAVER (0537-0292-9)',
                                  'FEDEX Next Day (0537-0292-9)',
                                  'FEDEX GROUND (0537-0292-9)',
                                ].includes(line.shipViaCode) &&
                                !line.shipViaCode.includes('WILL CALL')
                              "
                            >
                              <a
                                :href="
                                  'https://www.fedex.com/fedextrack/?tracknumbers=' +
                                  line.proNo
                                "
                                class="pdfLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                >{{ line.proNo }}</a
                              >
                            </span>

                            <span
                              v-else-if="
                                [
                                  'UPS',
                                  'UPS Next Day',
                                  'UPS Freight',
                                  'UPS 2nd Day',
                                  'UPS 3rd Day',
                                  'UPS Ground',
                                ].includes(line.shipViaCode) &&
                                !line.shipViaCode.includes('WILL CALL')
                              "
                            >
                              <a
                                :href="
                                  'https://www.ups.com/track?loc=null&tracknum=' +
                                  line.proNo +
                                  '&requester=WT/trackdetails'
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                class="pdfLink"
                                >{{ line.proNo }}</a
                              >
                            </span>

                            <span
                              v-else
                            >
                              {{ line.shipmentId }}
                            </span>

                          </span>

                          <span v-else>
                            <span
                  
                            >
                              {{ line.shipmentId }}
                            </span>
                          </span>

                        </span>
                      </span>
                    </p>
                  </li>
                </ul>
              </div>

              <!--
             <h6 class="my-0">
              <i class="fas fa-table"></i> Date Promised
            </h6>
            <p class="text-muted mb20">
              <small v-if="order.orderDetail[0].dateEntered" class="up lh1">
                {{
                moment(order.orderDetail[0].dateEntered).format("MM-DD-YYYY")
                }}
              </small>
            </p>
            -->


              <div id="orderdetails-step-5" class="tab-container">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      class="nav-item nav-link active"
                      id="nav-lines-tab"
                      data-toggle="tab"
                      href="#nav-lines"
                      role="tab"
                      aria-controls="nav-lines"
                      aria-selected="false"
                      >Lines</a
                    >
                    <a
                      class="nav-item nav-link"
                      id="nav-bill-tab"
                      data-toggle="tab"
                      href="#nav-bill"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      >BOL</a
                    >
                    <a
                      class="nav-item nav-link"
                      id="nav-charges-tab"
                      data-toggle="tab"
                      href="#nav-charges"
                      role="tab"
                      aria-controls="nav-charges"
                      aria-selected="false"
                      >Charges</a
                    >
                    <a
                      class="nav-item nav-link"
                      id="nav-comments-tab"
                      data-toggle="tab"
                      href="#nav-comments"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      >Comments</a
                    >
                  </div>
                </nav>

                
                <div class="tab-content" id="nav-tabContent">
              
                  <div
                    v-if="order"
                    class="tab-pane fade"
                    id="nav-comments"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div v-if="order.comment.length">
                      <div
                        v-for="(comment, index) in order.comment"
                        v-bind:key="index"
                        class="comments"
                      >
                        <div>
                          <p class="d-flex mb0">
                            <small class="text-muted">
                              <i class="fas fa-comment"></i>
                              {{ " " }}{{ comment.senderUser }}
                            </small>

                            <small class="ml text-muted" style="display: flex">
                              <i class="material-icons mr2 fs20">schedule</i>
                              {{ " "
                              }}{{
                                $moment(comment.dateSent)
                                  .subtract(1, "minutes")
                                  .fromNow()
                              }}
                            </small>
                          </p>
                          <h6 class="my-0" style="max-width: 80%">
                            {{ comment.message }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <h6 v-else class="my-0">No Comments</h6>

              
                    <hr />
                    <form>
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea
                          v-model="commentString"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </div>
                      <button
                        v-if="!commentString.length"
                        disabled
                        class="
                          mb0
                          lh1
                          mlauto
                          list-btn
                          dib
                          d-flex
                          center
                          router-link-active
                          submit-btn
                        "
                      >
                        Submit
                      </button>
                      <button
                        v-else
                        @click.prevent="insertComment()"
                        class="
                          mb0
                          lh1
                          mlauto
                          list-btn
                          dib
                          d-flex
                          center
                          router-link-active
                          submit-btn
                        "
                      >
                        Submit
                      </button>
                    </form>
                  </div>

             
                  <transition name="height" mode="out-in">
                    <div
                      class="tab-pane fade"
                      id="nav-bill"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div v-if="order != null && order.shipmentLines.length">
                        <ul
                          @click="makeActive(index)"
                          v-for="(line, index) in order.shipmentLines"
                          v-bind:key="index"
                          class="bol bgwhite bs-shadow"
                        >
                        
                          <li
                            class="parent"
                            v-bind:id="
                              'parent_' + line.shipmentId + '_' + index
                            "
                          >
                            <small class="ship-head">
                              <span class="ship-number" style="display: flex; align-items: center;" >
                                <!--
                                <i class="fas fa-dolly-flatbed"></i>
                                -->
                                <span class="material-icons" style="font-size:18px">pallet</span>
                                <span style="margin-left: 5px;">{{ " " }}Shipment #{{ line.shipmentId }}</span>
                              </span>

                              <span class="ship-date" v-if="line.shipDate" style="display: flex; align-items: center;">
                                <span class="material-icons" style="font-size:18px">calendar_month</span>
                               
                                <span style="margin-left: 5px;">{{ $moment(line.shipDate).format("MM-DD-YYYY") }}</span>
                              </span>

                              <span class="ship-date" v-else>
                                <i class="fas fa-calendar-check"></i>
                                Not Specified
                              </span>

                              <i
                                data-v-5a90ec03
                                class="p-1 my-auto fas fa-arrow-circle-down"
                                style="width: 1.5em; height: 1.5rem"
                              ></i>
                            </small>
                          </li>
                          <transition name="smooth" mode="out-in">
                            <div v-if="open === index" class="info">
                              <small class="ship-head">
                                <span class="ship-number" style="display: flex; align-items: center;">
                                  <span class="material-icons" style="font-size:18px">local_shipping</span>
                                  <!--
                                  <i class="fas fa-shipping-fast"></i>
                                  -->
                                  <span style="margin-left: 5px;">
                                  {{ " " }}{{ line.shipViaCode }}
                                </span>
                                </span>

                                <span class="ship-date">
                                  <i class="fas fa-truck-loading truck-no"></i>
                                  <span v-if="line.proNo"
                                    >{{ " " }}Trailer #{{ line.proNo }}</span
                                  >
                                </span>
                              </small>
                              <ul
                                class="child-list"
                                v-bind:id="
                                  'child_' + line.shipmentId + '_' + index
                                "
                              >
                                <li
                                  v-for="(
                                    detail, index
                                  ) in line.shipmentHandlingUnitDTOs"
                                  v-bind:key="index"
                                  class="child"
                                >
                                  <small class="w100 dflex">
                                    <span class="pallet">
                                      <i class="fas fa-pallet"></i>
                                      {{ " " + detail.palletType }}
                                    </span>
                                    <span class="weight">
                                      <i class="fas fa-weight"></i>
                                      {{ +detail.netWeight + " lbs" }}
                                    </span>
                                  </small>
                                </li>
                              </ul>
                            </div>
                          </transition>
                        </ul>
                      </div>
                      <h6 v-else class="my-0">No Bill of Lading</h6>
                    </div>
                  </transition>

                  <div
                    class="tab-pane fade show active"
                    id="nav-lines"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div
                      v-if="order"
                      class="tab-pane fade show active"
                      id="nav-lines"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                    <div style="display: flex;">
            
                      <download-csv
                    v-if="downloadLines && downloadLines.length"
                    :data="downloadLines"
                    :name="order.orderDetail[0].orderNo + '-order-lines.csv'"
                    class="mb20 lh1 mlauto list-btn dib" 
                    style="margin-left: auto; display: flex; justify-content: center; align-items: center;"
                    >
                    <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Download CSV</span></download-csv>
                    </div>
                    

                      <table
                        v-if="order != null && order.orderDetail[0].orderLines"
                        class="
                          table table-striped table-bordered
                          lines-table
                          mb0
                        "
                      >
                        <thead>
                          <tr>
                            <th scope="col">Part #</th>
                            <th scope="col">Description</th>
                            <th scope="col" style="width: 8%">Qty</th>
                            
                            <th scope="col" style="width: 15%">
                              <span v-if="calcDisplayPromised">Promised</span>
                              <span v-else>Estimated</span>
                            </th>
                            
                            <th scope="col" style="width: 15%">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(line, index) in order.orderDetail[0]
                              .orderLines"
                            v-bind:key="index"
                          >
                            <th scope="row">
                              <small
                                class="text-muted"
                                style="word-break: break-all"
                              >
                                {{ line.catalogNo }}
                              </small>
                            </th>
                            <td>
                              <small class="text-muted">
                                {{ line.catalogDesc }}
                              </small>
                            </td>
                            <td>
                              <small class="text-muted">
                                {{ line.buyQty }}
                              </small>
                            </td>
                        
                            <td>
                              <small class="text-muted">
                                {{
                                  $moment(line.promisedDeliveryDate).format(
                                    "MM-DD-YYYY"
                                  )
                                }}
                              </small>
                            </td>
                      
                            <td>
                              <small class="text-muted">
                                {{ formatPrice(line.total) }}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h6 v-else class="my-0">No Lines</h6>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-charges"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div
                      v-if="order"
                      class="tab-pane fade show active"
                      id="nav-lines"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <table
                        v-if="
                          order != null &&
                          order.orderDetail[0].orderCharges.length
                        "
                        class="
                          table table-striped table-bordered
                          lines-table
                          mb0
                        "
                      >
                        <thead>
                          <tr>
                            <th scope="col">Type</th>
                            <th scope="col">Description</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(charge, index) in order.orderDetail[0]
                              .orderCharges"
                            v-bind:key="index"
                          >
                            <th scope="row">
                              <small class="text-muted">
                                {{ charge.chargeType }}
                              </small>
                            </th>
                            <td>
                              <small class="text-muted">
                                {{ charge.chargeTypeDesc }}
                              </small>
                            </td>
                            <td>
                              <small class="text-muted">
                                {{ charge.chargedQty }}
                              </small>
                            </td>
                            <td>
                              <small class="text-muted">
                                {{ formatPrice(charge.totalCharge) }}
                              </small>
                            </td>
                            <td>
                              <small class="text-muted">
                                {{ formatPrice(charge.totalCharge) }}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h6 v-else class="my-0">No Charges</h6>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>




          <div class="col-lg-4 mb30">
            <div class="card-back mb30 rounded bs-shadow p-3">
              <ul id="orderdetails-step-4" class="list-group">
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Operating Instructions</span> 
                    </h6>
                    <router-link
                      :to="{ name: 'FieldService' }"
                      class="pdfLink fw300"
                      style="text-decoration: none"
                      >Service and Installation</router-link
                    >
                    </div>
                  </li>

                
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> ETO Install Instructions</span>  
                    </h6>

                    <small class="text-muted">
                      <p
                        v-if="order != null && order.etoDocument.length"
                        class="mb0 fw300"
                      >
                        <a
                          target="_blank"
                          :href="order.etoDocument[0].filePath"
                          rel="noopener noreferrer"
                          class="pdfLink planning-link"
                        >
                          {{ order.etoDocument[0].docNo }}</a
                        >
                      </p>
                      <p v-else class="mb0 text-muted fw300">NA</p>
                    </small>
                  </div>
                </li>

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Acknowledgment</span>
                    </h6>

                    <small class="text-muted fw300">
           
                      <p
                        v-if="order != null && order.ack"
                        @click="showPDF(order.ack.pdfblog)"
                        class="pdfLink"
                      >
                        {{ $moment(order.ack.pdfdate).format("MM-DD-YYYY") }}
                      </p>
                      <p v-else class="mb0">NA</p>
                    </small>
                  </div>
                  </li>

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0 Third" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Bundling Sheet</span>
                    </h6>
                    <small class="text-muted fw300">
                      <p v-if="order != null && order.bundlesheet[0].doesExist == true" class="mb0">
                      
                        <a
                          target="_blank"
                          :href="
                            'https://reportviewer.spacesaver.com/Viewer.aspx?orderid=' +
                            orderId
                          "
                          rel="noopener noreferrer"
                          class="pdfLink planning-link"
                        >
                          Report</a
                        >
                       
      
                      </p>
                      <p v-else class="mb0 text-muted">NA</p>
                    </small>
                  </div>
                </li>
                <!--

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div class>
                    <h6 class="my-0">
                      <i class="fas fa-file-download"></i> CONFIGURA FILES
                    </h6>

                    <small class="text-muted fw300">
                      <p v-if="order != null && order.configura.length">
                        <a
                          target="_blank"
                          :href="order.configura[0].filePath"
                          rel="noopener noreferrer"
                          class="pdfLink planning-link"
                        >
                          {{ order.configura[0].fileName }}</a
                        >
                      </p>
                      <p v-else class="mb0 text-muted">NA</p>
                    </small>
                  </div>
                  </li>
                -->

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div class>
                 
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Invoice(s)</span>  
                    </h6>
                    <div
                      v-if="
                        order != null && order.invoices && order.invoices.length
                      "
                    >
                      <small
                        v-for="invoice in order.invoices"
                        :key="invoice.pdfid"
                        class="text-muted fw300"
                      >
                        <p @click="showPDF(invoice.pdfblog)" class="pdfLink">
                          {{
                            invoice.pdfid +
                            " - " +
                            $moment(invoice.pdfdate).format("MM-DD-YYYY")
                          }}
                        </p>
                      </small>
                    </div>
                    <div v-else>
                      <small>
                        <p class="mb0 text-muted fw300">NA</p>
                      </small>
                    </div>
                  </div>
                  </li>

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">download</span><span style="margin-left: 5px;"> Planning Paperwork</span>  
                    </h6>

                    
                   
                    <small v-if="order != null && order.planning.length" class="text-muted fw300">
                      <p
                      v-for="(paper, index) in order.planning"
                            v-bind:key="index"
                        class="mb0"
                      >
                        <a
                          target="_blank"
                          :href="paper.filePath"
                          rel="noopener noreferrer"
                          class="pdfLink planning-link"
                        >
                          {{ paper.docNo }}</a
                        >
                      </p>
                      
                    </small>

                    <small v-else><p class="mb0 text-muted">NA</p></small>
                  
                  </div>

                  </li>

                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <!--
                    <h6 class="my-0"><i class="fas fa-file-alt"></i> RGAs</h6>
                    -->
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> RGAs</span>  
                    </h6>

                    <div v-if="order != null && order.rga.length">
                      <small
                        v-for="rga in order.rga"
                        :key="rga.rgaid"
                        class="text-muted fw300"
                      >
                        <div>
                          <router-link
                            :to="/rga-details/ + rga.rgaid"
                            class="pdfLink"
                            style="text-decoration: none"
                            >{{ rga.rgaid + " - " + rga.status }}</router-link
                          >
                        </div>
                      </small>
                    </div>
                    <p v-else class="mb0 text-muted">
                      <small class="fw300">No RGA Submitted</small>
                    </p>
                  </div>
                  </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> RFRs</span>  
                    </h6>

                    <div v-if="order != null && order.rfr.length">
                      <small
                        v-for="rfr in order.rfr"
                        :key="rfr.rfrid"
                        class="text-muted fw300"
                      >
                        <div>
                          <router-link
                            :to="/rfr-details/ + rfr.rfrid"
                            class="pdfLink"
                            style="text-decoration: none"
                            >{{ rfr.rfrid + " - " + rfr.status }}</router-link
                          >
                        </div>
                      </small>
                    </div>
                    <p v-else class="mb0 text-muted">
                      <small class="fw300">No RFR Submitted</small>
                    </p>
                  </div>
                  </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    lh-condensed
                  "
                >
                  <div>
                    <h6 class="my-0" style="display: flex; align-items: center;">
                      <span class="material-icons" style="font-size:18px">text_snippet</span><span style="margin-left: 5px;"> Expedited Orders</span>  
                    </h6>

                    <div v-if="order != null && order.eo.length">
                      <small
                        v-for="eo in order.eo"
                        :key="eo.eoid"
                        class="text-muted fw300"
                      >
                        <div>
                          <router-link
                            :to="/expedited-order-details/ + eo.id"
                            class="pdfLink"
                            style="text-decoration: none"
                            >{{ eo.id }}</router-link
                          >
                        </div>
                      </small>
                    </div>
                    <p v-else class="mb0 text-muted">
                      <small class="fw300">No Expedited Order Submitted</small>
                    </p>
                  </div>
                  </li>
              </ul>
            </div>

        
            <div
              id="orderdetails-step-6"
              class="card-back mb5 rounded bs-shadow p-3"
            >
              <ul style="margin-left: 0; padding-left: 0; margin-bottom: 0">
                <li class="button-list">
                  <div class="d-flex w100">
                    
                    <router-link
                      :to="{ name: 'RGAadd', params: { id: orderId } }"
                      class="mb0 lh1 mlauto list-btn dib d-flex center w100"
                    >
                      RGA
                      <i class="material-icons">add</i>
                    </router-link>
                
                  </div>
                </li>

                <li class="button-list">
                  <div class="d-flex w100">
                    
                    <router-link
                      :to="{ name: 'RFRadd', params: { id: orderId } }"
                      class="mb0 lh1 mlauto list-btn dib d-flex center w100"
                    >
                      RFR
                      <i class="material-icons">add</i>
                    </router-link>
                  
                  </div>
                </li>

                <li class="button-list">
                  <div class="d-flex w100">
                   
                    <router-link
                      :to="{
                        name: 'ExpeditedOrderAdd',
                        params: { id: orderId },
                      }"
                      class="mb0 lh1 mlauto list-btn dib d-flex center w100"
                    >
                      Expedited Order Request
                      <i class="material-icons">add</i>
                    </router-link>
                
                  </div>
                </li>
                </ul>
            </div>
             
     
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
import moment from 'moment';
import orderdetailsTour from "../components/Tours/orderdetailsTour.vue";
import { formatCurrency } from '../utils/utils';

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
  },
};

export default {
  name: "OrderDetails",
  components: {
    AtomSpinner,
    // Avatar,
    orderdetailsTour,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      server: import.meta.env.VITE_APP_SERVER_URL,
      orderId: this.$route.params.id,
      userID: this.$store.state.user.UserId,
      bundleSheet: "",
      order: null,
      loading: false,
      processing: false,
      preprod: false,
      inprod: false,
      shipped: false,
      notFound: false,
      open: "",
      pdfPreview: "",
      commentString: "",
      commentString4Email: "",
      days: null,
      scrolled: false,
      view: "orders",
      downloadLines: null,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios
        .get(`${this.ordermanageapi}/order/${this.orderId}`, auth)
        .then((response) => {
          // console.log(response);
          if (response.status == 204) {
            // console.log("no content");
            this.loading = false;
            this.notFound = true;
          } else {
            this.order = response.data;
            this.loading = false;
            document
              .getElementById("mobileScrollTarget")
              .scrollIntoView({ behavior: "smooth" });
          }
          this.orderLinesCSV();
        });
    },
    formatPrice(amount) {
      return formatCurrency(amount);
    },
    makeActive(item) {
      //console.log(item);
      if (this.open === item) {
        this.open = "";
      } else {
        this.open = item;
      }
    },
    copyLink: function () {
      this.$toast.success("Successfully copied link to clipboard!");
    },
    showPDF(doc) {
      this.pdfPreview = doc;
    },
    closeLightbox() {
      this.pdfPreview = "";
    },
    backLink: function () {
      this.$router.back();
    },
    openBundleSheet: function (link) {
      this.bundleSheet = link;
    },
    closeBundleSheet: function () {
      this.bundleSheet = "";
    },
    orderLinesCSV: function(){
      let i = 0;



      console.log("ran")
      let data = this.order.orderDetail[0].orderLines;
      
      /*
      this.downloadLines =  this.downloadLines.filter(function(ele){ 
            return ele != ele.relNo; 
      });
      */
      while (data[i]) {
        delete data[i].relNo;
        delete data[i].lineItemNo;
        delete data[i].currencyRate;
        // data[i]["orderNo"] = data[i]["Order Number"];
        i++;
      }
      this.downloadLines = data.sort((a,b) => a.lineNo - b.lineNo)
      console.log(data)
      //.sort(function(data){return data.lineNo - data.lineNo})
    },
    insertComment() {
      let createCase;
      let daysElapsed;
      const thePostDate = new Date();
      console.log("the post date " + thePostDate);
      //server time now
      let nowDate = this.$moment(this.order.serverTime);
      //console.log("now date: " + nowDate);
      //let lastPostDate = order.comment.dateSent;

      //console.log("last post sent " + lastPostObject.dateSent);
      if (this.order.comment.length && !this.employee) {
        const lastPostObject = [...this.order.comment].pop();
        const lastPostDate = this.$moment(lastPostObject.dateSent);
        //const daysElapsed = moment.duration(lastPostDate.diff(nowDate)).asDays();
        let daysElapsed = nowDate.diff(lastPostDate, "days");
        this.days = nowDate.diff(lastPostDate, "days");
        //console.log("days elapsed: " + daysElapsed);
        //console.log("last post: " + lastPostDate);
      } else {
        let daysElapsed = 0;
        this.days = 0;
      }

      this.commentString4Email = this.commentString;

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };

      let theDATA = {
        parentId: this.orderId,
        commentType: "Order",
        senderUserID: this.userID,
        dateSent: thePostDate,
        message: this.commentString,
        subject: `Order Comment: ${this.orderId}`,
      };

      let sfData = {
        orderNum: this.orderId,
        name: this.getName,
        emailAddress: this.getEmail,
        comment: this.commentString4Email,
      };

      /*
      let customerNotifydata = {
        message: this.commentString4Email,
        sender: this.getName,
        linkID: `order-details/${this.orderId}`,
        notify: this.rga.rga[0].requestorEmail,
      };
      */

      return axios
        .post(`${this.ordermanageapi}/comments`, theDATA, axiosConfig)
        .then((response) => {
          console.log(response);
          // this.$toast.success("Successfully added comment!");
          this.$toast.success("Successfully added comment!", { hideProgressBar: false, icon: false });
          //this.order.comment.push(theDATA);
          this.order.comment = response.data;
          this.commentString = "";
        })
        .then((response) => {
          //console.log("Send the comment");

          if (this.order.comment.length && this.employee == false) {
            //if there is no comments and not an employee
            //console.log("create an original case");
            return axios
              .post(
                `${this.server}/api/v1/salesforce-case-email`,
                sfData,
                axiosConfig
              )
              .then((response) => {
                //console.log(response);
                this.commentString4Email = "";
                // this.$toast.success("Successfully created a new salesforce case!");
                this.$toast.success("Successfully created a new salesforce case!", { hideProgressBar: false, icon: false });
              })
              .catch((err) => {
                //console.log(err);
                // this.$toast.error("Sorry, there was an issue making a new salesforce case");
                this.$toast.error("Sorry, there was an issue making a new salesforce case", { hideProgressBar: false, icon: false });
              });
          } 
          /*
          else if (
            this.order.comment.length &&
            this.employee == false &&
            this.days > 3
          ) {
            // there are comments, it's not an employee commenting, and the last comment is older than 4 days
            //console.log("Make a new case, it's aged");
            return axios
              .post(
                `${this.server}/api/v1/salesforce-case-email`,
                sfData,
                axiosConfig
              )
              .then((response) => {
                //console.log(response);
                this.commentString4Email = "";
                this.$toast.success(
                  "Successfully created a new salesforce case!"
                );
              })
              .catch((err) => {
                //console.log(err);
                this.$toast.error(
                  "Sorry, there was an issue making a new salesforce case"
                );
              });
          } 
          */
        })
        .catch((err) => {
          //console.log(err);
          // this.$toast.error("Sorry, there was an issue posting your comment");
          this.$toast.error("Sorry, there was an issue posting your comment", { hideProgressBar: false, icon: false });
        })
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    getEmail() {
      return this.$store.state.user.Email;
    },
    getName() {
      return (
        this.$store.state.user.FirstName + " " + this.$store.state.user.LastName
      );
    },
    calcProcessing: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "In Engineering" ||
          orderstate == "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          //console.log("true");
          // this.processing = true;
          return true;
        } else {
          // this.processing = false;
          //console.log("false");
          return false;
        }
      } else {
        return;
      }
    },
    calcPreProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate === "In Planning" ||
          orderstate == "Planned" ||
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcInProd: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcShipped: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (orderstate === "Delivered" || orderstate === "Invoiced/Closed") {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    calcDisplayPromised: function () {
      if (this.order != null && this.loading == false) {
        let orderstate = this.order.orderStatus[0].status;
        if (
          orderstate == "Released" ||
          orderstate == "Reserved" ||
          orderstate == "Picked" ||
          orderstate == "Partially Delivered" ||
          orderstate == "Delivered" ||
          orderstate == "Invoiced/Closed"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return;
      }
    },
    employee() {
      return this.$store.getters.isSpacesaverEmployee;
    },
  },
  created() {
    this.$store.commit("showNavigation");
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.card-back {
  background: #fff;
}
.article {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.tab-container {
  background: #f2f2f3;
  padding: 10px;
  border-radius: 4px;
}
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
}
#nav-tabContent {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.d-flex {
  display: flex;
}
.ml {
  margin-left: auto;
}
/*
.comments:nth-child(even) {
  background: #efefef;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
}
*/
.comments {
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}
.comments p {
  margin-bottom: 10px;
}
.comments:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.comments:last-child p {
  margin-bottom: 0;
}
.tab-container a {
  color: #429da8;
}
.mt30 {
  margin-top: 30px;
}
.badge {
  display: inline-block;
  padding: 0.5em 0.8em;
}
.progtrckr {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
}

.progtrckr[data-progtrckr-steps="2"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="3"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="4"] li {
  width: 25%;
}
.progtrckr[data-progtrckr-steps="5"] li {
  width: 25%;
}

.progtrckr li.progtrckr-done {
  color: #fff;
  border-bottom: 4px solid #fff;
}
.progtrckr li.progtrckr-todo {
  color: #3e4651;
  border-bottom: 4px solid #3e4651;
}
.progtrckr li.progtrckr-partial {
  color: #ffff;
  border-bottom: 4px solid rgba(255, 255, 255, 0.5);
}

.progtrckr li:after {
  content: "\00a0\00a0";
  margin-left: 28px;
  /* this is the text*/
}
.progtrckr li:before {
  position: relative;
  bottom: -2.5em;
  float: left;
  left: calc(50% - 20px);
  line-height: 1em;
}
.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: #3e4651;
  background-color: #fff;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: none;
  border-radius: 40px;
}
.progtrckr li.progtrckr-todo:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: #3e4651;
  background-color: #3e4651;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}
.progtrckr li.progtrckr-partial:before {
  /*
  content: "\039F";
  color: #fff;
  background-color: #fff;
  font-size: 2.2em;
  bottom: -1.2em;
  */
  content: "\2713";
  color: rgba(255, 255, 255, 0);
  background-color: #9bdada;
  height: 2.2em;
  width: 2.2em;
  line-height: 2.2em;
  border: none;
  border-radius: 2.2em;
}

/* trackker titles */
.tracker-title-wrap {
  margin-top: 15px;
}
.trckr-title {
  padding-left: 0;
}
.trckr-title li {
  display: inline-block;
  text-align: center;
  line-height: 3.5em;
  width: 25%;
  color: #fff;
}
.trckr-title-done {
  color: #fff !important;
}
.trckr-title-todo {
  color: #3e4651 !important;
}
.badge-success {
  color: #fff;
  background-color: #3e4651;
}
@media all and (max-width: 700px) {
  .trckr-title li {
    font-size: 12px;
  }
}
.up {
  text-transform: uppercase;
}
.lines-table td,
.lines-table th {
  padding: 5px;
}
.small,
small {
  font-size: 90% !important;
}

.table td .small,
small {
  font-size: 90% !important;
}
.bol {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  padding: 10px;
  list-style-type: none;
  /*border: 1px solid #dee2e6;*/
  background: #efefef;
  margin-bottom: 5px;
  transition: background 0.8s linear;
  border-radius: 4px;
  cursor: pointer;
}
.bol:hover {
  background: #e6e5e5;
}
.parent {
  display: flex;
  align-items: center;
}
.parent i {
  transition: color 0.8s linear;
}
.parent:hover i {
  color: #000;
}
.parent:hover {
  color: #000;
}
.parent:active i {
  color: #00b4b4;
}
.child-list {
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  /*transition: height 0.8s linear;*/
  max-height: 100%;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}
.child-list li {
  position: relative;
  list-style-type: none;
  margin-left: 0;
  border-bottom: 1px solid #dedede;
  padding: 5px;
}
.child-list li:last-of-type {
  border-bottom: none;
}
.is-closed {
  max-height: 0;
}
.show {
  /*height: 100% !important;*/
  max-height: 100% !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: opacity 0.5s;
  height: auto;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}

.height-enter-active,
.height-leave-active {
  transition: height 0.5s;
  height: auto;
}
.height-enter,
.height-leave-to {
  height: 0;
}
.ship-head {
  display: flex;
  width: 100%;
}
.pallet {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ship-number {
  width: calc(50% + 3px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 700px) {
  .pallet {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ship-number {
    width: calc(60% + 3px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fa-arrow-circle-down {
  margin-left: auto;
}

.w100 {
  width: 100%;
}
.dflex {
  display: flex;
}
.info {
  border-top: 1px solid #dedede;
  padding-top: 5px;
  margin-top: 5px;
}
.truck-no {
  margin-left: -5px;
}
.closeX {
  top: 0;
}
.pdfLink {
  color: #429da8;
  cursor: pointer;
  margin-bottom: 0;
}
.pdfLink:hover {
  color: #00b4b4;
}
.pdfLink:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10003 !important;
}
.button-list {
  margin-bottom: 5px;
  list-style-type: none;
}
.submit-btn {
  border: none !important;
  padding: 7px 12px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}
.planning-link:hover {
  text-decoration: none;
}
.planning-link:active {
  text-decoration: none;
}

@media all and (max-width: 991px) {
  #nav-tabContent {
    overflow: scroll;
  }
  #nav-lines {
    width: 800px;
  }
}
.table td small {
  word-break: break-word;
}
.fw300 {
  font-weight: 300;
}
</style>
