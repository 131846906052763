<template>
  <div>
  
    <rfradd-tour v-if="$store.state.tour" />
    
    <section class="py-5 bg9 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Request for Reimbursement</h1>
        <h5 class="text-white lh1 mb0">Order #: {{ orderId }}</h5>
      </div>
    </section>

    <div class="container">
      <div class="d-flex number-showing mt20">
        <back-button/>
        <!--
        <router-link
          :to="{ path: $store.state.route.from.fullPath }"
          class="mb0 lh1 mrauto list-btn dib d-flex center"
        >
          <i class="material-icons">keyboard_arrow_left</i>
          Back to Order
        </router-link>
      -->
      </div>
    </div>

    <section class="py-5 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <div class="loader teal">
          <div
            v-bind:class="progressWidth"
            class="progress-bar"
            id="RFRadd-step-1"
          >
            <div class="progress-stripes"></div>
            <div class="percentage">{{ progressPercentage }}</div>
          </div>
        </div>

        <transition
          enter-active-class="animated fadeIn

"
          leave-active-class="animated fadeOut"
          :duration="500"
          mode="out-in"
          appear
        >
          <div v-if="step == 1" class="step1" key="step1">
            <div class="listing">
              <h5>
                <span class="teal">Step 1:</span> Customer Satisfaction Policies
                & Procedures
              </h5>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-12">
                  <h3>SCOPE</h3>
                  <hr />
                  <p>
                    This procedure applies to all products manufactured and/or
                    sold by Spacesaver. The main purpose for this procedure is
                    to ensure Spacesaver’s involvement in resolving any issues
                    created by Spacesaver and encountered by it’s Area
                    Contractors and Distributors. Spacesaver reserves the right
                    to work with Area Contractors and Distributors in gaining
                    amiable resolution to issues in an effort to satisfy our
                    mutual customers and to reduce costs incurred by Area
                    Contractors, Distributors, and Spacesaver.
                  </p>
                  <br />
                  <h3>POLICY</h3>
                  <hr />
                  <p>
                    Spacesaver will ensure prompt, courteous and responsible
                    service for all customers. Spacesaver will reimburse costs
                    incurred by Distributors due to manufacturing shortages,
                    defective products and incorrectly shipped products.
                  </p>
                  <p>
                    Anytime a Distributor is considering the submission of a
                    part “Shortage” related Request for Reimbursement (RFR),
                    they can access SpaceNet and submit the RFR. Spacesaver will
                    reimburse for drive time and mileage expenses. Labor to
                    install shortage parts will not be reimbursed as labor was
                    already paid by the customer to install all parts.
                  </p>
                  <p>
                    Anytime a Distributor is considering the submission of a
                    “Service” related RFR, they must first contact Spacesaver’s
                    Field Service Department for resolution to the problem and a
                    Case Number. This Case Number is the Distributor’s
                    pre-authorization number to submit a RFR. Without it, access
                    to submit a RFR on SpaceNet is not possible.
                  </p>
                  <p>
                    Spacesaver will reimburse Distributors for their labor
                    expense on reimbursement claims, provided the labor hours
                    billed do not exceed the hours calculated by Configura.
                    Negotiation of labor hours in excess of the times calculated
                    by Configura must take place with Spacesaver prior to the
                    reimbursement process. Spacesaver will work with
                    Distributors in specific situations to work toward fair and
                    equitable resolutions for both Spacesaver and Distributors.
                  </p>
                  <p>
                    Spacesaver will reimburse for travel time on an hourly basis
                    and mileage will be reimbursed at the rate specified by
                    Spacesaver, additional expenses such as fuel, per diem
                    charges, etc., will not be reimbursed. Spacesaver reimburses
                    these expenses on a portal to portal basis, which covers
                    round trip travel (to and from the customer’s facility) from
                    the Distributors nearest service dispatch location or
                    nearest office.
                  </p>
                  <p>
                    Travel hours will be reimbursed for one certified technician
                    only, unless prior authorization by Spacesaver is granted.
                    If authorization is granted for more than one certified
                    technician, additional labor will be billed at the same
                    rate.
                  </p>
                  <p>
                    Additional labor charges for miscellaneous shop supplies,
                    disposal, wait time, or tools will not be reimbursed. No
                    labor rate will be paid in excess of Spacesaver’s specified
                    reimbursement rate. Charges for overtime or weekend work
                    must be preauthorized by Spacesaver before the work is
                    performed.
                  </p>
                  <p>
                    In the event that a Distributor identifies defective product
                    before installation; and installs the defective product
                    without prior authorization from Spacesaver, the Distributor
                    will not be reimbursed for labor to install and remove the
                    defective product before installing the replacement parts
                    from Spacesaver.
                  </p>
                  <p>
                    For reimbursement claims regarding warranty items; if the
                    job is within it’s first year of warranty, the Distributor
                    will provide the service/labor and Spacesaver will provide
                    the replacement parts at no cost to one another or to the
                    customer. (See Spacesaver’s Warranty Policies for terms and
                    conditions).
                  </p>
                  <p>
                    The Distributor is responsible for the quality of the work
                    performed; if subcontractors that are not Spacesaver factory
                    trained and certified are used, they must be supervised by a
                    Spacesaver factory certified technician. Any RFR submissions
                    where an untrained, uncertified subcontractor was used in
                    the absence of a Spacesaver factory certified technician
                    will be denied. If the Distributor chooses to use the
                    services of a subcontractor (i.e. commercial refrigeration
                    specialists, locksmiths, etc.), labor charges must be
                    authorized by Spacesaver prior to any work being performed.
                    Invoices from these subcontractors must be submitted to
                    Spacesaver for verification.
                  </p>
                  <p>
                    Spacesaver will review the reimbursement rates annually
                    using regional prevailing wage reports and survey
                    Distributors to arrive at an average. Any changes will
                    become effective, if approved, on February 1st of the same
                    year.
                  </p>
                  <br />
                  <h3>DEFINITIONS</h3>
                  <hr />
                  <ul>
                    <li>
                      The
                      <b
                        >Installation/Field Quality Report (FQR) Inspection
                        Forms</b
                      >
                      are identified by form numbers. (Rev. 7.0/OP-9831 for
                      Mobile Installations. Rev. 2.0/SC-9201 for all other
                      product installations).
                    </li>
                    <li>
                      <b>Request for Reimbursement (RFR)</b> is a process
                      available to Spacesaver Distributors to be reimbursed for
                      extra costs incurred due to Spacesaver error(s). Available
                      through SpaceNet (see attached link).
                      http://spacenet2.spacesaver.com/OrderManage/RFR.aspx
                    </li>
                    <li>
                      <b>Defective product</b> is generally determined prior to
                      customer acceptance. However, once the customer has signed
                      the system acceptance or sixty days after shipment,
                      product defects are covered under Spacesaver’s Warranty
                      Policy. Normal wear and improper product use are not
                      covered under this policy. Please reference Specific
                      Product Statement of Warranty (see attached link).
                      http://spacenet2.spacesaver.com/Documents/SC-0913StatementofWarranty_1009.pdf
                    </li>
                    <li>
                      <b>Manufacturer shipment shortage</b> claims (full skids,
                      cartons, packages, etc.) will only be considered within
                      thirty days of original shipment. Manufacturer shipment
                      shortage claims (within sealed skids, cartons, packages,
                      etc.) will only be considered within fifteen days of
                      installation commencement. The identification number from
                      the skid, carton, or package that does not have the
                      correct parts must be listed on the FQR.
                    </li>
                    <li>
                      <b>Incorrect shipments</b> will only be recognized when
                      Spacesaver ships product different from what was ordered
                      or as amended and confirmed in writing with Spacesaver’s
                      support group. The Distributor must review all order
                      acknowledgements and verify planned product shipments for
                      correctness prior to scheduled shipping dates.
                    </li>
                    <li>
                      <b>Damaged Product</b> is identified under Spacesaver’s
                      Terms and Conditions Guidelines under Damaged Shipments.
                      (Page 6 of the Statement of Warranty).
                    </li>
                  </ul>
                  <br />
                  <h3>PROCEDURE</h3>
                  <hr />
                  <ul>
                    <li>
                      All installations require the submission of a FQR to
                      Spacesaver within 15 days after installation. FQR’s must
                      be filed regardless of identifiable defects/problems.
                    </li>
                    <li>
                      In the event that a Distributor is considering the
                      submission of a part “Shortage” RFR, the Distributor can
                      access SpaceNet and submit the RFR. Part shortage RFR’s
                      will reimburse for drive time and mileage only.
                    </li>
                    <li>
                      In the event that a Distributor is considering the
                      submission of a “Service” RFR, the Distributor must
                      contact Spacesaver’s Field Service Department as soon as
                      possible. Contact with Spacesaver is to be made before or
                      during the installation process of the job. Once contact
                      is made, Spacesaver’s Field Service Department will work
                      with the Distributor to make recommendations on how to
                      resolve the defects/problems encountered, and if agreed
                      upon, will issue a Case Number which will pre-authorize
                      the submission of a RFR.
                    </li>
                    <li>
                      Requests for Reimbursement must be submitted via SpaceNet
                      (see attached link).
                      http://spacenet2.spacesaver.com/OrderManage/RFR.aspx
                    </li>
                    <li>
                      Requests without a FQR on file, or not accompanied by a
                      FQR on a new installation, will not be considered.
                    </li>
                    <li>
                      The Field Service Department will administer the
                      resolution of Requests for Reimbursements in accordance
                      with the following timeframes:
                      <ul>
                        <li>
                          Shortages, incorrect parts shipped, or defective
                          products not requiring lab testing, outside
                          verification, additional information, or further
                          follow up:
                        </li>
                        <li>
                          and are under $1,000 will be processed in 3 working
                          days
                        </li>
                        <li>
                          and are over $1,000 and less than $5,000 will be
                          processed in 4 work days
                        </li>
                        <li>
                          and are over $5,000 will be processed in 6 work days
                        </li>
                        <li>
                          Defective products requiring lab testing or outside
                          verification will be processed within 6 weeks.
                        </li>
                      </ul>
                    </li>
                    <li>
                      The Field Service Department will work with the
                      Distributor to resolve all issues. If agreement cannot be
                      reached on a particular issue, settlement will be
                      arbitrated by the Field Service Department,
                      Manager-Product Engineering & Quality, VP-Strategic
                      Business Unit, and/or the Regional Business Manager.
                    </li>
                    <li>
                      The Field Service Department will verify all installation
                      labor charges.
                    </li>
                    <li>
                      Hourly labor rates and mileage reimbursement will be
                      established each year. Each installation group is to use
                      their current labor and mileage rates up to a maximum of
                      $60.00 per hour for labor and $0.51 for mileage requests.
                      Mileage is determined and verified using Rand McNally or
                      MapQuest using the “most direct route”.
                    </li>
                    <li>
                      Distributors are not permitted to deduct a Request for
                      Reimbursement amount from a Spacesaver invoice unless
                      explicitly instructed to do so by the Spacesaver Finance
                      Department.
                    </li>
                    <li>
                      Warranty labor and/or authorized backorder expenses will
                      not be considered for reimbursement.
                    </li>
                    <li>
                      Electric parts are only covered under the SSC
                      Reimbursement Policy when: the parts in question are
                      received by SSC, unopened from static protection, under a
                      RGA number and determined to be defective by SSC’s
                      Electronics Department. Defective electronic parts are
                      parts with discernible defects or incorrect parts. Parts
                      that are faulted-out but correctly manufactured are not
                      covered under SSC’s Reimbursement Policy.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                    <button @click.prevent="setPage(step + 1)" class="mlauto">
                      I Agree
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 2" class="step2 listing" key="step2">
            <h5>
              <span class="teal">Step 2:</span> Request for reimbursement
              <span class="teal">(required)</span>
            </h5>
            <hr />
            <div class="row">
              <div class="col-xs-12 col-md-6 col-12">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Reason</label>
                  <select
                    v-model="reason"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <!--
                      <option :value="null" selected class="all-contractors">Select a lead carrier</option>
                    -->
                    <option value="null" selected>Select a reason</option>
                    <option value="shortage">Shortage</option>
                    <option value="service">Service</option>
                  </select>
                </div>
                <div class="form-group" v-if="reason == 'service'">
                  <label for="exampleFormControlTextarea1"
                    >Please provide your salesforce.com case #</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="Salesforce case number"
                    placeholder="Enter Salesforce case number"
                    v-model="caseNumber"
                    required
                  />
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Don't have one? Call in to start a case.
                  </small>
                </div>

                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >What were the total labor hours incurred?</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="total labor hours"
                    placeholder="Enter total labor hours"
                    v-model="laborHours"
                    required
                  />
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Current labor rate is $65.00
                  </small>
                </div>
              </div>
              <div class="col-xs-12 col-md-6 col-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >What were the total miles incurred?</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="total miles"
                    placeholder="Enter total miles"
                    v-model="totalMiles"
                    required
                  />
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Current mileage rate is $.67
                  </small>
                </div>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >Please total any additional expenses incurred.</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="additional expenses"
                    placeholder="Enter additional expenses"
                    v-model="additionalExpenses"
                    required
                  />
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Receipts of additional expenses must be uploaded.
                  </small>
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="rd_2"
                    name="rd"
                    class="custom-control-input"
                    value="US Dollars"
                    v-model="currency"
                  />
                  <label class="custom-control-label red" for="rd_2"
                    >US Dollars</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <input
                    type="radio"
                    id="rd_1"
                    name="rd"
                    class="custom-control-input"
                    value="Canadian Dollars"
                    v-model="currency"
                  />
                  <label class="custom-control-label green" for="rd_1"
                    >Canadian Dollars</label
                  >
                </div>
                <div class="custom-control custom-radio mb20">
                  <input
                    type="radio"
                    id="rd_3"
                    name="rd"
                    class="custom-control-input"
                    value="Pounds"
                    v-model="currency"
                  />
                  <label class="custom-control-label green" for="rd_3"
                    >Pounds</label
                  >
                </div>
              </div>
              <div class="col-xs-12 col-md-12 col-12">
                <div class="form-group">
                  <label for="exampleFormControlTextarea1"
                    >Please provide specific information details the problem and
                    how you determined the amounds requested</label
                  >

                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    v-model="notes"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col col-12">
                <div
                  class="
                    nav-btns
                    listing-header
                    d-flex
                    align-items-center
                    mt10
                    mb10
                    rounded
                    b-shadow
                  "
                >
                  <button @click.prevent="setPage(step - 1)" class="mrauto">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-if="reason == 'service' && caseNumber == ''"
                    disabled
                    class="mlauto"
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                  <button
                    v-else-if="
                      reason !== 'null' &&
                      typeof laborHours === 'number' &&
                      typeof totalMiles === 'number' &&
                      notes.length
                    "
                    @click.prevent="submitOrder"
                    class="mlauto"
                  >
                    Create RFR
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button v-else disabled class="mlauto">
                    Create RFR
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 3" class="step4 listing" key="step4">
            <form>
              <h5>
                <span class="teal">Step 4:</span> Attachments
                <span class="teal" v-if="additionalExpenses.length"
                  >(Required due to additional expenses)</span
                >
                <span class="teal" v-else>(optional)</span>
              </h5>
              <hr />
              <div class="row">
                <div class="col col-12">
                  <form enctype="multipart/form-data">
                    <div
                      class="file is-boxed is-primary"
                      style="text-align: center"
                    >
                      <input
                        v-if="files.length > 5"
                        disabled
                        multiple
                        type="file"
                        ref="files"
                        class="file-input"
                        aria-describedby="upload files"
                        placeholder="Upload Files"
                        @change="selectFile"
                        style="display: none"
                        id="img"
                      />
                      <input
                        v-else
                        multiple
                        type="file"
                        ref="files"
                        class="file-input"
                        aria-describedby="upload files"
                        placeholder="Upload Files"
                        @change="selectFile"
                        style="display: none"
                        id="img"
                        accept=".txt,.pdf,.jpg,.png,.jpeg,.om"
                      />

                      <label
                        for="img"
                        class="label file-input"
                        style="text-align: center"
                      >
                        <span class="file-cta">
                          <span class="material-icons"> insert_photo </span>

                          <div class="file-label">Choose Files</div>
                        </span>
                      </label>
                    </div>

                    <div
                      v-if="message && fileCountError == ''"
                      :class="`message ${
                        error ? 'text-danger' : 'text-success'
                      }`"
                      style="font-style: italic; text-align: center"
                    >
                      {{ message }}
                    </div>
                    <div
                      v-else-if="fileCountError.length"
                      :class="`message ${
                        error ? 'text-danger' : 'text-success'
                      }`"
                      style="font-style: italic; text-align: center"
                    >
                      {{ fileCountError }}
                    </div>
                    <div v-if="files.length && !sending">
                      <hr />
                      <div
                        v-for="(file, index) in files"
                        :key="index"
                        :class="`edit-list ${
                          file.invalidMessage && 'text-danger'
                        }`"
                      >
                        <div class="file-left">
                          <div class="file-item">
                            {{ file.name
                            }}<span v-if="file.invalidMessage"
                              >&nbsp;-
                              <small>{{ file.invalidMessage }}</small></span
                            >
                          </div>
                        </div>
                        <div class="file-right">
                          <div class="file-item">
                            <button @click.prevent="removeFromList(index)">
                              <span class="material-icons"> clear </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                v-if="
                  message == 'Please be patient, we are processing your files'
                "
                class="row"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-12 col-lg-12">
                      <div class="flex-box-wrap" style="padding: 30px 0">
                        <div class="flex-internal">
                          <atom-spinner
                            :animation-duration="1000"
                            :size="40"
                            :color="'#575757'"
                          />
                          <p>Uploading......</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col col-12">
                  <div
                    class="
                      nav-btns
                      listing-header
                      d-flex
                      align-items-center
                      mt10
                      mb10
                      rounded
                      b-shadow
                    "
                  >
                    <button
                      v-if="
                        fileCountError.length ||
                        message ==
                          'Please be patient, we are processing your files'
                      "
                      disabled
                      class="mlauto"
                    >
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button
                      v-else-if="files.length && fileCountError == ''"
                      @click.prevent="sendFile()"
                      class="mlauto"
                    >
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button
                      v-else-if="!files.length && additionalExpenses.length"
                      disabled
                      class="mlauto"
                    >
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>

                    <button v-else @click.prevent="justSubmit" class="mlauto">
                      Submit
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>


<script>
import axios from "axios";
import rfraddTour from "../components/Tours/rfraddTour.vue";
import { AtomSpinner } from "epic-spinners";
// import Avatar from "vue-avatar";
import _ from "lodash";
import BackButton from '@/components/Buttons/BackButton.vue';
// import "animate.css";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "RFRAdd",
  components: {
    AtomSpinner,
    BackButton,
    // Avatar,
    rfraddTour,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      server: import.meta.env.VITE_APP_SERVER_URL,
      orderId: this.$route.params.id,
      step: 1,
      userID: this.$store.state.user.UserId,
      totalPage: 3,
      loading: true,
      percentage: "10%",
      notes: "",
      caseNumber: "",
      todos: [],
      reason: "null",
      laborHours: "",
      totalMiles: "",
      additionalExpenses: "",
      notes: "",
      returnedID: "",
      files: [],
      uploadFiles: [],
      message: "",
      error: false,
      fileCountError: "",
      allowFileCount: 5,
      sending: false,
      currency: "US Dollars",
      acid: this.$store.state.user.ACId[0],
      commentString4Email: "",
    };
  },
  methods: {
    firstLoad: function () {
      //this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.scrollBehavior();
      //return axios.get(this.endPointCompute, auth).then((response) => {
      /*
      return axios
        .get(`${this.ordermanageapi}/order/${this.orderId}`, auth)
        .then((response) => {
          console.log(response);
          this.order = response.data;
          this.loading = false;
        });
        */
    },
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    setPage: function (idx) {
      document.activeElement.blur();
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.step = idx;
    },
    submitTask() {
      this.todos.push({
        partNumber: this.partNum,
        completed: false,
        quantity: this.quantity,
        color: this.color,
        reason: this.reason,
        notes: this.notes,
      });

      this.partNum = "";
      this.quantity = "";
      this.color = "";
      this.reason = "Select One";
      this.notes = "";
    },
    remove(index) {
      this.todos.splice(index, 1);
    },
    selectFile() {
      this.disabled = false;
      this.message = "";
      //
      const files = this.$refs.files.files;
      if (files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
      }

      //set files to files that are already there, and add the files that the user just selected
      //the actual javascript files object to be uploaded
      this.uploadFiles = [...this.uploadFiles, ...files];

      this.files = [
        ...this.files,
        ..._.map(files, (file) => ({
          name: file.name,
          size: file.size,
          type: file.type,
          invalidMessage: this.validate(file),
        })),
      ];
    },
    validate(file) {
      const MAX_SIZE = 4 * 1024 * 1024; // 4 MB
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic",
        "application/pdf",
      ];
      if (file.size > MAX_SIZE) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `The max file size is 2mb`;
      }

      if (file.name.includes(".exe") || file.name.includes(".dmg")) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `An executable file, really?`;
      }

      if (!allowedTypes.includes(file.type)) {
        this.error = true;
        this.message = "Problematic files will be ignored";
        return `Sorry, but this file isn't an image or PDF`;
      }
      return "";
    },
    async sendFile() {
      try {
        const axiosConfig = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
          },
        };

        this.sending = true;
        this.message = "Please be patient, we are processing your files";

        const formData = new FormData();
        formData.append("parent", this.returnedID);
        formData.append("attachtype", "RFRs");

        _.forEach(this.uploadFiles, (file) => {
          if (this.validate(file) === "") {
            formData.append("files", file);
            console.log("File added:", file);
          } else {
            console.log("File validation failed:", file);
          }
        });

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        const response = await axios.post(
          `${this.ordermanageapi}/attachments`,
          formData,
          axiosConfig
        );

        this.sending = false;
        console.log(response);
        this.message = "Files have been uploaded!";
        this.files = [];
        this.uploadFiles = [];
        this.error = false;
        this.$toast.success("Successfully added RFR!");
        this.$router.push(`/rfr-details/${this.returnedID}`);
      } catch (err) {
        this.sending = false;
        console.error(err);
        this.message = err.response?.data?.error || "Something went wrong";
        this.error = true;
        this.$toast.error("Sorry, there was an issue uploading your files");
      }
    },
    removeFromList(index) {
      this.files.splice(index, 1);
      this.uploadFiles.splice(index, 1);
      if (this.files.length > this.allowFileCount) {
        this.fileCountError = `Sorry, only ${this.allowFileCount} files are allowed`;
      } else {
        this.fileCountError = "";
        /*
				_.forEach(this.uploadFiles, (file) => {
					if (this.validate(file) !== "") {
						this.message = "Problematic files will be ignored";
					} else {
						this.message = "Good to go!";
					}
                });
        */
      }
    },
    justSubmit() {
      ///expedited-order-details/27538
      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });
      this.$toast.success("Successfully added expedited order!");
      this.$router.push(`/rfr-details/${this.returnedID}`);
    },
    submitOrder() {
      var thePostDate = new Date();
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        orderId: this.$route.params.id,
        CaseNumber: this.caseNumber,
        requestedByUser: this.userID,
        SubmitDate: thePostDate,
        acid: this.acid,
        status: "Pending",
        explanation: this.notes,
        requestReason: this.reason,
        requestedHours: this.laborHours,
        requestedLaborRate: 65.0,
        requestedMileage: this.totalMiles,
        requestedSecondaryTotal: this.additionalExpenses,
        currencyType: this.currency,
        mileageRate: 0.67,
      };

      let sfData = {
        orderNum: this.$route.params.id,
        name: this.getName,
        emailAddress: this.getEmail,
      };

      document
        .getElementById("mobileScrollTarget")
        .scrollIntoView({ behavior: "smooth" });

      axios
        .post(`${this.ordermanageapi}/rfr`, postData, axiosConfig)
        .then(
          (response) => {
            //console.log(response);
            this.returnedID = response.data.rfrid;
            this.step = 3;
          },
          (error) => {
            //console.log(error);
          }
        )
        .then(() => {

          return axios
            .post(`${this.server}/api/v1/new-rfr-email`, sfData, axiosConfig)
            .then((response) => {
        
              this.$toast.success("Successfully created a new salesforce case!");
            })
            .catch((err) => {
        
              this.$toast.error(
                "Sorry, there was an issue making a new salesforce case"
              );
            });

        });
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },
    getUsername() {
      return this.$store.state.user;
    },
    progressWidth: function () {
      let className = "first-step";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      } else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      }
    },
    progressWidthComplex: function () {
      let className = "zero";
      if (this.step == 1) {
        let className = "first-step";
        return className;
      } else if (this.step == 2) {
        let className = "second-step";
        return className;
      } else if (this.step == 3) {
        let className = "third-step";
        return className;
      } else if (this.step == 4) {
        let className = "fourth-step";
        return className;
      }
    },
    progressPercentage: function () {
      if (this.step == 1) {
        return "20%";
      } else if (this.step == 2) {
        return "60%";
      } else if (this.step == 3) {
        return "90%";
      } else if (this.step == 4) {
        return "90%";
      }
    },
    companyID() {
      let number = this.$store.state.user.ACId[0];
      this.acid = number;
    },
    getEmail() {
      return this.$store.state.user.Email;
    },
    getName() {
      return (
        this.$store.state.user.FirstName + " " + this.$store.state.user.LastName
      );
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
  },
};
</script>

<style scoped>
.loader {
  margin: 0 auto 20px;
  width: 100%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}

.percentage {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  font-size: 16px;
}
/* PURPLE */
.teal .progress-bar {
  background: #00b4b4;
}
.teal .progress-stripes {
  color: #00b4b4;
}
.teal .percentage {
  color: #eee;
}
.first-step {
  width: 20%;
}
.second-step {
  width: 60%;
}
.third-step {
  width: 90%;
}
.fourth-step {
  width: 100%;
}

.teal {
  color: #00b4b4;
}
.listing {
  border-radius: 4px;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
td input {
  border: none !important;
}
.table-striped td {
  background: #efefef;
}
td select {
  border: none;
}
.file-input {
  background: #00b4b4;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}
.file-input .material-icons {
  font-size: 2em;
}
.flex-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}
.text-success {
  color: #00c7c7 !important;
}
.edit-list {
  display: flex;
  padding: 5px 10px;
  background: #f5f5f5;
  margin-bottom: 5px;
  border-radius: 4px;
}
.edit-list .file-right {
  margin-left: auto;
}
.edit-list .file-left {
  margin-right: 20px;
  text-align: left;
}
.file-item button {
  border: none;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db5440;
  color: #fff;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}

.file-item button:hover {
  background: #cc4e3c;
}

.file-item button:active {
  background: #cc4e3c;
}
.file-item .material-icons {
  line-height: 1;
  font-size: 16px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}

.delete-btn {
  border-radius: 4px;
  background: #db5440;
  border: none;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}

.delete-btn button:hover {
  background: #cc4e3c;
}
.delete-btn button:active {
  background: #cc4e3c;
}
.delete-btn span {
  font-size: 18px;
}
.step1 p {
  line-height: 1.5;
}
.step1 li {
  line-height: 1.5;
}
.custom-radio .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #00c7c7;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #00c7c7;
  border: 1px solid #00c7c7;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #00c7c7;
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: none;
}

.custom-control-input:checked ~ .custom-control-label.green::before {
  background-color: #00c7c7;
}

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fff;
}
</style>