<template>
  <div class="questions inside-banner mobile-top">

    <collaborate-tour v-if="$store.state.tour & !loading" />

    <banner-4>
      <input
        id="collaborate-step-1"
        class="top-search mt0"
        type="text"
        placeholder="Search All Posts..."
        v-model="search"
        @keyup.enter.prevent="searchPosts"
        @click="searchReset()"
        style="padding: 15px;"
      />
      <div class="search-btns">
        <div class="right-side dflex">
          <button
            v-if="search.length && searchStarted == false"
            @click.prevent="searchPosts"
            class="search"
          >
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>
          <button v-else class="search" disabled>
            <span class="material-icons">search</span>
            {{ " " }} Search
          </button>

          <!--
          <button
            v-if="search.length"
            @click="clearSearch"
            class="clear"
            style="margin-left: 10px"
          >
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
          <button v-else disabled class="clear" style="margin-left: 10px">
            <span class="material-icons">find_replace</span>
            {{ " " }} Clear
          </button>
          -->
        </div>
      </div>
    </banner-4>

    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-xs-12 d-lg-block">
          <div
            class="card-block mt-3 bs-shadow"
            style="margin-bottom: 0!important;"
          >
            <h5 id="collaborate-step-2" class="dflex">
              <i class="material-icons">category</i> Filter by Category
            </h5>
            <ul class="flexwrap pl0">
              <li v-for="cat in forumCats" v-bind:key="cat.id" class="mktBtns">
                <!-- condition ? exprIfTrue : exprIfFalse -->
                <!-- <button class="btn-primary" :class="{active:cat.id == selectedCat}" @click="selectedCat = cat.id">{{cat.name}}</button> -->
                <button
                  class="btn-primary"
                  :class="{ active: cat.id == selectedCat }"
                  @click="catSwitcher(cat.id)"
                >
                  {{ cat.name }}
                </button>
                <!--
            <button @click="changeMarket(cat.id)" class="btn-primary">{{cat.name}}</button>
                -->
              </li>
            </ul>
          </div>
        </div>

        <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12 col-lg-8">
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !filteredQuestions.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-8 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-8 mb20">
          <!-- new Post -->

          <div
            class="question-header d-flex align-items-center p-3 mt1r mb5 rounded bs-shadow"
          >
            <!--
            {{"Filtered Page length: " + filteredQuestions.length / 25}}
            -->

            <div class="w100">
              <div class="top-wrap">
                <div class="dflex mb10" v-if="firstName && lastName">
                  <avatar
                    v-once
                    class="mr5"
                    color="#fff"
                    v-bind:username="firstName + ' ' + lastName"
                    v-bind:backgroundColor="
                      colorGetter[
                        Math.floor(Math.random() * colorGetter.length)
                      ]
                    "
                  ></avatar>
                  <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                  -->
                  <div class="dflex vcenter">
                    <div>
                      <h6 class="mb0">
                        <b>{{ firstName }} {{ lastName }}</b>
                      </h6>
                      <p class="lh1 mb0" style="max-width: 150px">
                        <small>{{ company }}</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mlauto dflex vcenter">
                  <p class="lh1 mb0"></p>
                </div>
              </div>

              <form class="w100">
                <div class="form-group">
                  <input
                    id="collaborate-step-3"
                    type="text"
                    class="form-control"
                    v-model="postSubject"
                    placeholder="Have a topic to share?"
                  />
                </div>
                <div class="form-group" v-if="postSubject.length">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="postContent"
                    placeholder="What's your question?"
                  ></textarea>
                </div>

                <div v-if="postSubject.length" class="form-group">
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    v-model="postInsertCat"
                  >
                    <option :value="null">Select a category</option>
                    <option
                      v-for="cat in postforumCats"
                      :key="cat.id"
                      :value="cat.id"
                    >
                      {{ cat.name }}
                    </option>
                  </select>
                </div>

                <button
                  v-if="!postContent.length && !postSubject.length"
                  class="post-button"
                  disabled
                  style="cursor: default"
                >
                  Post
                </button>
                <button
                  v-else-if="postInsertCat == null || !postContent.length"
                  disabled
                  class="post-button"
                  style="background: #dedede; cursor: default"
                >
                  Select a Category
                </button>
                <button
                  v-else
                  @click.prevent="postNewQuestion()"
                  class="post-button"
                  style="cursor: pointer"
                >
                  Post
                </button>
              </form>
            </div>
          </div>

          <!-- end new post -->
          <div
            class="question-header d-flex align-items-center p-3 mt1r mb5 rounded bs-shadow"
          >
            <!--
            {{"Filtered Page length: " + filteredQuestions.length / 25}}
            -->

            <div class="d-flex number-showing">
              <p class="mb0">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredQuestions.length / 25) }}
              </p>
            </div>

            <div class="pag-wrapper mlauto">
              <div id="collaborate-step-4" class="nav-btns">
                <button v-if="this.currPage === 1" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-else @click.prevent="setPage(currPage - 1)">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button
                  v-if="
                    this.currPage === this.totalPage ||
                    this.currPage ==
                      Math.ceil(this.filteredQuestions.length / 25)
                  "
                  disabled
                >
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>

                <button v-else @click.prevent="setPage(currPage + 1)">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              v-bind:key="question.Tid"
              v-for="(question, index) in filteredQuestions.slice(
                pageStart,
                pageStart + countOfPage
              )"
            >
              <!--
          <div v-for="question in questions" :key="question.Tid">
              -->
              <div class="question bs-shadow mt1r mb5">
                <div class="top-wrap">
                  <div class="dflex mb10">
                    <avatar
                      class="mr5"
                      color="#fff"
                      v-bind:username="
                        question.FirstName + ' ' + question.LastName
                      "
                      v-bind:backgroundColor="
                        colorGetter[
                          Math.floor(Math.random() * colorGetter.length)
                        ]
                      "
                    ></avatar>
                    <!--
                  {{colorGetter[Math.floor(Math.random() * colorGetter.length)]}}
                    -->
                    <div class="dflex vcenter">
                      <div>
                        <h6 class="mb0">
                          <b>
                            {{ question.FirstName + " " + question.LastName }}
                          </b>
                        </h6>
                        <p class="lh1 mb0" style="max-width: 150px">
                          <small>{{ question.Name }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mlauto">
                    <div class="dflex vcenter">
                      <new-feature-tag
                        v-if="
                          $store.state.newPostCount > 0 &&
                          $moment(question.Posted).isAfter(ifNewDate)
                        "
                      />
                      <!--
                      <span
                        v-if="
                          $store.state.newPostCount > 0 &&
                          moment(question.Posted).isAfter(ifNewDate)
                        "
                        class="material-icons"
                        >fiber_new</span
                      >
                      -->
                      <i v-else class="material-icons mr2 fs20">schedule</i>
                      <p v-if="question.Posted" class="lh1 mb0">
                        {{
                          $moment(question.Posted)
                            .subtract(1, "minutes")
                            .fromNow()
                        }}
                      </p>
                      <p v-else class="lh1 mb0">Unknown date</p>
                    </div>

                    <div class="mlauto dflex">
                      <p v-if="question.replies == 1" class="mlauto dflex"></p>
                      <p
                        v-else
                        class="mlauto dflex vcenter"
                        style="line-height: 1; margin-top: 10px"
                      >
                        <span
                          class="badge badge-light"
                          style="margin-left: 5px"
                          >{{ question.replies - 1 }}</span
                        >

                        <small
                          v-if="question.replies == 2"
                          style="margin-left: 5px"
                          >Comment</small
                        >
                        <small v-else style="margin-left: 5px">Comments</small>
                      </p>
                    </div>
                  </div>
                </div>

                <h5 class="dflex mb20" v-html="question.Subject"></h5>
                <div class="question-bottom dflex">
                  <p class="mb0 lh1 mrauto">Category: {{ question.Fname }}</p>

                  <router-link
                    :id="index === 0 ? 'collaborate-step-5' : ''"
                    :to="/questions/ + question.Tid"
                    class="mb0 lh1 mlauto list-btn"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 1px 1px 1px 6px;
                    "
                    >View Post
                    <i class="material-icons"
                      >keyboard_arrow_right</i
                    ></router-link
                  >

                  <!--
                <p class="mb0 lh1 mlauto">Replies: {{question.replies}}</p>
                  -->
                </div>
              </div>
              <!-- card -->
            </div>

            <!-- bottom pagination -->
            <div
              v-if="filteredQuestions.length >= 4"
              class="question-header d-flex align-items-center p-3 mb1r mt1r rounded bs-shadow"
            >
              <div class="d-flex number-showing">
                <p class="mb0">
                  Page {{ currPage }} of
                  {{ Math.ceil(this.filteredQuestions.length / 25) }}
                </p>
              </div>

              <div class="pag-wrapper mlauto">
                <div class="nav-btns">
                  <button v-if="this.currPage === 1" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-else @click.prevent="setPage(currPage - 1)">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-if="
                      this.currPage === this.totalPage ||
                      this.currPage ==
                        Math.ceil(this.filteredQuestions.length / 25)
                    "
                    disabled
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button v-else @click.prevent="setPage(currPage + 1)">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>
              </div>
            </div>
            <!-- end bottom pagination -->
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "../components/Atoms/Avatar.vue";
import CollaborateTour from "../components/Tours/CollaborateTour.vue";
import Banner4 from "../components/Banners/banner4.vue";
import NewFeatureTag from "../components/Atoms/NewFeatureTag.vue";
//import moment from "moment";
//var now = moment();
//var mom = momentFromServer < now ? now : momentFromServer;
//mom.fromNow();

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer thisisanexampleofunauthorized"
  },
};

export default {
  name: "Questions",
  components: {
    AtomSpinner,
    Avatar,
    CollaborateTour,
    Banner4,
    NewFeatureTag,
  },
  data() {
    return {
      server: import.meta.env.VITE_APP_SERVER_URL,
      search: "",
      questions: [],
      postSubject: "",
      postContent: "",
      selectedCat: 0,
      postInsertCat: null,
      loading: true,
      countOfPage: 25,
      currPage: 1,
      noQuestionFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Sales" },
        { id: 2, name: "Products" },
        { id: 3, name: "Configura" },
        { id: 4, name: "Competitors" },
        { id: 5, name: "Other" },
        { id: 6, name: "Installation" },
        { id: 7, name: "Announcements" },
        { id: 14, name: "Museum" },
      ],
      postforumCats: [
        { id: 1, name: "Sales" },
        { id: 2, name: "Products" },
        { id: 3, name: "Configura" },
        { id: 4, name: "Competitors" },
        { id: 5, name: "Other" },
        { id: 6, name: "Installation" },
        { id: 7, name: "Announcements" },
        { id: 14, name: "Museum" },
      ],
      userFirst: "",
      userLast: "",
      spacesaverToken: localStorage.getItem("SSCUSER"),
      userIP: null,
      searchStarted: false,
      fullName:
        this.$store.state.user.FirstName +
        " " +
        this.$store.state.user.LastName,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "collaborate");
      this.setFirstLast();

      const token = localStorage.getItem("SSCUSER");

      if (!token) {
        console.warn("JWT token not available yet.");
        return;
      }

      const CREDENTIALS = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      // get the users IP address
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.userIP = ip;
        });

      this.collaborateSync(CREDENTIALS);
    },
    decodeHtml: function (html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },

    collaborateSync(CREDENTIALS) {
      this.loading = true;

      if (!this.$store.state.collaborate.questions.length) {
        axios
          .get(`${import.meta.env.VITE_APP_SERVER_URL}/api/v1/questions`, CREDENTIALS)
          .then((response) => {
            console.log(response);
            this.questions = response.data;
            this.$store.commit("UPDATE_COLLABORATE", this.questions);
            this.loading = false; // Set loading to false on success
          })
          .catch((error) => {
            console.error("Error fetching questions:", error);
            this.loading = false; // Set loading to false on error
          });
      } else {
        this.questions = this.$store.state.collaborate.questions;
        this.loading = false; // Handle case where questions are already loaded
      }
    },

    postNewQuestion: function () {
      var thePostDate = new Date();
      thePostDate.setMinutes(thePostDate.getMinutes() - 1);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        UserIp: this.userIP,
        Subject: this.postSubject,
        Message: this.postContent,
        EditedBy: this.UName,
        fullName: this.fullName,
        Topic: this.postInsertCat,
        UserId: this.UseridNumber,
        Date: thePostDate,
      };
      return axios
        .post(`${this.server}/api/v1/questions`, postData, axiosConfig)
        .then(
          (response) => {
            if (response.status === 401) {
              window.location = "/logout";
            } else {
              this.$toast.success("Successfully added your post!", { hideProgressBar: false, icon: false });
              // this.$noty.success("Successfully added your post!");
              this.questions = response.data;
              this.$store.commit("UPDATE_COLLABORATE", this.questions);
              this.postSubject = "";
              this.postContent = "";
              this.selectedCat = 0;
              this.$forceUpdate();
            }
          },
          (error) => {
            this.$noty.error("There seems to be an issue posting your content");
            this.$router.push("/");
          }
        );
    },

    searchReset: function () {
      this.selectedCat = 0;
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catID) {
      let category = catID;
      this.searchStarted = false;
      this.search = "";
      if (category !== 0) {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = 0;
      }
    },
    catSelector: function (catID) {
      this.postInsertCat = catID;
    },
    setFirstLast() {
      this.userFirst = this.firstName;
      this.userLast = this.lastName;
    },
    clearSearch: function () {
      this.searchStarted = false;
      this.search = "";
    },
    searchPosts: function () {
      this.selectedCat = 0;
      this.searchStarted = true;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },
    //the main v-for loop for the questions
    filteredQuestions: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "" && this.searchStarted == true) {
        return this.questions.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.Subject.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== 0 && this.searchStarted == false) {
        this.search = "";
        //console.log(this.selectedCat);
        return this.questions.filter((f) => {
          return f.Fid == this.selectedCat;
        });
      } else {
        //if the search box is not filled in
        return this.questions;
      }
    },

    foundNoQuestion: function () {
      if (this.search.length > 1 && this.filteredQuestions.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },
    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },
    totalPage: function () {
      return Math.ceil(this.questions.length / this.countOfPage);
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    company() {
      return this.$store.state.user.Company;
    },
    userToken() {
      return this.$store.state.token;
    },
    ifNewDate() {
      return this.$store.state.user.LastActivity;
    },
    UseridNumber() {
      return this.$store.state.user.UserId;
    },
    UName() {
      return this.$store.state.user.UserName;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },
  mounted() {
    this.firstLoad();
    this.$store.commit("SET_VIEWED_COLLABORATE");
    //this.userFirst = this.firstName();
    //this.userLast = this.lastName();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.question {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
  border-radius: 4px;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.post-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #429da8;
  color: #fff;
  border-radius: 4px;
  margin-left: auto;
  width: 100%;
  padding: 5px;
}
.badge-light {
  color: #ffffff !important;
  background-color: #575757 !important;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
  height: 40px;
}
</style>
