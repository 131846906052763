<template>
    <section class="pt-5 pb-3 bg9 bs-shadow">
      <div id="collaborate-step-1" class="container">
          <slot></slot>
      </div>
    </section>
</template>


<script>
// used in collaborate
  export default {
        name: 'Banner4',
        //props: ['title', 'desc'],
  }
</script>


<style scoped>
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.bg9 {
	background-color: #00b4b4;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%2300bfbf' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%2300c9c9' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%2300d4d4' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%2300dfdf' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%2300eaea' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%2300f4f4' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%2300ffff' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E");
	background-attachment: fixed;
	background-size: cover;
}
</style>