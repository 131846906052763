<template>
       <footer
          v-if="navigationState == true"
          class="footer-bs noprint"
          style="
            min-height: 300px;
            background-size: cover;
            background-position: right;
            background-repeat: no-repeat;
            background: #fff;
          "
        >
          <div class="container">
            <div class="row">
              <div class="col col-12 col-lg-6 footer-social animated fadeIn">
                <h3>Tools & Resources</h3>
                <div class="row">
                  <div class="col col-6 col-md-6 footer-social animated fadeIn">
                    <ul class="pages">
                      <li>
                        <img v-if="this.$store.state.isDashboard" class="icons" style="max-width: 20px;
                    margin-right: 5px;" src="@/assets/img/window-line.svg"/>
                        <a v-if="this.$store.state.isDashboard" @click="setLayout('website')" style="cursor:pointer;" class="layout-a">                   
                          Classic Layout
                        </a>

                        <img v-if="!this.$store.state.isDashboard" class="icons" style="max-width: 20px;
                    margin-right: 5px;" src="@/assets/img/layout-masonry-line.svg"/>
                        <a v-if="!this.$store.state.isDashboard" @click="setLayout('dashboard')" style="cursor:pointer;" class="layout-a">                        
                          Dashboard Layout
                        </a>

                        <!--
                        <img class="footer-icon" src="@/assets/img/layout-masonry-line.svg"/>
                        <a href="/home">Classic Layout</a>
                        -->
                      </li>
                      <li>
                        <img class="footer-icon" src="@/assets/img/profile-line.svg"/>
                        <a href="/home">Home</a>
                      </li>
                      <li v-if="!this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/funds-box-line.svg"/> 
                        <a href="/listings">Order Listings</a>
                      </li>
                       <li v-if="!this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/numbers-line.svg"/> 
                        <a href="/reports">Reports</a>
                      </li>                
                       <li v-if="!this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/newspaper-line.svg"/> 
                        <a href="/lead-times">Lead Times</a>
                      </li>
                       <li v-if="!this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/swap-box-line.svg"/> 
                        <a href="/interterritorial">IT Projects</a>
                      </li>

                       <li v-if="this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/article-line.svg"/> 
                        <a href="/announcements">Announcements</a>
                      </li>                
                       <li v-if="this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/book-read-line.svg"/> 
                        <a href="/from-the-fort">From The Fort</a>
                      </li>
                       <li v-if="this.$store.getters.hideOrders"> 
                       <img class="footer-icon" src="@/assets/img/bill-line.svg"/> 
                        <a href="/interterritorial">Trailblazer</a>
                      </li>

                      <!--
                      <li>
                        <span class="material-icons"> question_answer </span>
                        <a href="/collaborate">Ask a Question</a>
                      </li>
                      <li v-if="!this.$store.getters.hideOrders">
                        <span class="material-icons"> article </span>
                        <a href="/expedited-orders">Expedited Orders</a>
                      </li>
                      <li v-if="!this.$store.getters.hideOrders">
                        <span class="material-icons"> point_of_sale </span>
                        <a href="/interterritorial/add">Create an IT Project</a>
                      </li>
                      -->
                    </ul>
                  </div>
                  <div class="col col-6 col-md-6 footer-social animated fadeIn">
                    <ul class="pages">
                      <li>
                        
                        <img class="footer-icon" src="@/assets/img/polaroid-2-line.svg"/> 
                        <a href="/marketing-materials">Marketing Materials</a>
                      </li>

                      <li>
                        <img class="footer-icon" src="@/assets/img/archive-drawer-line.svg"/> 
                        <a href="/service-and-installation"
                          >Service &amp; Installation</a
                        >
                      </li>
                      <li>
                        <img class="footer-icon" src="@/assets/img/honour-line.svg"/> 
                        <a href="/sales-and-training">Sales &amp; Training</a>
                      </li>
                      <li>
                        <img class="footer-icon" src="@/assets/img/question-answer-line.svg"/> 
                        <a href="/collaborate">Collaborate</a>
                      </li>
                      <li>
                        <img class="footer-icon" src="@/assets/img/global-line.svg"/> 
                        <a href="/ac-list">AC Locator</a>
                      </li>
                      <li v-if="!this.$store.getters.hideOrders">
                        <img class="footer-icon" src="@/assets/img/article-line.svg"/>
                        <a href="/from-the-fort">From The Fort</a>
                      </li>
<!--
                      <li>
                        <span class="material-icons">dynamic_feed</span>
                        <a href="/trailblazer">Trailblazer</a>
                      </li>
                      
                      -->
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col col-6 col-md-6 col-lg-3 footer-social animated fadeIn">
                <h3>Links</h3>
                <ul class="pages">
                  <li>
                    <img class="footer-icon" src="@/assets/img/window-line.svg"/>
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://www.spacesaver.com"
                      >Spacesaver.com</a
                    >
                  </li>

                  <li>
                    
                    <img class="footer-icon" src="@/assets/img/calendar-event-line.svg"/>
                    <a href="https://tradeshow.spacesaver.com"
                    target="_blank"
                      >Tradeshow Requests</a
                    >
                  </li>
                  <li>
                    <img class="footer-icon" src="@/assets/img/shield-check-line.svg"/>
                    <a
                      target="blank"
                      rel="noopener noreferrer"
                      href="https://chemmanagement.ehs.com/9/647ea530-bca3-414b-afb7-3287444d464e/ebinder/?nas=True"
                      >MSDS</a
                    >
                  </li>

                  <li>
                    <span
                      ><img
                        class="config-icon2"
                        src="@/assets/img/configura-dashboard.svg"
                    /></span>
                    <a
                      href="https://spacenet3.s3.amazonaws.com/files/ExtConfigura.zip"
                      target="_blank"
                      rel="noopener"
                      download
                      >Configura Download</a
                    >
                  </li>

                  <li>
                    <img class="footer-icon" src="@/assets/img/feedback-line.svg"/>
                    <a
                      href="mailto:spacenet3feedback@spacesaver.com?subject=SpaceNET 3 Support"
                      >SpaceNET 3 Feedback</a
                    >
                  </li>

                  <li>
                    <img class="footer-icon" src="@/assets/img/store-3-line.svg"/>
                    <a @click="SSCstoreLink" style="cursor: pointer">
                      Spacesaver Store
                    </a>
                  </li>
                  

                  <!--
                <li>
                  <form
                    style="margin-bottom: 0"
                    target="_blank"
                    method="post"
                    action="https://www.delzer.com/spacesaver/sso.asp"
                  >
                    <input
                      id="FirstName"
                      name="FirstName"
                      :value="firstName"
                      type="hidden"
                    />
                    <input
                      id="LastName"
                      name="LastName"
                      :value="lastName"
                      type="hidden"
                    />
                    <input
                      id="Email"
                      name="Email"
                      :value="email"
                      type="hidden"
                    />
                    <button type="submit" class="storeBtn d-flex">
                      <i class="material-icons">store</i> Spacesaver Store
                    </button>
                  </form>
                </li>
                --></ul>
              </div>

              <div class="col col-6 col-md-6 col-lg-3 footer-social animated fadeIn">
                <h3>Connect</h3>
                <ul class="pages">
                  <li>
                    <a
                      href="https://www.facebook.com/SpacesaverCorporation?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211093447"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/facebook-box-line.svg"/>
                    <!--
                      <i class="fab fa-facebook-square"></i>--> Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/spacesaver?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211111802"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/twitter-line.svg"/>
                    <!--
                      <i class="fab fa-twitter-square"></i>--> Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/spacesavercorp/?ss_uuid=ngy2y2fj-ea-5205164&ss_tmidx=1570211203545"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/instagram-line.svg"/>
                    <!--
                      <i class="fab fa-instagram"></i>--> Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHCWdnxP_QUcwAAAW2X3UnIF4XrPtAON3R9A6hA7CjHPfV0jZGifaH7nFR9LWPnaTVb3rGXagr0-c8rGUshhvE4ra5U5TjGJbrC0YWHsq445CgOOOole1Nss1E6bDgUq1s1ntU=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F56990%3Ftrk%3Dtyah%26ss_uuid%3Dngy2y2fj-ea-5205164%26ss_tmidx%3D1570211044945"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/linkedin-box-line.svg"/>
                    <!--
                      <i class="fab fa-linkedin"></i>--> Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/SpacesaverCorp"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/youtube-line.svg"/>
                   <!--   <i class="fab fa-youtube"></i> -->Youtube
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/spacesaver/"
                      target="_blank"
                      rel="noopener"
                    >
                    <img class="footer-icon" src="@/assets/img/pinterest-line.svg"/>
                    <!--
                      <i class="fab fa-pinterest"></i>--> Pinterest
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <hr style="border-top: solid 1px rgba(255, 255, 255, 0.1)" />
            <div class="text-center">
              <ul class="ls-none">
                <li>
                  <small class="terms">
                    <a
                      href="https://spacesaver.widen.net/view/pdf/jnqqq9qjk4/spacesaver-terms-and-conditions.pdf?t.download=true&u=sphzbh"
                      target="_blank"
                      rel="nooopener noreferrer"
                      >Order Terms &amp; Conditions</a
                    >
                  </small>
                </li>
                <li>
                  <small class="terms">
                    <a
                      href="https://spacesaver.widen.net/view/pdf/ookgx9pq3c/spacesaver-statement-of-warranty.pdf?t.download=true&u=sphzbh"
                      target="_blank"
                      rel="nooopener noreferrer"
                      >Statement of Warranty</a
                    >
                  </small>
                </li>
                <li>
                  <small class="terms">
                    <a
                      href="https://spacesaver.widen.net/view/pdf/7qmnznhs7r/spacesaver-return-goods-policy.pdf?t.download=true&u=sphzbh"
                      target="_blank"
                      rel="nooopener noreferrer"
                      >Return Goods Policy</a
                    >
                  </small>
                </li>
              </ul>
            </div>

            <div class="text-center copyright">
              <p>
                <small>
                  Copyright © {{ getyear }} Spacesaver Corporation, All Rights
                  Reserved
                </small>
              </p>
            </div>
          </div>
          <!-- test comment-->
        </footer>
</template>

<script>
export default {
  name: "FooterNew",
  components: {

  },
  data() {
    return {
      prevHeight: 0,
    };
  },
  methods: {
    SSCstoreLink() {
      let url = 'https://portal.mypropago.com/Security/Spacesaver?companyGuid=ae007963-e6dc-47cc-92c5-898fc8add77a&email=' + this.email + '&username=' + this.userName + '&fName=' + this.firstName + '&lName=' + this.lastName
      window.open(url , '_blank');
    },
    setLayout(layout) {
      localStorage.removeItem("SSCview");
      localStorage.setItem("SSCview", layout);
      this.$store.commit("SET_LAYOUT", layout);
      console.log('Switched');
      //this.$forceUpdate();
      this.$router.go(0);
    },
  },
  computed: {
    navigationState() {
      return this.$store.state.nav;
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    userName(){
      return this.$store.state.user.UserName
    },
    email() {
      return this.$store.state.user.Email;
    },
    getyear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped>
.config-icon2{
    max-width: 19px;
    margin-right: 0px;
}
.footer-bs .footer-social a {
  color: #575757;
}
.terms a:hover, .terms a:active {
    color: #575757;
    text-decoration: none;
}
.footer-social a:hover, .footer-social a:active{
  color: #b5b5b5;
}
.layout-a:hover, .layout-a:active{
  color: #b5b5b5!important;
}
.footer-icon{
    max-width: 20px;
    margin-right: 5px;

}
.ls-none {
    list-style-type: none;
    padding-left: 0;
}
.ls-none li {
    display: inline-block;
    margin-right: 20px;
    border-right: 1px solid hsla(0, 0%, 100%, .2);
    padding-right: 20px;
    line-height: 1;
}
.terms a, footer h3 {
    color: #b5b5b5;
}


</style>