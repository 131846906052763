<template>
    <header>External Layout</header>
    <main>
      <router-view />
    </main>

</template>

<script>
export default {
    name: "ExternalLayout",
}
</script>