<template>
  <div>
    <div>
      <Carousel
        :settings="settings"
        class="carousel"
        @update:currentSlide="onSlideChange"
        ref="carousel"
      >
        <Slide v-for="(slide, index) in slides" :key="index">
          <a
            v-if="slide.ACF.external_link"
            :href="slide.ACF.external_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              :src="slide.ACF.slider_image.sizes.large"
              :alt="slide.slug"
              class="img-fluid"
              style="object-fit: fill;"
            />
          </a>
          <router-link
            v-else
            :to="`/announcement/${slide.slug}`"
            style="width: 100%;"
          >
            <img
              :src="slide.ACF.slider_image.sizes.large"
              :alt="slide.slug"
              class="img-fluid"
              style="object-fit: fill;"
            />
          </router-link>
        </Slide>
        <template #addons>
          <navigation />
          <!-- Uncomment if pagination is needed -->
          <!-- <pagination /> -->
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "NewCarousel",
  props: ["slides"],
  data() {
    return {
      settings: {
        itemsToShow: 1,
        autoplay: true, // Enable autoplay
        autoplayTimeout: 3000, // Set autoplay interval (milliseconds)
        pauseAutoplayOnHover: false, // Disable pause on hover to test autoplay
        wrapAround: true, // Loop slides
        transition: 500, // Transition duration in milliseconds
      },
      intervalId: null,
    };
  },
  methods: {
    onSlideChange(currentSlideIndex) {
      console.log(`Transitioning to slide index: ${currentSlideIndex}`);
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
  startAutoplay() {
    this.intervalId = setInterval(() => {
      const totalSlides = this.slides.length; // Total number of slides
      const currentSlide = this.$refs.carousel.data.currentSlide.value; // Current active slide index
      
      // Check if the current slide is the last one
      if (currentSlide === totalSlides - 1) {
        // Reset to the first slide
        this.$refs.carousel.slideTo(0);
      } else {
        // Move to the next slide
        this.$refs.carousel.next();
      }

      // console.log(this.$refs.carousel.data.currentSlide.value);
      // console.log(`Current slide: ${this.$refs.carousel.data.currentSlide.value}`);
    }, 3000);
  },
},
mounted() {
  // Start autoplay when the component is mounted
  this.startAutoplay();
},
beforeUnmount() {
    // Clear the interval to prevent memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null; // Reset the interval ID
    }
  },
}
</script>

<style scoped>
.carousel-slide {
  width: 100% !important; /* Ensure each slide takes the full width of the carousel */
  height: auto; /* Adjust height dynamically to maintain aspect ratio */
  position: relative; /* Prevent absolute positioning issues */
  margin: 0 !important; /* Ensure there's no unwanted margin between slides */
  padding: 0 !important;
}

.carousel__slide a {
  width: 100%;
  height: 100%;
}
.carousel__slide img {
  object-fit: cover; /* Ensure the image covers the slide area without distortion */
  width: 100%;
  height: 100%;
}
.carousel__track {
  margin-bottom: 0;
}
.carousel {
  overflow: hidden; /* Prevents horizontal overflow */
}

.carousel__slide img {
  object-fit: cover; /* Ensure images are properly scaled on smaller screens */
}

@media (max-width: 768px) {
  .carousel__slide img {
    object-fit: cover; /* Ensure images are properly scaled on smaller screens */
  }
}
</style>
