import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import moment from 'moment';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueNoty from 'vuejs-noty'
import VueClipboard from 'vue3-clipboard'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ToastPlugin from 'vue-toast-notification';
import JsonCSV from 'vue-json-csv'
import CKEditor from '@ckeditor/ckeditor5-vue';
import { install } from "vue3-recaptcha-v2";
import VueDatePicker from '@vuepic/vue-datepicker';
import Vue3Tour from 'vue3-tour';
import '@vuepic/vue-datepicker/dist/main.css';
import * as Sentry from "@sentry/vue";



import 'vue3-tour/dist/vue3-tour.css'

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

// import { useNoty } from 'vuejs-noty';


const app = createApp(App).use(install, {
    sitekey: "6Lfd0S0aAAAAAG5QikWeyx_7v6eNKg7zHrFoY0Tq",
    cnDomains: false, // Optional, If you use in China, set this value true
  });
/*
.use(install, {
    sitekey: "6Lfd0S0aAAAAAG5QikWeyx_7v6eNKg7zHrFoY0Tq",
    cnDomains: false, // Optional, If you use in China, set this value true
  })
  .mount("#app");
// const { noty } = useNoty();
*/

// const { noty } = useNoty();

// .component("avatar", Avatar)

app.use(router)
app.use(store)
app.config.globalProperties.$moment = moment;
app.use(VueSweetalert2);
app.use( CKEditor );

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});

app.use(ToastPlugin, {
    // One of the options
    position: 'top-right',
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true,
});

app.use(Vue3Tour);

Sentry.init({
  app,
  dsn: "https://3414fffc56e74876a55b2eed31155f4d@o556644.ingest.sentry.io/5687918",  // Replace with your actual DSN
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // Adjust sampling rate for performance monitoring
});

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('downloadCsv', JsonCSV);
app.component('VueDatePicker', VueDatePicker);

app.config.compilerOptions.isCustomElement = (tag) => tag === 'sodipodi:namedview';

// app.use(VueNoty, { timeout: 4000 });
// app.use(VueNoty)
// app.config.globalProperties.$noty = noty;

app.mount('#app')
