<template>
 <vue-avatar />
</template>

<script>
import VueAvatar from "@webzlodimir/vue-avatar";
import "@webzlodimir/vue-avatar/dist/style.css";

  export default {
    name: 'Avatar',
    props: ['title'],
    components: {
        VueAvatar,
    },
  }
</script>


<style scoped>

</style>