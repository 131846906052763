<template>
  <div class="question bs-shadow mt5 mb5">
    <div class="top-wrap">
      <div class="dflex mb10">
        <avatar
          v-once
          class="mr5"
          color="#fff"
          v-bind:username="FirstName + ' ' + LastName"
          v-bind:backgroundColor="
            colorGetter[Math.floor(Math.random() * colorGetter.length)]
          "
        ></avatar>
        <div class="dflex vcenter">
          <div>
            <h6 class="mb0">
              <b>
                {{ FirstName + " " + LastName }}
              </b>
            </h6>
            <p class="lh1 mb0" style="max-width: 200px">
              <small>{{ company }}</small>
            </p>
          </div>
        </div>
      </div>
      <div class="mlauto">
        <div class="dflex vcenter">
          <new-feature-tag v-if="
            $store.state.newPostCount > 0 &&
            $moment(Posted).isAfter(ifNewDate)
          "/>
                        <!--
          <span
            v-if="
              $store.state.newPostCount > 0 &&
              $moment(Posted).isAfter(ifNewDate)
            "
            class="material-icons"
            >fiber_new</span
          >-->
          <i v-else class="material-icons mr2 fs20">schedule</i>
          <p v-if="Posted" class="lh1 mb0">
            {{ $moment(Posted).subtract(1, "minutes").fromNow() }}
          </p>
          <p v-else class="lh1 mb0">Unknown date</p>
        </div>

        <div class="mlauto dflex">
          <p v-if="replies == 1" class="mlauto dflex"></p>
          <p
            v-else
            class="mlauto dflex vcenter"
            style="line-height: 1; margin-top: 10px"
          >
            <span class="badge badge-light" style="margin-left: 5px">{{
              replies - 1
            }}</span>

            <small v-if="replies == 2" style="margin-left: 5px"
              >Comment</small
            >
            <small v-else style="margin-left: 5px">Comments</small>
          </p>
        </div>
      </div>
    </div>

    <h5 class="dflex mb10" v-html="Subject"></h5>
    <div class="question-bottom dflex">
      <p class="mb0 lh1 mrauto">Category: {{ Fname }}</p>

      <router-link
        :to="/questions/ + Tid"
        class="mb0 lh1 mlauto list-btn"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1px 1px 1px 6px;
        "
        >View Post
        <i class="material-icons">keyboard_arrow_right</i></router-link
      >
    </div>
  </div>
  <!-- card -->
</template>
<script>
// import Avatar from "vue-avatar";
import NewFeatureTag from '../components/Atoms/NewFeatureTag.vue';
import moment from 'moment';
import Avatar from '../components/Atoms/Avatar.vue'

export default {
  name: "HomeQuestion",
  components: {
      // Avatar,
      NewFeatureTag,
      Avatar,
  },
  props: ['FirstName', 'LastName', 'company','Posted','Fname','Subject','Tid','replies','Fid'],
  data() {
    return {
     
    };
},
computed: {
    colorGetter() {
      return this.$store.state.colors;
    },
    ifNewDate() {
      return this.$store.state.user.LastActivity;
    },
    isNew() {
      return moment(Posted).isAfter(ifNewDate)
    }
}
}
</script>
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.question {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
  border-radius: 4px;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.question-bottom {
  align-items: flex-end;
}
.question-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.post-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #429da8;
  color: #fff;
  border-radius: 4px;
  margin-left: auto;
  width: 100%;
  padding: 5px;
}
.badge-light {
  color: #ffffff !important;
  background-color: #575757 !important;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.right-side {
  text-align: right;
  flex: 1;
  height: 40px;
}
.mt5{
    margin-top: 5px;
}
</style>