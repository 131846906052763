<template>
  <div>
    <div id="mobileScrollTarget"></div>
    <div id="app" v-cloak>

      <!--
      <div v-if="navigationState == true">
        <AutoLogout />
      </div>  
    -->    
      

      <!--
      <component :is="layoutComponent" />
      -->
      <component :is="layout" />
      


    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import MainNavbar from "./components/MainNavbar.vue";
// import MainNavbarDEV from "@/components/MainNavbarDEV";
// import AutoLogout from "./components/AutoLogout.vue";
import DashboardFooter from './components/Footers/DashboardFooter.vue';
import DashboardLayout from "./components/layouts/DashboardLayout.vue";
// import WebLayout from './components/layouts/WebLayout.vue';
// import { useRoute } from 'vue-router';

import ExternalLayout from './components/layouts/ExternalLayout.vue'
import LoginLayout from './components/layouts/LoginLayout.vue';
import DefaultLayout from './components/layouts/DefaultLayout.vue';
// import Avatar from "vue3-avatar";



export default {
  name: "AppDashboard",
  components: {
    MainNavbar,
    // MainNavbarDEV,
    // AutoLogout,
    DashboardFooter,
    DashboardLayout,


    ExternalLayout,
    DefaultLayout,
    LoginLayout
    // Avatar
    // Avatar,
  },
  data() {
    return {
      prevHeight: 0,
    };
  },
  methods: {
    setLayout(){     
      localStorage.removeItem("SSCLayout");
      localStorage.setItem("SSCview", "website");
      this.$store.commit('SET_LAYOUT', 'website');
      this.$forceUpdate();
      this.$router.go(0);
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    SSCstoreLink() {
      let url = 'https://portal.mypropago.com/Security/Spacesaver?companyGuid=ae007963-e6dc-47cc-92c5-898fc8add77a&email=' + this.email + '&username=' + this.userName + '&fName=' + this.firstName + '&lName=' + this.lastName
      window.open(url , '_blank');
    },
     colorGetter() {
      return this.$store.state.colors;
    },
  },
  computed: {
    
    /*
    layoutComponent() {
      if (this.$store.state.isDashboard && this.$store.state.nav) {
        return DashboardLayout
      } else {
        return WebLayout;
      }     
    },
    */
    layout() {
      const route = this.$route; // Access the current route
      const layoutName = route.meta.layout || 'default'; // Use 'default' layout if none is specified

      // Map layout names to components
      const layouts = {
        default: 'DefaultLayout',
        login: 'LoginLayout',
        external: 'ExternalLayout',
      };

      return layouts[layoutName] || layouts.default; // Return the layout component
    },
    /*
    layout() {
      const route = useRoute();
      const layoutName = route.meta && route.meta.layout ? route.meta.layout : 'default';
      return {
        login: LoginLayout,
        default: DashboardLayout,
        external: ExternalLayout
      }[layoutName];   
    },
    */
    getyear() {
      return new Date().getFullYear();
    },
    cart() {
      return this.$store.state.cart;
    },
    images() {
      return this.$store.state.images;
    },
    videos() {
      return this.$store.state.videos;
    },
    pdfs() {
      return this.$store.state.pdfs;
    },
    navigationState() {
      return this.$store.state.nav;
    },
    firstName() {
      return this.$store.state.user.FirstName;
    },
    lastName() {
      return this.$store.state.user.LastName;
    },
    userName(){
      return this.$store.state.user.UserName
    },
    email() {
      return this.$store.state.user.Email;
    },
    activity() {
      return this.$store.state.idleVue.isIdle;
    },
  },

  mounted() {
    if (this.$store.state.isLoggedIn) {
      this.$store.commit("SET_EO_COUNT");
      // this.$store.commit("SET_NEW_POST_COUNT");
      //this.$store.commit("GET_LAUNCH_GAME");
      /*
			this.$store.watch(
				state => state.idleVue.isIdle,
				(value) => {
					if (value) { console.log(value) }
				}
			)
			*/
    }
  },
  watch: {
    /*
        activity(newValue) {
            alert(`yes, computed property changed: ${newValue}`);
        }
		*/
  },
};
</script>

<style scoped>

/*@import "/css/main.css";*/


[v-cloak] {
  display: none;
}

.pages i {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages button {
  color: #b5b5b5;
  list-style-type: none;
  padding: 0px;
  --webkit-appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 300 !important;
  transition: color 0.5s ease;
}
.pages button:hover {
  text-decoration: none;
  color: #fff;
}
.pages button:active {
  text-decoration: none;
  color: #fff;
}
.pages button:hover i {
  text-decoration: none !important;
}
.pages button:active i {
  text-decoration: none !important;
}

.pages span {
  color: #b5b5b5;
  margin-right: 5px;
}
.pages a {
  transition: color 0.5s ease;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.pages a:hover {
  color: #fff;
  text-decoration: none;
}
.pages a:active {
  color: #fff;
  text-decoration: none;
}
.table-striped a:visited {
  color: #4d5967 !important;
}
footer h3 {
  color: #b5b5b5;
}
.terms a {
  color: #b5b5b5;
  transition: color 0.5s ease;
}
.terms a:hover {
  color: #fff;
  text-decoration: none;
}
.terms a:active {
  color: #b5b5b5;
  text-decoration: none;
}
.ls-none {
  list-style-type: none;
  padding-left: 0;
}
.ls-none li {
  display: inline-block;
  margin-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 20px;
  line-height: 1;
}
.ls-none li:last-child {
  border-right: none;
}
@media all and (max-width: 767px) {
  .ls-none li:nth-child(2) {
    border-right: none;
  }
}

@media all and (max-width: 552px) {
  .ls-none li {
    display: block;
    margin-right: 0px;
    border-right: none;
    margin-left: 0;
    padding-right: 0px;
    padding-left: 0;
    margin-bottom: 10px;
  }
}
/*
.ls-none li span {
    border-radius: 50%;
    border: 1px dashed black;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    background-color: #fff;
    display: inline-block;
    position: relative;
    text-align: center;
}

.ls-none li:before {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 15px;
    background: #000;
    z-index: -1;
}

.ls-none li:last-child:before {
    display: none;
}
*/
/*
 * CKEditor 5 (v28.0.0) content styles.
 * Generated on Mon, 07 Jun 2021 06:33:15 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-html-embed/theme/htmlembed.css */
.ck-content .raw-html-embed {
  margin: 1em auto;
  min-width: 15em;
  font-style: normal;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  --webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
  .noprint {
    visibility: hidden;
  }
}

/* start sidebar */
header{
  z-index: 1;
  position: fixed;
  width: calc(100% - 0%);
  top: 0;
  height: 30px;
}

.left_area h3{
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 900;
}

.left_area span{
  color: #19B3D3;
}

.logout_btn{
  padding: 5px;
  background: #19B3D3;
  text-decoration: none;
  float: right;
  margin-top: -30px;
  margin-right: 40px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  transition: 0.5s;
  transition-property: background;
}

.logout_btn:hover{
  background: #0B87A6;
}

.sidebar{
  z-index: .5;
  top: 0;
  background: #fff;
  margin-top: 70px;
  padding-top: 10px;
  position: fixed;
  left: 0;
  width: 250px;
  height: 100%;
  transition: 0.5s;
  transition-property: left;
  overflow-y: auto;
  font-weight: 300;
}

.profile_info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar .profile_info .profile_image{
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-bottom: 10px;
}

.sidebar .profile_info h4{
  color: #575757;
  margin-top: 0;
  margin-bottom: 20px;
}

.sidebar a{
  color: #575757;
  display: block;
  width: 100%;
  line-height: 50px;
  text-decoration: none;
  padding-left: 40px;
  box-sizing: border-box;
  transition: 0.5s;
  transition-property: background;
}

.sidebar a:hover{
  background: #ebf0f3;
}

.sidebar i{
  margin-right: 10px;
}

label #sidebar_btn{
  z-index: 1;
  color: #fff;
  position: fixed;
  cursor: pointer;
  left: 300px;
  font-size: 20px;
  margin: 5px 0;
  transition: 0.5s;
  transition-property: color;
}

label #sidebar_btn:hover{
  color: #19B3D3;
}

#check:checked ~ .sidebar{
  left: -185px;
}

#check:checked ~ .sidebar a span{
  display: none;
}

#check:checked ~ .sidebar a{
  font-size: 20px;
  margin-left: 165px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content{
    /*
  width: calc(100% - 250px);
  margin-top: 60px;
  */
  padding: 20px;
  margin-left: 250px;
  transition: 0.3s;
  background: #e7edf2;
}

#check:checked ~ .content{
  margin-left: 60px;
}

#check:checked ~ .sidebar .profile_info{
  display: none;
}

#check{
  display: none;
}

.mobile_nav{
  display: none;
}

/* Responsive CSS */

@media screen and (max-width: 780px){
  .sidebar{
    display: none;
  }

  #sidebar_btn{
    display: none;
  }

  .content{
    margin-left: 0;
    margin-top: 0;
    padding: 10px 20px;
    transition: 0s;
  }

  #check:checked ~ .content{
    margin-left: 0;
  }

  .mobile_nav{
    display: block;
    width: calc(100% - 0%);
  }

  .nav_bar{
    background: #222;
    width: calc(100% - 0px);
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .nav_bar .mobile_profile_image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .nav_bar .nav_btn{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    transition: 0.5s;
    transition-property: color;
  }

  .nav_bar .nav_btn:hover{
    color: #19B3D3;
  }

  .mobile_nav_items{
    background: #2F323A;
    display: none;
  }

  .mobile_nav_items a{
    color: #fff;
    display: block;
    text-align: center;
    letter-spacing: 1px;
    line-height: 60px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
  }

  .mobile_nav_items a:hover{
    background: #19B3D3;
  }

  .mobile_nav_items i{
    padding-right: 10px;
  }

  .active{
    display: block;
  }
}

.footer-bs .footer-social a {
    color: #575757;
}
.pages span {
    color: #575757;
    margin-right: 5px;
}

</style>
