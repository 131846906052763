<template>
    <div v-if="loading" class="banner d-flex align-items-start p-3 my-3 rounded bs-shadow">
    <ul class="flexwrap ml-0" style="margin-left: 0; list-style-type: none; padding: 0; margin-bottom: 0!important;">
      <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
        <button
          class="btn-primary"
          :class="{ active: file.search == fileType }"
        >
          {{ file.title }}
        </button>
      </li>
      <li v-for="file in Types" :key="file.title" class="mktBtns">
        <button
          class="btn-primary"
          :class="{ active: file.search == catType }"
        >
          {{ file.title }}
        </button>
      </li>
    </ul>
  </div>
  <div v-else class="banner d-flex align-items-start p-3 my-3 rounded bs-shadow">
    <ul class="flexwrap ml-0" style="margin-left: 0; list-style-type: none; padding: 0; margin-bottom: 0!important;">
      <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
        <button
          class="btn-primary"
          :class="{ active: file.search == fileType }"
          @click="changeFile(file.search, file.title)"
        >
          {{ file.title }}
        </button>
      </li>
      <li v-for="file in Types" :key="file.title" class="mktBtns">
        <button
          class="btn-primary"
          :class="{ active: file.search == catType }"
          @click="changeCatType(file.search, file.title)"
        >
          {{ file.title }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MediaTopControls",
  props: {
    loading: Boolean,
    file: Object,
    fileType: String,
    catType: String,
    fileTypes: Array,
    Types: Array,
    changeFile: {
        type: Function,
    },
    changeCatType: {
        type: Function,
    }
    }
}
</script>

<style scoped>
button:disabled {
    /*
    background: #dedede!important;
    */
    color: #dedede!important;
    border: 2px solid #dedede!important;
   
    background: transparent;

    cursor: default;
}
</style>
