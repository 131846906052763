<template>
  <div class="page-listings">
   
    <gsa-tour v-if="$store.state.tour & !loading && filteredGSAs.length" />
    
    <transition name="menu-popover" v-if="PDFload === true">
      <section class="lightbox">
        <div class="flex-box-wrap pt150 pb150">
          <div class="flex-internal">
            <atom-spinner
              :animation-duration="1000"
              :size="40"
              :color="'#fff'"
            />
            <p class="text-white">Loading PDF file......</p>
          </div>
        </div>
      </section>
    </transition>

    <transition name="menu-popover" v-if="PDFfile != null || PDFError == true">
      <div v-if="PDFfile != null || PDFError == true" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div v-if="PDFError == true" class="pdfError">
          <div>
            <img src="@/assets/img/sad.svg" style="border: none" />
            <h5 class="text-white">
              Sorry, this invoice either isn't available,
              <br />or hasn't been printed yet.
            </h5>
          </div>
        </div>
        <embed
          v-else
          width="90%"
          height="100%"
          title="If you are having issues viewing this PDF, your browser security settings are set to download PDFs instead of viewing in the browser. You can also try using Chrome instead of Edge."
          :src="'data:application/pdf;base64,' + this.PDFfile"
        />
      </div>
    </transition>

    <section
      v-if="loading || !gsas"
      data-v-5a90ec03
      class="pt-5 pb-3 bg9 bs-shadow mobile-top"
      style="min-height: 260px"
    >
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Loading GSAs....</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>
    <section v-else class="pt-5 pb-3 bg9 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">

        <div class="input-wrap">
        <input
          id="GSA-step-1"
          class="top-search mt0"
          type="text"
          placeholder="Search for GSA by order number, invoice number, or job name"
          v-model="searchValue"
          @keyup.enter.prevent="searchQuery"
          style="padding:15px;"
        />
        <button
            :class="[showButton ? 'clear-btn' : 'clear-hide']"
            @click="clearSearch"
            type="button"
            class="d-flex align-items-center pr-3 clear-btn"
          >
            <svg
              class="w-4 h-4 text-[#429da8] hover:text-[#1E3040]"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
              ></path>
            </svg>
          </button>
        </div>


        <div class="search-btns">
          <div class="left-side">
            <div id="GSA-step-2" class="ac-select">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                v-model="acFilter"
              >
                <option :value="null" selected class="all-contractors">
                  Area Contractor
                </option>
                <option v-for="ac in acs" :key="ac.acid" :value="ac.acid">
                  {{ " " + ac.acName + " (" + ac.acid + ")" }}
                </option>
                <!--
				>{{ " " + ac.acName + " (" + ac.acid + ")" }}</option>
                -->
              </select>
            </div>

            <div class="dates" id="dates" style="margin-right: 14px;"> 
              <VueDatePicker v-model="startDate" 
              input-class-name="dp-custom-input"
              :enable-time-picker="false" 
              format="MMM dd yyyy"
                placeholder="From Date"
                wrapperClass="pickthedate"
                :typeable="true"
                style="padding-right: 10px;"
                ></VueDatePicker>

                <VueDatePicker v-model="endDate" 
                id="GSA-step-3"
              input-class-name="dp-custom-input"
              :enable-time-picker="false" 
              format="MMM dd yyyy"
                placeholder="To Date"
                wrapperClass="pickthedate"
                :typeable="true"
                ></VueDatePicker>

                <!--
              <datepicker
                format="MMM dd yyyy"
                placeholder="From Date"
                v-model="startDate"
                wrapperClass="pickthedate"
                :typeable="true"
              ></datepicker>
              <datepicker
                id="GSA-step-3"
                format="MMM dd yyyy"
                placeholder="To Date"
                v-model="endDate"
                wrapperClass="pickthedate"
                :typeable="true"
              ></datepicker>
                -->
            </div>
          </div>

          <div class="right-side">
            <button
              v-if="
                (searchValue || startDate || endDate || acFilter || searchStarted) &&
                !searchStarted
              "
              @click.prevent="searchQuery"
              class="search"
            >
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <button v-else class="search" disabled>
              <span class="material-icons">search</span>
              {{ " " }} Search
            </button>
            <!--
            <button
              v-if="searchValue || startDate || endDate || acFilter"
              @click="clearSearch"
              class="clear"
            >
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
            <button v-else disabled class="clear">
              <span class="material-icons">find_replace</span>
              {{ " " }} Clear
            </button>
              -->
          </div>
        </div>

        <!--
        <div class="date-area">
          <vc-calendar mode="range" v-model="attributes" :max-date="new Date()" is-inline />
        </div>
        -->
      </div>
    </section>
    <!--

		<section v-else class="py-5 bg9 bs-shadow mobile-top">
			<div class="container">
				<input
					class="top-search bs-large mt0"
					type="text"
					placeholder="Search GSAs by order number, invoice number, or job name"
					v-model="search"
					@click="searchReset()"
				/>
			</div>
		</section>
    -->
    <div :class="loading == false && !filteredGSAs.length ? 'message-bg' : ''">
      <div class="container">
        <div class="row">
          <div
            v-if="loading == true"
            class="col-xs-12 col-sm-12 col-12 col-lg-12"
          >
            <div class="flex-box-wrap">
              <div class="flex-internal">
                <atom-spinner
                  :animation-duration="1000"
                  :size="40"
                  :color="'#575757'"
                />
                <p>Loading......</p>
              </div>
            </div>
          </div>

          <div
            v-else-if="loading == false && !filteredGSAs.length"
            class="col-xs-12 col-sm-12 col-12 col-lg-12 flexVHcenter"
          >
            <div>
              <h2 style="margin: 30px 20px; color: #429da8; font-weight: 400">
                It looks like there are no GSA orders to show...
              </h2>
              <img src="@/assets/img/noData.svg" style="width: 100%" />
            </div>
          </div>

          <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
            <div
              class="
                gsa-header
                d-flex
                align-items-center
                p-3
                mt-3
                mb10
                rounded
                box-shadow
              "
            >
              <p class="mrauto mbo">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredGSAs.length / 100) }}
              </p>
              <div class="pag-wrapper mlauto">
                <div id="GSA-step-4" class="nav-btns">
                  <button v-if="this.currPage === 1" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-else @click.prevent="setPage(currPage - 1)">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-if="
                      this.currPage === this.totalPage ||
                      this.currPage == Math.ceil(this.filteredGSAs.length / 100)
                    "
                    disabled
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button v-else @click.prevent="setPage(currPage + 1)">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>

                <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              --></div>
            </div>

            <div>
              <div class="listing">
                <h2>GSAs</h2>

                <!--
          <div v-for="gsa in gsas" :key="gsa.Tid">
              -->
                <div class="gsa table-responsive">
                  <table
                    class="table-striped table-bordered"
                    style="display: table; min-width: 100%"
                  >
                    <thead>
                      <tr>
                        <th
                          @click="sortCol('acName')"
                          class="table-link twenty"
                          :class="columnSort == 'acName' ? 'column-active' : ''"
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>AC </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'acName'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('invoiceNumber')"
                          class="table-link ten"
                          :class="
                            columnSort == 'invoiceNumber' ? 'column-active' : ''
                          "
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Invoice </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'invoiceNumber'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('invoiceAmount')"
                          class="table-link ten"
                          :class="
                            columnSort == 'invoiceAmount' ? 'column-active' : ''
                          "
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Total </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'invoiceAmount'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('invoiceDate')"
                          class="table-link ten"
                          :class="
                            columnSort == 'invoiceDate' ? 'column-active' : ''
                          "
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Date </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'invoiceDate'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('orderNo')"
                          class="table-link ten"
                          :class="
                            columnSort == 'orderNo' ? 'column-active' : ''
                          "
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Order </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'orderNo'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>
                        <th
                          @click="sortCol('projName')"
                          class="table-link twenty"
                          :class="
                            columnSort == 'projName' ? 'column-active' : ''
                          "
                          scope="col"
                        >
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Name </span>
                            <span class="ml-auto">
                              <i
                                :class="
                                  columnSort == 'projName'
                                    ? 'carrot-icon-active'
                                    : ''
                                "
                                class="material-icons carrot-icon"
                              >
                                filter_list
                              </i>
                            </span>
                          </span>
                        </th>

                        <th class="ten" scope="col">
                          <span style="display: flex; flex: 0 0 100%">
                            <span>Paid </span>
                          </span>
                        </th>

                        <th scope="col" class="ten">Commision</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-bind:key="gsa.OrderNumber"
                        v-for="(gsa, index) in filteredGSAs.slice(
                          pageStart,
                          pageStart + countOfPage
                        )"
                      >
                        <td>{{ gsa.acName }}</td>
                        <td>
                          <a
                            :id="index === 0 ? 'GSA-step-5' : ''"
                            class="pdfButton"
                            @click="getPDF(gsa.fullInvoiceNumber)"
                          >
                            {{ gsa.invoiceNumber }}</a
                          >
                        </td>
                        <td>{{ formatPrice(gsa.invoiceAmount) }}</td>
                        <td v-if="gsa.invoiceDate">
                          {{ $moment(gsa.invoiceDate).format("MM-DD-YYYY") }}
                        </td>
                        <td v-else></td>
                        <td>{{ gsa.orderNo }}</td>
                        <td>{{ gsa.projName }}</td>
                        <td v-if="gsa.paidDate">
                          {{ $moment(gsa.paidDate).format("MM-DD-YYYY") }}
                        </td>
                        <td v-else>Open</td>

                        <td style="text-align: center">
                          <a
                            :href="gsa.commissionURL"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span class="material-icons document-link"
                              >description</span
                            >
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- card -->
              </div>
            </div>

            <div
              class="
                gsa-header
                d-flex
                align-items-center
                p-3
                mb-3
                mt10
                rounded
                box-shadow
              "
            >
              <p class="mrauto mbo">
                Page {{ currPage }} of
                {{ Math.ceil(this.filteredGSAs.length / 100) }}
              </p>
              <div class="pag-wrapper mlauto">
                <div class="nav-btns">
                  <button v-if="this.currPage === 1" disabled>
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button v-else @click.prevent="setPage(currPage - 1)">
                    <i class="material-icons">keyboard_arrow_left</i> Back
                  </button>

                  <button
                    v-if="
                      this.currPage === this.totalPage ||
                      this.currPage == Math.ceil(this.filteredGSAs.length / 100)
                    "
                    disabled
                  >
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>

                  <button v-else @click.prevent="setPage(currPage + 1)">
                    Next
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
                </div>

                <!--
          <p class="mtauto" v-if="search.length">Results are being filtered</p>
          <p class="mtauto" v-else>Page {{currPage}} of {{ totalPage }} </p>
          <div class="nav-btns">

              <button v-if="this.currPage === 1" disabled><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-else @click.prevent="setPage(currPage-1)"><i class="material-icons">keyboard_arrow_left</i> Back</button>

              <button v-if="this.currPage === this.totalPage" disabled>Next <i class="material-icons">keyboard_arrow_right</i></button>

              <button v-else @click.prevent="setPage(currPage+1)">Next <i class="material-icons">keyboard_arrow_right</i></button>

          </div>
              --></div>
            </div>
          </div>
          <!-- end other column -->
        </div>
      </div>
      <!-- container -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// import Datepicker from "vuejs-datepicker";
import gsaTour from "../components/Tours/gsaTour.vue";
import { formatCurrency } from '../utils/utils';

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "GSAs",
  components: {
    AtomSpinner,
    VueDatePicker,
    // Datepicker,
    gsaTour,
  },
  data() {
    return {
      ordermanageapi: import.meta.env.VITE_APP_ORDER_MANAGE_API,
      backend: import.meta.env.VITE_APP_SERVER_URL,
      searchValue: "",
      search: "",
      storedListings: [],
      searchRender: false,
      startDate: null,
      endDate: null,
      searchStarted: false,
      acs: [],
      gsas: [],
      selectedCat: "Show All",
      acFilter: null,
      searching: false,
      loading: true,
      countOfPage: 100,
      currPage: 1,
      noGSAFound: false,
      forumCats: [
        { id: 0, name: "Show All" },
        { id: 1, name: "Configura" },
        { id: 2, name: "Marketing" },
        { id: 3, name: "Other" },
        { id: 4, name: "Process" },
        { id: 5, name: "Product" },
        { id: 6, name: "Spacenet" },
      ],
      PDFload: false,
      PDFfile: null,
      PDFError: false,
      colSort: false,
      columnSort: "",
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.searchValue = "";
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      this.searchStarted = false;
      this.$store.commit("setActivePage", "order-manage");
      this.acListSync();
      //return axios.get(this.endPointCompute, auth).then((response) => {
      return axios.get(`${this.ordermanageapi}/gsa/`, auth).then((response) => {
        //console.log(response);
        this.gsas = response.data;
        this.loading = false;
      });
    },
    formatPrice(amount) {
      return formatCurrency(amount);
    },

    acListSync: function () {
      //this.loading = true;

      if (!this.$store.state.acList.acList.length) {
        //console.log("getting acList");
        return axios
          .get(`${this.ordermanageapi}/aclist?active=true`, auth)
          .then((response) => {
            //console.log(response);
            const data = response.data;
            /*

            const low = data.filter(function (e) {
              return e.acid < 210;
            });

            const mid = data.filter(function (e) {
              return e.acid == 80069;
            });

            const high = data.filter(function (e) {
              return e.acid > 800000 && e.acid < 800250;
            });

            const all = low.concat(high).concat(mid);
            const alph = all.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            */
            const alph = data.sort((a, b) => (a.acName > b.acName ? 1 : -1));
            this.acs = alph;
            //this.loading = false;
            this.$store.commit("UPDATE_ACLIST", this.acs);
          });
      } else {
        //console.log("vuex has length");
        this.acs = this.$store.state.acList.acList;
        //this.loading = false;
      }
    },

    searchReset: function () {
      this.selectedCat = "Show All";
    },

    setPage: function (idx) {
      this.search = "";
      if (idx <= 0 || idx > this.totalPage) {
        return;
      }
      this.currPage = idx;
    },

    catSwitcher: function (catType) {
      //console.log(catType);
      let category = catType;
      //console.log(category);
      if (category !== "Show All") {
        this.selectedCat = category;
        this.search = "";
        //console.log(category);
      } else {
        this.selectedCat = "Show All";
      }
    },
    searchQuery(event) {
      this.searchStarted = true;
      if (this.searchStarted === true) {
        this.storedListings = this.gsas;
      }

      this.search = "";
      this.loading = true;
      this.searching = true;

      //console.log(event);

      //console.log(`${this.ordermanageapi}/orders?search=${this.searchValue}`);
      /*
			return axios;
				.get(`${this.ordermanageapi}/order?search=${this.searchValue}`, auth)
				.then((response) => {*/
      return axios.get(this.searchEndpointCompute, auth).then((response) => {
        this.gsas = response.data;
        //console.log(response);
        this.loading = false;
        this.searching = false;
      });
    },
    clearSearch() {
      if ((this.searchStarted == true)) {
        this.gsas = this.storedListings;
        this.searchStarted = false;
      }

      //this.listings = this.storedListings;

      this.searchValue = "";
      this.searchRender = !this.searchRender;
      this.startDate = null;
      this.acFilter = null;
      this.endDate = null;
      // this.$forceUpdate();

      //this.$forceUpdate();
      //Vue.set(this.listings, this.storedListings);
    },

    toUpperCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
      });
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getPDF: function (id) {
      let PDFID = id;
      this.PDFload = true;
      return axios
        .get(`${this.ordermanageapi}/pdf?type=Invoice&id=${PDFID}`, auth)
        .then((response) => {
          //console.log(response);
          if (response.data.pdf.length) {
            this.PDFfile = response.data.pdf[0].pdfblog;
          } else {
            this.PDFError = true;
          }
          this.PDFload = false;
        });
    },
    closeLightbox: function () {
      this.PDFfile = null;
      this.PDFload = false;
      this.PDFError = false;
    },
    sortCol: function (type) {
      this.columnSort = type;
      const COLUMN = type;
      console.log(COLUMN);
      if (this.colSort) {
        this.filteredGSAs.sort((a, b) => (a[COLUMN] > b[COLUMN] ? 1 : -1));
      } else {
        this.filteredGSAs.sort((a, b) => (a[COLUMN] < b[COLUMN] ? 1 : -1));
      }
      this.colSort = !this.colSort;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },
    showButton() {
      if (this.searchValue.trim().length > 0 || this.acFilter || this.startDate || this.endDate) {
        // console.log('true')
        return true;
      } else {
        // console.log('false')
        return false;
      }
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    filteredGSAs: function () {
      //set the current page to 0
      this.currPage = 1;
      //if the search box is filled in
      if (this.search.trim() !== "") {
        return this.gsas.filter((e) => {
          //filter the subject line by what is filled in the search box
          return (
            e.OrderNumber.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      } else if (this.selectedCat !== "Show All") {
        this.search = "";
        //console.log(this.selectedCat);
        return this.gsas.filter((f) => {
          return f.Type == this.selectedCat;
        });
      } else {
        return this.gsas;
      }
    },

    foundNoGSA: function () {
      if (this.search.length > 1 && this.filteredGSAs.length === 0) {
        //this.noUserFound = true;
        return true;
      }
    },

    pageStart: function () {
      return (this.currPage - 1) * this.countOfPage;
    },

    totalPage: function () {
      return Math.ceil(this.gsas.length / this.countOfPage);
    },
    listingRestore: function () {
      if (
        !this.searchValue &&
        !this.loading &&
        !this.searching &&
        this.storedListings.length >= this.listings
      ) {
        //console.log("fired off");
        this.listings = this.storedListings;
      }
      return;
    },
    searchEndpointCompute: function () {
      //has order number, no start, no end, no ac
      if (
        this.searchValue.length &&
        !this.startDate &&
        !this.endDate &&
        !this.acFilter
      ) {
   
        console.log('1')
        return `${this.ordermanageapi}/gsa?search=${this.searchValue.trim()}`;
      } else if (
        //has order number, and start, no end, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        console.log('2')
        return `${this.ordermanageapi}/gsa?search=${
          this.searchValue.trim()
        }&start=${this.formatDate(this.startDate)}`;
      } else if (
        //has order number, and end, no start, no ac
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        console.log('3')
        return `${this.ordermanageapi}/gsa?search=${
          this.searchValue.trim()
        }&end=${this.formatDate(this.endDate)}`;
      } else if (
        // has three, no ac
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter === null &&
        this.endDate != null
      ) {
        console.log('4')
        return `${this.ordermanageapi}/gsa?search=${
          this.searchValue.trim()
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has end
        !this.searchValue &&
        this.startDate === null &&
        this.endDate != null &&
        this.acFilter === null
      ) {
        console.log('5')
        return `${this.ordermanageapi}/gsa?end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //only has start
        !this.searchValue &&
        this.startDate != null &&
        this.endDate === null &&
        this.acFilter === null
      ) {
        console.log('6')
        return `${this.ordermanageapi}/gsa?start=${this.formatDate(
          this.startDate
        )}`;
      } else if (
        //has start and end
        !this.searchValue &&
        this.startDate != null &&
        this.endDate != null
      ) {
        console.log('7')
        return `${this.ordermanageapi}/gsa?start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}`;
      } else if (
        //has all 4
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        console.log('8')
        return `${this.ordermanageapi}/gsa?ac=${this.acFilter}&start=${this.formatDate(
          this.startDate
        )}&end=${this.formatDate(this.endDate)}&search=${
          this.searchValue.trim()
        }`;
      } else if (
        //has only ac
        !this.searchValue &&
        this.startDate === null &&
        this.endDate === null &&
        this.acFilter != null
      ) {
        console.log('9')
        return `${this.ordermanageapi}/gsa?ac=${this.acFilter}`;
      } else if (
        //has ac and start
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        console.log('10')
        return `${this.ordermanageapi}/gsa?start=${this.formatDate(
          this.startDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and end
        !this.searchValue &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        console.log('11')
        return `${this.ordermanageapi}/gsa?end=${this.formatDate(
          this.endDate
        )}&ac=${this.acFilter}`;
      } else if (
        //has ac and number
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        console.log('12')
        return `${this.ordermanageapi}/gsa?search=${this.searchValue.trim()}&ac=${this.acFilter}`;
      } else if (
        //has ac and start and end
        !this.searchValue &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        console.log('13')
        return `${this.ordermanageapi}/gsa?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&end=${this.formatDate(
          this.endDate
        )}`;
      } else if (
        //has ac, search and start
        this.searchValue.length &&
        this.startDate != null &&
        this.acFilter != null &&
        this.endDate === null
      ) {
        console.log('14')
        return `${this.ordermanageapi}/gsa?ac=${
          this.acFilter
        }&start=${this.formatDate(this.startDate)}&?search=${this.searchValue.trim()}`;
      } else if (
        //has ac, search and end
        this.searchValue.length &&
        this.startDate === null &&
        this.acFilter != null &&
        this.endDate != null
      ) {
        console.log('15')
        return `${this.ordermanageapi}/gsa?ac=${
          this.acFilter
        }&end=${this.formatDate(this.startDate)}&search=${this.searchValue.trim()}`;
      } else {
        console.log('16')
        return `${this.ordermanageapi}/gsa`;
      }
    },
  },
  created() {
    this.$store.commit("showNavigation");
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media all and (min-width: 992px) {
  .flex-box-wrap {
    height: 200px;
    padding: 150px 0 125px 0;
    margin: 0;
  }
}

@media all and (max-width: 991px) {
  .flex-box-wrap {
    height: 100px;
    padding: 75px 0 50px 0;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.listing {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.gsa-bottom {
  align-items: flex-end;
}
.gsa-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: scroll;
}

.table-responsive {
  display: table;
  margin: 0;
  padding: 0;
  min-width: 100%;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
  line-height: 1;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
  padding: 23px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.search-btns .clear {
  margin-right: 0;
  margin-left: auto;
  padding: 23px 15px;
}
.search-btns .search {
  /*margin-left: auto;*/
  margin-right: 0;
  margin-left: auto;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.pdfButton {
  cursor: pointer;
}
@media all and (max-width: 991px) {
  /*
  .listing {
    overflow: scroll;
  }
  */
  table {
    width: 900px !important;
    overflow: scroll;
  }
}
.document-link {
  color: #429da8;
  cursor: pointer;
}
.document-link:hover {
  color: #00b4b4;
  cursor: pointer;
}
.document-link:active {
  color: #00b4b4;
  cursor: pointer;
}
.clear-btn {
  position: absolute;
  right: 0;
  top: 14px;
  border: none;
  background: none;
}
.clear-hide {
  display: none !important;
}
.input-wrap {
  position: relative;
  display: block;
}
</style>
